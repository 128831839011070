/* Copyright (C) 2023 Christian Miley - All Rights Reserved */

/**
 * SignMeasure.js
 * This page will be designed with the user who simply wants to sign the measure
 * in mind. For now, just downloads a 1 page signature packet, and provides instructions.
 * Someday, include abilities to organize signature gathering... gatherings... by verified users.
 */

import React, { useState, useEffect, useContext } from 'react';
import { useParams, Link, useNavigate } from "react-router-dom";
import { Container, Button, Card } from 'react-bootstrap';
import Follow from '../Follow.js';
import { getMeasure, getFullTextFile } from '../../firebase.js';
import { Header } from './ModularMeasurePage.js';
import { UserContext, PersonContext } from '../../Context.js';
//import { countiesByState } from '../Information.js';
//import { getSignatureCount } from '../Utility.js';
import { createFullPacketUrl } from '../PDFFunctions.js';
import { TooltipLink } from '../Utility.js';
import './SignMeasure.css';
import { 
    EnvelopeIcon, 
    PrinterIcon
} from '@heroicons/react/24/outline';

function SignMeasure(props) {
    const userContext = useContext(UserContext);
    const personContext = useContext(PersonContext);
    const { id } = useParams();
    const navigate = useNavigate();

    const [measure, setMeasure] = useState(undefined);
    const [url, setUrl] = useState(undefined);
    const [error, setError] = useState(undefined);

    //for request the measure through the mail
    const [requested, setRequested] = useState(false);

    /*useEffect(() => {
        if (!id || !personContext || !userContext) return;

        async function retrieveMeasure() {
            try {
                const measureDownload = await getMeasure(id);
                setMeasure(measureDownload);
                const fileDownload = await getFullTextFile(id, "letter");
                measureDownload['fullTextFile'] = fileDownload;
                setMeasure(measureDownload);
                const newUrl = await createFullPacketUrl(id, measureDownload, personContext.County, 1, fileDownload, false);
                setUrl(newUrl);
            } catch(error) {
                console.error(error);
                //something like that?
                setError(error.message);
            }
        }

        retrieveMeasure();

    }, [id, personContext, userContext]);*/

    // now with propositionsObj
    useEffect(() => {
        if (!id || !personContext || !props || !props.propositionsObj || !props.propositionsObj.ordering || !props.setPropositionsObj) return;

        //note 12/8/23: This is all kind of deprecated since I'm not doing generation... god what a waste of time lol
        const grabFile = async () => {
            try { 
                const file = await getFullTextFile(id, "letter"); 
                const newPropsObj = { ...props.propositionsObj };
                newPropsObj[id].FullTextFile = file;
                props.setPropositionsObj(newPropsObj);
                return;
            } catch (error) {
                console.error(error);
                setError(error.message);
                return;
            }
        }

        const grabMeasureAndFile = async () => {
            try {
                const measureDownload = await getMeasure(id);
                //if we're generating, grab the file too.
                if (measureDownload.rBallotServices && measureDownload.rBallotServices.includes("generation")) {
                    const file = await getFullTextFile(id, "letter");
                    measureDownload.FullTextFile = file;
                }
                const newPropsObj = { ...props.propositionsObj };
                newPropsObj[id] = measureDownload;
                props.setPropositionsObj(newPropsObj);
                return;
            } catch (error) {
                console.error(error);
                setError(error.message);
                return;
            }
        }

        const createUrl = async (id, measure, county) => {
            try {
                const newUrl = await createFullPacketUrl(id, measure, county, 1, measure.FullTextFile, false);
                setUrl(newUrl);
            } catch (error) {
                console.error(error);
                setError(error.message);
                return;;
            }
        }

        let newMeasure;
        if (props.propositionsObj[id]) {
            // everything is present!
            if ('FullTextFile' in props.propositionsObj[id]) {
                newMeasure = props.propositionsObj[id];
                setMeasure(newMeasure);
                createUrl(id, newMeasure, personContext.County);
            // grab the file only if this measure is actually generating
            } else if (props.propositionsObj[id].rBallotServices && props.propositionsObj[id].rBallotServices.includes("generation")) {
                grabFile();
                return;
            // if there's no file, but measure is not generating, we're set
            } else {
                newMeasure = props.propositionsObj[id];
                setMeasure(newMeasure);
                return;
            }
        } else {
            grabMeasureAndFile();
            return;
        }

    }, [id, props, props.propositionsObj, props.setPropositionsObj, personContext]);

    //Check for errors loading measure
    if (error === "No measure with this id exists.") return (
        <div style={{marginTop: '6em'}}><h3>Error loading measure. {error}</h3></div>
    );

    //Wait until loaded
    if (!measure) {
        return (
            <div style={{marginTop: '6em'}}>
                <h3>Loading...</h3>
            </div>
        );
    }

    //Only signed up users can use page (requires personContext)
    if (!personContext || !userContext || userContext === "Guest") {
        return (
            <Container style={{marginTop: '6em', backgroundColor: 'white', padding: '3em', borderRadius: '4px'}}>
                <div className="Background2"/>
                <div style={{textAlign: 'center'}}>
                    <Header measure={measure} />
                    <h4 style={{margin: '1em 0em'}}>You must have an account to sign a measure.</h4>
                    <Link to={"/emailsignup/signmeasure/"+id} className="SignOptionButton">Sign Up Here</Link>
                </div>
            </Container>
        )
    }

    // if not signed up yet
    if (!measure.rBallotServices || measure.rBallotServices.length === 0) {
        return (
            <Container style={{marginTop: '6em', backgroundColor: 'white', padding: '3em', borderRadius: '4px'}}>
                <div className="Background2"/>
                <div style={{textAlign: 'center'}}>
                    <Header measure={measure} />
                    {/*<p style={{marginTop: '1em', fontSize: '0.8em', color: 'gray'}}>
                        Signatures must be written in ink on physical paper, not digital.
                    </p>*/}
                    <h5 style={{margin: '1em 0em'}}>This measure is not set up on rBallot yet.</h5>
                    <p>To receive an email when this measure signs up, click follow below:</p>
                    <Follow 
                        user={userContext} 
                        id={measure.id} 
                        measure={measure}
                        following={personContext.following && personContext.following.includes(id) ? true : false}
                        // changed={changingFollow}
                        // setChanged={setChangingFollow}
                        setMeasure={(followers) => {
                            const newPropsObj = { ...props.propositionsObj };
                            newPropsObj[id] = { ...measure, Followers: followers};
                            props.setPropositionsObj(newPropsObj);
                        }}
                        buttonClass={"FollowButton"}
                    />
                </div>
            </Container>
        )
    }

    //If everything is set
    return (
        <Container className="MeasureContainer" /*style={{marginTop: '6em', maxWidth: '1000px'}}*/>
            <div className="Background2"/>
            <div className="PropositionInfoContainer">
            <Header measure={measure} />
            <div className="InformationContainer" /*style={{backgroundColor: 'white', borderRadius: '10px'}}*/>
            <div className="PropBox" style={{textAlign: 'center', paddingBottom: '1em'}}>
                {/*<Header measure={measure} />*/}
                <p style={{textAlign: 'left', padding: '0em 1em', marginTop: '1em', fontSize: '1em', color: 'black'}}>
                    You can sign this petition through the following methods:
                </p>
                {/*<h5 style={{margin: '1em 0em'}}>
                    {measure.rBallotServices && measure.rBallotServices.length !== 0 ? 
                        ("You have " + measure.rBallotServices.length + " option" + (measure.rBallotServices.length === 1 ? "" : "s") +" for signing this measure:")
                    :
                        "This measure is not set up on rBallot yet."
                    }
                </h5>*/}
                {/*<p>Signatures must be done by hand. There are two options for you to sign this petition:</p>
                <ul style={{listStylePosition: 'inside'}}>
                    <li key="1">Download and mail in yourself</li>
                    <li key="2">Request a letter from the campaign</li>
                </ul>*/}
                {/*!measure.rBallotStatus || measure.rBallotStatus === "not active" ?
                    <p style={{color: 'red', textAlign: 'justify'}}>This measure is not active on rBallot. Pick an option below, and we will keep track and notify you when the measure activates their rBallot account.</p>
                    :
                    ""
                */}
            {/*</div>
            <div className="SignContainer">*/}
                {/*measure.rBallotServices && measure.rBallotServices.includes("downloads") ?
                    <Card className="SignCard">
                        <Card.Header>Download and print a petition</Card.Header>
                        <Card.Body style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                            <Button
                                className="SignOptionButton"
                                onClick={() => {
                                    console.log("This user wants to download and mail in the petition!");
                                    navigate("/measure/" + id + "/petitions");
                                }}
                            >
                                Download
                            </Button>
                        </Card.Body>
                    </Card>
                    :
                    ""
                }
                { /*measure.rBallotServices && measure.rBallotServices.includes("generation") ?
                    <Card className="SignCard">
                        <Card.Header>Print and mail in yourself</Card.Header>
                        <Card.Body style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                            {url ?
                            <a
                                className="SignOptionButton"
                                href={url}
                                download={"rBallot" + id + "signature"}
                                onClick={() => console.log("You clicked me brother")}
                            >
                                Download
                            </a>
                            :
                            <Button disabled className="SignOptionButton">Download</Button>
                            }
                            { error ?
                                <Card.Text style={{marginTop: '1em', fontSize: '0.8em', color: 'gray'}}>
                                    This measure is not set up to sign yourself.
                                </Card.Text>
                                :
                                ""/*<Card.Text style={{marginTop: '1em', fontSize: '0.8em', color: 'gray'}}>
                                    For more options in creating your packet, {<Link to={"/measure/" + id + "/campaign"}>click here</Link>}.
                                </Card.Text>
                            }
                        </Card.Body>
                    </Card>
                    :
                    ""
                */}
                { /*measure.rBallotServices && measure.rBallotServices.includes("mailing") ?
                    <Card className="SignCard">
                        <Card.Header>Request a petition from the campaign</Card.Header>
                        {personContext.requested && personContext.requested.includes(id) ?                     
                            <Card.Body style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                                <Button
                                    disabled
                                >
                                    Request via Mail
                                </Button>
                                <Card.Text style={{marginTop: '1em', fontSize: '0.8em', color: 'gray'}}>You have already requested this petition.</Card.Text>
                            </Card.Body>
                            :
                            <Card.Body style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                                <Button
                                    className="SignOptionButton"
                                    onClick={() => {
                                        console.log("This user wants to receive the measure via the mail!");
                                        setRequested(true);
                                        navigate("/addresssignup/" + id);
                                    }}
                                    disabled={requested}
                                >
                                    Request via Mail
                                </Button>
                            </Card.Body>
                        }
                    </Card>
                    :
                    ""
                */}
                { /*measure.rBallotServices && measure.rBallotServices.includes("generation") ?
                    <Card className="SignCard">
                        <Card.Header>Gather signatures in your community</Card.Header>
                        <Card.Body style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                            <Button
                                className="SignOptionButton"
                                onClick={() => navigate("/measure/" + id + "/campaign")}
                            >
                                Volunteer
                            </Button>
                        </Card.Body>
                        {""/*<Card.Body style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backgroundColor: 'lightgray'}}>
                            <Button
                                disabled
                            >
                                Volunteer
                            </Button>
                            <Card.Text style={{marginTop: '1em', fontSize: '0.8em', color: 'gray'}}>This measure is not set up for volunteering.</Card.Text>
                        </Card.Body>
                        <Card.Footer>Most Helpful</Card.Footer>}
                    </Card>
                    :
                    ""    
                */}

                { measure.rBallotServices && measure.rBallotServices.includes("downloads") ?
                    <TooltipLink 
                        title="Print a petition"
                        id={'print'+measure.id}
                        className="ModernButton"
                        to={"/measure/"+measure.id+"/petitions"}
                    >
                        <span className="IconText" text="Print " /><PrinterIcon style={{height: '24px', width: '24px'}}/>
                    </TooltipLink>
                    :
                    ""
                }
                { measure.rBallotServices && measure.rBallotServices.includes("mailing") ?
                    <TooltipLink 
                        title="Mail me a petition"
                        id={'mail'+measure.id}
                        className="ModernButton"
                        to={"/addresssignup/"+measure.id}
                    >
                        <span className="IconText" text="Mail " /><EnvelopeIcon style={{height: '24px', width: '24px'}}/>
                    </TooltipLink>
                    :
                    ""
                }

            </div>
            </div>
            </div>
        </Container>
    );
}

export default SignMeasure;