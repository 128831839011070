/* Copyright (C) 2024 Christian Miley - All Rights Reserved */

/**
 * PendingMeasureSetUp.js
 * 2/8/24
 * "admin section of the website where pending measures show up 
 * (this could be in the form of rBallotStatus: 'pending' or something).
 * I would program the ability to interact with OpenAI's gpt and dalle 
 * capabilities there. I would have to manually click through to the 
 * links of full texts and then upload them there, since CORS will restrict
 * automatically downloading and uploading them (and some will not work anyway).
 * I'll likely need to use pdf-lib functionality to be able to cut out extraneous
 * pages of the full text to get just the text, and possibly to create multiple
 * documents stored in storage. I'll need to transform the text into markdown, 
 * and to implement a text editor capable of viewing and editing markdown 
 * (possibly the one used by StackOverflow and StackExchange more generally).
 * Then, I'll need to be able to use OpenAI's gpts to take that correct markdown 
 * text and actually create all the different information needed, 
 * with the ability to edit it to get the right thing."
 */

import React, { useState, useEffect, useContext } from 'react';
import { useParams, Link } from "react-router-dom";
import { Form, Nav, Button, ToggleButton, Spinner } from 'react-bootstrap';
import { UserContext } from '../../Context.js';
import { updateMeasure } from '../../firebase.js';
import { allStates } from '../Information.js';
import { getFunctions, httpsCallable } from "firebase/functions";
// importing pdfjs in order to convert pdfs to images before using gpt4vision
import * as pdfjsLib from 'pdfjs-dist/webpack.mjs';

// props includes propositionsObj
export default function PendingMeasureSetUp(props) {
    const { measureId } = useParams();
    const userContext = useContext(UserContext);
    /*const [measure, setMeasure] = useState();
    useEffect(() => {
        if (!props || !measureId || !props.propositionsObj) return;
        setMeasure(props.propositionsObj[measureId]);
    }, [props, measureId]);*/

    //so, I would like it to be possible to go navigate through the form.
    //this would therefore be an imperative way of ordering it to do something.
    // potential fallback: when loading begins it automatically removes the step to allow it to follow regular rules? hmm...
    // steps: landing/console, (this will allow admin to pick between making full text html, or generating content)
    // uploadTextPDF, combineHTML, editAndUploadHTML, generateAndUploadContent
    const [step, setStep] = useState('console');
    const steps = {
        console: 'Console',
        uploadTextPDF: 'Upload PDF', 
        combineHTML: 'Combine HTML',
        editAndUploadHTML: 'Upload HTML', 
        generateAndUploadContent: 'Generate content',
        adaptableUploadContent: 'Adaptable content'
    };

    //the pdf file, and the pages from it to OCR
    const [file, setFile] = useState("");
    const [pages, setPages] = useState('');
    //might want to give instructions on styling, like using <del> over a class
    const [promptOCR, setPromptOCR] = useState('Return the text in this pdf image as HTML, preserving styling.');

    const initialHTMLText = { response: "View the html for this text here" };
    // all of the OCR'd pages
    const [textsAndHTMLs, setTextsAndHTMLs] = useState([initialHTMLText]);
    // below basically holds the info for the currently selected html page...
    // I think eventually this will be removed and just a value will identify
    // which page is selected by its page number
    const [displayOptions, setDisplayOptions] = useState({});
    // this will tell the page what to display, the html or the editable response
    const [htmlOrResponseView, setHTMLOrResponseView] = useState("html");
    
    // this will hold the entire content once it is combined
    const [fullTextHTML, setFullTextHTML] = useState('');
    const [promptCombineMode, setPromptCombineMode] = useState('html');
    //"Combine the previous documents into one HTML document" may have worked better, though so did gpt-3.5-turbo over gpt-4-turbo rn
    const [promptCombine, setPromptCombine] = useState('Combine these HTML documents into one document with consistent styling.');

    //10/22/24 Here we have a variable that the user can use to choose between creating all
    //the HTML pages sequentially or in parallel (after creating the first page)
    const generationOptions = ['sequential', 'first and parallel', 'fully parallel']
    const [generationType, setGenerationType] = useState('first and parallel');

    // here we want to store the content sections that will be generated by AI
    /* these include:
    Concise, simple summary of how the measure actually changes the law
    Clear, simple descriptions of what the result from a “yes” or “no” vote on the measure would be
    Pro and con arguments for the measure
    Summaries of different sections of the text, grouped together logically
    
    Prompts used in webscraping:
        summary_prompt = 'Write a summary of the following ballot measure description in very, very simple, plain English: ' + measure_description
        yes_and_no_prompt = "Describe in very, very simple English what a yes and a no vote do for this ballot measure being described: " + measure_description
    
    Each includes: a prompt, a result/response, a label (for the form), a field name for firestore
    note: should this be in an array? Or an object of objects?
    one idea: have the labels floating in rounded bubbles that indicate whether they are selected/uploaded
    */
    const [aiContents, setAiContents] = useState({
        rBallotTitle: {
            prompt: 'Come up with an alternative title without any punctuation for this ballot measure that is descriptive, impartial, and concise: ',
            response: '',
            label: 'rBallot Title',
            fieldName: 'rBallotTitle',
            uploaded: false
        },
        rBallotOneLiner: {
            prompt: 'Come up with a descriptive, impartial, brief one-liner that uses elliptical language construction to avoid mentioning the title for the following ballot measure that will give prospective voters a good understanding of what it does: ',
            response: '',
            label: 'rBallot One-liner',
            fieldName: 'rBallotOneLiner',
            uploaded: false
        },
        rBallotYesAndNo: {
            prompt: 'Describe in concise, simple, impartial terms what a yes and a no vote mean for this ballot measure while using elliptical language construction to avoid mentioning its title: ',
            response: '',
            label: 'Yes And No Votes',
            fieldName: 'rBallotYesAndNo',
            uploaded: false
        },
        rBallotSummary: {
            prompt: 'Summarize without mentioning its title what the following ballot measure actually does in concise, simple terms for the average voter, leaving out any miscellaneous information: ',
            //'Summarize the most important information in the following ballot measure text in concise, simple terms for the average voter: ',
            response: '',
            label: 'Summary',
            fieldName: 'rBallotSummary',
            uploaded: false
        },
        rBallotProAndCon: {
            prompt: 'Write straightforward pro and con arguments using the following example Markdown structure: ```#### Pro Arguments **1. Equality and Non-Discrimination:** The amendment supports equality by ensuring that the right to marry is recognized as a fundamental right for all individuals, regardless of gender. It removes discriminatory language that excludes same-sex couples. **2. Legal Consistency:** The proposed changes bring state constitutional law into alignment with federal constitutional principles of equal protection and due process as interpreted by the Supreme Court in landmark cases like Obergefell v. Hodges. **3. Social Progress:** Updating the constitution to reflect contemporary values of inclusivity and equality represents societal progress and respects the dignity of all citizens. #### Con Arguments **1. Traditional Values:** Some may argue that the original language preserving marriage as between a man and a woman reflects long-standing traditional and cultural values that should not be altered. **2. Religious Beliefs:** Opponents might contend that redefining marriage in the state constitution challenges religious doctrines and moral beliefs held by many residents, possibly infringing on freedom of religious expression."``` for and against the following ballot measure: ',
            response: '',
            label: 'Pro and Con Arguments',
            fieldName: 'rBallotProAndCon',
            uploaded: false
        },
    });
    const [selectedContent, setSelectedContent] = useState('rBallotSummary');

    // info for measure to be manually added
    const [website, setWebsite] = useState("");
    const [fullTextLink, setFullTextLink] = useState("");
    const [impartialAnalysisLink, setImpartialAnalysisLink] = useState("");
    const [campaignName, setCampaignName] = useState("");
    const [rBallotStatus, setrBallotStatus] = useState("");
    // below for manually adapting generation and upload of content 
    const [adaptableUploadContent, setAdaptableUploadContent] = useState("");
    const [adaptableUploadPrompt, setAdaptableUploadPrompt] = useState("");
    const [adaptableFieldName, setAdaptableFieldName] = useState("");

    // this effect will load the current measure's info
    // also will mean that anytime we navigate to dashboard, we lose saved progress
    useEffect(() => {
        if (!measureId || !props || !props.propositionsObj || !props.propositionsObj[measureId]) return;
        const measure = props.propositionsObj[measureId];

        const newAIContents = { ...aiContents };
        for (const key in aiContents) {
            // set the starting response to the current one in db, if present
            if (measure[key]) {
                newAIContents[key].response = measure[key];
            } else {
                newAIContents[key].response = "";
            }
        }
        setAiContents(newAIContents);
        if (measure.FullTextHTML) {
            setFullTextHTML({ 
                response: measure.FullTextHTML, 
                html: measure.FullTextHTML,
                display: 'html',
                uploaded: true
            });
        } else {
            setFullTextHTML('');
        }
        if (measure.Website) setWebsite(measure.Website);
        if (measure.Campaign) setCampaignName(measure.Campaign);
        if (measure.FullTextLink) setFullTextLink(measure.FullTextLink);
        if (measure.ImpartialAnalysisLink) setImpartialAnalysisLink(measure.ImpartialAnalysisLink);
        if (measure.rBallotStatus) setrBallotStatus(measure.rBallotStatus);

    }, [measureId]);

    /*useEffect(() => {
        //quit if no html text yet (also can change it to markdown, even, but then all this wouldn't work)
        if (!htmlText || htmlText === initialHTMLText);

        console.log(htmlText);
        // Extract everything enclosed in <html> and </html> tags
        //const regex = /<html>[\s\S]*?<\/html>/;
        // asked chatgpt to allow the html tag to have any number of attributes
        const regex = /<html\s*[^>]*>[\s\S]*?<\/html>/;
        const justTheHTMLText = htmlText.match(regex);
        console.log(justTheHTMLText);
        //create an html element using this text for display
        //https://stackoverflow.com/questions/3103962/converting-html-string-into-dom-elements
        //there may be some issues with it creating a whole new document object as opposed to just a div
        //though, then I can just use doc.firstChild.firstChild to keep traveling down the tree...
        /*const doc = new DOMParser().parseFromString(justTheHTMLText, "text/html");
        console.log(doc);
        setHTMLElements(doc);
        setHTMLElements(justTheHTMLText);
    }, [htmlText]);*/

    // this should become a helper function
    const extractHTML = (text) => {
        const regex = /<html\s*[^>]*>[\s\S]*?<\/html>/;
        const justTheHTMLText = text.match(regex);
        //console.log(justTheHTMLText[0]);
        //no html text was included
        if (!justTheHTMLText || justTheHTMLText.length === 0) return "";
        return justTheHTMLText[0];
    }

    //8/3/24: to make it continuable, remove the final </html> tag
    /*const extractHTMLWithoutClosingTag = (text) => {
        console.log(text);
        let html = extractHTML(text);
        console.log(html);
        if (html.endsWith("</html>")){
            //</html> is 7 characters
            html = html.slice(0, -7);
        }
        if (html.startsWith("<html>")){
            html = html.slice(6);
        }
        console.log(html);
        return html;
        //const htmlWithoutClosing = html.slice(0, -7);
        //return htmlWithoutClosing;
    }*/
    //10/27/24: previous one was not how model was actually behaving
    //This one uses the code fences the model is implementing, I believe
    //because Markdown uses them, to find and cut out the html text.
    const extractHTMLBasedOnCodeFences = (text) => {
        //console.log(text);
        const regex = /```html\s*([\s\S]*?)```/; // /```html\s*(.*?)```/;
        const justTheHTMLText = text.match(regex);
        //no html text was included
        if (!justTheHTMLText || justTheHTMLText.length === 0) return "";
        console.log(justTheHTMLText[1]);
        return justTheHTMLText[1];
    }

    // 10/28/24: from ChatGPT. Will be used to remove the final HTML tag
    // from html in sequential HTML generation so that it can be filled in appropriately
    // by the next page's generation.
    function removeFinalHTMLClosingTag(html) {
        // Regular expression pattern to match the last closing HTML tag
        const pattern = /<\/([a-zA-Z][a-zA-Z0-9]*)\s*>[\s\n\r]*$/; // /<\/([a-zA-Z][a-zA-Z0-9]*)\s*>[\s\n\r]*$/;
    
        console.log("Finding final html tag:");
        console.log(html.match(pattern));

        // Replace the last closing tag with an empty string
        return html.replace(pattern, '');
    }

    // extract just the style section of the html
    const extractHTMLStyle = (text) => {
        const regex = /<style\s*[^>]*>[\s\S]*?<\/style>/;
        const justTheStyleText = text.match(regex);
        //console.log(justTheHTMLText[0]);
        //no html text was included
        if (!justTheStyleText || justTheStyleText.length === 0) return "";
        return justTheStyleText[0];
    }

    // this should eventually go live in firebase.js
    const functions = getFunctions();
    const textFromImageFile = httpsCallable(functions, 'textFromImageFile');
    const generateText = httpsCallable(functions, 'generateText');
    //gpt-4-turbo-previw has the 128K token context, but only 4096 token output!
    //gpt-4-32k has a smaller total context, but it appears it can allocate it equally between input and output
    //it is also not widely supported, and might be more expensive.
    //https://community.openai.com/t/gpt-4-128k-only-has-4096-completion-tokens/515790
    //it would likely be better to figure out how to cobble together multiple 'turbo' calls rather than using the '32k'
    const [model, setModel] = useState('gpt-4o');
    const [visionModel, setVisionModel] = useState('gpt-4o');
    const textModels = ['gpt-4o-mini', 'gpt-3.5-turbo', 'gpt-4o', 'gpt-4-turbo', 'gemini-pro'];
    const visionModels = ['gpt-4o', 'gpt-4o-mini', 'gpt-4-vision-preview', 'gemini-pro-vision'];

    // this function will use the html of each page and call generate text
    // to unify it into one complete html document
    const combinePages = async() => {
        if (!textsAndHTMLs || textsAndHTMLs.length < 1) return;
        
        // ALTERNATIVE: trying whether putting them all in one message is better.
        /*let content = 'Combine the following HTML documents into one, preserving all of the characters: ';
        // idk bout this vs regular C style for loop
        for (const index in textsAndHTMLs) {
            console.log(textsAndHTMLs[index]);
            content += textsAndHTMLs[index].html;
            content += "\t";
        }
        // now make the messages thing
        const messages = [
            {
                role: 'system',
                content: 'Your task is to read multiple HTML documents provided by the user, and then combine them together into one HTML document with all of their information in order'
            },
            {
                role: 'user',
                content: content
            }
        ];
        */

        const messages = textsAndHTMLs.map((object) => {
            console.log(object);
            //because .match returns an array of matches, my html was all in arrays. Fixed that, but adding
            //this in so I don't have to redo the OCR rn
            //if (Array.isArray(object.html) && object.html.length === 1) object.html = object.html[0];
            if (promptCombineMode === 'html') {
                return {
                    role: 'system',
                    content: object.html
                }
            } else if (promptCombineMode === 'response') {
                return {
                    role: 'assistant', // assistant role since entire response included
                    content: object.response
                }
            // this added to remove error message
            } else {
                return {};
            }
        });
        // add the prompt as the final message
        messages.push({
            role: "user",
            content: promptCombine,
        });

        console.log(messages);
        const response = await generateText({
            // prompt: promptCombine, 
            messages: messages,
            //gpt-4-turbo-previw has the 128K token context, but only 4096 token output!
            //gpt-4-32k has a smaller total context, but it appears it can allocate it equally between input and output
            //it is also not widely supported, and might be more expensive.
            //https://community.openai.com/t/gpt-4-128k-only-has-4096-completion-tokens/515790
            //it would likely be better to figure out how to cobble together multiple 'turbo' calls rather than using the '32k'
            model: model//'gpt-4-turbo-preview'
        });
        console.log(response);
        setFullTextHTML({
            response: response.data.message.content,
            html: extractHTML(response.data.message.content),
            display: 'html',
            uploaded: false
        });
    }

    // Importing file stuff from EditMeasure
    //in order to allow people to edit the measure without reuploading full text
    /*const fullTextRef = useRef(null);
    useEffect(() => {
        if (!measure) return;
        if (fullTextRef === null || fullTextRef.current === null) return;
        if (measure.rBallotStatus === "signing up") return;
        if (measure.rBallotStatus === "volunteering") {
            //check if we need to update, or if current is up to speed with fullTextFile
            if (fullTextRef.current.files[0] !== measure.fullTextFile) {
                //from https://stackoverflow.com/questions/1696877/how-to-set-a-value-to-a-file-input-in-html/70485949#70485949
                //DataTransfer is designed to hold data during a drag and drop operation, so we're basically
                //dragging and dropping the file in.
                let container = new DataTransfer();
                container.items.add(measure.fullTextFile);
                fullTextRef.current.files = container.files;
                return;
            } else {
                return;
            }
        }

    }, [fullTextRef, measure]);*/

    //a function to turn strings denoting the pages to use into an array of numbers
    //this could go to utility.js probably
    const parsePagesString = (string) => {
        //console.log(string);
        //split based on commas
        const substrings = string.split(',');
        let pageNumbers = [];
        for (let i = 0; i<substrings.length; i++){
            const substringRange = substrings[i].split('-');
            //if this is a range
            if (substringRange.length === 2) {
                let lower, higher, direction;
                if (Number(substringRange[0]) < Number(substringRange[1])){
                    lower = Number(substringRange[0]);
                    higher = Number(substringRange[1]);
                    direction = 'ascending';
                //descending
                } else {
                    lower = Number(substringRange[1]);
                    higher = Number(substringRange[0]);
                    direction = 'descending';
                }
                const difference = higher - lower;
                for (let j = 0; j<=difference; j++){
                    if (direction === 'ascending') pageNumbers.push(lower+j);
                    if (direction === 'descending') pageNumbers.push(higher-j);
                }
            //not a range, just a single value
            //well, technically it could also have any length that is not 2, but I'm assuming only 1 or 2
            } else {
                pageNumbers.push(Number(substringRange[0]));
            }
        }
        // remove 0s
        pageNumbers = pageNumbers.filter((pageNumber) => pageNumber !== 0);
        //console.log(pageNumbers);
        return pageNumbers;
    }

    // these should be displayed to user so they can check if they've picked the right pages
    // in this scenario, they would be created whenever the user changes the pages... which
    // could be really annoying if it updated instantly every time
    const [imageUrls, setImageUrls] = useState([]);
    //https://medium.com/@charanvinaynarni/pdf-to-image-conversion-using-reactjs-fd250a25bf05#:~:text=Step%204
    const renderPage = async (data, document, pageNumbers) => {
        console.log(document);
        // setLoading(true);
        const imagesList = [];
        const canvas = document.createElement("canvas");
        canvas.setAttribute("className", "canv");
        const pdf = await pdfjsLib.getDocument({ data }).promise;

        const pushImageOfPage = async (pdf, i) => {
            var page = await pdf.getPage(i);
            var viewport = page.getViewport({ scale: 1.5 });
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            var render_context = {
            canvasContext: canvas.getContext("2d"),
            viewport: viewport,
            };
            await page.render(render_context).promise;
            let img = canvas.toDataURL("image/png");
            imagesList.push(img);
        }

        // my addition to allow controlling the pages used
        if (pageNumbers) {
            const actualPageNumbers = parsePagesString(pageNumbers);
            for (const i in actualPageNumbers) {
                await pushImageOfPage(pdf, actualPageNumbers[i]);
            }
        } else {
            for (let i = 1; i <= pdf.numPages; i++) {
                await pushImageOfPage(pdf, i);
            }
        }
        // setNumOfPages((e) => e + pdf.numPages);
        setImageUrls((e) => [...e, ...imagesList]);
        return imagesList;
    };

    //https://stackoverflow.com/questions/48172934/error-using-async-and-await-with-filereader
    //https://thecompetentdev.com/weeklyjstips/tips/65_promisify_filereader/
    // move to utility eventually
    const blobToBase64 = (blob, removeEncoding) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        // the load event will fire when the readAsDataURL operation completes successfully,
        // and 'result' in reader will then hold the converted base64string
        //Mozilla says: "To retrieve only the Base64 encoded string, first remove data:*/*;base64, from the result."
        //second note: the readAsDataURL page specifically mentions 'loadend' as the event that fires upon conclusion of the read,
        //though this event fires whether read was successful or not. onload is using by thecompetentdev source above.
        reader.onload = () => {
            let data = reader.result;
            //Mozilla says: "To retrieve only the Base64 encoded string, first remove data:*/*;base64, from the result."
            //https://medium.com/@charanvinaynarni/pdf-to-image-conversion-using-reactjs-fd250a25bf05#:~:text=Step%204
            if (removeEncoding === true) data = data.replace(/.*base64,/, ''); //this may not even work
            resolve(data);
        }
        // the error event fires when the read failed due to an error
        reader.onerror = reject;
        // this does not actually return it, but set's the reader's 'result' attribute to it
        reader.readAsDataURL(blob);
    });

    // this function will take in the index of the entry in textsAndHTMLs
    // and use it to ask for a new reading from gpt4, and then replace that element
    // this is breaking right now... why? It appears to be functioning the same as the calls
    // made by the submit button, which are going through... I wonder if I should try reusing
    // the image already created?
    const redoPage = async (index) => {
        // not a correct index
        if (index < 0 || index > textsAndHTMLs.length) return;
        const page = textsAndHTMLs[index].pdfPageNumber;
        //console.log(page);
        //console.log(parsePagesString(String(page)));
        // now redo the steps to OCR this page
        let base64Encoding = await blobToBase64(file);
        let image;
        // the base64 prefix for pdfs
        const formatSpecs = 'data:application/pdf;base64,';
        // if the uploaded file is a pdf, need to convert it to an array of images (PNG)
        if (base64Encoding.startsWith(formatSpecs)) {
            // .replace will remove any data prefix
            // document should be the document object from DOM
            // turn page into a single entry string array for renderPage
            // note: hmmm... atob is deprecated, do I even need it?
            image = await renderPage(atob(base64Encoding.replace(/.*base64,/, '')), document, String(page));
        }
        console.log(image);
        // now call the callable function to do the OCR
        const response = await textFromImageFile({ 
            measureId: measureId,
            base64Encoding: image[0], // really it is an imagesList, but with only one entry
            prompt: promptOCR,
            model: visionModel
        });
        console.log(response);
        //cut it down to just the content
        const newHTMLDocument = {
            pdfPageNumber: page, // reuse page from above
            response: response.data.message.content,
            html: extractHTML(response.data.message.content)
        };
        console.log(newHTMLDocument);
        //now create a new version of the textsAndHTMLs array with the element at 'index' replaced
        //by our new version
        const newTextsAndHTMLs = textsAndHTMLs;
        newTextsAndHTMLs[index] = newHTMLDocument;
        setTextsAndHTMLs([ ...newTextsAndHTMLs ]);
    }

    // update the file value based off of the form
    const onFileChange = (e) => {
        const fileList = e.target.files;
        console.log(fileList[0].name);
        setFile(fileList[0]);
    }

    // display a loading animation during loading times
    const [loading, setLoading] = useState(false);

    // this onSubmit function will take the uploaded file,
    // and call the 'textFromImageFile' callable firebase function
    // in order to receive and display
    const onSubmit = async (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
           e.stopPropagation();
           setValidated(true);
           return;
        }
        setLoading(true);
        try {
            // first, remember the pages we are using, so that we can update if needed
            const pageNumbers = parsePagesString(pages);
            // now, turn the pdf file into base64 encoding
            let base64Encoding = await blobToBase64(file);
            // this list will hold the images provided by 'renderPage'
            let imagesList = [];

            // the base64 prefix for pdfs
            const formatSpecs = 'data:application/pdf;base64,'; // 'data:*/*;base64,' is the generic
            // if the uploaded file is a pdf, need to convert it to an array of images (PNG)
            if (base64Encoding.startsWith(formatSpecs)) {
                //base64Encoding = base64Encoding.slice(formatSpecs.length);
                //https://developer.mozilla.org/en-US/docs/Web/API/atob
                // seems like we need to remove that part before using pdfjs?
                // this will remove any data prefix
                imagesList = await renderPage(atob(base64Encoding.replace(/.*base64,/, '')), document, pages);
            }

            //8/1/24: added this to try to get cohesive styling
            //before converting all the different pages to HTML, which is what promptOCR is saying,
            //take a look at the first page and create a superior prompt.
            const styleInstructionsPrompt = "Based on the text in the image, write a set of definitive instructions for turning the text in the image into HTML. The instructions must consist of the following two items, in order:\n\n"
            + "1. An HTML <style></style> section that preserves both the styling of the document and the semantic meaning of the styling, such as by using HTML elements to signify content to be <ins>inserted</ins> or <del>deleted</del>. The style sheet should be as minimal as possible.\n\n"
            +" 2. A description in plain English for when each entry in the HTML <style></style> section should be used on a section of text in the document, that also identifies cases where it should not be used, e.g. \"DO NOT use for any text that does not include the word \'Section\'\". While writing these instructions, DO NOT use specific examples from the text on the image, as these instructions will be used without this text accessible."//";//"at specific font styling choices mean semantically for the document. It should try to specify when to use";//, and for how to present element likes headers, lists, etc.";
            const betterOCRInstructions = await textFromImageFile({
                measureId: measureId,
                base64Encoding: imagesList[0],
                prompt: styleInstructionsPrompt,
                model: visionModel
            });
            //console.log(betterOCRInstructions);
            console.log(betterOCRInstructions.data.message.content);

            //8/3/24: What if instead of in parallel, we do sequentially
            //this will take way longer, but will allow the information from the previous page to be incorporated
            //10/22/24: It seems like I should be redoing this to do all of the subsequent pages in parallel after doing the first page
            let htmlDocuments = [];
            const firstPagePrompt = "Use the following styling instructions to turn the text in this image into HTML. The image is the first page in a scanned document, and all text on the image should be included in the HTML, which should end with a closing HTML tag and ```.";
            const subsequentPagePrompt = "Take this image, and turn the text on the image into HTML to add to the end of the HTML document described next. The image is the next page of the scanned document, and you are transforming its contents into HTML sequentially, using the <style> elements described at the beginning of the document. It is possible that the first element on the page is part of a section from the prior page, so don't add any headers that are not present, and IMPORTANT! if it seems like the first line in the image is a widow, add that in to the previous element in the document so far."; // Don't retype any text that was on the previous page, just begin typing without an opening HTML tag in this case.";
            //10/22/24 below is the prompt for pages after the first when done in parallel
            const parallelPagePrompt = "Take this image, and turn the text on the image into HTML that will appear as part of a document where the first page is the HTML provided next. The image is a subsequent page of the scanned document, and you are transforming its page contents into HTML in parallel for all pages after the first, using the <style> elements described next.";

            //begin constructing full html, which is only used by sequential
            let fullHTML = "<html>\n"+extractHTMLBasedOnCodeFences(betterOCRInstructions.data.message.content);

            const firstPageResponse = await textFromImageFile({ 
                measureId: measureId,
                base64Encoding: imagesList[0], //first page!
                prompt: /*firstPagePrompt*/subsequentPagePrompt + "\n\n" + fullHTML,
                model: visionModel
            });
            fullHTML += extractHTMLBasedOnCodeFences(firstPageResponse.data.message.content);

            //if we're trying to get the pages in sequence, rather than in parallel
            //note: currently there's no way to run this sequentially since sequentially is set to false, and has no way to change it!
            if (generationType === 'sequential') {
                console.log("Trying to get HTML sequentially");

                //sequentially go through rest of pages, appending
                for (let i = 1; i<imagesList.length; i++){
                    console.log("Working on page ", i+1);
                    const response = await textFromImageFile({ 
                        measureId: measureId,
                        base64Encoding: imagesList[i],
                        prompt: subsequentPagePrompt + "\n\n" + fullHTML,
                        model: visionModel
                    });

                    let normalizedMessage = extractHTMLBasedOnCodeFences(response.data.message.content);
                    //if it's not the final message, normalize it by removing the final up to two html tags
                    if (i+1 !== imagesList.length) {
                        // do this function twice because there may be two at the end; if there's only 1, or none, will do nothing.
                        normalizedMessage = removeFinalHTMLClosingTag(removeFinalHTMLClosingTag(normalizedMessage));
                        console.log("normalizedMessage is:");
                        console.log(normalizedMessage);
                    }
                    fullHTML += normalizedMessage;
                }
                fullHTML += "\n</html>";
                console.log("Finished sequential html generation; fullHTML is: ");
                console.log(fullHTML);

                //in this case, we should skip the combination step, and proceed directly
                //to the full html
                setFullTextHTML({                
                    response: fullHTML,
                    html: fullHTML,
                    display: 'html',
                    uploaded: false
                });
                setStep('editAndUploadHTML');
                setLoading(false);
                return;

            //if we're trying to get the first page's html, then do the rest in parallel
            } else if (generationType === 'first and parallel') {
                console.log("Trying to get HTML in parallel");

                // remove the first page from the array before doing this in parallel
                const parallelHTMLDocuments = await Promise.all(imagesList.slice(1).map((image, index) => {
                    return textFromImageFile({
                        measureId: measureId,
                        base64Encoding: image,
                        prompt: parallelPagePrompt + "\n\n" + fullHTML,
                        model: visionModel
                    });
                }))

                //then, for each, cut it down to just the content
                //first page is separate
                htmlDocuments[0] = { 
                    pdfPageNumber: 0, 
                    response: firstPageResponse.data.message.content, 
                    html: extractHTML(firstPageResponse.data.message.content) 
                };
                //rest of parallel pages
                for (let i = 0; i<parallelHTMLDocuments.length; i++) {
                    htmlDocuments[i+1] = {
                        pdfPageNumber: pageNumbers[i+1], // due to first page
                        response: parallelHTMLDocuments[i].data.message.content,
                        html: extractHTML(parallelHTMLDocuments[i].data.message.content)
                    };
                }
            // do all of them in parallel, fully
            } else if (generationType === 'fully parallel') {
                //create an array of promises for fetching text of each image, and await them all
                htmlDocuments = await Promise.all(
                    imagesList.map((image) => {
                        //here we actually call the callable firebase function that calls openai's gpt4vision
                        //I can also specify a prompt for the image, but it will execute its own otherwise
                        return (textFromImageFile({ 
                            measureId: measureId,
                            base64Encoding: image,
                            prompt: "Use the following styling instructions to turn the text in this image into HTML. Remember, these instructions are for a document of which this page is only one part, so not all styling elements may be present:\n\n" + betterOCRInstructions.data.message.content,//promptOCR,
                            model: visionModel
                        }));
                    })
                );
            }

            //then, for each, cut it down to just the content
            for (let i = 0; i<htmlDocuments.length; i++) {
                htmlDocuments[i] = {
                    pdfPageNumber: pageNumbers[i], // I think this should be the same?
                    response: htmlDocuments[i].data.message.content,
                    html: extractHTML(htmlDocuments[i].data.message.content)
                };
            }

            /*for (let i = 0; i<imagesList.length; i++){
                console.log("Working on page ", i);
                // special case for first page
                if (i==0) {
                    // this will remove the <html> and </html> tags... wait that might be bad? hmm
                    const response = await textFromImageFile({ 
                        measureId: measureId,
                        base64Encoding: imagesList[i],
                        prompt: firstPagePrompt + betterOCRInstructions.data.message.content,
                        model: visionModel
                    });
                    fullHTML = extractHTMLWithoutClosingTag(response.data.message.content);
                    continue;
                // otherwise, for other pages
                } else {
                    const response = await textFromImageFile({ measureId: measureId,
                        base64Encoding: imagesList[i],
                        prompt: subsequentPagePrompt + "\n\n" + fullHTML,
                        model: visionModel
                    });
                    fullHTML += extractHTMLWithoutClosingTag(response.data.message.content);
                }
            }
            console.log(fullHTML);*/

            //create an array of promises for fetching text of each image, and await them all
            /*const htmlDocuments = await Promise.all(
                imagesList.map((image) => {
                    //here we actually call the callable firebase function that calls openai's gpt4vision
                    //I can also specify a prompt for the image, but it will execute its own otherwise
                    return (textFromImageFile({ 
                        measureId: measureId,
                        base64Encoding: image,
                        prompt: "Use the following styling instructions to turn the text in this image into HTML. Remember, these instructions are for a document of which this page is only one part, so not all styling elements may be present:\n\n" + betterOCRInstructions.data.message.content,//promptOCR,
                        model: visionModel
                    }));
                })
            );

            //then, for each, cut it down to just the content
            for (let i = 0; i<htmlDocuments.length; i++) {
                htmlDocuments[i] = {
                    pdfPageNumber: pageNumbers[i], // I think this should be the same?
                    response: htmlDocuments[i].data.message.content,
                    html: extractHTML(htmlDocuments[i].data.message.content)
                };
            }*/

            console.log(htmlDocuments);
            setTextsAndHTMLs(htmlDocuments);
            setDisplayOptions({
                ...htmlDocuments[0],
                index: 0,
                display: 'html'
            });
            setStep('combineHTML');
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
            return;
        }
    }

    // I should practice using regular html input elements...
    const [validated, setValidated] = useState(false);
    // especially now that I have multiple forms
    const [validated2, setValidated2] = useState(false);

    // these filters are used in the 'Dashboard' view
    const [filters, setFilters] = useState({
        rBallotStatus: 'Any',
        State: 'Any',
        JurisdictionType: 'Any',
        ElectionDate: 'Any'
    });
    const updateFilters = (key, value) => {
        setFilters({
            ...filters,
            [key]: value
        });
    }

    if (loading) return (
        <div style={{display:'flex', justifyContent: 'center', alignItems:'center', marginTop: '12em'}}>
            <Spinner animation="border" role="status" variant="primary">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    );

    // this will render the indicators for what has been uploaded to database
    const DatabaseContent = (props) => {
        const measure = props.measure || {};
        const margin = props.margin || '0em 0em 0em 12em';
        const content = {
            FullTextHTML: { label: 'Full Text HTML' },
            Website: { label: 'Website' },
            Campaign: { label: 'Campaign' },
            FullTextLink: { label: 'Full Text Link' },
            ...aiContents,
        };
        return (
            <div style={{display: 'flex', flexWrap:'wrap', justifyContent: 'flex-start', margin: margin}}>
                { Object.keys(content).map((key) => {
                    return (
                        <span
                            key={key}
                            style={{
                                padding: '0.25em',
                                border: '1px solid',
                                borderRadius:'12px',
                                margin: '0.5em',
                                backgroundColor: (measure[key] && measure[key].trim() !== "" ? 'skyblue' : 'pink')
                            }}
                        >
                            {content[key].label + (measure[key] && measure[key].trim() !== "" ? ' \u2713' : '')}
                        </span>
                    );
                })}
            </div>
        )
    }

    // there should be a dashboard page to see all measures
    if (!measureId) {
        const measures = props.propositionsObj || {};
        return (
            <div style={{margin: '6em 2em 1em'}}>
                <div style={{display:'flex'}}>
                    <div style={{margin: '0em 1em 1em 0em'}}>
                        <span>Filter by rBallotStatus</span>
                        <br />
                        <select
                            onChange={(e) => { updateFilters('rBallotStatus', e.target.value); }}
                        >
                            <option key="any">Any</option>
                            <option key="pending">pending</option>
                            <option key="not active">not active</option>
                            <option key="volunteering">volunteering</option>
                        </select>
                    </div>
                    <div style={{margin: '0em 1em 1em 0em'}}>
                        <span>Filter by State</span>
                        <br />
                        <select
                            onChange={(e) => { updateFilters('State', e.target.value); }}
                        >
                            <option key="any">Any</option>
                            {allStates.map((state) => {
                                return (<option key={state}>{state}</option>);
                            })}
                        </select>
                    </div>
                    <div style={{margin: '0em 1em 1em 0em'}}>
                        <span>Filter by JurisdictionType</span>
                        <br />
                        <select
                            onChange={(e) => { updateFilters('JurisdictionType', e.target.value); }}
                        >
                            <option key="any">Any</option>
                            <option key="state">State</option>
                            <option key="county">County</option>
                            <option key="city">City</option>
                        </select>
                    </div>
                    <div style={{margin: '0em 1em 1em 0em'}}>
                        <span>Filter by ElectionDate</span>
                        <br />
                        <select
                            onChange={(e) => { updateFilters('ElectionDate', e.target.value); }}
                        >
                            <option key="any">Any</option>
                            <option key="march">March 5, 2024</option>
                            <option key="november">November 5, 2024</option>
                        </select>
                    </div>
                </div>
                <ol>
                    {  Object.keys(measures).map((id) => {
                        if (id === 'ordering') return '';
                        // filter measures out based on filters set above
                        //note: this may not actually refresh...
                        for (const key in filters) {
                            if (filters[key] === "Any") continue;
                            if (filters[key] !== measures[id][key]) return "";
                        }
                        return (
                            <li key={id}>
                                <Link 
                                    to={"/pendingmeasuresetup/"+id}
                                    style={{
                                        textDecoration: 'none'
                                    }}
                                >
                                    {measures[id].BallotName ? measures[id].BallotName : measures[id].rBallotTitle ? measures[id].rBallotTitle : measures[id].BallotpediaTitle}
                                </Link>
                                <div style={{display: 'flex', flexWrap:'wrap', justifyContent: 'flex-start'}}>
                                    <DatabaseContent measure={measures[id]} margin={'0.5em 0em 0.5em'}/>
                                </div>
                            </li>
                        );
                    })}
                </ol>
            </div>
        );
    }

    return (
    <div style={{display: 'flex', height: '100%'}}>
        <Nav className="Sidebar" style={{paddingLeft: '1em', paddingRight: '1em'}} defaultActiveKey="console">
            <Nav.Link key="dashboard" as={Link} to={'/pendingmeasuresetup'}>Dashboard</Nav.Link>
            {Object.keys(steps).map((step) => {
                return (
                    <Nav.Link 
                        className="AboutNav"
                        key={step}
                        eventKey={step}
                        onClick={() => setStep(step)}
                    >
                        {steps[step]}
                    </Nav.Link>
                );
            })}
        </Nav>
        <div style={{display:'flex',flexDirection:'column'}}>
        <div style={{display: 'flex', margin: '5em 1em 0em 12em', justifyContent: 'space-evenly', minHeight: '80vh', width: '94%'}}>
            {   /*!textsAndHTMLs || textsAndHTMLs[0] === initialHTMLText || (fullTextHTML && fullTextHTML !== '')*/ step !== 'combineHTML'  ? "" :
                <div style={{display:'flex', flexDirection:'column', width: '75%'}}>
                    <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                        <ToggleButton id="htmlToggle" checked={displayOptions.display === 'html'} onClick={() => setDisplayOptions({...displayOptions, display: 'html'})}>HTML</ToggleButton>
                        <ToggleButton id="responseToggle" checked={displayOptions.display === 'response'} onClick={() => setDisplayOptions({...displayOptions, display: 'response'})}>Response</ToggleButton>
                        <Button onClick={() => redoPage(displayOptions.index)}>Redo this page</Button>
                    </div>
                    <div style={{display: 'flex', alignItems: 'stretch', height: '100%'}}>
                        {displayOptions.display === 'response' ? 
                            <textarea 
                                style={{margin: '1em 1em 0em', padding: '1em', width: '100%', borderRadius: '10px'}}
                                value={displayOptions.response}
                                onChange={(e) => {
                                    //here, need to set both displayOptions, and the actual thing
                                    const index = displayOptions.index || 0; // or with 0 in case no display specified
                                    const newTextsAndHTMLs = [ ...textsAndHTMLs ];
                                    // so that this is never undefined (if skipping a step)
                                    if (!(index in newTextsAndHTMLs)) newTextsAndHTMLs[index] = {};
                                    newTextsAndHTMLs[index].response = e.target.value;
                                    newTextsAndHTMLs[index].html = extractHTML(e.target.value);
                                    setTextsAndHTMLs(newTextsAndHTMLs);
                                    setDisplayOptions({
                                        ...newTextsAndHTMLs[index],
                                        index: index,
                                        display: 'response' //I think it should always be this
                                    });
                                }}
                            />
                            :
                            <iframe title="HTMLPage" style={{padding: '1em', width: '100%', margin: '1em', border: '1px solid black', borderRadius: '10px'}} srcDoc={displayOptions.html}></iframe>
                        }
                    </div>
                    <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                        {/** What should actually be here is a back and next arrow buttons, and the current page number. It should also be up with other buttons. */}
                        {textsAndHTMLs.map((object, index) => {
                            return (
                                <Button 
                                    style={{maxWidth: '3em'}} 
                                    key={index} 
                                    onClick={() => setDisplayOptions({
                                        ...object,
                                        index: index, // save the index this elements takes up in the array
                                        display: 'html'
                                    })}
                                >
                                    {index+1}
                                </Button>
                            )
                        })}
                    </div>
                </div>
            }
            { /*!fullTextHTML || fullTextHTML === '' || fullTextHTML.uploaded*/ step !== 'editAndUploadHTML' ? "" :
                <div style={{display:'flex', flexDirection:'column', width: '75%'}}>
                    <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                        <ToggleButton id="htmlToggle" checked={htmlOrResponseView === 'html'} onClick={() => setHTMLOrResponseView('html')}>HTML</ToggleButton>
                        <ToggleButton id="responseToggle" checked={htmlOrResponseView === 'response'} onClick={() => setHTMLOrResponseView('response')}>Response</ToggleButton>
                    </div>
                    <div style={{display: 'flex', alignItems: 'stretch', height: '100%'}}>
                    {
                        // using displayOptions below since it will still react to togglebuttons above
                        htmlOrResponseView === 'response' ?
                            <textarea 
                                style={{margin: '1em 1em 0em', padding: '1em', width: '100%', borderRadius: '10px'}}
                                value={fullTextHTML.response}
                                onChange={(e) => {
                                    setFullTextHTML({
                                        ...fullTextHTML,
                                        response: e.target.value,
                                        html: extractHTML(e.target.value)
                                    });
                                }}
                            />
                        :
                            <iframe title="fullTextHTML" style={{margin: '1em', padding: '1em', width: '100%', border: '1px solid black', borderRadius: '10px'}} srcDoc={fullTextHTML.html}></iframe>
                    }
                    </div>
                </div>
            }
            { /*!fullTextHTML || !fullTextHTML.uploaded*/ step !== 'generateAndUploadContent' ? "" :
            <div style={{width: '75%', margin: '0em 0em 0em 1em'}}>
                <p>Buttons</p>
                <textarea 
                    style={{
                        marginTop: '1em',
                        padding: '1em', 
                        width: '100%', 
                        borderRadius: '10px',
                        height: '90%'
                    }}
                    value={aiContents[selectedContent].response}
                    onChange={(e) => {
                        setAiContents({
                            ...aiContents,
                            [selectedContent]: {
                                ...aiContents[selectedContent],
                                response: e.target.value
                            }
                        });
                    }}
                />
            </div>
            }
            { /* Here we create an escape hatch for generating and uploading whatever */
            step !== 'adaptableUploadContent' ? "" :
            <div style={{width: '75%', margin: '0em 0em 0em 1em'}}>
                <textarea 
                    style={{
                        marginTop: '1em',
                        padding: '1em', 
                        width: '100%', 
                        borderRadius: '10px',
                        height: '90%'
                    }}
                    value={adaptableUploadContent}
                    onChange={(e) => setAdaptableUploadContent(e.target.value)}
                />
            </div>
            }
            <div style={{
                display: 'flex',
                alignItems: 'flex-start'
            }}>
            <div style={{
                minWidth: '15em',
                margin: '0em 1em 1.5em',
                padding: '0.5em',
                border: '1px solid gray',
                borderRadius: '10px',
                boxShadow: '3px 3px black'
                }}
            >   
                { step !== 'console' ? "" :
                    <div>
                        <Form
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                padding: '0.5em',
                                width: '40em'
                            }}
                            onSubmit={async(e) => {
                                e.preventDefault();
                                const upload = {
                                    Website: website,
                                    FullTextLink: fullTextLink,
                                    ImpartialAnalysisLink: impartialAnalysisLink,
                                    Campaign: campaignName,
                                    rBallotStatus: rBallotStatus
                                };
                                try {
                                    setLoading(true);
                                    await updateMeasure(measureId, upload);
                                    setLoading(false);
                                } catch (error) {
                                    console.error(error);
                                    setLoading(false);
                                }
                            }}
                        >
                            <Form.Group style={{display: 'flex', flexDirection:'column'}}>
                                <Form.Label>Full Text Link</Form.Label>
                                <Form.Control 
                                    name="fullTextLink"
                                    value={fullTextLink}
                                    onChange={(e) => setFullTextLink(e.target.value)}
                                />
                                <Form.Text>
                                    {fullTextLink ? <a href={fullTextLink} target="_blank" rel="noreferrer">Link to full text</a> : ""}
                                </Form.Text>
                            </Form.Group>
                            <br />
                            <Form.Group style={{display: 'flex', flexDirection:'column'}}>
                                <Form.Label>Impartial Analysis Link</Form.Label>
                                <Form.Control 
                                    name="impartialAnalysisLink"
                                    value={impartialAnalysisLink}
                                    onChange={(e) => setImpartialAnalysisLink(e.target.value)}
                                />
                                <Form.Text>
                                    {impartialAnalysisLink ? <a href={impartialAnalysisLink} target="_blank" rel="noreferrer">Link to impartial analysis</a> : ""}
                                </Form.Text>
                            </Form.Group>
                            <br />
                            <Form.Group>
                                <Form.Label>Website</Form.Label>
                                <Form.Control 
                                    name="website"
                                    value={website}
                                    onChange={(e) => setWebsite(e.target.value)}
                                />
                                <Form.Text>
                                    {website ? <a href={website} target="_blank" rel="noreferrer">Link to website</a> : ""}
                                </Form.Text>
                            </Form.Group>
                            <br />
                            <Form.Group>
                                <Form.Label>Campaign Name</Form.Label>
                                <Form.Control 
                                    name="campaignName"
                                    value={campaignName}
                                    onChange={(e) => setCampaignName(e.target.value)}
                                />
                            </Form.Group>
                            <br />
                            <Form.Group>
                                <Form.Label>rBallotStatus</Form.Label>
                                <Form.Select 
                                    name="rBallotStatus"
                                    value={rBallotStatus}
                                    onChange={(e) => setrBallotStatus(e.target.value)}
                                >
                                    <option key="pending" value="pending">pending</option>
                                    <option key="not active" value="not active">not active</option>
                                    <option key="signing up" value="signing up">signing up</option>
                                    <option key="volunteering" value="volunteering">volunteering</option>
                                    <option key="set up" value="set up">set up</option>
                                </Form.Select>
                            </Form.Group>
                            <br />
                            <Button type="submit">Submit</Button>
                        </Form>
                    </div>
                }
                { /*textsAndHTMLs && textsAndHTMLs[0] !== initialHTMLText*/ step !== 'uploadTextPDF' ? "" :
                <Form 
                    noValidate 
                    validated={validated} 
                    onSubmit={async (e) => onSubmit(e)} 
                    style={{display: 'flex', 
                        flexDirection: 'column', 
                        padding: '0.5em',
                    }}
                >
                    {props.propositionsObj && props.propositionsObj[measureId] && props.propositionsObj[measureId].FullTextLink ?
                        <Form.Group>
                            <Form.Label>Full Text of Measure {measureId}</Form.Label>
                            <br />
                            <a href={props.propositionsObj[measureId].FullTextLink}>Link to full text</a>
                        </Form.Group>
                        :
                        ""
                    }
                    <br />
                    <Form.Group controlId="fileUpload">
                        <Form.Label>PDF File Upload</Form.Label>
                        <Form.Control
                            required
                            type="file"
                            name="file"
                            //ref={fullTextRef}
                            onChange={onFileChange}
                        />
                        <Form.Control.Feedback type="invalid">
                            Please upload a PDF or image of the full text.
                        </Form.Control.Feedback>
                        <Form.Text>
                            Upload the PDF file of the text to be turned into HTML.
                        </Form.Text>
                    </Form.Group>
                    <br />
                    <Form.Group>
                        <Form.Label>PDF Pages to OCR</Form.Label>
                        <Form.Control 
                            required
                            name="pages"
                            value={pages}
                            onChange={(e) => setPages(e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid">
                            Please input the pages you would like to OCR.
                        </Form.Control.Feedback>
                        <Form.Text>
                            Pages: {parsePagesString(pages).toString()}
                        </Form.Text>
                        <br />
                        <Form.Text>
                            Type in a comma-separated list of the pages you want in the order you want.
                            You can use ranges like '2-4' or '6-3'.
                        </Form.Text>
                    </Form.Group>
                    <br />
                    <Form.Group>
                        <Form.Label>Choose model</Form.Label>
                        <Form.Select 
                            required
                            name="visionModelChoice"
                            value={visionModel}
                            onChange={(e) => setVisionModel(e.target.value)}
                        >
                            {visionModels.map((model) => {
                                return (
                                    <option key={model}>{model}</option>
                                );
                            })}
                        </Form.Select>
                    </Form.Group>
                    <br />
                    <Form.Group>
                        <Form.Label>OCR Prompt</Form.Label>
                        <Form.Control 
                            required
                            name="promptOCR"
                            value={promptOCR}
                            onChange={(e) => setPromptOCR(e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid">
                            Please include a prompt for how text should be formatted.
                        </Form.Control.Feedback>
                        <Form.Text>
                            The prompt given to the AI model for how to reformat the text in the image/PDF.
                        </Form.Text>
                    </Form.Group>
                    <br />
                    <Form.Group>
                        <Form.Label>Generation Type</Form.Label>
                        <Form.Select 
                            required
                            name="geneartionType"
                            value={generationType}
                            onChange={(e) => setGenerationType(e.target.value)}
                        >
                            {generationOptions.map((generationType) => {
                                return (
                                    <option key={generationType}>{generationType}</option>
                                );
                            })}
                        </Form.Select>
                        <Form.Text>
                            In sequential mode, each page will be generated with the HTML of the full document to that point fed in. Takes a while.
                            In first and parallel mode, the first page is generated, and then the subsequent pages are generated using it as an example.
                            In fully parallel mode, a style sheet is generated from the first page, and then all the pages are generated using that style sheet.
                        </Form.Text>
                    </Form.Group>
                    <br />
                    <Button style={{margin: '1em 0em'}} type="submit">Submit</Button>
                </Form>
                }
                { //this is still showing after the data was lost already, not sure why
                //only show if we have pages of HTML, but not the full HTML
                /*!textsAndHTMLs || textsAndHTMLs[0] === initialHTMLText || (fullTextHTML && fullTextHTML !== "")*/ step !== 'combineHTML' ? "" :
                <Form 
                    noValidate
                    validated={validated2}
                    style={{
                        display: 'flex', 
                        flexDirection: 'column', 
                        justifyContent: 'center',
                        padding: '0.5em',
                    }}
                    onSubmit={async (e) => {
                        e.preventDefault();
                        const form = e.currentTarget;
                        if (form.checkValidity() === false) {
                            e.stopPropagation();
                            setValidated2(true);
                            return;
                        }
                        setLoading(true);
                        //console.log(combinePages);
                        try { 
                            await combinePages(); 
                            setStep('editAndUploadHTML');
                            setLoading(false);
                        }
                        catch (error) { 
                            console.error(error); 
                            setLoading(false);
                        }
                    }}
                >
                    <Form.Group>
                        <Form.Label>Combine documents</Form.Label>
                        <Form.Check 
                            type="radio"
                            label="HTML Directly"
                            id="htmlRadio"
                            name="combineGroup"
                            checked={promptCombineMode === 'html'}
                            value={promptCombineMode === 'html'}
                            onChange={(e) => {
                                if (e.target.checked) setPromptCombineMode('html');
                            }}
                        />
                        <Form.Check 
                            type="radio"
                            label="Full response"
                            id="responseRadio"
                            name="combineGroup"
                            checked={promptCombineMode === 'response'}
                            value={promptCombineMode === 'response'}
                            onChange={(e) => {
                                if (e.target.checked) setPromptCombineMode('response');
                            }}
                        />
                        <Form.Text>Choose whether to feed the AI model just the HTML for each document, or to include the whole response.</Form.Text>
                    </Form.Group>
                    <br />
                    <Form.Group>
                        <Form.Label>Choose model</Form.Label>
                        <Form.Select 
                            required
                            name="modelChoice"
                            value={model}
                            onChange={(e) => setModel(e.target.value)}
                        >
                            {textModels.map((model) => {
                                return (
                                    <option key={model}>{model}</option>
                                );
                            })}
                        </Form.Select>
                    </Form.Group>
                    <br />
                    <Form.Group>
                        <Form.Label>Combine Prompt</Form.Label>
                        <Form.Control 
                            required
                            name="promptCombine"
                            value={promptCombine}
                            onChange={(e) => setPromptCombine(e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid">
                            Please include a prompt for how documents should be combined.
                        </Form.Control.Feedback>
                        <Form.Text>
                            The prompt given to the AI model for how to combine the multiple pages/documents.
                        </Form.Text>
                    </Form.Group>
                    <br />
                    <Button type="submit">Combine</Button>
                </Form>
                }
                { //only show this when full text exists 
                /*!fullTextHTML || fullTextHTML === "" || fullTextHTML.uploaded*/ step !== 'editAndUploadHTML' ? "" :
                <Form
                    name="editAndUploadFullTextForm"
                    style={{
                        display: 'flex', 
                        flexDirection: 'column', 
                        justifyContent: 'center',
                        padding: '0.5em',
                    }}
                    onSubmit={ async(e) => {
                        e.preventDefault();
                        setLoading(true);
                        try {
                            await updateMeasure(measureId, { FullTextHTML: fullTextHTML.html });
                            setStep('generateAndUploadContent');
                            setLoading(false);
                            // log that the html is uploaded
                            setFullTextHTML({
                                ...fullTextHTML,
                                uploaded: true
                            });
                        } catch(error) {
                            console.error(error);
                            setLoading(false);
                        }
                    }}
                >
                    <Form.Group>
                        <Form.Text>
                            This will eventually be a way to chat directly with chatgpt to ask for changes to the text.
                        </Form.Text>
                    </Form.Group>
                    <br />
                    <Button type="submit">Upload HTML</Button>
                    <br />
                    <Button onClick={() => setFullTextHTML({ ...fullTextHTML, uploaded: true })}>Continue without uploading</Button>
                </Form>
                }
                { /* Form to create AI explanatory content about the measure */
                /*!fullTextHTML || fullTextHTML === '' || !fullTextHTML.uploaded*/ step !== 'generateAndUploadContent' ? "" :
                <Form
                    name="editContentForm"
                    style={{
                        display: 'flex', 
                        flexDirection: 'column', 
                        justifyContent: 'center',
                        padding: '0.5em',
                    }}
                    onSubmit={async (e) => {
                        e.preventDefault();
                        const response = await generateText({
                            model: model,
                            messages: [{
                                role: 'system', //or should it be user?
                                // the prompts are currently designed to take in the html of the measure at the end
                                content: (aiContents[selectedContent].prompt + fullTextHTML.html)
                            }]
                        });
                        console.log(response);
                        // everything stays the same, but we add the response in to this content object
                        setAiContents({
                            ...aiContents,
                            [selectedContent]: {
                                ...aiContents[selectedContent],
                                response: response.data.message.content
                            }
                        })
                    }}
                >
                    <div style={{display: 'flex', flexWrap:'wrap', justifyContent: 'space-evenly'}}>
                    { Object.keys(aiContents).map((key) => {
                        return (
                            <ToggleButton 
                                key={key}
                                style={{
                                    borderRadius:'12px',
                                    margin: '0.5em',
                                    backgroundColor: 'skyblue'
                                }}
                                name={key}
                                onClick={() => {
                                    setSelectedContent(key);
                                }}
                            >
                                {aiContents[key].label + (aiContents[key].response && aiContents[key].response !== '' ? ' \u2713' : '')}
                            </ToggleButton>
                        );
                    })}
                    </div>
                    <br />
                    <h4>{aiContents[selectedContent].label}</h4>
                    <br />
                    <Form.Group>
                        <Form.Label>Choose model</Form.Label>
                        <Form.Select 
                            required
                            name="modelChoice"
                            value={model}
                            onChange={(e) => setModel(e.target.value)}
                        >
                            {textModels.map((model) => {
                                return (
                                    <option key={model}>{model}</option>
                                );
                            })}
                        </Form.Select>
                    </Form.Group>
                    <br />
                    <Form.Group>
                        <Form.Label>{aiContents[selectedContent].label} Prompt</Form.Label>
                        <Form.Control 
                            required
                            as="textarea"
                            name={selectedContent+ "Prompt"}
                            value={aiContents[selectedContent].prompt}
                            onChange={(e) => {
                                // hopefully changes just the prompt for the currently
                                // selected content, and not anything else
                                setAiContents({
                                    ...aiContents,
                                    [selectedContent]: {
                                        ...aiContents[selectedContent],
                                        prompt: e.target.value
                                    }
                                });
                            }}
                        />
                        <Form.Control.Feedback type="invalid">
                            Please include a prompt for how '{aiContents[selectedContent].label}' should be written.
                        </Form.Control.Feedback>
                        <Form.Text>
                            The prompt given to the AI for how to write this content.
                        </Form.Text>
                    </Form.Group>
                    <br />
                    <Button type="submit">Generate {aiContents[selectedContent].label}</Button>
                    <br />
                    {
                        <Button onClick={async () => {
                            setLoading(true);
                            // create and fill in an object with all the changes to be uploaded
                            /*const changesObject = {};
                            for (const key in aiContents){
                                if (aiContents[key].response !== "") {
                                    // key is the same as field, but could not be in future
                                    changesObject[aiContents[key].field] = aiContents[key].response
                                }
                            }*/
                            try {
                                await updateMeasure(measureId, {
                                    [aiContents[selectedContent].fieldName]: aiContents[selectedContent].response 
                                });//changesObject);
                                setLoading(false);
                            } catch (error) {
                                console.error(error);
                                setLoading(false);
                            }
                        }}>
                            Upload {aiContents[selectedContent].fieldName}
                        </Button>
                    }
                </Form>
                }
                { /* be able to input whatever */ 
                step !== 'adaptableUploadContent' ? "" :
                <Form
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '0.5em'
                    }}
                    onSubmit={async (e) => {
                        e.preventDefault();
                        const upload = {
                            [adaptableFieldName]: adaptableUploadContent,
                        };
                        try {
                            setLoading(true);
                            await updateMeasure(measureId, upload);
                            setLoading(false);
                        } catch (error) {
                            console.error(error);
                            setLoading(false);
                        }
                    }}
                >
                    <Form.Group>
                        <Form.Label>Choose model</Form.Label>
                        <Form.Select 
                            required
                            name="modelChoice"
                            value={model}
                            onChange={(e) => setModel(e.target.value)}
                        >
                            {textModels.map((model) => {
                                return (
                                    <option key={model}>{model}</option>
                                );
                            })}
                        </Form.Select>
                    </Form.Group>
                    <br />
                    <Form.Group>
                        <Form.Label>Write Prompt</Form.Label>
                        <Form.Control 
                            required
                            as="textarea"
                            rows={10}
                            name={"adaptableUploadPrompt"}
                            value={adaptableUploadPrompt}
                            onChange={(e) => setAdaptableUploadPrompt(e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid">
                            Please include a prompt for how this content should be generated.
                        </Form.Control.Feedback>
                        <Form.Text>
                            The prompt given to the AI for how to write this content.
                        </Form.Text>
                    </Form.Group>
                    <br />
                    <Form.Group>
                        <Form.Label>Firestore Field Name</Form.Label>
                        <Form.Control 
                            required
                            name="adaptableFieldName"
                            value={adaptableFieldName}
                            onChange={(e) => setAdaptableFieldName(e.target.value)}
                        />
                        <Form.Text>The field name for where this content will be stored on measure {measureId}.</Form.Text>
                    </Form.Group>
                    <br />
                    <Button 
                        onClick={async () => {
                            //e.preventDefault();
                            console.log("Generating adaptable content...");
                            const response = await generateText({
                                model: model,
                                messages: [{
                                    role: 'user',
                                    content: adaptableUploadPrompt
                                }]
                            });
                            setAdaptableUploadContent(response.data.message.content);
                        }}
                    > Generate Adaptable Content</Button>
                    <br />
                    <Button type="submit">Upload to Firestore</Button>
                </Form>
                }
            </div>
            </div>
        </div>
        <DatabaseContent 
            measure={
                props.propositionsObj ?
                    props.propositionsObj[measureId] 
                    : 
                    {}
            }
        />
        </div>
    </div>
    )
}

