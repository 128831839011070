/* Copyright (C) 2023 Christian Miley - All Rights Reserved */

import React, { useState, useEffect, useContext } from 'react';
import { useParams, Link } from "react-router-dom";
import { getUser, getVoteInfo } from '../../firebase.js';
import { Container, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './User.css';
import '../../Variables.css';
import { UserContext, PersonContext } from '../../Context.js';
import UnverifiedEmail from '../UnverifiedEmail.js';
import { dateFromTimestamp, datetimeFromTimestamp, capitalize } from '../Utility.js';
import { SharingDropdown } from '../SharingLinks.js';
import ReactionsComments from '../ReactionsComments.js';
//import Posts from '../Posts.js';

function User(props)
{
    //useParams returns an object of key/value pairs of URL parameters
    //here, only one parameter is passed (id)
    //note: we must name the parameter the same as the URL parameter (id here)
    let { id } = useParams();

    const userContext = useContext(UserContext);
    const personContext = useContext(PersonContext);

    const [user, setUser] = useState(undefined);
    const [measureLikesDislikes, setMeasureLikesDislikes] = useState({});
    /*const [name, setName] = useState("Loading...");
    const [city, setCity] = useState("City");
    const [county, setCounty] = useState("County");
    const [state, setState] = useState("State");*/
    //const [congressionalDistrict, setCongressionalDistrict] = useState("");
    //const [stateAssemblyDistrict, setStateAssemblyDistrict] = useState("");
    //const [stateSenateDistrict, setStateSenateDistrict] = useState("");

    //for getting the user's information on page load
    useEffect(() =>
    {
        if (!id || !userContext || !personContext) return;

        const getUserInfo = async () => {
            console.log("Getting user info from database.");
            try {
                const user = await getUser(id);
                setUser(user);
                const likes = user.Likes || {};
                const dislikes = user.Dislikes || {};
                const newLikesDislikes = {
                    ...Object.fromEntries(Object.entries(likes).map(([key, value]) => {
                        return(
                            [ key, { ...value, 'type': 'like' } ]
                        );
                    })),
                    ...Object.fromEntries(Object.entries(dislikes).map(([key, value]) => {
                        return(
                            [ key, { ...value, 'type': 'dislike' } ]
                        );
                    })),
                };
                // let's see if adding the info outside of the object.entries is better
                for (const key in newLikesDislikes){
                    //this is being done sequentially, but there is really no need.
                    // But probably not needing a Promise.all yet
                    const voteInfo = await getVoteInfo(key, id) || {};
                    newLikesDislikes[key] = {
                        ...newLikesDislikes[key],
                        ...voteInfo
                    }
                }
                //console.log(newLikesDislikes);
                setMeasureLikesDislikes(newLikesDislikes);
            } catch (error) {
                console.error(error);
                setUser({Nickname: "No user found."});
            }
        }

        const getSelfInfo = async () => {
            console.log("Getting user info from own personContext.");
            setUser(personContext);
            const likes = personContext.Likes || {};
            const dislikes = personContext.Dislikes || {};
            const newLikesDislikes = {
                ...Object.fromEntries(Object.entries(likes).map(([key, value]) => {
                    return(
                        [ key, { ...value, 'type': 'like' } ]
                    );
                })),
                ...Object.fromEntries(Object.entries(dislikes).map(([key, value]) => {
                    return(
                        [ key, { ...value, 'type': 'dislike' } ]
                    );
                })),
            };

            try {
                // let's see if adding the info outside of the object.entries is better
                for (const key in newLikesDislikes){
                    //this is being done sequentially, but there is really no need.
                    // But probably not needing a Promise.all yet
                    //note: does take a bit of time
                    const voteInfo = await getVoteInfo(key, id) || {};
                    newLikesDislikes[key] = {
                        ...newLikesDislikes[key],
                        ...voteInfo
                    }
                }
                setMeasureLikesDislikes(newLikesDislikes);
            } catch(error) {
                console.error(error);
            }
        }

        //if we are viewing our own page, just use personContext
        if (id === userContext.uid) {
            getSelfInfo();
        //otherwise, fetch and set
        } else {
            getUserInfo();
        }

        /*var userRef = db.collection("users").doc(id);
        var userData;*/

        /*const getUserInfo = async () => 
        {
            await userRef.get().then(function(doc) 
            {
                userData = doc.data();
            });
            console.log("Fetched user data from database: ");
            console.log(userData);
            //no user by this id
            if (!userData) {
                setName("No user found for " + id);
                return;
            }
            if (userData.Nickname) setName(userData.Nickname);
            if (userData.City) setCity(userData.City);
            if (userData.County) setCounty(userData.County);
            if (userData.State) setState(userData.State);
            //if (userData.Congressional_District) setCongressionalDistrict(userData.Congressional_District);
            //if (userData.State_Assembly_District) setStateAssemblyDistrict(userData.State_Assembly_District);
            //if (userData.State_Senate_District) setStateSenateDistrict(userData.State_Senate_District);
        }

        //if we are viewing our own page, just use personContext
        if (userContext && personContext && id === userContext.uid) {
            if (personContext.Nickname) setName(personContext.Nickname);
            if (personContext.City) setCity(personContext.City);
            if (personContext.County) setCounty(personContext.County);
            if (personContext.State) setState(personContext.State);
        } else {
            getUserInfo();
        }*/

    }, [id, userContext, personContext]);

    //for fetching collections info
    //const [following, setFollowing] = useState(undefined);
    //const [followers, setFollowers] = useState(undefined);
    //const [parties, setParties] = useState(undefined);
    //const [interests, setInterests] = useState(undefined);

    /*useEffect(() => {
        /**
        * For fetching information to populate connections tab
        * Note: missing permissions as of now
        * @param {*} 
        * @returns 
        */
        /*async function populateConnections(connection, setConnection)
        {
            let connectionReference = db.collection("users").doc(id).collection(connection);
            let querySpecs = connectionReference.limit(6);

            let connectionsList = [];
            let connectionsSize;

            await querySpecs.get().then((querySnapshot) => 
            {
                connectionsSize = querySnapshot.size;

                querySnapshot.forEach((doc) =>
                {
                    let newConnection = doc.data();
                    newConnection.id = doc.id;
                    connectionsList.push(newConnection);
                })
            })

            const connectionsObject = {size: connectionsSize, list: connectionsList};

            setConnection(connectionsObject);
            return;
        }

        //populateConnections("following", setFollowing);
        //populateConnections("followers", setFollowers);
        //populateConnections("parties", setParties);
        //populateConnections("interests", setInterests);
    }, [id])*/

    //for following the person whose page it is
    /*async function followUser()
    {
        if (!userContext)
        {
            console.log("Cannot follow as a guest.");
            return;
        }

        let userRef = db.collection("users").doc(id).collection("followers").doc(userContext.uid);
        let ourRef = db.collection("users").doc(userContext.uid).collection("following").doc(id);

        await ourRef.get().then(async function(doc)
        {
            if (doc.exists)
            {
                console.log("Already following " + name);
                return;
            } else {
                await ourRef.set
                ({
                    ID: id,
                    name: name,
                    timestamp: timestamp()
                }).then(() =>
                {
                    console.log("Set ourselves to follow " + name);
                })
            }
        })

        //should this be managed by client? or by automatically firing cloud function?
        await userRef.get().then(async function(doc)
        {
            if (doc.exists)
            {
                console.log(name + " is already being followed by us.");
                return;
            } else {
                await userRef.set
                ({
                    ID: userContext.uid,
                    name: userContext.displayName,
                    timestamp: timestamp()
                }).then(() =>
                {
                    console.log("Set " + name + " to be followed by " + userContext.displayName);
                })
            }
        })
        
        return;
    }*/

    /**
    * Displaying the petitions the user has signed and the progress on this front
    * */
    /*const [usersSignatures, setUsersSignatures] = useState(undefined);

    useEffect(() => {
        const signaturesRef = db.collection("users").doc(id).collection("signatures");
        let signatureCardList = [];

        async function getSignatures()
        {
            await signaturesRef.get().then(function(querySnapshot) {
                querySnapshot.forEach(function(document) {
                    const data = document.data();
                    
                    if (!data.title) {
                        console.log("No title present for " + document.id + "; petition is only here because it's been ignored.");
                        return;
                    }

                    signatureCardList.push(
                        <Card key={data.petitionID} style={{width: '90vw', maxWidth: '18rem', margin: '1em'}}>
                            <Card.Body>
                                <Card.Title>
                                    <Link to={"/propositionpage/" + data.petitionID + "/"}>
                                        {data.petitionID + ": " + (data.title.length <= 70 ? data.title : data.title.slice(0, 69) + "...")}
                                    </Link>
                                </Card.Title>
                                <Card.Text>
                                    {"Signed on: " + dateFromTimestamp(data.dateSigned)}
                                </Card.Text>
                                <Card.Text>
                                    {data.dateOrdered ? ("Ordered on: " + dateFromTimestamp(data.dateOrdered)) : ""}
                                </Card.Text>
                                <Card.Text>
                                    {data.dateMailed ? "Mailed on: " + dateFromTimestamp(data.dateMailed) : ""}
                                </Card.Text>
                                <Card.Text>
                                    {data.dateReceived ? "Received by rBallot on: " + dateFromTimestamp(data.dateReceived) : ""}
                                </Card.Text>
                                <Card.Text>
                                    {data.dateSentToProponents ? "Sent to measure proponents on: " + dateFromTimestamp(data.dateSentToProponents) : ""}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    )
                })
            })

            setUsersSignatures(signatureCardList);
        }

        getSignatures();

    }, [id]);*/

    const [usersSignatures, setUsersSignatures] = useState(undefined);
    useEffect(() => {
        if (!user || !props || !props.propositionsObj || !user.petitionSections) return;

        const measures = user.petitionSections['measures'] || [];
        //const requested = user.petitionSections['requestedMeasures'] || [];
        //const downloaded = user.petitionSections['downloadedMeasures'] || [];
        // here will have others, like circulating or printed or build a ballot, or maybe these should be fields within the card?
        if (measures.length === 0) {
            setUsersSignatures(
                <div style={{textAlign: 'center'}}>
                    <h2 className="UserBox">Signatures</h2>
                    <h6>Sign some measures to see them here!</h6>
                </div>
            );
            return;
        }

        //const c

        //in below, going to iterate through a list of the measures this user has interacted with. From there,
        //going to iterate through every section for that measure. Could also do this by iterating through the
        //entire petitionSections and skipping on 'count' etc.
        setUsersSignatures(
            <div style={{textAlign: 'center'}}>
                <h2 className="UserBox">Signatures</h2>
                {/*<h5 className="UserBox">Requested via mail</h5>*/}
                <div className="SignaturesGrid">
                    {measures.map((measureID) => {
                        //if we don't have the info loaded from db, or the user does not have any petition sections specfied
                        if (!props.propositionsObj[measureID] || !user.petitionSections[measureID] || user.petitionSections[measureID].length === 0) return "";
                        //will below be available?
                        const measure = props.propositionsObj[measureID];
                        //measureID should still be available in below map?
                        const petitionCards = user.petitionSections[measureID].map((petitionID) => {
                            //put up some default values in case of missing ones
                            let status = "requested";
                            let cardText = <Card.Text>Status: Requested</Card.Text>;
                            let mailedBack = false;
                            let signatures = 0;
                            let signaturesGathered = 0;
                            //now assign the actual values
                            if (user.petitionSections[petitionID]) {
                                status = user.petitionSections[petitionID]['status'];
                                const timestamp = user.petitionSections[petitionID][status + '_timestamp'];
                                signatures = user.petitionSections[petitionID]['signatures'] || 0;
                                signaturesGathered = user.petitionSections[petitionID]['signaturesGathered'] || 0;
                                cardText = (
                                    <div style={{margin: '0em', padding: '0em'}}>
                                        <Card.Text style={{margin: '0em', padding: '0em'}}>{capitalize(status).replace('_', ' ')} on: {dateFromTimestamp(timestamp)}</Card.Text>
                                    </div>
                                )
                                if (user.petitionSections[petitionID].status === "mailed_in") mailedBack = true;
                            }

                            return (
                                <Card key={petitionID} style={{margin: '0.25em 0.5em', width: '12em'}}>
                                    <Card.Header>
                                        <Link to={"/measure/" + measureID}>
                                            {measure.rBallotTitle ? 
                                                measure.rBallotTitle.length > 42 ? 
                                                    measure.rBallotTitle.slice(0, 39) + "..."
                                                : 
                                                    measure.rBallotTitle
                                            :
                                                measure.BallotpediaTitle}
                                        </Link>
                                    </Card.Header>
                                    <Card.Body style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                        { cardText }
                                        Signatures: {
                                            status === "requested" || status === "downloaded" ? 
                                                (signatures + " possible") 
                                                : 
                                                status === "mailed_in" ?
                                                (signaturesGathered)
                                                :
                                                ""
                                        }
                                    </Card.Body>  
                                    {userContext && userContext.uid === id ?
                                        <Card.Footer>
                                        {  !mailedBack ?
                                            <Link to={"/managepetitionsection/" + petitionID}>Manage and Submit</Link>
                                        :
                                            <SharingDropdown 
                                                size={24} 
                                                measureID={measure.id} 
                                                shareTitle={"I gathered " + signaturesGathered + " signature" + (signaturesGathered === 1 ? "" : "s") + " for the " + measure.rBallotTitle + ". Help me get it on the ballot!"} 
                                                round={false}
                                            />
                                            /*<SharingLinks 
                                                round={false}
                                                spacing={'0em'}
                                                size={32} 
                                                shareUrl={"https://www.rballot.org/measure/" + measureID + '/' + id} 
                                                shareTitle={"I gathered " + signaturesGathered + " signature" + (signaturesGathered === 1 ? "" : "s") + " for the " + measure.rBallotTitle + ". Help me get it on the ballot!"}
                                                measureID={measureID}
                                            />*/
                                        }
                                        </Card.Footer>
                                    :
                                    ""
                                    }
                                </Card>
                            );
                        });
                        //not sure if the 2d array we will end up with will work correctly...
                        //tried doing return ...petitionCards but it complained
                        return petitionCards;
                    })}
                </div>
            </div>
        )

    }, [user, props, id, userContext]);

    //===========================For displaying comments=======================
    //3/7/24: Eventually, might include a timestamp on comments so they can be ordered...
    //but either way, don't want to separate them out, so combine them here into one object
    /*const measureLikesDislikes = {
        ...user.Likes,
        ...user.Dislikes
    }*/
    //Actually, going to do it above in the useEffect

    //const [copiedText, setCopiedText] = useState("");
    return ( 
        <Container>

            <div className="UserBackground"></div>

            <br />
            <br />
            <br />
            <br />

            {
                userContext && userContext.uid === id ?
                <div>
                    <p style={{textAlign: 'center', marginBottom: '1em'}}> 
                        {"Welcome to your page, " + userContext.displayName + "! "}
                    </p>
                    <div style={{textAlign: 'center', marginBottom: '1em'}}>
                        {
                            userContext.emailVerified ?
                            ""/*<p>
                            Your email has been verified successfully.
                            </p>*/
                            :
                            <UnverifiedEmail />
                        }
                    </div> 
                    {/*
                    <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center', paddingLeft: '1em'}}>
                        <Button
                            className="GetPacketButton"
                            onClick={() => {
                                navigator.clipboard.writeText("www.rballot.org/emailsignup/referredBy/" + id + "/");
                                setCopiedText("Copied link!");
                            }}
                        >
                            Copy my sharing link
                        </Button>
                        {copiedText}
                    </div>
                    */}
                </div>
                :
                ""
                /*<Button onClick={followUser} disabled={userContext ? false : true}>Follow</Button> */
            }

            <div className="UserPage">
                <div className="Left">
                    { !user ? "" :
                        <Container className="UserInfoContainer">
                            <h1 className="UserBox" /*style={{marginTop: '0em'}}*/>{ user.Nickname }</h1>
                            {/* Note: I was having problems with below, and realized that probably
                            as of now only the user who is logged in will be able to access userContext.emailVerified
                            <p className="UserBox"> { "Verified user: " + userContext ? userContext.emailVerified : false} </p>*/}
                            { user.City ? <p className="UserBox"> { "City: " + user.City } </p> : ""}
                            <p className="UserBox"> { "County: " + user.County }</p>
                            <p className="UserBox"> { "State: " + user.State } </p>
                            {/*<p className="UserBox"> { congressionalDistrict } </p>
                            <p className="UserBox"> { stateAssemblyDistrict } </p>
                            <p className="UserBox"> { stateSenateDistrict } </p>*/}
                        </Container>
                    }
                    { !user || !user.Upline || user.Upline.length === 0 ? "" :
                        <Container className="UserInfoContainer">
                            <h3 className="UserBox">Upline</h3>
                            <p className="UserBox" key={user.Upline[0]}>
                                <Link to={'/user/'+user.Upline[0]}>
                                    {user.Connections && user.Connections[user.Upline[0]] ? 
                                        user.Connections[user.Upline[0]]
                                        :
                                        user.Upline[0] 
                                    }
                                </Link>
                            </p>
                        </Container>
                    }
                    {/* !user || !user.Downline || user.Downline.length === 0 ? "" :
                        <Container className="UserInfoContainer">
                            <h3 className="UserBox">Downline</h3>
                            { user.Downline.map((id) => {
                                return (
                                    <p key={id} className="UserBox">
                                        <Link to={'/user/'+id}>
                                            {user.Connections && user.Connections[id] ? 
                                                user.Connections[id]
                                                :
                                                id
                                            }
                                        </Link>
                                    </p>
                                )
                            })}
                        </Container>
                        */}
                    {/*<div className="UserInfoContainer">
                        <h1 className="UserBox">Following</h1>
                        <p>{
                            !following ? "Not following anyone yet!" : 
                            following.size === 1 ? 
                            "Following " + following.size + " person." :
                            "Following " + following.size + " people."
                        }</p>
                        {following ? mapConnections(following.list, "user") : ""}
                        <h1 className="UserBox">Followers</h1>
                        <p>{
                            !followers ? "Not followed by anyone yet!" : 
                            followers.size === 1 ? 
                            "Followed by " + followers.size + " person." :
                            "Followed by " + followers.size + " people."
                        }</p>
                        {followers ? mapConnections(followers.list, "user") : ""}
                        {/*<h1 className="UserBox">Parties</h1>
                        <p>{
                            !parties ? "Not in any parties yet!" :
                            parties.size === 1 ?
                            "Member of " + parties.size + " party." :
                            "Member of " + parties.size + " parties."
                        }</p>
                        <h1 className="UserBox">Interests</h1>
                        <p>{
                            !interests ? "No interests yet!" :
                            interests.size === 1 ?
                            "Cares about " + interests.size + " interest." :
                            "Cares about " + interests.size + " interests." 
                        }</p>
                    </div> */}
                </div>


                <div className="Right">
                    <Container style={{border: '1px solid pink', borderRadius: '0px 7px 7px 0px'}}>
                        {/*<Posts 
                            location={db.doc('users/' + id)} 
                            maxDepth={2} 
                            id={id}
                            destinationName={ userContext ? userContext.displayName : undefined }
                        />*/}
                        {/* !user ? "" :
                            <div className="UserBox" style={{textAlign: 'center'}}>
                                <h2>{userContext && id === userContext.uid ? "My " : ""}Impact</h2>
                                <p className="ImpactStats">
                                    <span>Measures: {<strong>{user.petitionSections && user.petitionSections.count ? user.petitionSections.count : '0'}</strong>}</span>
                                    <span>Signatures: {<strong>{user.signatures ? (user.signatures.total || '0')+" + "+(user.signatures.totalProspective || '0') : '0'}</strong>}</span>
                                    <span>People: {<strong>{user.Downline ? user.Downline.length : '0'}</strong>}</span>
                                </p>
                            </div>
                        }

                    {usersSignatures ? usersSignatures : ""*/}

                        <div>
                            {!user || user==="" ? "" : 
                            Object.entries(measureLikesDislikes).map(([measureId, reaction]) => {
                                return (
                                    <div className="UserBox" key={measureId}>
                                        <strong>{user.Nickname}</strong> {reaction.type === "like" ? "liked " : "disliked "}
                                        <Link to={"/measure/"+measureId}>
                                            {!props.propositionsObj || !(measureId in props.propositionsObj) ? "a measure" :
                                            props.propositionsObj[measureId].BallotName || props.propositionsObj[measureId].rBallotTitle || props.propositionsObj[measureId].BallotpediaTitle
                                            }
                                        </Link>
                                        <br />
                                        <span style={{fontSize: '0.8em', color: 'gray', fontStyle: 'italic'}}>
                                            { reaction.timestamp ? datetimeFromTimestamp(reaction.timestamp) : ""}
                                        </span>
                                        <div style={{margin: '1em 1em 1em 0em', display:'flex',flexWrap:'wrap'}}>
                                            {reaction.descriptors.map((descriptor) => {
                                                return (
                                                    <span
                                                        key={descriptor}
                                                        style={{
                                                            backgroundColor: reaction.type === 'like' ? 'lightgreen' : 'pink',
                                                            padding: '0.35em',
                                                            borderRadius: '10px',
                                                            boxShadow: '3px 3px 5px lightgray',
                                                            margin: '0.5em'
                                                        }}
                                                    >
                                                        {descriptor}
                                                    </span>
                                                );
                                            })}
                                        </div>
                                        <span>"{reaction.comment}"</span>
                                        <br />
                                        <ReactionsComments 
                                            measureId={measureId} 
                                            authorId={id} 
                                            vote={reaction}
                                            setVotes={(newVote) => setMeasureLikesDislikes({ ...measureLikesDislikes, [measureId]: { ...newVote } })}
                                            userReaction={
                                                !userContext ? "" :
                                                'Likes' in reaction && reaction.Likes.includes(userContext.uid) ?
                                                "Like" :
                                                reaction.Loves && reaction.Loves.includes(userContext.uid) ?
                                                "Love" :
                                                reaction.Insights && reaction.Insights.includes(userContext.uid) ?
                                                "Insight" :
                                                reaction.Questions && reaction.Questions.includes(userContext.uid) ?
                                                "Question" :
                                                ""
                                            }
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </Container>
                </div>
            </div>

            {/*<div className="LoadButton">
                <Button>Load More</Button>
                    </div>*/}

        </Container>
    )

    /**
     * Maps connection data to a displayable format
     * @param {Array} connections 
     * @returns 
     *
    function mapConnections(connections, type)
    {
        if (!connections) return;

        let mappedConnections = connections.map((connection) => 
        {
            return (
                <Link key={connection.ID} to={"/" + type + "/" + connection.ID}>{connection.name}</Link>
            )
        })

        return mappedConnections;
    }*/

    //These were written in chat.js first. Need to abstract them to a helper file
    
    /**
    * Turn a timestamp into a string
    * in locale time or, if 'null',
    * into the string 'Sending...'
    * @param {timestamp} timestamp 
    */
    /*function dateFromTimestamp(timestamp)
    {
         //if the server hasn't received message yet
         if (timestamp === null) return "Sending...";
 
         console.log(timestamp);
         let date = timestamp.toDate();
         return date.toLocaleDateString();
    }*/
}

export default User;