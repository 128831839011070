/* Copyright (C) 2023 Christian Miley - All Rights Reserved */

/** CirculatorVerification.js
This page will present the person who wants to circulate a petition
with all of the things they need to fill out to receive access token
Key question: do I need this for the volunteers even? It would be good
for them to have some training, but they won’t be signing the declaration
until they are done collecting signatures anymore… I guess that is the
CirculationTutorial.js page, which will be for helping with the end
and mailing process. This will be information about not harassing
people and what not.
 */

import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import '../Entry.css';
import { UserContext, PersonContext } from '../../Context.js';
import { db, timestamp } from '../../firebase.js';
import {countiesByState} from '../Information.js';
import Loading from '../Loading.js';

//props will hold terms, which will allow us to log what version
//of the terms and conditions the person has indicated agreement to
function CirculatorVerification(props)
{
   const navigate = useNavigate();
   //if we are coming directly from a measure they want to circulate
   const { id, state } = useParams();

   //should already be a user
   const userContext = useContext(UserContext);
   const personContext = useContext(PersonContext);

   //terms
   const [terms, setTerms] = useState(undefined);
   useEffect(() => {
      if (!props || !props.terms) return;
      setTerms(props.terms);
   }, [props]);

   const [firstName, setFirstName] = useState("");
   const [lastName, setLastName] = useState("");
   const [is18, setIs18] = useState(false);
   const [termsAgreement, setTermsAgreement] = useState(false);
   //const [city, setCity] = useState("");
   //const [state, setState] = useState("");
   const [county, setCounty] = useState("");

   const [errorMessage, setErrorMessage] = useState("");

   //should look into suspense
   useEffect(() => {
      if (personContext) {
         setCounty(personContext.County);
      }
   }, [personContext])


   //function to request to be classified as a circulator for this
   //petition (if sent by a specific one)
   async function requestCirculatorAccess()
   {

      console.log("Requesting circulator access", id ? " to initiative " + id : "", " for ", firstName, " now.");

      let requestRef;
      let requestInfo = {
         First_Name: firstName,
         Last_Name: lastName,
         County: county,
         is18: is18,
         termsAgreement: termsAgreement,
         termsID: terms.id,
         status: 'new',
         request_timestamp: timestamp(),
         //below two needed for 'waiting' requests
         measure: id,
         category: 'circulatorAccess'
      }

      //if no user signed in, switch to start of sign up flow
      if (!userContext) {
         navigate('/EmailSignUp/CirculatorVerification/' + id + '/California');
         return;
      } else if (userContext.emailVerified) {
         //new path to be able to restrict writable requests paths by user (? old note)
         requestRef = db.collection('requests')
                        .doc(userContext.uid)
                        .collection('organizations')
                        .doc(id)
                        .collection('circulatorAccess')
                        .doc()
      //if the user exists but is not verified, write a waiting request
      } else if (!userContext.emailVerified) {
         requestInfo['status'] = 'email-not-verified';
         requestRef = db.collection('requests')
                        .doc(userContext.uid)
                        .collection('waiting')
                        .doc()
      }

      await requestRef.set(requestInfo)
         .then(() => console.log("Requested circulator status for ", firstName, " to measure #", id));

      return (requestRef);
   }

   //this will emulate the object that we will pass through a separate file
   const countyOptions = {};
   countyOptions["California"] = (
      <Form.Control required as="select" value={county} onChange={(e) => setCounty(e.target.value)}>
         <option key={"select"} value="">Select county</option>
         {countiesByState["California"].map((county) => <option key={county}>{county}</option>)}
      </Form.Control>
   );

   countyOptions["Oregon"] = (
      <Form.Control required as="select" value={county} onChange={(e) => setCounty(e.target.value)}>
         <option key={"select"} value="">Select county</option>
         {countiesByState["Oregon"].map((county) => <option key={county}>{county}</option>)}
      </Form.Control>
   );

   const [validated, setValidated] = useState(false);
   const [requestRef, setRequestRef] = useState(undefined);
   //for loading animation
   const [submitting, setSubmitting] = useState(false); 

   //this function should also set up a listener on the request, so that it can
   //tell when it has been fulfilled.
   //needs to set up a loading icon, too
   const onSubmit = async (e) => {
      e.preventDefault(); 
      setSubmitting(true);
      setErrorMessage("");

      const form = e.currentTarget;
      if (form.checkValidity() === false) {
         e.stopPropagation();
         setValidated(true);
         setSubmitting(false);
         return;
      }

      const ref = await requestCirculatorAccess();
      setRequestRef(ref);
   }

   //once a request has been made, listen to its outcome
   useEffect(() => {
      if (!requestRef || !userContext) return;

      //this doesn't seem to be firing despite the request being modified by server
      const unsubscribe = requestRef.onSnapshot(function(doc) {
         if (doc.exists) {
            const data = doc.data();
            console.log(data);
            if (data.status === "success") {
               //here, we should redirect as email must be verified already
               setSubmitting(false);
               console.log("Success!");
               navigate("/measure/" + id + "/campaign");
            } else if (data.status === "rejected") {
               setSubmitting(false);
               console.error(data.error);
               setErrorMessage(data.error);
            //below not implemented elsewhere yet
            //} else if (data.status === "waiting") {
            //   console.log("Your request requires manual evaluation. Check your email for updates.");
            //   setErrorMessage("Your request requires manual evaluation. Check your email for updates.");
            } else if (data.status === "new") {
               console.log("Request not evaluated yet...");
            } else if (data.status === 'email-not-verified') {
               console.log("You must verify your email to complete your request.");
               setErrorMessage("You must verify your email to complete your request.");
               navigate('/user/' + userContext.uid);
            }
         } else {
            console.log("No such request yet: " + requestRef.id);
            return;
         }
      })

      return function cleanUp() {
         unsubscribe();
      }

   }, [requestRef, userContext, id, navigate]);

   //let's see if we can get the loading animation to work correctly
   //OK. So for some reason, when I have the following useState
   //and useEffect, it works correctly, even though it's not in use.
   //When I don't, it does not work correctly. I imagine it has to do
   //with having a useEffect working on submitted that is doing something,
   //but it doesn't work when it just console.logs something. Perhaps the
   //more intensive useState operation matters? I am not sure.
   //note: it seems like when I don't have this, the loading animation
   //doesn't start until requestRef useEffect fires or something
   //second note: All that needs to happen for it work correctly is that
   //this needs to assign some html. If it's just a number, doesn't work.
   //I hate this.
   const [submitSection, setSubmitSection] = useState("");
   useEffect(() => {
      if (submitting) {
         setSubmitSection(<div></div>);
      }
   }, [submitting]);

   return (
      <Container className="SignUpContainer">
         <div className="Background"></div>
         <div className="Background2"></div>

         <Link to={"/"}><h2 className="SignUpHeader">rBallot</h2></Link>

         <div className = "Guidelines">
            <p>Let's get you set up to gather signatures for measure {id}!
            </p>
            <p>
               We'll need you to clarify some information about yourself, and 
               agree to our Terms and Conditions and Privacy Policy.
            </p>
         <hr/>
         </div>

         <Form 
            className="SignUpForm" 
            noValidate
            validated={validated}
            onSubmit = {(e) => onSubmit(e)}
         >

         <Row className="SignUpRow">
            <Col>
               <Form.Group className="SignUpRow" as={Form.Col}>
                  <Form.Label>First Name</Form.Label>
                  <Form.Control 
                     required
                     type = "text" 
                     placeholder = "Enter first name" 
                     name="first_name" 
                     value={firstName} 
                     onChange={(e) => setFirstName(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                     Please provide your first name.
                  </Form.Control.Feedback>
               </Form.Group>
            </Col>
            <Col>
               <Form.Group className="SignUpRow" >
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                     required
                     type = "text" 
                     placeholder = "Enter last name" 
                     name="last_name" 
                     value={lastName} 
                     onChange={(e) => setLastName(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                     Please provide your last name.
                  </Form.Control.Feedback>
               </Form.Group>
            </Col>
         </Row>

         <Row className="SignUpRow">
            <Form.Group>
               <Form.Label>Are you 18 years of age or older?</Form.Label>
               <Form.Check 
                  required
                  name = "ageVerification" 
                  value={is18} 
                  onChange={(e) => setIs18(!is18)}
                  label={"Yes, I am 18 years of age or older"}
                  feedback="You must confirm before submitting."
                  feedbackType="invalid"
               />
            </Form.Group>
         </Row>
         
         <Row className="SignUpRow">
            <Form.Group  className="SignUpRowItem" as = {Form.Col} controlId="formBasicCounty">
               <Form.Label>County</Form.Label>
                {countyOptions[state]}
               <Form.Text>Confirm where you will be circulating this petition.</Form.Text>
               <Form.Control.Feedback type="invalid">
                  Please indicate which county you plan to gather signatures in.
               </Form.Control.Feedback>
            </Form.Group>
         </Row>

         <Row>
            <Form.Text className="ErrorMessage">{errorMessage}</Form.Text>
         </Row>

         {submitting ? <Loading /> : ""}
         {submitting ? "" :
         <Button 
            className="SignUpSubmit" 
            variant = 'primary' 
            type = 'submit'
         >
               Sign Up To Circulate
         </Button>}

         {/*<p style={{color: 'gray', fontSize: '0.8em', marginTop: '2em'}}>
            Legal agreement goes here
         </p>*/}

         <Row className="SignUpRow">
            <Form.Group style={{color: 'gray', fontSize: '0.8em', marginTop: '2em', textAlign: 'center'}}>
               <Form.Label>
                  Check to agree to the {" "}
                  <Link to="/terms" target="_blank">
                     Terms and Conditions and Privacy Policy
                  </Link>
                  , including {" "}
                  <Link to="/terms#Circulators" target="_blank">
                  using the Services and Products as a Signature Gatherer or Circulator.
               </Link>
               </Form.Label>
               <div style={{display: 'flex', justifyContent: 'center', gap: '1em'}}>
               <Form.Check.Input 
                  required
                  style={{flexShrink: '0'}}
                  name = "termsAgreement" 
                  value={termsAgreement} 
                  onChange={(e) => setTermsAgreement(!termsAgreement)}
               />
               <Form.Check.Label>
                  I have read and agree to the Terms and Conditions and Privacy Policy.
               </Form.Check.Label>
               <Form.Control.Feedback type="invalid">
                  You must agree before submitting.
               </Form.Control.Feedback>
               </div>
            </Form.Group>
         </Row>

         {/*<Row className="SignUpRow">
            <Form.Group style={{display: 'flex', justifyContent: 'center', color: 'gray', fontSize: '0.8em', marginTop: '2em'}}>
               <Form.Label>
                  I acknowledge that it is a misdemeanor under state law (Section 18650 of the Elections Code) to knowingly
                  or willfully allow the signatures on an initiative petition to be used for any purpose other than qualification
                  of the proposed measure for the ballot.
               </Form.Label>
               <Form.Check 
                  required
                  name = "termsAgreement" 
                  value={termsAgreement} 
                  onChange={(e) => setTermsAgreement(!termsAgreement)}
                  label={"I certify that I will not knowingly or willfully allow the signatures for this initiative to be used for any purpose other than qualification of the measure for the ballot."}
                  feedback="You must confirm before submitting."
                  feedbackType="invalid"
               />
            </Form.Group>
            </Row>*/}

         </Form>

      </Container>

    )

}

export default CirculatorVerification;
