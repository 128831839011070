/* Copyright (C) 2023 Christian Miley - All Rights Reserved */

/**
 * UnverifiedEmail.js
 * This component will render a way for the user to resend their
 * verification email, and generally remind them they must verify.
 * Can be deploying in User.js, EditMeasure.js, OrganizationPage.js, etc.
 */

import React from 'react';
import { Button } from 'react-bootstrap';
import { sendFirebaseEmailVerification } from '../firebase.js';
import './Entry.css';

function UnverifiedEmail() {
    return (
        <div>
            <p>
                Your email has not yet been verified. 
                You should have received an email from noreply@rballot.org to verify.
            </p>
            <Button className="NotBlueButton" onClick={() => sendFirebaseEmailVerification()}>Resend Verification Email</Button>
        </div>
    )
}

export default UnverifiedEmail;