/* Copyright (C) 2023 Christian Miley - All Rights Reserved */

/**
 * PhoneVerification.js
 * This file will render a page where a phone number
 * verification code can be entered, and it will update
 * the currently signed in user's phone number in their profile.
 */

import React, { useState, useEffect, useContext } from 'react';
import { updateFirebasePhoneNumber, resubmitRequestsUponVerification/*reloadUser*/ } from '../../firebase.js';
import { Container, Form, Button } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import './PhoneVerification.css';
import { UserContext } from '../../Context.js';

function PhoneVerification(){
    const navigate = useNavigate();
    const userContext = useContext(UserContext);
    const { verificationID, phoneNumber, measureID } = useParams();
    const [verificationCode, setVerificationCode] = useState("");
    const [validated, setValidated] = useState(false);
    const [feedback, setFeedback] = useState("");

    const onSubmit = async (e) => {
        e.preventDefault();
        console.log(userContext);

        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true);
            return;
        }

        setValidated(true);
        const result = await updateFirebasePhoneNumber(verificationID, verificationCode);
        console.log(result);
        //await reloadUser(); //is this needed? I don't think so
        console.log(userContext);
    }

    useEffect(() => {
        if (!userContext || !navigate) return;
        console.log(userContext.phoneNumber);
        //there may be a phone number, but it is not the one we are verifying. Abort.
        if (userContext.phoneNumber !== phoneNumber) return;

        //here, we will grab all requests with status 'phone-not-verified' that the user
        //has submitted and resubmit them, then after that we will redirect
        const handleRequestsAwaitingPhoneVerification = async () => {
            await resubmitRequestsUponVerification('phone-not-verified')
                .catch((error) => {
                    console.error(error);
                    setFeedback("Error: " + error);
                });

            //navigate("/ViewProponentRequests");
            //redirect to the edit measure page for this measure
            navigate("/measure/" + measureID + "/edit");
        }

        handleRequestsAwaitingPhoneVerification();

    }, [userContext, phoneNumber, navigate]);

    return (
        <Container className="PhoneVerificationContainer">
            <br />
            <br />
            <br />
            <br />
            <Form
                noValidate
                validated={validated}
                onSubmit={(e) => onSubmit(e)}
                className="PhoneVerification"
            >
                <Form.Group className="PhoneVerification">
                    <Form.Label>Enter Phone Verification Code</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        name="verificationCode"
                        value={verificationCode}
                        onChange={(e) => setVerificationCode(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                        Enter the code you received in a text message.
                    </Form.Control.Feedback>
                </Form.Group>
                <Button 
                    type="submit"
                    className = "submitButton"
                >
                    Submit
                </Button>
            </Form>
            <p>
                {feedback}
            </p>
        </Container>
    );
}

export default PhoneVerification;