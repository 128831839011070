/* Copyright (C) 2023 Christian Miley - All Rights Reserved */

/**
 * UserFeedPage.js
 * List of propositions for user to click on.
 */
import React, { useContext, useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
// import { daysUntilTimestamp, AddTooltip } from '../Utility.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container/*, Card, Button, ButtonGroup, Form, OverlayTrigger, Tooltip*/ } from 'react-bootstrap';
import { UserContext, PersonContext } from '../../Context.js';
import { allStates, countiesByState } from '../Information.js';
//import testPropositions from '../../TestPropositions.js';
//import scrapedPropositions from '../../ScrapedPropositions.js';
import Loading from '../Loading.js';
import './UserFeedPage.css';
import '../../Variables.css';
// import Follow from '../Follow.js';
import MeasureCard from '../MeasureCard.js';
import JurisdictionForm from '../JurisdictionForm.js';

//this function displays a page of propositions, eventually based
//on the user's search or location, but now just of all propositions
//props includes propositions (from displayedPropositions),
//setPropositions (really is setDisplayedPropositions), and setPersonInfo
//and now setPropositionsObj and propositionsObj
function UserFeedPage(props)
{
    //const signaturesContext = useContext(SignaturesContext);
    const personContext = useContext(PersonContext);
    const userContext = useContext(UserContext);

    //to set personInfo if a guest is using the site
    //const [county, setCounty] = useState(undefined);
    //const [state, setState] = useState(undefined);

    //for loading animation
    const [finished, setFinished] = useState(false);

    // local propositionsObj
    const [propositionsObj, setPropositionsObj] = useState(props.propositionsObj);
    //const setMainPropositionsObj = props.setPropositionsObj;

    //============Refocus previous element on rerender due to props change===========
    //const [refocusElementId, setRefocusElementId] = useState();
    //I'm wondering if decoupling directly from props is a good idea...
    //although, this might be good in general, but maybe is bad to do here if we don't
    //do it entirely in general? Hmm...
    /*const [measures, setMeasures] = useState();
    useEffect(() => {
        if (!props || !props.propositionsObj) return;
        setMeasures(props.propositionsObj);
    }, [props, props.propositionsObj]);*/
    //this useEffect will actually do the changing, by telling the window to focus
    //on the element that is refreshing afterwards...
    /*useEffect(() => {
        console.log(refocusElementId);
        if (!refocusElementId) return;
        const element = document.getElementById(refocusElementId);
        window.focus(element);
    }, [refocusElementId]);*/

    // this useEffect will propagate the changes to propositionsObj up to App.js
    // but ONLY when this component unmounts, i.e. user navigates away from UserFeedPage,
    // which will mean that the entire feed page should not refresh on likes/dislikes
    /*useEffect(() => {
        // component mounted

        return () => {
            //console.log("Unmounting user feed page...");
            // component unmounted
            // 3/6/24: Not using props here... I think this was to prevent rerenders?
            setMainPropositionsObj(propositionsObj);
        };
    }, [setMainPropositionsObj, propositionsObj]);*/

    /**This function returns an array of React-Router Links containing
    React Cards with some information on the proposition it links to */
    //2/25/24: I think I need to pull these out into components, so that they can be updated without
    //fully reloading the whole feed... Hopefully, at least, that's how it will work
    function listPropositions(propositionsObj, signatures, user, personInfo)
    {
        //console.log(propositionsObj);
        /* This section will allow guests to explore and then sign up */

        //Display a loading animation until we know what kind of user we have
        //"Not loaded" means we haven't loaded it yet.
        //Second is because userContext is loaded before personInfo by App.js
        //Second check should be run even more rarely than first
        if (/*!finished || */userContext === "Not loaded" || (userContext && !personInfo)) {
            return (
                <Loading minimumIterations={1} setFinished={setFinished}/>
            )
        }

        // we must have loaded userContext, or we are a guest
        // so, let's check personInfo to see which is which

        if (!personInfo || !personInfo.State || personInfo.State === "") {
            console.log("Displaying a means for guest user to set personInfo.");

            return (
                <JurisdictionForm setPersonInfo={props.setPersonInfo} jurisdictions={props.indexing ? props.indexing.jurisdictions : undefined}/>
            );

            /*const countyOptions = {};
            countyOptions["California"] = (
               <Form.Control as="select" value={county} onChange={(e) => setCounty(e.target.value)}>
                  <option key={"default"}>Select county</option>
                  {countiesByState["California"].map((county) => <option key={county}>{county}</option>)}
               </Form.Control>
            );
         
            countyOptions["Oregon"] = (
               <Form.Control as="select" value={county} onChange={(e) => setCounty(e.target.value)}>
                  <option key={"default"}>Select county</option>
                  {countiesByState["Oregon"].map((county) => <option key={county}>{county}</option>)}
               </Form.Control>
            );
        
            return (
                <Form 
                    className="ResidencyForm"
                    onSubmit = {(e) => { e.preventDefault();
                                         props.setPersonInfo({State : state, County : county}); 
                                        }
                                }
                >
                    <Form.Group>
                        <Form.Label>State</Form.Label>
                        <Form.Control as="select" value={state} onChange={(e) => setState(e.target.value)}>
                            <option>Select state</option>
                            {allStates.map((state) => {
                                return(
                                    <option key={state}>{state}</option>
                                );
                            })}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>County</Form.Label>
                        {(state && state !== "Choose state" && state !== "") ?
                            countyOptions[state] : 
                            (
                            <Form.Control as="select" disabled>
                                <option>Pick a State first</option>
                            </Form.Control>
                        )}
                    </Form.Group>
                    <Button className="SignUpSubmit" variant = 'primary' type = 'submit'>
                        See measures in my area
                    </Button>
                </Form>
            );*/
        }

        //----there must be a personInfo in order for us to continue below

        // there must be measures specified for display to continue below
        if (!propositionsObj.ordering) return;
        if (propositionsObj.ordering.length === 0) return(
            <div style={{marginTop:'5em'}}>
                <p>There are currently no measures listed in the specified jurisdiction(s).</p>
            </div>
        );

        let list = [];
        let signedList = [];
        let unsignedList = [];
        let hiddenList = [];
        let volunteeringList = [];
        let coordinatingList = []; //put the ones that are directly involved with rBallot first
        // measures can be on the ballot, but not necessarily on an election that is immediate...
        // usually them having a 'BallotName' like Measure M or Proposition 12 indicates they are imminent.
        // Otherwise, they should probably be interspersed with ones seeking the ballot...
        // Ultimately, will need to develop an algorithm to decide what measures are shown to people.
        // First one may just be doing a zipper on onBallotList and unsignedList *shrug*
        const ballotNameIssuedList = [];
        const onBallotList = [];
        propositionsObj.ordering.forEach(function (id) {

            const propCard = (
                <MeasureCard 
                    key={id}
                    id={id}
                    personInfo={personContext}
                    setPersonInfo={props.setPersonInfo}
                    measure={propositionsObj[id]}
                    //propositionsObj={propositionsObj}
                    setPropositionsObj={setPropositionsObj}
                />
            );

            //this relied on getUserSignatureStatus before; now it should be the...
            //contrapositive? I think? of the first check in getUserSignatureStatus
            /*if (proposition.id && signatures && signatures[proposition.id])
            {
                //if the user has hidden this petition, 
                console.log(personInfo.hiddenMeasures);
                if (personInfo.hiddenMeasures && personInfo.hiddenMeasures.indexOf(proposition.id) !== -1) {
                //if (signatures[proposition.id].hiddenByUser) {
                    hiddenList.push(propCard);
                    //the two next possibilities are there to see if the
                    //user hidden, then unhidden the petition,
                    //creating a signature reference without signing
                } else if (signatures[proposition.id].title) {
                    signedList.push(propCard);
                } else {
                    unsignedList.push(propCard);
                }*/
            if (id && personInfo.hiddenMeasures && personInfo.hiddenMeasures.indexOf(id) !== -1) {
                hiddenList.push(propCard);
            } else if (propositionsObj[id].BallotName) {
                ballotNameIssuedList.push(propCard);
            } else if (propositionsObj[id].Status === 'Qualified for the ballot' || propositionsObj[id].Status === 'On the ballot') {
                onBallotList.push(propCard);
            } else if (propositionsObj[id].rBallotStatus === "volunteering") {
                if (propositionsObj[id].rBallotCoordination) {
                    coordinatingList.push(propCard);
                } else {
                    volunteeringList.push(propCard);
                }
            //} else if (personInfo.following.indexOf(proposition.id) !== -1){
            //here, once following is changed to be a field on user's document,
            //put measures being followed first
            } else {
                unsignedList.push(propCard);
            }

        });

        // chat gpt says this is a simple implementation of the Fisher-Yates shuffle algorithm
        /*function shuffleArray(array) {
            for (let i = array.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [array[i], array[j]] = [array[j], array[i]];
            }
            return array;
        }*/

        // Custom comparison function to sort based on sum of Likes and Dislikes lengths
        function orderByVoteCount(array) {
            return array.sort((a, b) => {
                const sumA = (a.props.measure.Likes ? Object.keys(a.props.measure.Likes).length : 0) + (a.props.measure.Dislikes ? Object.keys(a.props.measure.Dislikes).length : 0);
                const sumB = (b.props.measure.Likes ? Object.keys(b.props.measure.Likes).length : 0) + (b.props.measure.Dislikes ? Object.keys(b.props.measure.Dislikes).length : 0);
                return sumB - sumA; // Sort in descending order
            });
        }

        // here, to make it more dynamic, let's do a zipper on the two arrays
        // thanks to chatgpt for below:
        function intersperseArrays(array1, array2) {
            const combinedArray = [];
            const maxLength = Math.max(array1.length, array2.length);
        
            const reorderedArray1 = orderByVoteCount(array1);
            const reorderedArray2 = orderByVoteCount(array2);

            for (let i = 0; i < maxLength; i++) {
                if (i < reorderedArray1.length) {
                    combinedArray.push(reorderedArray1[i]);
                }
                if (i < reorderedArray2.length) {
                    combinedArray.push(reorderedArray2[i]);
                }
            }
        
            return combinedArray;
        }

        const zipperList = intersperseArrays(onBallotList, unsignedList);

        // create the full feed below
        list = [...ballotNameIssuedList, ...coordinatingList, ...volunteeringList, ...zipperList,/*...onBallotList, ...unsignedList,*/ ...signedList, 
            <h1 
                key='hiddenHeader' 
                style={{margin: '0em 3em 1em', textAlign: 'center', padding: '0.5em', /*border: '1px solid black',*/ backgroundColor: 'white', borderRadius: '8px', boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.2)'}}
            >
                Hidden Measures
            </h1>,
            ...hiddenList
        ];

        //this is just the hidden measures header, which we don't want to show up yet
        if (list.length === 1) return [];

        return list;
    }

    return (
        <Container style={{maxWidth: '1000px'}}>
            <div className="FeedBackground"></div>

            <div className="Feed">
                <br />
                {listPropositions(
                    /*Object.values(props.propositionsObj),*/
                    //pass the ordering array to display MeasureCards
                    propositionsObj ? propositionsObj : {},
                    //props.propositions,
                    [],//signaturesContext ? signaturesContext.signatures : [],
                    userContext,
                    personContext, //? {County: personContext.County, State: personContext.State, City: personContext.City} : undefined
                )}
            </div>

            {/* signaturesContext && signaturesContext.unorderedSignatures.length >= 7 ?
            <div className="MobileSignBox">
                <Link className="MobileSignLink" to="/checkoutpage">
                    {"Mail in " +
                    (signaturesContext ? signaturesContext.unorderedSignatures.length : "") + 
                    " signature" + (signaturesContext ? (signaturesContext.unorderedSignatures.length === 1 ? "" : "s") : "")}
                </Link>
            </div> : signaturesContext ?
            <div className="MobileSignBox">
                <span className="MobileSignSpan">
                    {"Sign " + (7 - 
                    (signaturesContext.unorderedSignatures ?
                        signaturesContext.unorderedSignatures.length :
                        0
                    ))
                    + " more to receive your letter!"
                    }
                </span>
            </div> :
            ""
            */}
        </Container>
    );

}

/**
 * this will return the correct main action
 * options will be: follow (if not active),
 * volunteer (if volunteering and user not a circulator),
 * organizationPage (if user is circulator || proponent)
 * @param {Object} measure
 * @param {Object} userContext 
 */
/*function OrganizationButton (props) {
    const measure = props.measure;
    const userContext = props.userContext;
    const personInfo = props.personInfo;
    const propositions = props.propositionsObj;
    const setPropositionsObj = props.setPropositions;

    //three of these are the same...
    switch (measure.rBallotStatus) {
        case undefined:
            if (userContext) {
                return (
                    <Follow 
                        user={userContext} 
                        id={measure.id} 
                        measure={measure}
                        following={personInfo.following && personInfo.following.indexOf(measure.id) !== -1 ? true : false}
                        // changed={changingFollow}
                        // setChanged={setChangingFollow}
                        setMeasure={(followers) => {
                            /*const newProps = [ ...props.propositions ];
                            newProps[measure.index] = { ...measure, Followers: followers };
                            props.setPropositions(newProps);*/ /*
                            //NOTE: below untested, since following no longer shows up on userFeedPage
                            const newPropsObj = { ...propositions };
                            newPropsObj[measure.id] = { ...measure, Followers: followers};
                            setPropositionsObj(newPropsObj);
                        }}
                        buttonClass={"OrgButton"}
                    />
                );
            } else {
                return (
                    <Link to={"/emailsignup"} className="OrgButton">Follow</Link>
                );
            }
        case "not active":
            if (userContext) {
                return (
                    <Follow 
                        user={userContext} 
                        id={measure.id} 
                        measure={measure}
                        following={personInfo.following && personInfo.following.indexOf(measure.id) !== -1 ? true : false}
                        // changed={changingFollow}
                        // setChanged={setChangingFollow}
                        setMeasure={(followers) => {
                            const newPropsObj = { ...propositions };
                            newPropsObj[measure.id] = { ...measure, Followers: followers };
                            setPropositionsObj(newPropsObj);
                        }}
                        buttonClass={"OrgButton"}
                    />
                );
            } else {
                return (
                    <Link to={"/emailsignup"} className="OrgButton">Follow</Link>
                );
            }
        case "signing up":
            if (userContext) {
                return (
                    <Follow 
                        user={userContext} 
                        id={measure.id} 
                        measure={measure}
                        following={personInfo.following && personInfo.following.indexOf(measure.id) !== -1 ? true : false}
                        // changed={changingFollow}
                        // setChanged={setChangingFollow}
                        setMeasure={(followers) => {
                            const newPropsObj = { ...propositions };
                            newPropsObj[measure.id] = { ...measure, Followers: followers };
                            setPropositionsObj(newPropsObj);
                        }}
                        buttonClass={"OrgButton"}
                    />
                );
            } else {
                return (
                    <Link to={"/emailsignup"} className="OrgButton">Follow</Link>
                );
            }
        case "volunteering":
            return (
                <Link to={"/measure/" + measure.id + "/sign"} className="OrgButton">Sign</Link>
            );
            //make sure the link and text are correct for every possibility
            /*let link, text;
            //guest user
            if (!userContext) {
                link = '/emailsignup/circulatorverification/' + measure.id + '/' + measure.JurisdictionName;
                text = 'Volunteer';
            //already affiliated user
            //this would be a lot cleaner if I were to use permissionObject...
            //but, that doesn't seem to always match up. If I were to include its
            //write in a transaction/batched write in cloud functions I could be
            //sure that it would match reality, and not use this
            } else if   (userContext.permissions &&
                        ((userContext.permissions.c && userContext.permissions.c.indexOf(measure.id) !== -1) ||
                        (userContext.permissions.p && userContext.permissions.p.indexOf(measure.id) !== -1) ||
                        (userContext.permissions.m && userContext.permissions.m.indexOf(measure.id) !== -1))) 
            {
                link = '/measure/' + measure.id + '/campaign';
                text = 'Campaign';
            //signed up user, but not affiliated with campaign
            } else {
                link = '/circulatorverification/' + measure.id + '/' + measure.JurisdictionName;
                text = 'Volunteer';
            }

            return (
                <Link to={link} className="OrgButton">
                    {text}
                </Link>
            )
            //break;*//*
        default:
    }
    return ""
}

//note: the signature thing is done. Will rename this soon.
//What this function should do is to correctly display the relevant buttons
//for different measures based on who is viewing and the rBallotStatus of the measure.
//User options: guest user, signed in user, user with access privileges to measure
//Measure options: not active/signing up, volunteering
//In future, follow button will be here, but it is not yet. In meantime, this means:
//not active measure/signing up measure + guest: explore button + follow (but redirects to sign up)
//not active measure/signing up measure + user: hide/unhide + explore buttons + follow button
//volunteering measure + guest: explore + volunteer button (redirects to signup first)
//volunteering measure + user: hide/unhide, explore, volunteer/organization
//oh right... and especially what's up with hidden measures
function getUserSignatureStatus(proposition, user, personInfo, propositions, setPropositions, propositionsObj)
{
    //if the user isn't signed in or has no personInfo, and the measure is volunteering
    if ((!user || !personInfo) && proposition.rBallotStatus === "volunteering") return (
        <Card.Footer>
            <ButtonGroup>
                <Link to={"/measure/" + proposition.id} className="SeeMoreButton" style={{marginLeft: '0em', borderRadius: '7px 0px 0px 7px'}}>
                    Explore
                </Link>
                <OrganizationButton 
                    measure={proposition}
                    userContext={user}
                    personInfo={personInfo}
                    propositions={propositions}
                    setPropositions={setPropositions}
                    propositionsObj={propositionsObj}
                />
            </ButtonGroup>
        </Card.Footer>
    );

    //if the user isn't signed in or has no personInfo, and the measure is not active
    if ((!user || !personInfo) && proposition.rBallotStatus !== "volunteering") return (
        <Card.Footer>
            <ButtonGroup>
                <Link to={"/measure/" + proposition.id} className="SeeMoreButton" style={{marginLeft: '0em', borderRadius: '7px 0px 0px 7px'}}>
                    Explore
                </Link>
                <OrganizationButton 
                    measure={proposition}
                    userContext={user}
                    personInfo={personInfo}
                    propositions={propositions}
                    setPropositions={setPropositions}
                    propositionsObj={propositionsObj}
                />
            </ButtonGroup>
        </Card.Footer>
    );

    //this isn't really the signature status, it's just the ability to sign
    //here, the user is signed up, and they have not hidden this measure
    if (!proposition  //why is this here? I guess just so proposition.id doesn't break?
        || !personInfo.hiddenMeasures
        || personInfo.hiddenMeasures.indexOf(proposition.id) === -1
    ) return (
        <Card.Footer>
            <ButtonGroup>
                <Button 
                    className="HideButton" 
                    onClick={() => setHide(proposition.id, user.uid, true) }
                >
                    Hide
                </Button>
                <Link to={"/measure/" + proposition.id} className="SeeMoreButton" style={{marginLeft: '0em'}}>
                    Explore
                </Link>
                <OrganizationButton 
                    measure={proposition}
                    userContext={user}
                    personInfo={personInfo}
                    propositions={propositions}
                    setPropositions={setPropositions}
                    propositionsObj={propositionsObj}
                />
                {/*<ServicesButtons measure={}/>*//*}
            </ButtonGroup>
        </Card.Footer>
    )

    //hidden propositions go to the end
    if (personInfo.hiddenMeasures && personInfo.hiddenMeasures.includes(proposition.id))
    {
        return (
            <Card.Footer style={{background: 'lightgray'}}>
                <ButtonGroup>
                    <Button className="HideButton" onClick={() => setHide(proposition.id, user.uid, false)}>
                        Unhide
                    </Button>
                    <Link to={"/measure/" + proposition.id} className="SeeMoreButton" style={{marginLeft: '0em'}}>
                        Explore
                    </Link>
                    <OrganizationButton 
                        measure={proposition}
                        userContext={user}
                        personInfo={personInfo}
                        propositions={propositions}
                        setPropositions={setPropositions}
                        propositionsObj={propositionsObj}
                    />
                </ButtonGroup>
            </Card.Footer>
        )
    }
}*/

export default UserFeedPage;

















/* Acknowledgements:
* Learned about the getOwnPropertyNames method to compare objects by
*     their fields @ http://adripofjavascript.com/blog/drips/object-
*     equality-in-javascript.html
* Learned to check of a document is in the database @ https://
*     stackoverflow.com/questions/47308159/whats-the-best-way-to-
*     check-if-a-firestore-record-exists-if-its-path-is-known
*/



/* Below should be moved to a different file at some point */

    //Below lines were in useEffect before (and were commented out)
        // add scraped propositions for extra testing, only do once
        // test if propositions correctly scraped
        // testScrapedPropositions();
        // uploadScrapedPropositions();
        // could not finish the below method
        // testUploadedPropositions();

    /**
     * Checks if the properties of obj1 and obj2 are equivalent.
     * @param {any} obj1
     * @param {any} obj2
     * @return true if properties of obj1 match properties of obj2,
     * false otherwise
     */
    /*function objectsEqual(obj1, obj2)
    {
        // get arrays of property names
        var obj1PropertyNames = Object.getOwnPropertyNames(obj1);
        var obj2PropertyNames = Object.getOwnPropertyNames(obj2);

        // check if both objects have the same number of properties
        if (obj1PropertyNames.length !== obj2PropertyNames.length)
        {
            console.log("Objects have different number of properties");
            return false;
        }

        // compare each pair of properties for mismatches
        var i;
        for (i = 0; i < obj1PropertyNames.length; i++)
        {
            // compare property names
            if (obj1PropertyNames[i] !== obj2PropertyNames[i])
            {
                console.log("Object property names differ: " +
                    obj1PropertyNames[i] + " !== " +
                    obj2PropertyNames[i]);
                return false;
            }

            // compare properties
            if (obj1[obj1PropertyNames[i]] !==
                obj2[obj2PropertyNames[i]])
            {
                console.log("Object properties differ: " +
                    obj1[obj1PropertyNames[i]] + " !== " +
                    obj2[obj2PropertyNames[i]])
                return false;
            }
        }

        // if no mismatch, return true
        return true;
    }

    /**
     * Checks if proposition is in database
     * @param {any} proposition
     * @returns true if proposition is in database, false otherwise
     */
    /*function propositionInDatabase(proposition)
    {
        var propositionId = proposition.Id
        var databasePropositions =
            db.collection("petitions").doc(propositionId);
        databasePropositions.get().then(function (doc)
        {
            if (doc.exists)
            {
                return true;
            }
            else
            {
                return false;
            }
        });
    }

    /**
     * Adds proposition to database
     * @param {any} proposition
     * @returns none
     */
    /*function addPropositionToDatabase(proposition)
    {
        var databasePropositions = db.collection("petitions");

        databasePropositions.doc(proposition.Id).set({
            Creator: "",
            Deadline: proposition.Deadline,
            Proponents: proposition.Proponents,
            Signatures: 0,
            SignaturesReq: proposition.SignaturesReq,
            Residency: ['State', 'California'],
            Summary: proposition.Summary,
            Text: 'N/A',
            Title: proposition.Title,
            signaturesArray: []
        });
    }

    /**
     * Uses test-propositions.json to check if scrapedPropositions.js
     * has correct propositions in correct format.
     * @params none
     * @return none
     */
    /*function testScrapedPropositions()
    {
        // for each proposition in test-propositions.js
        var i, j;
        for (i = 0; i < testPropositions.length; i++)
        {
            console.log("Starting test case " + (i + 1));

            var testProposition = testPropositions[i];

            // use flag to check if found match
            var foundMatch = false;

            // check if it is in scrapedPropositions.js
            for (j = 0; j < scrapedPropositions.length; j++)
            {
                // check to make sure propositions are identical
                var scrapedProposition = scrapedPropositions[j];

                if (objectsEqual(testProposition, scrapedProposition))
                {
                    // record success
                    console.log("Test case " + (i + 1) + ": pass");
                    foundMatch = true;
                    break;
                }
            }

            // if no match, fail
            if (!foundMatch)
            {
                // record failure
                console.log("Test case " + (i + 1) + ": fail");
            }
        }
    }

    /**
     * Stores propositions from ScrapedPropositions.js in database,
     * provided they aren't already in the database.
     * @params none
     * @return none
     */
    /*function uploadScrapedPropositions()
    {
        var i
        for (i = 0; i < scrapedPropositions.length; i++)
        {
            // make sure proposition not in database
            var scrapedProposition = scrapedPropositions[i];
            if (!propositionInDatabase(scrapedProposition))
            {
                addPropositionToDatabase(scrapedProposition);
            }
        }
    }

    /**
     * Tests if uploadScrapedPropositions() uploaded the propositions
     * to the database correctly.
     * @params none
     * @return none
     */
    /*
    function testUploadedPropositions()
    {
        var propositions = [];
        var i = 0;
        var databasePropositions = db.collection("petitions").get();
        databasePropositions.forEach(doc =>
        {
            propositions[i] = doc.data();
            i++;
        });

        // make sure that each proposition is in scrapedPropositions
        var j;
        for (i = 0; i < propositions.length; i++)
        {
            var fullProposition = propositions[i];
            console.log(toString(fullProposition))
            var proposition =
            {
                "Deadline": fullProposition.Deadline,
                "Proponents": fullProposition.Proponents,
                "SignaturesReq": fullProposition.SignaturesReq,
                "Summary": fullProposition.Summary,
                "Text": fullProposition.Text,
                "Title": fullProposition.Title
            }
            var foundMatch = false;
            for (j = 0; j < scrapedPropositions.length; j++)
            {
                var scrapedProposition = scrapedPropositions[j];
                if (objectsEqual(proposition, scrapedProposition))
                {
                    console.log("Test case " + (i + 1) + ": pass");
                    foundMatch = true;
                    break;
                }
            }

            if (!foundMatch)
            {
                console.log("Test case " + (i + 1) + ": fail");
            }
        }
    }
    */