/* Copyright (C) 2023 Christian Miley - All Rights Reserved */

import React from 'react';
import { useLocation } from 'react-router-dom';

function Notfound()
{
	let location = useLocation();
	return(
		<div className="Feed" style={{marginTop:"180px", padding:"40px"}}>
			<div className="Background"></div>
			<div className="Background2"></div>
			<h1> The page that you are looking for at <code>{location.pathname}</code> does not exist, sorry.</h1>
		</div>
	)
}

export default Notfound