/* Copyright (C) 2024 Christian Miley - All Rights Reserved */

/**
 * MeasureCard.js
 * This component will display a measure on userFeedPage, and take out
 * usage of measure context/measuresObj so that UserFeedPage doesn't
 * need to refresh due to a props change, just the individual components
 */

import React/*, { useContext, useState, useEffect }*/ from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
//import { MeasuresContext } from '../Context.js';
import LikesDislikes from './LikesDislikes.js';
import ServicesButtons from './ServicesButtons.js';
import { daysUntilTimestamp } from './Utility.js';

export default function MeasureCard(props) {
    //const [measuresContext, setMeasuresContext] = useContext(MeasuresContext);
    const personInfo = props.personInfo;
    const setPersonInfo = props.setPersonInfo;
    const measure = props.measure;
    //const measure = measuresContext[props.id];

    // to make sure it reloads when the measure changes (when it's liked or disliked)
    /*const [measure, setMeasure] = useState(undefined);
    useEffect(() => {
        if (!props || !props.measure) return;//.id || !measuresContext) return;
        setMeasure(props.measure);
    }, [props, props.measure]);*/


    // decide what title to display
    let title;
    /*switch (measure.rBallotStatus) {
        case undefined:
            title = measure.BallotpediaTitle;
            break;
        case "pending":
            title = measure.BallotpediaTitle;
            break;
        case "not active":
            title = measure.BallotpediaTitle;
            break;
        case "signing up":
            title = measure.BallotpediaTitle;
            break;
        case "volunteering":
            title = measure.rBallotTitle;
            break;
        default:
            title = "No relevant title."
    }*/
    if (!measure) return;    
    if (measure.BallotName) {
        title = (measure.JurisdictionName ? measure.JurisdictionName+" " : "") + measure.BallotName;
    } else if (measure.rBallotTitle) {
        title = measure.rBallotTitle;
    } else if (measure.BallotpediaTitle) {
        title = measure.BallotpediaTitle;
    }

    let signatureCount = "0";
    if (measure.SignaturesObj && measure.SignaturesObj['total']) signatureCount = measure.SignaturesObj['total'];
    if (measure.SignaturesObj && measure.SignaturesObj['totalProspective']) signatureCount += (" + " + measure.SignaturesObj['totalProspective'] /*+ " in progress"*/);
    //if (measure.CirculatorCount) signatureCount += measure.CirculatorCount;
    //{measure.Summary ? measure.Summary.slice(0, 220) + "..." : "No summary received."}
    let displayedSummary;
    if (measure.rBallotOneLiner) { 
        displayedSummary = measure.rBallotOneLiner; //.slice(0, 220) + "..."; }
    } else if (measure.Summary) {
        displayedSummary = measure.Summary;
    } else { 
        displayedSummary = "No summary available."; 
    }

    return (
        <div key={measure.id}>
            <Card className="PropCard" style={{boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.2)'}}>
                <div className="CardText">
                    <Card.Title style={{ fontWeight:"bold" }}>
                        <Link 
                            style={{color: 'black', textDecoration: 'none'}} 
                            to={"/measure/"+measure.id}
                        >
                            { title }
                        </Link>
                    </Card.Title>
                    {measure.Deadline ? <Card.Text style={{fontSize: '0.9em'}}>{daysUntilTimestamp(measure.Deadline)} days left to gather signatures</Card.Text> : ""}
                    {measure.Status !== 'Qualified for the ballot' && measure.Status !== 'On the ballot' ? "" :
                        measure.ElectionDate ? <Card.Text style={{fontSize: '0.9em'}}>On the ballot {measure.ElectionDate}</Card.Text> : ""
                    }
                    <Card.Text 
                        tabIndex={0} 
                        className="Summary" 
                        onClick={(e) => {
                            // unfocus the element if it is focused, or focus it if not
                            //if (document.activeElement === e.target) { e.target.blur(); }
                            //else { e.target.focus(); }
                            e.target.focus();
                        }}
                    >
                        {displayedSummary}
                    </Card.Text>

                    <div style={{borderTop: '1px solid gray', borderBottom: '1px solid gray', marginBottom: '0.5em' }}>
                        <ServicesButtons measure={measure} personInfo={personInfo} setPersonInfo={setPersonInfo}/>
                    </div>
                    
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <LikesDislikes 
                            //measuresObj={props.measuresObj}
                            setPropositionsObj={props.setPropositionsObj}
                            measure={measure}
                            //below will be called to refocus on that element!
                            //seems not to be working...
                            //setRefocusElementId={setRefocusElementId}
                        />
                    </div>
                </div>
            </Card>
        <br />
    </div>
    );
}