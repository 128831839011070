/* Copyright (C) 2023 Christian Miley - All Rights Reserved */

import React from 'react';

//this Context will be passed down to all other pages so that
//user authentication can be contained in one place (here)
//Note: is user authentication contained here? I feel like I see firebase.auth
//being used everywhere lol
const UserContext = React.createContext("Guest");

const PersonContext = React.createContext("Residency");

//const SignaturesContext = React.createContext("Signatures");

const TermsContext = React.createContext(undefined);

const MeasuresContext = React.createContext(undefined);

export { UserContext, PersonContext, TermsContext, MeasuresContext };

/*Note: it looks like below is basically something I redid in
App.js to make UserContext. Why? Not sure exactly. Maybe the useEffect didn't run enough?

export const AuthContext = React.createContext();

export AuthProvider = ({ children }) => {
	const [currentUser, setCurrentUser] = useState(null);

	useEffect(() => {
	    db.auth().onAuthStateChanged(setCurrentUser);
	}, []);

	return(
	    <AuthContext.Provider
	        value = {{
	            currentUser
	        }}
	    >
	        {children}
	    </AuthContext.Provider>
	);
}; */