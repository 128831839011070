/** Copyright 2024 Christian Miley -- all rights reserved
 * Chatbot.js
 * This page loads a chatbot about the ballot measures in California 2024.
 * Eventually may become its own Firebase project separately so that I can
 * host it at chat.rballot.org, but for now it lives at rballot.org/chat
 */

import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { getFunctions, httpsCallable } from "firebase/functions";
import { stateToPostalAbbr } from '../Information';
import ReactMarkdown from 'react-markdown';

// props contains indexing
export default function Chatbot(props) {
    // latest indexing document
    // contains measures by rBallot ID, and all jurisdictions (in California)
    const indexing = props.indexing;

    // 10/4/24: keep track of chat
    const [chatHistory, setChatHistory] = useState([]);
    // 11/3/24: set up second chat for measure-specific narrow search
    const [measureChatHistory, setMeasureChatHistory] = useState([]);

    // keep track of all the filters
    const [filters, setFilters] = useState([]);
    // 11/3/24: which measure are we focusing on in measure chat
    const [measureInQuestion, setMeasureInQuestion] = useState(null);

    // these could go in firebase.js
    const functions = getFunctions();
    const chatbot = httpsCallable(functions, 'langchainPropChatbot');

    const [prompt, setPrompt] = useState("");
    const [measurePrompt, setMeasurePrompt] = useState("");
    // function to access chatbot
    const chat = async (prompt, chatHistory, searchType, measureInQuestion) => {
        try {
            // add prompt/user message to chat history
            const newChatHistory = [...chatHistory, { type: 'user', message: prompt }];
            setChatHistory(newChatHistory);

            const currentFilters = findFilters(prompt);
            // now call the callable function to run the chat
            const response = await chatbot({ 
                prompt,
                filters: currentFilters, // pass along filters for search
                searchType,
                measureId: measureInQuestion // unused/null if general search
            });
            console.log("measureId was: ", measureInQuestion);
            console.log("response is: ", response);
            console.log(response.data);
            // use newChatHistory instead of chatHistory to make sure previous human message is included
            setChatHistory([...newChatHistory, { type: 'assistant', message: response.data }]);
            
        } catch (error) {
            console.error(error);
        }
    }

    /*
    {
        key="Measure" or "State" or "County"
        value="Prop 19" or "California" or "Santa Cruz County"
    } 
    */
    const updateFilterKey = (index, newKey) => {
        const newFilters = filters;
        newFilters[index].key = newKey;
        setFilters([...newFilters]);
    }
    const updateFilterValue = (index, newValue) => {
        const newFilters = filters;
        newFilters[index].value = newValue;
        setFilters([...newFilters]);
    }
    const removeFilter = (index) => {
        let newFilters = [...filters];
        if (filters.length > 1) {
            newFilters.splice(index, 1);
        } else {
            newFilters = [];
        }
        setFilters(newFilters);
    }

    // findFilters will match substrings in the user's question
    // to values present in Information.js
    // it will return the filters for jurisdictions and for measures as so:
    // {
    //      jurisdictions: [ ...jurisdictions ],
    //      measures: [ ...measures ]
    // }
    //10/4/24: note: current in qdrant states are stored like "New_York" not "New York" as we are passing
    function findFilters(question) {
        // create an array of the jurisdictions mentioned in the search to
        // include as possibilities for the filter
        const jurisdictions = [];
        for (let [state, abbreviation] of Object.entries(stateToPostalAbbr)) {
            // check if the name of the state is present in any form, and whether the abbreviation is present all caps with a space in front
            // could use a regex to achieve superior specificity, like allowing a space or punctuation mark after abbreviation
            if (question.toLowerCase().includes(state.toLowerCase()) || question.includes(" " + abbreviation)) {
                jurisdictions.push(state);
            }
        }
        // 9/29/24: ignore measures part below this for now, and just...
        const newFilters = jurisdictions.map((jurisdiction) => { return({ key:"State",value: jurisdiction }) });
        setFilters(newFilters);

        // below for individual measure
        const measures = [];
        // get it to recognize abbreviations for propositions, like Prop 19 vs proposition 19
        // this is called "normalization"
        function normalize(ballotName) {
            return ballotName.toLowerCase().replace(/proposition/g, 'prop').trim();
        }
        
        // need to use most recent indexing document, and go through it
        // note: currently does not include ballotName, need to change webscraping to include that
        function findMeasureByBallotName(data, ballotName) {
            for (const [measureId, mapObject] of Object.entries(data)) {
                // or maybe should just be: normalize(mapObject.BallotName) === normalize(ballotName)
                if (normalize(mapObject.BallotName).includes(normalize(ballotName))) {
                    console.log(`Found measure with ID: ${measureId}`);
                    return measureId;  // Return the ID when found
                }
            }
            console.log(ballotName + " not found!");
            return null;
        }
        

        // actually... that's not how this is supposed to work.
        // what I need is a big ass list of all the ballotNames,
        // and then I need to normalize the user's question so that
        // any ballotNames in the question match the schema of the names
        // listed in the big ass ballotNames list, and then I need to
        // go through the full list and check to see if any are present in the
        // question... oy vey, quite a lot to do!
        // it may also be beneficial to have the big ass ballot names list
        // be sorted by jurisdiction so that then I can winnow it down to just
        // the jurisdictions identified
        
        return newFilters;
    }

    // 11/3/24: Function to sort an array of Objects based on BallotName key
    const sortByBallotName = (array) => {
        // Remove ordering by removing any elements that are arrays
        array = array.filter(item => !Array.isArray(item));
        
        const sortedArray = array.slice(1).sort((a, b) => 
            // Check if both objects have `BallotName`
            a.BallotName && b.BallotName
              ? 
                // Extract the number at the end of each `BallotName` using a regex
                // `\d+$` matches one or more digits at the end of the string
                parseInt(a.BallotName.match(/\d+$/)[0]) - parseInt(b.BallotName.match(/\d+$/)[0])
              : 
                // If only `a` has `BallotName`, place `a` before `b`
                a.BallotName
                ? -1
                : 
                  // If only `b` has `BallotName`, place `b` before `a`
                  b.BallotName
                  ? 1
                  : 
                    // If neither `a` nor `b` has `BallotName`, keep their order unchanged
                    0
        );
        //sortedArray.forEach((measure) => console.log(measure.id, measure.BallotName, measure.rBallotTitle, measure.BallotpediaTitle))
        return sortedArray;
    }
    
      

    return(
        <Container className="d-flex flex-column mt-5 pt-5">
            <form
                onSubmit={async (e) => {
                    e.preventDefault();
                    console.log(prompt);
                    //findFilters(prompt);
                    await chat(prompt, chatHistory, 'general');
                }}
            >
                <div>
                    <h2>General Search</h2>
                </div>
                <div>
                {
                chatHistory.map((chatMessage, index) => {
                    return(
                        <div key={index}>
                            <p>{chatMessage.type}: </p>
                            <ReactMarkdown>{chatMessage.message}</ReactMarkdown>
                        </div>
                    );
                })
                }
                </div>
                { // Here display the filters the user has activated for this search
                filters.map((filter, index) => {
                    return (
                        <div key={index}>
                            <select value={filter.key} onChange={(e) => updateFilterKey(index, e.target.value)}>
                                <option key="Measure">Measure</option>
                                <option key="State">State</option>
                                <option key="County">County</option>
                                <option key="City">City</option>
                            </select>
                            <input value={filter.value} onChange={(e) => updateFilterValue(index, e.target.value)}/>
                            <button type="button" onClick={() => removeFilter(index)}>x</button>
                        </div>
                    )
                })
                }
                <div >
                    <input
                        style={{width: '100%'}} 
                        type="textarea" 
                        value={prompt} 
                        onChange={(e) => setPrompt(e.target.value)}
                    />
                </div>
                <div>
                    <button
                        type="submit"
                        className="p-2 border-2"
                    >
                        Message
                    </button>
                </div>
            </form>
            <form
                onSubmit={async (e) => {
                    e.preventDefault();
                    console.log(measurePrompt);
                    await chat(measurePrompt, measureChatHistory, 'measure', measureInQuestion);
                }}
            >
                <div>
                    <h2>Measure Search</h2>
                </div>
                <div>
                {
                measureChatHistory.map((chatMessage, index) => {
                    return(
                        <ReactMarkdown><p key={index}>{chatMessage.type}: {chatMessage.message}</p></ReactMarkdown>
                    );
                })
                }
                </div>
                { /* Here display the choice of which measure we are dealing with */ }
                <div>
                    <select value={measureInQuestion || ""} onChange={(e) => setMeasureInQuestion(e.target.value)}>
                        {sortByBallotName(Object.values(props.propositionsObj)).map((measure) => {
                            //console.log(measure);
                            return (
                                <option key={measure.id} value={measure.id}>
                                    {measure.BallotName || measure.rBallotTitle || measure.BallotpediaTitle}
                                </option>
                            );
                        })}
                    </select>
                    {/*<input value={filter.value} onChange={(e) => updateFilterValue(index, e.target.value)}/>
                    <button type="button" onClick={() => removeFilter(index)}>x</button>*/}
                </div>
                <div >
                    <input
                        style={{width: '100%'}} 
                        type="textarea" 
                        value={measurePrompt} 
                        onChange={(e) => setMeasurePrompt(e.target.value)}
                    />
                </div>
                <div>
                    <button
                        type="submit"
                        className="p-2 border-2"
                    >
                        Message
                    </button>
                </div>
            </form>
        </Container>
    );
}