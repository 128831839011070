/* Copyright (C) 2023 Christian Miley - All Rights Reserved */

/**
 * ViewProponentRequests
 * This page will use the view request component to display
 * the current status of all proponent access requests
 * for the signed in user.
 */

import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { UserContext } from '../../Context.js';
import { listenToMeasureCategoryRequests } from '../../firebase.js';
//import ViewRequest from '../ViewRequest.js';

//props will contain permissions instead of being fetched here
function ViewProponentRequests(props) {
    //const { measureID, requestID } = useParams()
    const userContext = useContext(UserContext);
    const [requests, setRequests] = useState({});
    const [measure, setMeasure] = useState("example");
    const [category, setCategory] = useState("proponentAccess");
    
    //isn't this duplicated in App.js? Couldn't we just use UserContext?
    /*const [permissions, setPermissions] = useState({});
    useEffect(() => {
        if (!userContext || userContext === "Not loaded") return;
        const unsubscribe = listenToPermissionsObject(setPermissions);

        return function cleanUp() {
            unsubscribe();
        }
    }, [userContext]);*/

    useEffect(() => {
        if (!userContext || userContext === "Not loaded") return;

        let measures = [];
        if (props && props.permissions) measures = Object.keys(props.permissions);
        //there are actually two unsubscribes, to waiting and regular, but they
        //are being lumped together
        const unsubscribe = listenToMeasureCategoryRequests(measures, category, setRequests);

        //here, I might need to call this function for every measure/category combo I have...
        //then, save all the unsubscribe functions they return and run them all at cleanUp
        
        //but then, the issue is that they're all overwriting requests. Needs to happen in the firebase func.

        return function cleanUp() {
            //should say "No user signed in." upon sign out
            if (typeof unsubscribe === "string") {
                console.log(unsubscribe);
                return;
            } else {
                console.log("Unsubscribing from proponent access request listeners.");
                unsubscribe();
            }
        }

    }, [props, props.permissions, category, userContext]);

    const [requestsView, setRequestsView] = useState([]);
    useEffect(() => {
        if (!requests) return;
        console.log(Object.entries(requests));

        let newRequestsView = [];
        for (const id in requests){
            newRequestsView.push(
                <div key={id} style={{padding: '2em', margin: '1em', border: '1px solid black'}}>
                    <h5>Request {id}</h5>
                    <br />
                    <p>Measure: {requests[id].measure}</p>
                    <p>Category: Proponent Access</p>
                    <p>Status: {requests[id].status}</p>
                    <p>Time: {requests[id].request_timestamp.toDate().toLocaleString()}</p>
                    {requests[id].status !== "success" ? "" :
                        <Link to={"/measure/"+requests[id].measure+"/edit"}>
                            Edit Measure
                        </Link>}
                </div>
            );
        }

        setRequestsView(newRequestsView);

    }, [requests]);

    return (
        <Container>
            <div style={{paddingTop: '4em'}}>
                {/*<ViewRequest 
                    measureID = {measureID}
                    requestID = {requestID}
                    category = "proponentAccess"
                />*/}
                {requestsView}
            </div>
        </Container>
    )
}

export default ViewProponentRequests;