/* Copyright (C) 2023 Christian Miley - All Rights Reserved */

/** ProponentVerification.js
This page will present the person who wants to take proponent
ownership of a measure on rBallot with the means to verify themselves.
I anticipate that this page will be little used, as most people
signing up as proponents will also be signing up to rBallot for the first
time too, at least in the first year, and so 
note: Sec of State says that for individual voter registration information requests:
"The requestor must specify the full name, date of birth, county of residence and residence address for each identified voter.""
 */

import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import '../Entry.css';
import { UserContext } from '../../Context.js';
import { 
   db,
   timestamp, 
   sendFirebasePhoneNumberVerification,
   createInvisibleRecaptcha,
   updateFirebaseEmail,
   sendFirebaseEmailVerification
} from '../../firebase.js';
import {countiesByState, allStates, voterRegistrationWebsites} from '../Information.js';
//let's see if importing directly changes things
//import { getAuth, RecaptchaVerifier } from 'firebase/auth';

//props for terms
function ProponentVerification(props)
{
   const navigate = useNavigate();
   //if we are coming directly from a measure they want to circulate
   const { id, paramState } = useParams();

   //should already be a user
   const userContext = useContext(UserContext);

   //need to make name accord with what they've already given us
   const [firstName, setFirstName] = useState("");
   const [middleNames, setMiddleNames] = useState("");
   const [lastName, setLastName] = useState("");
   //suffix required for full name?
   //should below be not a string, since it's a date?
   const [dob, setDob] = useState("");
   const [address1, setAddress1] = useState("");
   const [address2, setAddress2] = useState("");
   const [zip, setZip] = useState("");
   const [city, setCity] = useState("");
   const [state, setState] = useState(paramState);
   //county of residence we could get using address... but why not just ask
   const [county, setCounty] = useState("");
   //email and phone should go here too. Here, we can give the
   //would be proponent the option of switching their account's email to match
   //the one on their voter registration
   const [contactType, setContactType] = useState("Email");
   const [email, setEmail] = useState("");
   const [countryCode, setCountryCode] = useState("+1");
   const [phone, setPhone] = useState("");
   const [check, setCheck] = useState(false);
   const [termsAgreement, setTermsAgreement] = useState(false);
   //terms
   const [terms, setTerms] = useState(undefined);
   useEffect(() => {
      if (!props || !props.terms) return;
      setTerms(props.terms);
   }, [props]);

   //function to request to be classified as a circulator for this
   //petition (if sent by a specific one)
   //this should be put into firebase.js as an awaitable promise
   async function requestProponentAccess()
   {
      console.log("Requesting proponent access", id ? " to initiative " + id : "", " for ", firstName, " now.");

      const requestInfo = {
         First_Name: firstName,
         Middle_Names: middleNames,
         Last_Name: lastName,
         DOB: dob,
         Address1: address1,
         Address2: address2,
         City: city,
         County: county,
         State: state,
         Zip: zip,
         measure: id,
         category: 'proponentAccess', //this is necessary if we are waiting this request
         contactType: contactType,
         agreement: check,
         termsAgreement: termsAgreement,
         termsID: terms.id,
         status: 'new',
         request_timestamp: timestamp()
      };

      //minimize information sent out to necessary info
      //by setting a status other than 'new', these request
      //will be ignored by the cloud function. We will have to manually
      //resubmit them later after verification occurs
      if (contactType === "Email") {
         requestInfo.email = email;
         //if current email is not verified, or if the email is changing,
         //this request will need to wait for email verification to occur
         if (!userContext.emailVerified || userContext.email !== email)
            requestInfo.status = 'email-not-verified';
      } else if (contactType === "Phone (SMS)") {
         requestInfo.phoneNumber = countryCode + phone;
         //if there is no phone number, or it does not match, this request
         //will need to wait for phone verification to occur 
         if (!userContext.phoneNumber || userContext.phoneNumber !== (countryCode+phone))
            requestInfo.status = 'phone-not-verified';
      }

      //new path to be able to restrict writable requests paths by user
      let requestRef;
      if (requestInfo.status === 'new') {
         requestRef = db.collection('requests')
            .doc(userContext.uid)
            .collection('organizations')
            .doc(id)
            .collection('proponentAccess')
            .doc()
      } else if (requestInfo.status === 'email-not-verified' || requestInfo.status === 'phone-not-verified') {
         requestRef = db.collection('requests')
            .doc(userContext.uid)
            .collection('waiting')
            .doc()
      } else {
         throw new Error ("Requested status not supported.");
      }

      //if email is changing, update email and users_private
      const usersPrivateRef = db.collection('users_private').doc(userContext.uid);
      if (contactType === "Email" && email !== userContext.email) {
         await updateFirebaseEmail(email)
            .catch((error) => {
               console.error(error);
               return;
            });

         const redirectLink = ("measure/" + id + "/edit");
         await sendFirebaseEmailVerification(redirectLink)
            .catch((error) => {
               console.error(error);
               //I guess we don't have to return, they just will be confused
            });
         
         await usersPrivateRef.set({
            Email: email
         }, {merge: true});
      }

      //catch firebase errors here? probably good idea
      await requestRef.set(requestInfo)
         .then(() => console.log("Requested proponent status for ", firstName, " to measure #", id));

      return requestInfo;
   }

   //this will emulate the object that we will pass through a separate file
   //note: there will be issues with these as I am currently allowing addresses in all 50 states.
   const countyOptions = {};
   countyOptions["California"] = (
      <Form.Control required as="select" value={county} onChange={(e) => setCounty(e.target.value)}>
         <option value="" key={"select"}>Select county</option>
         {countiesByState["California"].map((county) => <option key={county}>{county}</option>)}
      </Form.Control>
   );

   countyOptions["Oregon"] = (
      <Form.Control required as="select" value={county} onChange={(e) => setCounty(e.target.value)}>
         <option key={"select"}>Select county</option>
         {countiesByState["Oregon"].map((county) => <option key={county}>{county}</option>)}
      </Form.Control>
   );

   //we will take control of html5 validation
   const [validated, setValidated] = useState(false);

   useEffect(() => {
      if (!userContext || email !== "") return;
      if (userContext.email) setEmail(userContext.email);
      //separate out the 10 digit phone number and the '+something' country code
      if (userContext.phoneNumber) {
         //it looks like default is +1?
         console.log(userContext.phoneNumber.slice(0, userContext.phoneNumber.length-10));
         console.log(userContext.phoneNumber.slice(-10));
         setCountryCode(userContext.phoneNumber.slice(0, userContext.phoneNumber.length-10));
         setPhone(userContext.phoneNumber.slice(-10));
      }
      //if (userContext.displayName) setFirstName(userContext.displayName);
   }, [userContext, email]);

   //let's see if we can correctly set up the invisible recaptcha here for phone auth
   //note: not changing yet, but I could have this only be set up if they choose the phone auth
   //option. No need to use recaptcha if not.
   const [recaptcha, setRecaptcha] = useState("");
   useEffect(() => {
      const submitButton = document.getElementById('submit-button');
      if (submitButton)
         setRecaptcha(createInvisibleRecaptcha(submitButton));
   }, []);

   const onSubmit = async (e) => { 
      e.preventDefault(); 
      const form = e.currentTarget;
      if (form.checkValidity() === false) {
         e.stopPropagation();
         setValidated(true);
         return;
      }

      //should below await?
      const requestInfo = await requestProponentAccess();
      //here, I am using a different format than the others, relying
      //on the input to requestProponentAccess rather than the output.
      //Works fine, but should probably standardize.
      if (contactType === "Phone (SMS)") {
         //strip all non-digits away.
         //Does this need to happen before this point?
         const phoneRE = /\D*/g;
         const phoneDigits = phone.replaceAll(phoneRE, '');
         console.log(phoneDigits);
         //if the phone number is new, verify it. Otherwise,
         //proceed straight to requests page
         if ((countryCode + phoneDigits) !== userContext.phoneNumber) {
            const verificationID = 
               await sendFirebasePhoneNumberVerification(countryCode + phoneDigits, recaptcha);

            //let's see if clearing here works 
            await recaptcha.clear();

            navigate("/PhoneVerification/" + verificationID + "/" + (countryCode + phoneDigits) + "/" + id);
         } else {
            //navigate("/ViewProponentRequests/");
            navigate("/measure/" + id + "/edit");
         }
      //using email for contact
      //send to measure edit whether email verified or not,
      //as measure edit now contains the unverifiedemail component
      } else if (contactType === "Email") {
         navigate("/measure/" + id + "/edit");
      }
      
      /*else if (requestInfo.status === 'new') {
         navigate("/measure/" + id + "/edit/");
         //navigate("/ViewProponentRequests/");
      //or maybe below should also view proponent requests, idk
      //User is now where the email verification is shown, but
      //it could be put in to the request itself
      } else if (requestInfo.status === 'email-not-verified') {
         navigate("/User/" + userContext.uid);
      }*/
   }

   //is this necessary?
   if (!userContext) return (
      <div>
         <h4>You must have an account with rBallot to claim your measure.</h4>
         <Link to={'/emailsignup/proponentverification/' + id + '/' + paramState}>Click here to begin creating your account and setting up your measure</Link>
      </div>
   );

   return (
      <Container className="ProponentSignUpContainer">
         <div className="Background"></div>
         <div className="Background2"></div>

         {/*<Link to={"/"}>*/}<h2 className="SignUpHeader">rBallot</h2>{/*</Link>*/}

         <div className = "Guidelines">
            <p>Welcome to rBallot! We will need some information to verify you as a proponent for measure {id}.</p>
            <p>Please check your {" " + paramState + " "} voter registration through { " " }
               <a target="_blank" rel="noreferrer" href={voterRegistrationWebsites[paramState]}>
                  { paramState + "'s "} voter registration website
               </a>
               , by clicking "Check Your Registration Status."
            </p>
            <p>
               If necessary, update it to include an email or cell phone number that we can send a verification message to.
            </p>
            {/*<p style={{fontSize: '0.8em'}}>
               rBallot is currently paying the $30 fee to request a voter registration record from the California Secretary of State for new Proponents.
               Should the information you submit not match that on the voter registration record we request, you will need to pay $60 to resubmit your request
               after having verified your information matches your voter registration. rBallot reserves the right to end or withhold this promotion at any time.
            </p>*/}
         <hr/>
         </div>

         <Form 
            noValidate
            validated={validated}
            className="SignUpForm"
            onSubmit = {onSubmit} //might want this to do the recaptcha?
         >

         <Row>
            <Row>
               <Form.Text><span className="Required">All fields with * are required.</span></Form.Text>
            </Row>
            <Col>
               <Form.Group className="SignUpRow">
                  <Form.Label>First Name<span className="Required">*</span></Form.Label>
                  <Form.Control 
                     required
                     type = "text" 
                     placeholder = "Enter first name" 
                     name="first_name" 
                     value={firstName} 
                     onChange={(e) => setFirstName(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                     Please input your first name.
                  </Form.Control.Feedback>
               </Form.Group>
            </Col>
            <Col>
               <Form.Group className="SignUpRow">
                  <Form.Label>Middle Name(s)</Form.Label>
                  <Form.Control 
                     type = "text" 
                     placeholder = "Enter middle name(s)" 
                     name="middle_names" 
                     value={middleNames} 
                     onChange={(e) => setMiddleNames(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                     Please input your middle name(s), if any.
                  </Form.Control.Feedback>
               </Form.Group>
            </Col>
            <Col>
               <Form.Group className="SignUpRow" >
                  <Form.Label>Last Name<span className="Required">*</span></Form.Label>
                  <Form.Control
                     required
                     type = "text" 
                     placeholder = "Enter last name" 
                     name="last_name" 
                     value={lastName} 
                     onChange={(e) => setLastName(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                     Please input your last name.
                  </Form.Control.Feedback>
               </Form.Group>
            </Col>
         </Row>

         <Row>
            <Form.Group className="SignUpRow">
               <Form.Label>Date of Birth<span className="Required">*</span></Form.Label>
               <Form.Control 
                  required
                  name="DOB"
                  type="date"
                  value={dob}
                  onChange={(e) => {
                     //prevent the component from becoming uncontrolled
                     if (e.target.value === null) {
                        setDob("");
                        return;
                     }
                     setDob(e.target.value);
                  }}
               />
               <Form.Control.Feedback type="invalid">
                  Please input your date of birth.
               </Form.Control.Feedback>
            </Form.Group>
         </Row>

         <Form.Group className="SignUpRow">
            <Form.Label>
               Residence Address
            </Form.Label>
            <Form.Control 
               required
               placeholder="Address line 1*"
               value={address1}
               onChange={(e) => { setAddress1(e.target.value); }}
            />
            <Form.Control 
               placeholder="Address line 2"
               value={address2}
               onChange={(e) => { setAddress2(e.target.value); }}
            />
            <Form.Control
               required
               placeholder="City*"
               value={city}
               onChange={(e) => { setCity(e.target.value); }}
            />
            <Form.Control
               required
               as="select"
               value={state}
               onChange={(e) => { setState(e.target.value); }}
            >
               <option value="" key="default">Choose state*</option>
               {allStates.map((state) => <option key={state}>{state}</option>)}
            </Form.Control>
            <Form.Control 
               required
               placeholder="Zip*"
               value={zip}
               onChange={(e) => { setZip(e.target.value); }}
            />
            <Form.Control.Feedback type="invalid">
               Please correct all errors in your residence address.
            </Form.Control.Feedback>
         </Form.Group>
         
         <Row>
            <Form.Group className="SignUpRow" controlId="formBasicCounty">
               <Form.Label>County<span className="Required">*</span></Form.Label>
               {state !== "" ? countyOptions[state] :
               <Form.Control as="select" disabled>
                  <option>Pick a State first</option>
               </Form.Control>
               }
               <Form.Control.Feedback type="invalid">
                  Please input your county of residence.
               </Form.Control.Feedback>
            </Form.Group>
         </Row>

         {/** Here we will show the person the option of using either email or SMS authentication,
          * and based on what they click, either show them the email address associated with their account,
          * or provide a space to input a phone number.
          */}
         <Row>
            <Form.Group style={{marginTop: '1em'}}>
               <Form.Label>Contact Method<span className="Required">*</span></Form.Label>
               <Row>
               <Col style={{minWidth: '200px', textAlign: 'justify'}}>
                  <Form.Select
                     value={contactType}
                     onChange={(e) => { setContactType(e.target.value); }}
                  >
                     <option key="default">Email</option>
                     <option key="phone">Phone (SMS)</option>
                  </Form.Select>
                  <Form.Text>Make sure the contact method you provide is present on your voter registration.</Form.Text>
               </Col>
               <Col style={{minWidth: '200px'}}>
                  { contactType === "Email" ?
                     <Row>
                        <Form.Control 
                           required
                           value={email}
                           onChange={(e) => setEmail(e.target.value)}
                        />
                        <Form.Text className="errorMessage">
                           {email !== "" && email !== userContext.email ? 
                           ("The email that you use to log in will be changed to " + email)
                           :
                           ""
                           }
                        </Form.Text>
                     </Row>
                     : ""
                  }
                  { contactType === "Phone (SMS)" ?
                     <Row>
                        <Form.Select
                           required
                           value={countryCode}
                           onChange={(e) => setCountryCode(e.target.value)}
                           className="CountryCode"
                        >
                           <option key="default">+1</option>
                           <option key="Mexico">+52</option>
                           <option key="Philippines">+63</option>
                        </Form.Select>

                        {/* 
                           https://stackoverflow.com/questions/2015915/how-can-i-use-a-regex-to-tell-if-a-string-has-10-digits 
                           https://stackoverflow.com/questions/8327705/what-are-and-in-regular-expressions
                           //seems I don't need the /^ or $/
                        */}
                        <Form.Control 
                           required
                           pattern="\D*(\d\D*){10}"
                           value={phone}
                           onChange={(e) => setPhone(e.target.value)}
                           className="Phone"
                        />
                        <Form.Text className="errorMessage">
                           {phone !== "" && (countryCode + phone) !== userContext.phoneNumber ? 
                           ("The phone number associated with your account will be changed to " + (countryCode + phone))
                           :
                           ""
                           }
                        </Form.Text>
                        <Form.Control.Feedback type="invalid">
                           Phone number must be 10 digits exactly.
                        </Form.Control.Feedback>
                     </Row>
                     : ""
                  }
                  {/*<Form.Control 
                     required
                     value={contactType === "Email" ? email : phone}
                     onChange={(e) => {
                        if (contactType === "Email") {
                           setEmail(e.target.value);
                        } else if (contactType === "Phone (SMS)") {
                           setPhone(e.target.value);
                        }
                     }}
                  />
                  <Form.Text
                     className="errorMessage"
                  >
                     {/* Note: phone does update, but have I made it so that email is updated? not yet */}
                     {/*contactType === "Email" && email !== "" && email !== userContext.email ? 
                        ("The email associated with your account will be changed to " + email)
                        :
                        ""
                     }
                     {contactType === "Phone (SMS)" && phone !== "" && phone !== userContext.phoneNumber ?
                        ("The phone number associated with your account will be changed to " + phone)
                        :
                        ""
                  }
               </Form.Text>*/}
               </Col>  
               </Row>
               { contactType === "Phone (SMS)" ?
                  <Row>
                     <Form.Text>
                        This site is protected by reCAPTCHA and the Google {" "}
                        <a href="https://policies.google.com/privacy">Privacy Policy</a> and{" "} 
                        <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                     </Form.Text>
                  </Row>
                  :
                  ""
               }
            </Form.Group>
         </Row>

         <Form.Group>
            <Form.Check 
               type="checkbox" 
               style={{marginTop: '1em', fontSize: '0.8em', textAlign: 'left'}}
               value={check}
               onChange={(e) => setCheck(e.target.checked) }
               required
               label="I confirm that the information I have provided is current and matches that of my California Voter Registration,
               and request that rBallot send a verification message to the contact method I have provided to confirm 
               my ownership of said contact method, if so necessary. Message and data rates may apply."
               feedback="You must confirm before submitting."
               feedbackType="invalid"
            />
         </Form.Group>

         {/*<p style={{color: 'gray', fontSize: '0.8em', marginTop: '2em'}}>
            Legal agreement goes here
            </p>*/}

         <Row className="SignUpRow">
            <Form.Group style={{textAlign: 'center', color: 'gray', fontSize: '0.8em', marginTop: '2em'}}>
               <Form.Label>
                  Check to agree to the {" "}
                  <Link to="/terms" target="_blank">
                     Terms and Conditions and Privacy Policy
                  </Link>
                  , including {" "}
                  <Link to="/terms#Proponents" target="_blank">
                  using the Services and Products as a Proponent.
               </Link>
               </Form.Label>
               <div style={{display: 'flex', justifyContent: 'center', gap: '1em'}}>
               <Form.Check.Input 
                  required
                  style={{flexShrink: '0'}}
                  name = "termsAgreement" 
                  value={termsAgreement} 
                  onChange={(e) => setTermsAgreement(!termsAgreement)}
               />
               <Form.Check.Label>
                  I have read and agree to the Terms and Conditions and Privacy Policy.
               </Form.Check.Label>
               <Form.Control.Feedback type="invalid">
                  You must agree before submitting.
               </Form.Control.Feedback>
               </div>
            </Form.Group>
         </Row>

         <Button 
            id="submit-button" 
            className="SignUpSubmit" 
            variant = 'primary' 
            type = 'submit'
         >
               Submit for Verification
         </Button>

         </Form>

      </Container>

    )

}

export default ProponentVerification;
