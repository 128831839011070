/* Copyright (C) 2023 Christian Miley - All Rights Reserved */

/**
 * Terms.js
 * This page will display the legal information for the website.
 * Currently, planning on having App.js listen to the terms and pass them down,
 * so that someday can easily implement a browserwrap or clickwrap agreement
 * that must be clicked when terms and conditions change.
 */

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './Terms.css';

function Terms(props) 
{
    const [terms, setTerms] = useState("");

    const location = useLocation();

    useEffect(() => {
        if (!props || !props.terms) return;

        const terms = props.terms;

        setTerms( 
        <div style={{marginTop: '4em', padding: '1em', textAlign: 'center'}}>
            <p>Last modified on {terms.timestamp.toDate().toLocaleString()}</p>
            <h1>Terms and Conditions</h1>
            <p className="Terms">{terms['Terms_and_Conditions']}</p>
            <h2>Disclaimer of Warranties</h2>
            <p className="Terms">{terms['Disclaimer_of_Warranties']}</p>
            <h2>Purposes, Use, and Misuse</h2>
            <p className="Terms">{terms['Purposes, Use, and Misuse']}</p>
            <h2>Using rBallot Services and Products</h2>
            <p className="Terms">{terms['Using rBallot Services and Products']}</p>
            <h3 className="SubTermsTitle">Proponents</h3>
            <p tabIndex="0" id="Proponents" className="SubTerms">{terms['Using rBallot Services and Products Proponents']}</p>
            <h3 className="SubTermsTitle">Circulators</h3>
            <p tabIndex="1" id="Circulators" className="SubTerms">{terms['Using rBallot Services and Products Circulators']}</p>
            <h2>Using rBallot Documents</h2>
            <p className="Terms">{terms['Using rBallot Documents']}</p>
            <h2>Code of Conduct</h2>
            <p className="Terms">{terms['Code of Conduct']}</p>
            <h2>Limitation of Liability</h2>
            <p className="Terms">{terms['Limitation of Liability']}</p>
            <h2>Indemnification</h2>
            <p className="Terms">{terms['Indemnification']}</p>
            <h2>General Information</h2>
            <p className="Terms">{terms['General Information']}</p>
            
            <h1>Privacy Policy</h1>
            <p className="Terms">{terms['Privacy Policy']}</p>
            <h3 className="SubTermsTitle">How we collect your information</h3>
            <p className="SubTerms">{terms['Privacy Policy How we collect your information']}</p>
            <h3 className="SubTermsTitle">How we use your information</h3>
            <p className="SubTerms">{terms['Privacy Policy How we use your information']}</p>
            <h3 className="SubTermsTitle">How we disclose and share your information</h3>
            <p className="SubTerms">{terms['Privacy Policy How we disclose and share your information']}</p>
            <h3 className="SubTermsTitle">How we protect your information</h3>
            <p className="SubTerms">{terms['Privacy Policy How we protect your information']}</p>
            <h1>Contact</h1>
            <p className="Terms">{terms['Contact']}</p>
        </div>
        );
    }, [props, props.terms]);

    useEffect(() => {
        if (terms === "" || !location || !location.hash) return;
        document.getElementById(location.hash.slice(1)).focus();
    }, [location.hash, terms]);

    return (
        <div>
            {terms}
        </div>
    )
}

export default Terms;