/* Copyright (C) 2024 Christian Miley - All Rights Reserved */

/**
 * JurisdictionForm.js
 * This component will be responsible for setting which jurisdictions to pull
 * measures from. Currently, that will happen by setting the person info, but perhaps
 * it should be more direct later on, like actually fetching the data itself.
 */

import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { allStates, countiesByState } from './Information.js';

//props will include setPersonInfo (for now, at least), and... jurisdictions from indexing document?
export default function JurisdictionForm(props) {
    const [county, setCounty] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    // starting off as a string to go along with others, but really should be a list...
    const [district, setDistrict] = useState("");

    // in addition to Information.js, I also now have a resource in indexing document, which tells me
    // which cities and districts are nested in which counties...
    const countyOptions = {};
    countyOptions["California"] = (
        <Form.Control as="select" value={county} onChange={(e) => setCounty(e.target.value)}>
            <option value="" key={"default"}>Select County</option>
            {countiesByState["California"].map((county) => <option key={county}>{county}</option>)}
        </Form.Control>
    );
    
    countyOptions["Oregon"] = (
        <Form.Control as="select" value={county} onChange={(e) => setCounty(e.target.value)}>
            <option value="" key={"default"}>Select County</option>
            {countiesByState["Oregon"].map((county) => <option key={county}>{county}</option>)}
        </Form.Control>
    );

    return (
        <Form 
            className="ResidencyForm"
            onSubmit = {(e) => { 
                e.preventDefault();
                props.setPersonInfo({
                    State: state, 
                    County: county,
                    City: city,
                    District: district
                }); 
            }}
        >
            <Form.Group style={{margin: '0.5em 0em'}}>
                <Form.Label>State</Form.Label>
                <Form.Control as="select" value={state} onChange={(e) => setState(e.target.value)}>
                    <option value="" key="selectState">Select State</option>
                    {allStates.map((state) => {
                        return(
                            <option key={state}>{state}</option>
                        );
                    })}
                </Form.Control>
            </Form.Group>
            { !state || state === "" || state === "Choose state" ? "" :
            <Form.Group style={{margin: '0.5em 0em'}}>
                <Form.Label>County</Form.Label>
                { countyOptions[state] }
            </Form.Group>
            }
            { !props.jurisdictions || !county || !props.jurisdictions[county] || props.jurisdictions[county].City.length === 0 || county === "" || county === "Choose county" ? "" :
            <Form.Group style={{margin: '0.5em 0em'}}>
                <Form.Label>City</Form.Label>
                <Form.Select
                    value={city} 
                    onChange={(e) => setCity(e.target.value)}
                >
                    <option value="" key="selectCity">Select City</option>
                    {props.jurisdictions[county].City.map((city) => {
                        return (
                            <option key={city}>{city}</option>
                        );
                    })}
                </Form.Select>
            </Form.Group>
            }
            { !props.jurisdictions || !county || !props.jurisdictions[county] || props.jurisdictions[county].District.length === 0 || county === "" || county === "Choose county" ? "" :
            <Form.Group style={{margin: '0.5em 0em'}}>
                <Form.Label>District</Form.Label>
                <Form.Select
                    value={district} 
                    onChange={(e) => setDistrict(e.target.value)}
                >
                    <option value="" key="selectDistrict">Select District</option>
                    {props.jurisdictions[county].District.map((city) => {
                        return (
                            <option key={city}>{city}</option>
                        );
                    })}
                </Form.Select>
            </Form.Group>
            }
            <br />
            <Button className="SignUpSubmit" variant = 'primary' type = 'submit'>
                See measures in my area
            </Button>
        </Form>
    );
}