/* Copyright (C) 2023 Christian Miley - All Rights Reserved */

/**
 * SignIn.js
 * This component will display the sign in tool, so that it may be used
 * elsewhere than the home page (specifically, on the verify email page).
 */

 import React, { useState } from 'react';
 import './pages/Home.css';
 import { useNavigate } from 'react-router-dom';
 import { auth } from '../firebase.js';
 import { Form, Button } from 'react-bootstrap';

function SignIn(props) {
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const redirect = props.redirect;
    const textColor = props.textColor || 'white';

    //sign user in
    async function signIn(email, password, navigate) {
        await auth.signInWithEmailAndPassword(email.trim(), password.trim())
        .then(function(userCredential){
            console.log("Signed in as: " + userCredential.user);
            if (redirect) navigate(redirect);
        })
        .catch(function(error) {
            // Handle Errors here.
            setErrorMessage(error.message);
            console.log("Error code: ", error.code, error.message);
        });
    }

    const onSubmit = (e) => {
    e.preventDefault();
    signIn(email, password, navigate);
    //if there was no error message, reset everything
    //Not sure why; this is a holdover from before the summer break I took
    /*if (errorMessage === "") {
        setEmail("");
        setPassword("");
    }*/
    }

    return (
        <Form onSubmit = {onSubmit}>
            <Form.Group controlId="formEmailSignIn">
                <Form.Label style={{color: textColor}}>Email</Form.Label>
                <Form.Control type = "email"
                            placeholder = "Enter email"
                            name="email"
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)}
                            className="SignInField"
                />
            </Form.Group>

            <Form.Group controlId="formPasswordSignIn" className="SignInFormField">
                <Form.Label style={{color: textColor}}>Password</Form.Label>
                <Form.Control type = "password"
                            placeholder = "Enter password"
                            name="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="SignInField"
                />
                <Form.Text>{errorMessage}</Form.Text>
            </Form.Group>
            <Button 
                className="SignIn"
                type = 'submit'
            >
                Sign In
            </Button>
        </Form>
    );
}

export default SignIn;