/* Copyright (C) 2023 Christian Miley - All Rights Reserved */

import React, { useState, useEffect } from 'react';
import {   
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  //Routes,
  RouterProvider,
  Outlet,
  /*BrowserRouter as Router, Route, Routes,*/
  ScrollRestoration 
} from 'react-router-dom';
import { 
  db, 
  auth, 
  //now, 
  listenToDocument,
  listenToQuery,
  getMeasuresFromPersonInfo,
  getIndexing
} from './firebase.js';
import Chatbot from './components/pages/Chatbot';
import ModularMeasurePage from './components/pages/ModularMeasurePage';
import OrganizationPage from './components/pages/OrganizationPage';
import SignMeasure from './components/pages/SignMeasure.js';
import UserFeedPage from './components/pages/UserFeedPage';
import EditMeasure from './components/pages/EditMeasure';
import Notfound from "./components/pages/Notfound";
import EmailSignUp from './components/EmailSignUp.js';
import NavigationBar from './components/NavigationBar.js';
import './App.css';
import AboutPage from './components/pages/AboutPage';
import Terms from './components/pages/Terms';
import Home from './components/pages/FunctionHome';
import User from './components/pages/User';
import ActionHandler from './components/pages/ActionHandler.js';
import CirculatorVerification from './components/pages/CirculatorVerification.js';
import ProponentVerification from './components/pages/ProponentVerification.js';
import PhoneVerification from './components/pages/PhoneVerification.js';
import ViewProponentRequests from './components/pages/ViewProponentRequests.js';
import AddressSignUp from './components/pages/AddressSignUp.js';
import ManagePetitionSection from './components/pages/ManagePetitionSection.js';
import PetitionDownloads from './components/pages/PetitionDownloads.js';
import ShareMeasure from './components/pages/ShareMeasure.js';
import PendingMeasureSetUp from './components/pages/PendingMeasureSetUp.js';
import { UserContext, PersonContext, TermsContext, MeasuresContext } from './Context.js';
import HTMLTextDisplayPage from './components/pages/HTMLTextDisplayPage.js';

function App()
{

//--------------------------User Context creation below-------------------------
//includes: {uid, displayName, emailVerified, permissions (custom claims object)
//later, will add state, city, county

//below will handle the firebase user, while a separate state will handle userContext
const [user, setUser] = useState("Not loaded");
const [token, setToken] = useState(null);
const [userContext, setUserContext] = useState("Not loaded");
  
  //not sure if below needs to be in useEffect but seems sensible
  //note: might want to switch to auth.onIdTokenChanged
  //onAuthStateChanged triggers only on sign in or out,
  //while onIdTokenChanged triggers on sign in/out and 
  //"token refresh events". This may alleviate the need
  //for the separate permissions object and useEffect below,
  //though the permissionsObject is useful to be able to see user's
  //access from the console. Also, may want to consider unsubscribing
  //from these.
  useEffect(() =>
  {       
      //set an observer on the Auth object
      //firebase recommends this because it waits until
      //the auth object has passed out of an intermediate state
      //(like initialization) before triggering

      //note: I changed this to onIdTokenChanged to fire on token events.
      //is force refreshing the token in the below one causing this to fire again?

      //second note 2/12/24: this seems to refresh more than once an hour, and when it does
      //the screen gets wiped of content.
      const unsubscribe = auth.onIdTokenChanged(async function(newUser){
      // User is signed in.
        if (newUser) {
          const newToken = await newUser.getIdTokenResult(); //10/22/24 had true inside parentheses before, chatGPT says not needed

          //Check if the new user and token are the same as the previous ones
          if (newUser === user && newToken.token === token)

          // 10/4/24: this is still occasionally causing the webpage to delete all content entered, which is mega ass
          setUser(newUser);
          setToken(newToken.token);
          console.log("Setting user to " + newUser.uid);
          console.log(newUser);

          //now build the userContext from this
          const newUserContext = {
            uid: newUser.uid,
            displayName: newUser.displayName,
            email: newUser.email,
            phoneNumber: newUser.phoneNumber,
            emailVerified: newToken.claims.email_verified,//user.emailVerified,
            permissions: newToken.claims
          }
          setUserContext(newUserContext);
          console.log(Object.entries(newUserContext));
        // No user is signed in.
        } else {
          setUser(undefined);
          setUserContext(undefined);
          console.log("Setting user to be undefined in auth.");
        }
    });

    return function cleanUp() {
      console.log("Unsubscribing from listening to user's auth.");
      unsubscribe();
    }
  }, []);

  const [permissionsObj, setPermissionsObj] = useState({});
  //below will put a listener on the user's permissions and refresh the
  //auth token if their permissions change to allow them to continue using site
  //with their new permissions and without refreshing
  //note: I thought I might not need this after updating to onIdTokenChanged above,
  //but it seems that it still is not refreshing when claims are updated.
  //This should not cause idTokenChanged to fire, so there should be no infinite loop
  useEffect(() => {
    if (!user || user === "Not loaded" || !user.uid) return;

    const userPermissionsRef = db.collection("users").doc(user.uid).collection("permissions").doc("permissionsObject");

    const unsubscribe = userPermissionsRef.onSnapshot(async function(doc) {
      //true forces a reset of the token
      const token = await user.getIdTokenResult(true);
      
      if (doc.exists) {
        setPermissionsObj(doc.data());
      }
      
      const newUserContext = {
        uid: user.uid,
        displayName: user.displayName,
        email: user.email,
        phoneNumber: user.phoneNumber,
        emailVerified: token.claims.email_verified, //user.emailVerified
        permissions: token.claims
      }
      setUserContext(newUserContext);
      console.log("Permissions updated for " + user.uid);
      console.log(Object.entries(newUserContext));
    })

    return function cleanUp() {
      console.log("Unsubscribing from listening to userPermissionsRef");
      unsubscribe();
    }

  }, [user]);

//----- User Context ---------- note: UserContext is provided by context.js
/*
  const [user, setUser] = useState("Not loaded");
  
  //not sure if below needs to be in useEffect but seems sensible
  useEffect(() =>
  {       
      //set an observer on the Auth object
      //firebase recommends this because it waits until
      //the auth object has passed out of an intermediate state
      //(like initialization) before triggering
      auth.onAuthStateChanged(function(user) {
      // User is signed in.
        if (user) {
          setUser(user);
          console.log(user);
          console.log("Setting user to " + user.uid);
        // No user is signed in.
        } else {
          setUser(undefined);
          console.log("Setting user to be undefined in auth.");
        }
    })
  }, []);

  //below will put a listener on the user's permissions and refresh the
  //auth token if their permissions change to allow them to easily continue using site
  useEffect(() => {
    if (!user || user === "Not loaded" || !user.uid) return;

    const userPermissionsRef = db.collection("users").doc(user.uid).collection("permissions").doc("permissionsObject");

    const unsubscribe = userPermissionsRef.onSnapshot(function(doc) {
      //true forces a reset of the token
      user.getIdTokenResult(true);
      console.log("Permissions updated for " + user.uid + ":\n" + doc.data());
    })

    return function cleanUp() {
      unsubscribe();
    }

  }, [user])
*/
//----- Person Context ----- note: also added by context.js
//setting it to start as being in California, because so far that's all the information needed
//setting hiddenMeasures to be empty array so that guests can hide too
const [personInfo, setPersonInfo] = useState({ 
  State: 'California',
  hiddenMeasures: [] 
});

useEffect(() =>
{
  //if (user) { getPersonInfo(user.uid);}
  //else { setPersonInfo(undefined); }
  if (!user || !user.uid) {
    return;
  }
  // note 2/16/24: I am seeing a bug where the Firebase refresh of the token refreshes the user
  // which refreshes the personInfo and leads to everything refreshing...
  // I started writing here because this is the entrypoint at which this propagates,
  // but as I consider the issue I think it might make even more sense to have
  // the issue dealt with at the propositionsObject level, because it doesn't really make any
  // sense to delete things from propositionsObj, just overwrite and add them once it exists.
  // This also assumes that this is why my stuff is refreshing... I think it is because the web
  // page is not refreshing as a whole
  console.log("Setting personInfo based on " + (user ? user.uid : undefined));

  const unsubscribe = listenToDocument(['users', user.uid], setPersonInfo);

  return function cleanUp() {
    console.log("Unsubscribing from listening to person's public user data: " + user.uid);
    unsubscribe();
  }
}, [user]);

/**
 * Return the personInfo information of the specified user as an object
 * @params userID
 * @return personInfo information of user for PersonContext
 */
/*async function getPersonInfo(userID)
{
  const userRef = db.collection("users").doc(userID);
  let newPersonInfo = {};

  await userRef.get().then(function(doc) 
  {
    if (doc.exists)
    {
      /*newPersonInfo['State'] = doc.data().State;
      newPersonInfo['County'] = doc.data().County;
      newPersonInfo['City'] = doc.data().City;
      newPersonInfo['Congressional_District'] = doc.data().Congressional_District;
      newPersonInfo['State_Assembly_District'] = doc.data().State_Assembly_District;
      newPersonInfo['State_Senate_District'] = doc.data().State_Senate_District;*/
      /*newPersonInfo = doc.data(); //right?
      console.log(newPersonInfo);
      setPersonInfo(newPersonInfo);
      return;
    }
    else {
      newPersonInfo = undefined;
    }
  });

}*/

//----- Signatures Context ----- note: also added by Context.js
//should rework to incorporate information about signatures that
//don't have all the needed information yet (title, summary, etc)

/*const [signatures, setSignatures] = useState(undefined);

useEffect(() => {
  if (!user) return;

  const signaturesRef = db.collection("users").doc(user.uid).collection("signatures");

  const unsubscribe = signaturesRef.onSnapshot(function(querySnapshot)
  {
    //dictionary for all signatures so that they can be accessed by id
    let signatures = {};
    let unorderedSignatures = [];
    querySnapshot.forEach(function(document) 
    {
      const id = document.id;
      signatures[id] = document.data();

      if (document.data().dateOrdered === null)
      {
        unorderedSignatures.push(document.data());
      }
    })
    setSignatures({
      signatures: signatures,
      unorderedSignatures: unorderedSignatures
    });
  })

  return function cleanUp()
  {
    console.log("Unsubscribing from listening to user's signatures (only relevant now for tracking whether a measure is hidden. This could be done with arrayUnion and a single document).");
    unsubscribe();
  }

}, [user])*/

//--------------------------Terms Listener-----------------------------
//should this be a context? Probably

const [terms, setTerms] = useState(undefined);

useEffect(() =>
{
  console.log("Setting listener to terms and conditions.");

  //Putting in undefined to signify no 'where' uses. This is stupid though.
  //note: do I need to specify descending or ascending for timestamp? maybe
  const unsubscribe = listenToQuery(['terms'], undefined, [['timestamp', 'desc']], 1, setTerms);

  return function cleanUp() {
    console.log("Unsubscribing from listening to terms and conditions document.");
    unsubscribe();
  }
}, []);

//-------------------------Get indexing------------------------------------
const [indexing, setIndexing] = useState(undefined);
useEffect(() => {
  console.log("Pulling most recent indexing document from database.");
  const grabIndexing = async () => {
    try {
      const indexingDoc = await getIndexing();
      setIndexing(indexingDoc);
    } catch(error) {
      console.error(error);
    }
  }
  grabIndexing();
}, []);

//---------Propositions Gathering (should this be a context?)--------------

  //const [propositions, setPropositions] = useState([]);
  //const [displayedPropositions, setDisplayedPropositions] = useState(null);
  //const [currentState, setCurrentState] = useState(null);

  const [propositionsObj, setPropositionsObj] = useState({});
  //const [ordering, setOrdering] = useState([]);

  //We are now going to get the proposition database here so that it is available
  //for all future pages visited
  //Note: I'm still going to leave the below in UserFeedPage under a conditional
  //because people can go directly to there if they want
  useEffect(() =>
  {
    //let localPropositions = JSON.parse(localStorage.getItem("propositions"));
    //Below will be used someday to save space probably, but for now I'll just pull every time
    //so that the website is responsive.
    /*if (localPropositions)
    {
      console.log("We already have propositions downloaded, so we'll just use those");
      console.log("Note: we'll need to provide a way to update without clearing local storage cache now");
      setPropositions(localPropositions);
      setDisplayedPropositions(localPropositions);
      return;
    }*/
    console.log(propositionsObj);
    //check for personInfo, but not user, so that guests can search by updating personInfo
    //but only refresh propositions if the state has changed, since this is the only thing it
    //is based on. This way, don't need to regrab everything when following or unfollowing, since
    //those values are also stored on personInfo
    if (!personInfo || !personInfo.State /*|| personInfo.State === currentState*/) return;
    
    //there are times when propositionsObj is being overwritten. We don't want this to happen.
    //so, if there is no ordering (meaning no measures to be shown), we'd better get them again.
    //5/20/24: Commenting out the final check, because when there are no measures for a place,
    //the ordering comes out to be of length 0, and I don't want it to keep trying. This might cause issues
    //with the bug I was trying to solve whenever I wrote this.
    if (propositionsObj && propositionsObj.ordering/* && propositionsObj.ordering.length > 0*/) {
      console.log("PropositionsObj updated, but not fetching again because it already has an ordering.");
      return;
    }

    //this will be useful later, when we only draw propositions that can still be signed
    //this is defined in firebase.js, and is a timestamp of now
    console.log("Fetching propositions...");
    /*const currentTime = now();
    //setCurrentState(personInfo.State);

    //for now, only do State, since we have no county or city petitions
    const propRef = db.collection("petitions")
      .where("JurisdictionName", "==", personInfo.State)
      .where("Deadline", ">", currentTime)
      // Can't do these as can only have one equality/inequality where statement
      //.where("rBallotStatus", "!=", "pending") // don't fetch pending measures
      //.where("Status", "==", "Cleared for signature gathering")
    ;
    var propsArray = [];
    const propsObject = {};
    const orderingArray = [];

    const getProps = async () => 
    {
      await propRef.get().then(function(querySnapshot)
      {
        querySnapshot.forEach(function(doc)
          {
              let data = doc.data();
              data.id = doc.id;
              propsArray.push(data);
              orderingArray.push(doc.id);
              propsObject[doc.id] = data;
          });
        });
        setPropositions(propsArray);
        setDisplayedPropositions(propsArray);
        setPropositionsObj({ ordering: orderingArray, ...propsObject });
        console.log("Updated propositionsObj!");
        //potentially stash for later? Could only grab new files if current ones not stashed,
        //but that's a lot to store on someone's computer too.
        //localStorage.setItem('propositions', JSON.stringify(propsArray));
    }

    //we call the asynchronous function within useEffect like this
    //to prevent returning a promise to useEffect
    getProps();*/
    getMeasuresFromPersonInfo(personInfo, setPropositionsObj);
  }, [personInfo, /*currentState,*/ propositionsObj]);

  /**
   * This Layout component will wrap the NavigationBar with an outlet, and later perhaps allow more components like error messages to be added to layout?
   */
  function Layout() {
    return(
      <main>
        {/*<ScrollRestoration />*/}
        {/*<MobileButtons />*/}
        <NavigationBar 
          setPersonInfo={setPersonInfo} 
          setPropositionsObj={setPropositionsObj}
          /*propositions={propositions} setDisplayedPropositions={setDisplayedPropositions}*/ 
        />
        <Outlet />
      </main>
    )
  }

  const router = createBrowserRouter(
    createRoutesFromElements(
      /*<Route path="/" element={<Root />}>
        <Route path="dashboard" element={<Dashboard />} />
        //etc...
      </Route>
    )
  );*/

  /*return (
    <Router>
      <Routes>*/
      <Route element={<Layout />}>
        <Route 
          path="/"
          element={
            <UserFeedPage 
              /*propositions={displayedPropositions} 
              setPropositions={setDisplayedPropositions}*/
              setPersonInfo={setPersonInfo}
              propositionsObj={propositionsObj}
              setPropositionsObj={setPropositionsObj}
              indexing={indexing}
            />
          } 
        />
        <Route path="/SignIn" element={<Home />} />
        <Route path="/verify" element={<ActionHandler />} />
        <Route path="/About" element={<AboutPage />} />
        <Route path="/Terms" element={<Terms terms={terms}/>} />
        <Route path="/chat" element={<Chatbot indexing={indexing} propositionsObj={propositionsObj} />} />
        <Route path="/measure/:id" element={
          <ModularMeasurePage propositionsObj={propositionsObj} setPropositionsObj={setPropositionsObj}/> 
        } /> 
        <Route path="/measure/:id/:referrerId" element={
          <ModularMeasurePage propositionsObj={propositionsObj} setPropositionsObj={setPropositionsObj}/> 
        } /> 
        <Route path="/measure/:id/campaign" element={<OrganizationPage propositionsObj={propositionsObj} setPropositionsObj={setPropositionsObj}/>} />
        <Route path="/measure/:id/edit" element={<EditMeasure permissions={permissionsObj}/>} />
        <Route path="/measure/:id/html" element={<HTMLTextDisplayPage propositionsObj={propositionsObj}/>}/>
        <Route path="/measure/:id/sign" element={<SignMeasure propositionsObj={propositionsObj} setPropositionsObj={setPropositionsObj}/>} />
        <Route path="/measure/:id/petitions" element={<PetitionDownloads propositionsObj={propositionsObj} setPropositionsObj={setPropositionsObj}/>} />
        <Route path="/measure/:measureID/share/:redirected" element={<ShareMeasure propositionsObj={propositionsObj}/>} />
        <Route path="/measure/:measureID/share/" element={<ShareMeasure propositionsObj={propositionsObj}/>} />
        <Route path="/managepetitionsection/:petitionID" element={<ManagePetitionSection propositionsObj={propositionsObj} setPropositionsObj={setPropositionsObj}/>} />
        <Route path="/User/:id" element={<User propositionsObj={propositionsObj} setPropositionsObj={setPropositionsObj}/>} />
        <Route path="/EmailSignUp/" element={<EmailSignUp terms={terms} setPersonInfo={setPersonInfo} />} />
        <Route path="/EmailSignUp/:redirectType/:id/" 
          element={<EmailSignUp terms={terms} setPersonInfo={setPersonInfo}/>} />
        <Route path="/EmailSignUp/:redirectType/:id/:paramState/" 
          element={<EmailSignUp terms={terms} setPersonInfo={setPersonInfo}/>} />
        <Route path="/AddressSignUp/:id" element={<AddressSignUp propositionsObj={propositionsObj}/>} />
        <Route path="/PendingMeasureSetUp" element={<PendingMeasureSetUp propositionsObj={propositionsObj}/>} />
        <Route path="/PendingMeasureSetUp/:measureId" element={<PendingMeasureSetUp propositionsObj={propositionsObj}/>} />
        <Route path="/CirculatorVerification/:id/:state" element={<CirculatorVerification terms={terms}/>} />
        {/* Named paramState to distinguish from another value in element. Under review. */}
        <Route path="/ProponentVerification/:id/:paramState" element={<ProponentVerification terms={terms}/>} />
        <Route path="/PhoneVerification/:verificationID/:phoneNumber/:measureID" element={<PhoneVerification />} />
        <Route path="/ViewProponentRequests/" element={<ViewProponentRequests permissions={permissionsObj} />} />
        <Route path="*" element={<Notfound />} />
      </Route>
      //</Routes>
    //</Router>
    )
  );

  return (
    <UserContext.Provider value={userContext}>
      <PersonContext.Provider value={personInfo}>
        <TermsContext.Provider value={terms}>
          <MeasuresContext.Provider value={[propositionsObj, setPropositionsObj]}>
            {/*<ScrollRestoration />
            <NavigationBar propositions={propositions} 
                          setDisplayedPropositions={setDisplayedPropositions}
            />*/}
            <RouterProvider router={router}/>
          </MeasuresContext.Provider>
        </TermsContext.Provider>
      </PersonContext.Provider>
    </UserContext.Provider>
  );

}

export default App;
export { UserContext, PersonContext, TermsContext };                  