/* Copyright (C) 2023 Christian Miley - All Rights Reserved */

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
//import { Nav } from 'react-bootstrap';
import './AboutPage.css';
import { Nav, Navbar } from 'react-bootstrap';

function AboutPage()
{
    let missionText = [
        <p key="1" className="AboutParagraph">rBallot is a platform built to facilitate grassroots ballot measure petition drives. For measures targeting the November 5, 2024 election, we offer a website for people to find initiatives they support and download packets for gathering signatures in their communities, and for ballot measure campaigns to manage and receive those packets through the mail.</p>,
        <h4 key="2">Background</h4>,
        <br key="2.5" />,
        <p key="3" className="AboutParagraph">In the United States, 21 states allow voters to place initiatives on the ballot, where they may be voted on to become law. These systems were mostly put in place a hundred years ago, during the 'Progressive' era of American politics associated with Teddy Roosevelt, as a means to allow ordinary people to legislate directly and break the stranglehold that corporate monopolies had upon government.</p>,
        <p key="4" className="AboutParagraph">Today however, while initiative and referendum processes theoretically allow anyone to write and submit legislation to the ballot, the quantity of signatures required poses a significant obstacle to ordinary citizens making their ideas heard. In California, over 500,000 signatures from across the state are required for new state laws, and over 800,000 for constitutional amendments. This usually restricts the ability to get initiatives on the ballot to well-funded organizations, often special interest groups funded by corporations.  On their own time and dime, individual people have little hope of obtaining this many signatures.</p>,  
        <p key="5" className="AboutParagraph">With a 21st-century system designed to supplement existing avenues for direct legislating, we can enable ordinary people to materially participate in government and make their voices heard.</p>
    ]
    //let researchText = "NA";
    //let documentationText ="NA";
    //let teamText = "NA";
    //let contactText = "NA";

    const demos = (
        <div>
            <h4>Petition Management Demonstrations</h4>
            <br />
            <p>Circulator example</p>
            <Link to="/measure/example/campaign">Example Measure - Circulator</Link>
            <br />
            <br />
            <p>Measure Management example</p>
            <Link to="/measure/example/edit">Example Measure - Manage Measure</Link>
        </div>
    );

    const [displayedText, setDisplayedText] = useState(missionText);

    return (
        <div className="AboutHeaderContainer">
            {/*<Link to={"/"} className="AboutHeader">rBallot</Link>
            <h3 className="AboutSubheader">Where People Make the Laws</h3>*/}
            {/*<Navbar
                expand="md" 
                fixed="top"
            >
                <Navbar.Toggle aria-controls="responsive-navbar"/>
            <Navbar.Collapse id="responsive-navbar">*/}
                    <Nav className="Sidebar" defaultActiveKey="Mission">
                        {/*<Nav.Link className="AboutNav" eventKey="Research" onSelect={() => setDisplayedText(researchText)} disabled>Research</Nav.Link>
                        <Nav.Link className="AboutNav" eventKey="Documentation" onSelect={() => setDisplayedText(documentationText)} disabled>Documentation</Nav.Link>*/}
                        <Nav.Link className="AboutNav" eventKey="Mission" onClick={() => setDisplayedText(missionText)}>Mission</Nav.Link>
                        <Nav.Link className="AboutNav" eventKey="Demos" onClick={() => setDisplayedText(demos)}>Demos</Nav.Link>
                        {/*<Nav.Link className="AboutNav" eventKey="Team" onSelect={() => setDisplayedText(teamText)} disabled>Team</Nav.Link>
                        <Nav.Link className="AboutNav" eventKey="Contact" onSelect={() => setDisplayedText(contactText)} disabled>Contact</Nav.Link>*/}
                    </Nav>
                {/*</Navbar.Collapse>
            </Navbar>*/}
            <div className="MainAbout">
                {displayedText instanceof Array ?
                    <div className="Text">
                        {displayedText}
                    </div>
                :
                    <div className="Text">
                        {displayedText}
                    </div>
                }
            </div>
        </div>
    )

}

export default AboutPage;
