/* Copyright (C) 2023 Christian Miley - All Rights Reserved */

/**
 * PDFCirculator.js
 * A component for rendering PDF versions
 * of the signature sheets people can circulate
 * and mail in to have their gathered signatures
 * submitted by the proponent.
 */

import React, { useEffect, useState } from 'react';
import boldSerifFont from '../PlayfairDisplayBold.ttf';
import boldSansSerifFont from '../SwanseaBold.ttf';
import { pdf, Document, Page, Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import { PDFDocument } from 'pdf-lib';
import { getSignatureCount } from './Utility.js';

//props includes: identifier, title, summary, fiscalImpact, county,
// example, style (someday), signaturesPerSheet, type (and possibly codes), topics, pages
//fullTextFile OR fullTextLink OR fullTextPdf
//note: it currently checks to see if it's given identifier or petitionID, but I think this is not an issue actually
function SignaturePDF(props)
{
    //this will make it so that it rerenders
    //when state changes. This is probably not always desirable
    //though; should probably figure out how to do it with
    //usePDF so that I can control when it rerenders
    const [state, setState] = useState(props);
    //keeps track of last refresh, and only rerenders on new
    //refresh
    const [lastRefresh, setLastRefresh] = useState(-1);
    useEffect(() => {
        //if the file has changed, rerender for sure
        if (props.fullTextFile !== state.fullTextFile) {
            setState(props);
            return;
        }
        
        /*console.log(props.signaturesPerSheet);
        console.log(state.signaturesPerSheet);
        //or, if signaturesPerSheet has changed
        if (props.signaturesPerSheet && state.signaturesPerSheet &&
            props.signaturesPerSheet[0] !== state.signaturesPerSheet[0] ||
            props.signaturesPerSheet[1] !== state.signaturesPerSheet[1] ||
            props.signaturesPerSheet[2] !== state.signaturesPerSheet[2]
        ) {
            console.log("it's true");
            setState(props);
            return;
        }*/

        //check if refreshCount is being used
        if (props.refreshCount) {
            //rerender only if refreshed
            if (props.refreshCount > lastRefresh) {
                setState(props);
                setLastRefresh(props.refreshCount);
            }
        // otherwise rerender regardless
        } else {
            setState(props);
        }
    }, [props, lastRefresh, state.fullTextFile]);

    //Below for day when database contains
    //numerous different styles for different
    //jurisdictions, like states, counties, etc.
    //const styles = props.styles;

    Font.register({
        family:"boldSerifFont",
        fontWeight:"bold",
        src: boldSerifFont
    })

    Font.register({
        family:"boldSansSerifFont",
        fontWeight:"bold",
        src: boldSansSerifFont
    })

    const styles = StyleSheet.create({
        page: {
            backgroundColor: 'white',
            fontSize: '11pt',
            lineHeight: '1',
            marginBottom: '0.2in'
        },
        section: {
            margin: '0.2in 0.3in',
            padding: '1pt'
        },
        firstSignatureSection: {
            marginTop: '0.2in',
            marginHorizontal: '0.3in',
            marginBottom: '0in',
            padding: '1pt'
        },
        signatureSection: {
            margin: '0in 0.3in',
            padding: '1pt'
        },
        line: {
            margin: '3pt 1pt'
        },
        boldLine: {
            margin: '3pt 1pt',
            fontFamily: 'boldSansSerifFont',
            fontSize: '11'
        },
        signatureLine: {
            margin: '5pt 1pt',
            marginLeft: '4%'
        },
        description: {
            textAlign: 'center',
            fontSize: '9',
            color: 'gray',
            marginBottom: '2pt',
            marginTop: '4pt',
            position: 'relative'
        },
        highlight: {
            backgroundColor: 'yellow'
        },
        circulatorDeclaration: {
            margin: '0.2in 0.3in',
            padding: '1pt',
            position: 'absolute',
            left: '0',
            bottom: '0',
            paddingTop: '4pt',
            fontSize: '10',
            backgroundColor: "white",
            borderTop: '2px',
            borderColor: 'black',
            borderStyle: 'solid'
        },
        fullText: {
            fontSize: '8pt',
            marginHorizontal: '0.3in'
        }
    });

    /*const circulatorDeclarationDefault = (
        <View style={[styles.section, {fontSize: '10'}]}>
            <Text style={{textSize: '11', textAlign:'center', fontWeight: '1000'}}>
                Declaration of Circulator
            </Text>
            <Text style={[styles.line, {lineHeight: '1.5'}]}>
            I, _________________ (print name), am 18 years of age or older. My residence address is
______________________ (address, city, state, zip). I circulated this section of the petition and witnessed
each of the appended signatures being written. Each signature on this
petition is, to the best of my information and belief, the genuine signature of the person whose name it
purports to be. I showed each signer of the petition a valid and unfalsified "Offical Top Funders" sheet.
All signatures on this document were obtained on the date of ________ (month/day/year).
I certify under penalty of perjury under the laws of the State of California that the foregoing is true and correct.
Executed on _________(month/day/year), at _____________________(place of signing).   
            </Text>
            <Text style={[styles.line, {marginTop: '20pt'}]}>
            ____________________________
            (complete signature indicating full name of circulator)
            </Text>
        </View>
    )*/

    //this function will be used to list in a grammatically correct fashion
    //the elements of an array (presuming they are strings)
    const listArrayContents = (array) => {
        if (!array || array.length === 0) return "__________";
        if (array.length === 1) return array[0];
        if (array.length === 2) return (array[0] + " and " + array[1]);
        
        let string = "";
        for (let i = 0; i < array.length; i++) {
            if (i>0) string += ", ";
            if (i+1 === array.length) string += "and ";
            string += array[i]
        }
        return string;
    }

    //circulator declaration right-aligned, will go at bottom of page
    const circulatorDeclarationRight = (
        <View style={styles.circulatorDeclaration} key={'circulatorDeclaration'}>
            <Text style={{textSize: '11'}}>
                <Text style={{fontFamily: 'boldSansSerifFont', border: '1px solid', padding: '1pt'}}>Declaration of Circulator: </Text>
                <Text> I am 18 years of age or older, and my name is 
                    <Text style={state.example ? styles.highlight : ""}> ________________________________________</Text>.
                </Text>
            </Text>
            <Text style={[styles.description, {left: '413px'}]}>
                (print name)
            </Text>
            <Text>
                My residence address is 
                <Text style={state.example ? styles.highlight : ""}> ________________________________________________________________________________</Text>.
            </Text>
            <Text style={[styles.description, {left: '297px'}]}>       
                (address, city, state)
            </Text>
            <Text style={{lineHeight: '1.5'}}>
                I circulated this section of the petition and witnessed each of the appended signatures being written.
                Each signature on this petition is, to the best of my information and belief, the genuine
                signature of the person whose name it purports to be. All signatures on this document were obtained between the dates of: 
            </Text>
            <Text style={{position: 'relative', left: '29vw'}}>
                <Text style={state.example ? styles.highlight : ""}> ________________________________</Text> and ________________________________.
            </Text>
            <Text style={[styles.description, {left: '37vw'}]}>
                (month/day/year)                                                     (month/day/year)
            </Text>
            <Text style={{lineHeight: '1.5'}}>
             I certify under penalty of perjury under the laws of the State of California
                that the foregoing is true and correct. Executed on: 
            </Text>
            <Text style={{marginLeft: '214pt'}}>
                <Text style={state.example ? styles.highlight : ""}> _____________________</Text>
                , at <Text style={state.example ? styles.highlight : ""}> _____________________________________</Text>.
            </Text>
            <Text style={[styles.description, {left: '240px'}]}>
            (month/day/year)                    (place of signing: for example, 'Los Angeles, CA')
            </Text>
            <Text style={[styles.line, {marginTop: '20pt'}]}>
                <Text style={state.example ? styles.highlight : ""}>_________________________________________</Text>
            </Text>
            <Text style={[styles.description, {left: '73px'}]}>
                (complete signature)
            </Text>
        </View>
    )

    //entire page, including declaration of circulator
    const introduction = (
        <View style={[styles.section, {fontSize: '11', marginTop: '1in', marginBottom: '0.5pt'}]} key={'introduction'}>
            <Text style={[styles.boldLine, {textAlign:'center', marginTop: '0in', marginBottom: '0.3in'}]}> {/* Or should it say the actual one? */}
                INITIATIVE MEASURE TO BE SUBMITTED DIRECTLY TO THE VOTERS
            </Text>
            <Text style={styles.boldLine}>
                The Attorney General of California has prepared the following circulating title and summary
                of the chief purposes and points of the proposed measure:
            </Text>
            <Text style={styles.boldLine}>
                {/*state.identifier*/}
            </Text>
            <Text style={styles.boldLine}>
                {state.petitionID ? state.petitionID : state.identifier + ": " + (state.title ? state.title.toUpperCase() : "") /**Is same line cool? Probly*/}
            </Text>
            <Text style={styles.line}>
                {state.summary}
                {"\nSummary of estimate by Legislative Analyst and Director of Finance of fiscal impact on state and local governments: "}
            </Text>
            <Text style={[styles.boldLine, {marginTop: '0'}]}>
                {state.fiscalImpact}
            </Text>
        </View>
    )

    //should replace with an actual top funders box that they have to include to run the petition
    const topFundersUnspecified = (
        <View style={[styles.section, {marginTop: '-5px'}]} key={'topFunders'}>
            <Text style={[styles.boldLine, {textAlign:'center'}]}>
                NOTICE TO THE PUBLIC
            </Text>
            <Text style={styles.boldLine}> {/**Tell user to look at top funders sheet in their acct */}
                YOU HAVE THE RIGHT TO SEE AN "OFFICIAL TOP FUNDERS" SHEET.
            </Text>
            <Text style={styles.line}>
                THIS PETITION MAY BE CIRCULATED BY A PAID SIGNATURE GATHERER OR
                A VOLUNTEER. YOU HAVE THE RIGHT TO ASK.
            </Text>
            <Text style={styles.line}>
                THE PROPONENTS OF THIS PROPOSED INITIATIVE MEASURE HAVE THE
                RIGHT TO WITHDRAW THIS PETITION AT ANY TIME BEFORE THE
                MEASURE QUALIFIES FOR THE BALLOT.
            </Text>
            <Text style={{marginTop: '20pt', marginBottom: '-20pt'}}>
                {!state.county ? "Circulated in ______________________ County" :
                    ("Circulated in: " + (state.county) + (state.county === "San Francisco" ? " City and County" : " County"))}
            </Text>
        </View>
    )

    /*const topFunders = (
        <View style={[styles.section, {marginTop: '-5px'}]} key={'topFunders'}>

        <Text style={{marginTop: '20pt', marginBottom: '-20pt'}}>
            {"Circulated in: " + (state.county ? state.county : "______________________") + " county"}
        </Text>
    </View>
    )*/

    /* Signature Boxes Below *
    const noNumberSignatureBox = (
        <View style={[styles.section, {fontSize: '9', border:'1px'}]} wrap={false}>
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <div style={{borderRight: '1px'}}>
                    <Text style={[styles.signatureLine, {marginLeft:'8.5%'}]}>
                            Print your name: <Text style={state.example ? styles.highlight : ""}> ______________________________ </Text>
                        Signature: <Text style={state.example ? styles.highlight : ""}> ________________________</Text>
                    </Text>
                    <Text style={[styles.signatureLine, {marginLeft: '4%'}]}>
                        Residence Address ONLY: <Text style={state.example ? styles.highlight : ""}> __________________________ </Text>
                        City: <Text style={state.example ? styles.highlight : ""}> ________________ </Text>
                        Zip: <Text style={state.example ? styles.highlight : ""}> ________</Text>
                    </Text>
                </div>
                <Text style={{width: '1in', marginTop: '-30pt'}}>
                    For Official Use Only
                </Text>
            </div>
        </View>
    )*/

    /* Signature Box Below */
    const nextSignatureBox = (count) => {

        //how to adjust line length based on number
        //currently supporting more than 1000? Excessive but whatevs
        let extraLine = "___";
        if (count > 9) extraLine = "__";
        if (count > 99) extraLine = "_";

        //at end, leave space for the declaration of circulator
        //let minPresenceAhead = 0;
        //should equal to number of signatures at end since start with 1
        //if (count === max) {
        //    minPresenceAhead = 100;
        //}

        //First signature box settings
        let officialUseDisclaimer = (
            <Text style={{width: '1in', marginTop: '-30pt'}}>
                For Official Use Only
            </Text>
        )
        let styleType = styles.firstSignatureSection;

        //Settings for rest of them
        if (count > 1) {
            officialUseDisclaimer = <div></div>;
            styleType = styles.signatureSection;
        }

        return (
            <View 
                style={[styleType, {fontSize: '9', border:'1px'}]}
                key={count}
                wrap={false}
                //minPresenceAhead={minPresenceAhead}
            >
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <div style={{borderRight: '1px'}}>
                        <Text style={[styles.signatureLine]}>
                            {count}.  Print your name: <Text style={state.example ? styles.highlight : ""}> ________________________________{extraLine} </Text>
                            Signature: <Text style={state.example ? styles.highlight : ""}> _____________________________</Text>
                        </Text>
                        <Text style={[styles.signatureLine]}>
                            Residence Address ONLY: <Text style={state.example ? styles.highlight : ""}> ______________________________ </Text>
                            City: <Text style={state.example ? styles.highlight : ""}> ____________________ </Text>
                            Zip: <Text style={state.example ? styles.highlight : ""}> _________</Text>
                        </Text>
                    </div>
                    {officialUseDisclaimer}
                </div>
            </View>
        )
    }

    /** Full text of measure */
    const fullText = (
        <View>
            <Text style={{textAlign: 'center', margin: '10pt', marginTop: '42pt'}}>
                To the Honorable Secretary of State of California
            </Text>
            <Text style={{marginHorizontal: '0.3in', marginVertical: '10pt', lineHeight: '1.5'}}>
                We, the undersigned, registered, qualified voters of California, residents of 
                { state.county ? 
                    state.county === "San Francisco" ?
                        " San Francisco City and County" 
                        :
                        " " + state.county + " County"
                    :
                    " the County (or City and County) referenced on the signature page of this petition"},
                hereby propose amendments to the
                {state.type === "CICA" ? " Constitution of California" : ""}
                {state.type === "CICA/SS" ? " Constitution of California and" : ""}
                {state.type === "CISS" || state.type === "CICA/SS" ? (" " + listArrayContents(state.codes) + " Code") : ""}
                {state.codes && state.codes.length > 1 ? "s" : ""}, relating to{" "}
                {state.topics && state.topics.length > 0 ? listArrayContents(state.topics) : "such matters"},
                and petition the Secretary of State to submit the same to the voters of California
                for their adoption or rejection at the next succeeding general election or at
                any special statewide election held prior to that general election or as
                otherwise provided by law. The proposed 
                {state.type === "CICA" || state.type === "CICA/SS" ? " constitutional " : " statutory "}
                {state.type === "CICA/SS" ? "and statutory " : ""}
                amendments read as follows:
            </Text>
            <Text style={styles.fullText}>
                {state.fullText ? state.fullText : ""}
            </Text>
        </View>
    );



    //put it together into a page here
    const getCirculatingPacket = () => {
        const sections = [/*introduction, */topFundersUnspecified]
        const signatures = getSignatureCount(props.pages, props.signaturesPerSheet);
        
        for (let i = 0; i < signatures; i++){
            //count starts at 1 instead of 0
            sections.push(nextSignatureBox(i+1));
        } 
        sections.push(circulatorDeclarationRight);

        const page = (
            <Document>
                <Page size="Letter" style={styles.page} wrap>
                    <View fixed>
                        {introduction}
                    </View>
                    {sections}
                </Page>
                <Page size="Letter" style={styles.page} wrap>
                    {introduction}
                    {fullText}
                </Page>
            </Document>
        )
        return page;
    }

    //added in because of below stuff, but think I will move all of this outside of this component
    //const [pdfInstance, updatePdfInstance] = usePDF({ document: getCirculatingPacket() });
    
    //-----------------------PDF-Lib below---------------------
    //if we do not have the text ourselves, we may have a pdf file to append

    const [fullPacket, setFullPacket] = useState("");
    //const [url, setUrl] = useState(undefined);
    const [pdfObj, setPdfObj] = useState(pdf(getCirculatingPacket()));

    //below so that we update our document when state changes
    //note: have changed it to change upon each individual signaturesPerSheet
    //so that proponent doesn't have to manually refresh each time. Does mean
    //that this will break if signaturesPerSheet is undefined though.
    useEffect(() => {
        setPdfObj(pdf(getCirculatingPacket()));
    }, 
    [
        state, 
        props.pages, 
        props.signaturesPerSheet[0], 
        props.signaturesPerSheet[1], 
        props.signaturesPerSheet[2]
    ]);
    
    useEffect(() => {
        console.log("Starting useEffect.");
        console.log(pdfObj.container);

        //everything breaks if we don't ensure the document has been
        //updated on the container
        //and also, if there's no state don't do anything
        if (pdfObj.container.document === null || !state) return;

        //optain blob data imperatively
        const createFullPacket = async () => {
            const signatureBlob = await pdfObj.toBlob();
            console.log("Got blob");
            //returns a promise that resolves to an array buffer
            //https://developer.mozilla.org/en-US/docs/Web/API/Blob/arrayBuffer#browser_compatibility
            const signatureArrayBuffer = await signatureBlob.arrayBuffer();
            console.log("Got buffer");

            //now we should be able to load this into PDF-Lib
            const fullPacketDoc = await PDFDocument.load(signatureArrayBuffer);

            //check that we've been fed a file (as opposed to just text)
            if (state.fullTextFile && state.fullTextFile !== "") {
                console.log(state.fullTextFile);
                //turn the File (which is a Blob polished up a bit) into an array buffer
                const fullTextBuffer = await state.fullTextFile.arrayBuffer();
                //now we can put it into pdf-lib
                const fullTextDoc = await PDFDocument.load(fullTextBuffer);
                const indices = fullTextDoc.getPageIndices();
                const copiedPages = await fullPacketDoc.copyPages(fullTextDoc, indices);
                for (let i = 0; i<indices.length; i++) {
                    fullPacketDoc.addPage(copiedPages[i]);
                }
            } else if (state.fullText) {
                //do whatever for text (actually would probably do this in React-PDF solely)
            //otherwise, if no full text, don't render anything
            } else {
                return;
            }

            //then turn it back into bytes...
            const fullPacketBytes = await fullPacketDoc.save();
            //now we have to turn it into a blob...
            const finalBlob = new Blob( [fullPacketBytes], { type: "application/pdf" });
            //then turn that into a url for react-pdf (if we want to still use PDFViewer)
            const finalURL = URL.createObjectURL(finalBlob);
            //return the full packet in an iframe
            setFullPacket(<iframe title="Circulator Packet PDF" width="100%" height="1000px" src={finalURL}></iframe>);
            //pass it back up
            //setUrl(finalURL);
            console.log(finalURL);
            /*setFullPacket(
            <Link 
                to={finalURL} 
                target="_blank" 
                style={{
                    marginTop: '1em', 
                    backgroundColor: 'aquamarine',
                    borderColor: 'gray',
                    color: 'black'
                }}
                //title="Circulator Packet PDF"
            >
                Open in new window
            </Link>)*/
            /*setFullPacket(
                <Button
                    type="submit"
                    style={{
                        marginTop: '1em', 
                        backgroundColor: 'aquamarine',
                        borderColor: 'gray',
                        color: 'black',
                        width: '100%'
                    }}
                    onClick={() => {
                        console.log("Clicking button; log and do actions here.");
                        const pdfWindow = window.open(finalURL, '_blank');
                        pdfWindow.focus();
                    }}
                >
                    Get new packet
                </Button>
            );*/
        }
        
        createFullPacket();

    }, [pdfObj.container.document, state]);

    //this one builds off of a link, which I think will not work due to
    //CORS impeding scripts from accessing resources from other domains
    /*const appendPdfFromLink = async (pdfLink) => {
        console.log("Append pdf from link.");
        
        //use React-PDF as normal to make signature section and intro to full text
        //const signatureSection = getCirculatingPacket();
        //console.log("Got packet");
        //optain blob data
        const signatureBlob = pdfInstance.blob;
        //const temp = pdf({});
        //temp.updateContainer(signatureSection);
        //console.log("Tryna get blob");
        //const signatureBlob = await temp.toBlob();
        console.log("Got blob");
        console.log(signatureBlob);
        //returns a promise that resolves to an array buffer
        //https://developer.mozilla.org/en-US/docs/Web/API/Blob/arrayBuffer#browser_compatibility
        const signatureArrayBuffer = await signatureBlob.arrayBuffer();
    
        console.log("Got array buffer");

        //now we should be able to load this into PDF-Lib
        const signatureDoc = await PDFDocument.load(signatureArrayBuffer);

        //load the pdf from the link we are using
        //this will not work due to CORS. Must download and then supply from firebase
        //or from file, for now
        //const fullTextBytes = await fetch(pdfLink).then(res => res.arrayBuffer());
        //const fullTextDoc = await PDFDocument.load(fullTextBytes);

        //join them together into a new document
        //I think I need to use copypages
        /*const fullPacket = await PDFDocument.create();
        for (const page in signatureDoc.getPages()) {
            fullPacket.addPage(page);
        }*/
        //for (const page in fullTextDoc.getPages()) {
        //    fullPacket.addPage(page);
        //}
        //back to a byte array...
        /*const fullPacketBytes = await signatureDoc.save();//fullPacket.save();
        //now we have to turn it into a blob...
        const finalBlob = new Blob( [fullPacketBytes], { type: "application/pdf" });
        //then turn that into a url for react-pdf (if we want to still use PDFViewer)
        const finalURL = URL.createObjectURL(finalBlob);
        console.log(finalURL);
        const finalDoc = (
            <Document
                file={finalURL}
            >

            </Document>
        )

        return finalDoc;
    }*/

    //eventually this file will be passed through by Firebase Storage;
    //for demonstration, I will manually pass it, but either way, should
    //be all the same to this component
    /*const appendPdfFromFile = async (pdfFile) => {
        const signatureSection = getCirculatingPacket();
        //optain blob data imperatively
        const signatureBlob = pdf(signatureSection).toBlob();
        //returns a promise that resolves to an array buffer
        //https://developer.mozilla.org/en-US/docs/Web/API/Blob/arrayBuffer#browser_compatibility
        const signatureArrayBuffer = await signatureBlob.arrayBuffer();
    
        //now we should be able to load this into PDF-Lib
        const signatureDoc = await PDFDocument.load(signatureArrayBuffer);
    
    
    }*/

    if (!state.title || !state.summary || !state.fiscalImpact || !state.fullTextFile) {
        return (
            <div>
                <p>Missing key fields. No packet created.</p>
            </div>
        )
    }

    return (   
        fullPacket
    )

}
export default SignaturePDF;