/* Copyright (C) 2023 Christian Miley - All Rights Reserved */

/**
 * NavigationBar.js
 * This component is called at the top level around the router,
 * and renders a navigation bar on specified pages for use with site.
 */

import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, NavLink } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './UI.css';
import { Button, Navbar, Nav, NavDropdown, Container, Dropdown} from 'react-bootstrap';
import { auth } from '../firebase.js';
import { UserContext, PersonContext } from '../Context.js';
import MobileButtons from './MobileButtons.js';
import { allStates } from './Information.js';

//props no longer used after removing search bar
//5/20/24: props now contains setPersonInfo
function NavigationBar(props) {
  const location = useLocation();
  /*useEffect(() => {
    console.log(location);
    console.log(window.history);
  }, [location, window.history]);
  /**
   * Note about navigate: I am currently going to just pass this navigate instance
   * to propositionSearchBar so that it can push a new user feed page navigate,
   * but this may result in passing old navigate objects at some points, I'm not sure.
   * Ideally I would use the useNavigate hook in propositionSearchBar, but I don't think
   * I can because it's a class component (not a functional one). Current solution should
   * be temporary.
   * Second note: well tbh it seems to work fine, maybe this is OK. After all, the old
   * functionality in React-Router was that history was passed as props...
   */
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const personContext = useContext(PersonContext);
  //const personContext = useContext(PersonContext);
  //const signaturesContext = useContext(SignaturesContext);
  //let state = JSON.parse(sessionStorage.getItem("state"));
  //let randomPlaceholder = randomSearchSuggestion();

  //below used to make expansion and collapse of navbar a controlled component
  const [expanded, setExpanded] = useState(false);
  //going to use this to make a header for what page the user is on when on mobile
  const [mobileHeader, setMobileHeader] = useState("");
  //change the mobileheader based on where we are
  //note: will need to be keeping track of users' info (and displayNames) to correctly
  //show user pages
  useEffect(() => {
    switch(location.pathname.toLowerCase()) {
      case "/":
        setMobileHeader('Measures');
        return;
      case "/about":
        setMobileHeader('About');
        return;
      //case "/measure":
        //how do I get this to work?
      default:
        setMobileHeader('');
        return;
    }
  }, [location]);

  //return if we're on a path that shouldn't display the navigation bar
  //I set it to /emails and /addres instead of /emailsignup because of how the slice works rn
  let pathsWithoutNavbar = [/*'/signin', */'/signup', /*'/emails',/* '/addres',*/ '/verify', '/circul', '/propon'];
  //slicing because /signup can have a parameter afterwards
  if (pathsWithoutNavbar.indexOf(location.pathname.slice(0, 7).toLowerCase()) !== -1) return null;

  //will only show user's petitions when "Your Petitions" button is clicked
  /*function showUserPetitions() {
    var allProps = props.propositions;

    //check if proposition belongs to the user
    function belongsToUser(proposition) {
        if (!userContext) return false;
        var theCreator = proposition.Creator.trim(); //the proposition's creator's id
        if (theCreator.indexOf(userContext.uid) > -1) {  //if the proposition's creator's id is that of the current user
            return true;   //user created the proposition so return true
        } else {
            return false;  //otherwise return false
        }
    }

  var userProps = allProps.filter(belongsToUser); //get the propositions the user created
  var newProps = userProps;
  props.setDisplayedPropositions(newProps);  //set the displayed propositions to the one the user created
  history.push('/UserFeedPage');
  }*/

  //will only show petitions in user's area when some button is clicked
  /*function showLocalPetitions() {
    var allProps = props.propositions;

    //check if proposition belongs to the user
    function inUsersArea(proposition) {
      if ( !personContext || !userContext || proposition.Residency === undefined) return false;
      
      switch(proposition.Residency[0]){
        case "State":
          if (proposition.Residency[1] === personContext.State) return true;
          break;
        case "County":
          if (proposition.Residency[1] === personContext.County) return true;
          break;
        case "City":
          if (proposition.Residency[1] === personContext.City) return true;
          break;
        default:
          break;
      }
      return false;
    }

    var localProps = allProps.filter(inUsersArea); //get the propositions in the user's personInfo
    var newProps = localProps;
    props.setDisplayedPropositions(newProps);  //set the displayed propositions to the ones the user is a resident for
    history.push('/UserFeedPage');
  }*/

  function signOut(){
    auth.signOut().then(function() {
      console.log('User signed out.');
      sessionStorage.setItem("state", JSON.stringify(undefined));
      navigate('/signin');
    }).catch(function(error) {
      console.log("Error in signout: ", error);
    });
  }

  /**
  * Returns a random search suggestion from a prebuilt array.
  * Note: should someday be a dynamic array.
  * @param {none}
  */
  /*function randomSearchSuggestion()
  {
    let keywords = ['Rent Control', 
                    'Cannabis', 
                    'Electronic Signature Gathering', 
                    'Wildlife', 
                    'Property Tax', 
                    'Ranked-Choice Voting',
                    'Psilocybin'
                    ];
    return keywords[Math.floor(Math.random() * keywords.length)];
  }*/

  return (
    <div>
    {/*<p className="CollapsedHeader" text={mobileHeader} />*/}
    <Navbar 
      expanded={expanded} 
      expand="md" 
      variant="dark" 
      fixed="top"
      className="NavigationBar"
    >
      <Container>
        <Navbar.Brand 
          className="NavBrand"
          as={NavLink} 
          to="/" 
          onClick={() => setExpanded(false)}
        >rBallot</Navbar.Brand>
        <MobileButtons />
        <span className="CollapsedHeader" text={mobileHeader} />
        <Navbar.Toggle style={{ borderWidth: '0.5px', borderColor: 'white'}} onClick={() => setExpanded(!expanded)} aria-controls="responsive-navbar"/>
        <Navbar.Collapse id="responsive-navbar" className="Toggle">
          <Nav 
            className="NavBarLinks me-auto"
            onClick={(e) => {
              //don't close if it's a dropdown
              if (e.target.className !== "dropdown-toggle nav-link") setExpanded(false);
            }} 
          >
            <div style={{display:'flex'}}>
              <Nav.Link 
                as={NavLink} 
                className="NavLink" 
                to="/" 
                title="Measures" 
                //onClick={(e) => { console.log(e.target.title); setMobileHeader(e.target.title); }}
              >
                Measures
              </Nav.Link>
              {location.pathname !== '/' ? "" :
                <button
                  className="ClearMeasuresButton"
                  style={{fontSize: '10pt', margin: '0em 1.5em 0em 0.5em',border:'1px solid white',borderRadius:'90px',padding:'0em 0.5em', backgroundColor:'transparent', color:'white'}}
                  onClick={() => { 
                    props.setPersonInfo({ hiddenMeasures: [] });
                    props.setPropositionsObj({});
                  }}
                >
                  Clear
                </button>
              }
            </div>
              {!userContext ? "" :
                <Nav.Link 
                  as={NavLink} 
                  className="NavLink" 
                  to={"/user/" + userContext.uid} 
                  title={userContext.displayName} 
                  //onClick={(e) => { console.log(e.target.title); setMobileHeader(e.target.title);}}
                >
                  {userContext.displayName
                  //+ (userContext && userContext.emailVerified ? "" : " - Click here to verify your account")
                  }
                </Nav.Link>
              }
              { /*// if we're on the userfeedpage, add in a way to modify the residencycontext
              location.pathname !== '/' ? "" :
                <Nav.Link style={{
                  //width: '100%', 
                  display:'flex', 
                  //position:'fixed',
                  //top:'3.5em',
                  backgroundColor:'var(--primary-bgd-color)', 
                  justifyContent:'flex-end', 
                  //padding: '0.25em 1em',
                  //zIndex: '100',
                  color:'black'
                  }}
                >
                  {/*<div>
                    {"State: "}
                    <select
                      style={{textAlign: 'right', backgroundColor:'var(--secondary-bgd-color)', border: '1px solid white', borderRadius:'10px'}}
                      value={personContext.State}
                      onChange={(e) => {
                        props.setPersonInfo({
                          //...personContext,
                          State: e.target.value
                        });
                        props.setPropositionsObj({});
                      }}
                    >
                      <option key="select" value="">Select state</option>
                      {allStates.map((state) => {
                        return <option key={state} value={state}>{state}</option>
                      })}
                    </select>*//*}
                    <button
                      style={{margin: '0em', padding:'0em', /*border:'1px solid white',borderRadius:'90px',padding:'0em 0.5em',*//* border:'0px', backgroundColor:'transparent', color:'white'}}
                      onClick={() => { 
                        props.setPersonInfo({ hiddenMeasures: [] });
                        props.setPropositionsObj({});
                      }}
                    >
                      Clear {personContext.State} Measures
                    </button>
                  {/*</div>
                  {/*<div>
                      County:
                      <select
                        onChange={(e) => {
                          setPersonInfo({
                            ...personContext,
                            State: e.target.value
                          });
                        }}
                      >

                      </select>
                    </div>*//*}
                </Nav.Link>
                      */}
              <Nav.Link 
                as={NavLink} 
                className="NavLink" 
                to={"/about"} 
                title="About" 
                //onClick={(e) => { console.log(e.target.title); setMobileHeader(e.target.title);}}
              >
                About
              </Nav.Link>
              { !userContext || userContext === "Not loaded" || !userContext.permissions || !userContext.permissions.p ? "" :
                userContext.permissions.p.map((measure) => {
                  return <Nav.Link as={NavLink} key={measure} className="NavLink" to={"/measure/" + measure + "/campaign"}>{measure}</Nav.Link>
                })
              }
              { !userContext || userContext === "Not loaded" || !userContext.permissions || !userContext.permissions.c ? "" :
                <NavDropdown title="My Volunteering" className="NavDropdown">
                  <div className="NavDropdownInside">
                    {userContext.permissions.c.map((measure) => {
                      return <Dropdown.Item as={NavLink} className="NavDropdownItem" key={measure} to={"/measure/" + measure + "/campaign"}>{measure}</Dropdown.Item>
                    })}
                  </div>
                </NavDropdown>
              }
              { /*!personContext || !personContext.requested ? "" :
                <NavDropdown title="Requested" className="NavDropdown">
                  {/*<div className="NavDropdownInside">*//*}
                    {personContext.requested.map((measure) => {
                      return <Dropdown.Item as={NavLink} className="NavDropdownItem" key={measure} to={"/measure/" + measure}>{measure}</Dropdown.Item>
                    })}
                  {/*</div>*//*}
                </NavDropdown>
                  */}
              {/*<Button className="NavButton" onClick={showUserPetitions}>
                Your Petitions
              </Button>*
              <Button className="NavButton" onClick={showLocalPetitions}>
                Local Petitions
              </Button>
              <Button className="NavButton" onClick={() => history.push("/AddPetitionPage")}>Create a Petition</Button> */}
            {/*<NavLink className="NavLink" to="/checkoutpage" style={{textDecoration: 'underline'}}>
              {"Mail in " +
              (signaturesContext ? signaturesContext.unorderedSignatures.length : "") + 
              " signature" + (signaturesContext ? (signaturesContext.unorderedSignatures.length === 1 ? "" : "s") : "")}
            </NavLink>*/}
          </Nav>
          <Nav>
            {/*<PropositionSearchBar 
              navigate={navigate} 
              propositions={props.propositions} 
              randomPlaceholder={randomPlaceholder} 
              setDisplayedPropositions={props.setDisplayedPropositions}
            />*/}
            {userContext ? 
            <Button className="SignOut" variant = 'outline-light'
            onClick={() => { setExpanded(false); signOut(); } }>
            Sign Out
            </Button>
            :
            <Button className="SignOut" variant = 'outline-light'
            onClick={() => { setExpanded(false); navigate("/signin"); } } >
            Sign In
            </Button>
            }
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    { // if we're on the userfeedpage, add in a way to modify the residencycontext
    location.pathname !== '/' ? "" :
      <div 
        className="QuickChangePersonContextBar" //will switch it to display none on small screens
        style={{
          width: '100%', 
          display:'flex', 
          position:'fixed',
          top:'3.5em',
          backgroundColor:'var(--secondary-bgd-color)', 
          justifyContent:'flex-end', 
          padding: '0.25em 1em',
          zIndex: '100',
          color:'black'
          }}
      >
        <div>
          {"State: "}
          <select
            style={{textAlign: 'right', backgroundColor:'var(--secondary-bgd-color)', border: '1px solid white', borderRadius:'10px'}}
            value={personContext.State}
            onChange={(e) => {
              props.setPersonInfo({
                //...personContext,
                State: e.target.value
              });
              props.setPropositionsObj({});
            }}
          >
            <option key="select" value="">Select state</option>
            {allStates.map((state) => {
              return <option key={state} value={state}>{state}</option>
            })}
          </select>
          <button
            style={{margin: '0em 5em 0em 1em', border:'1px solid white',borderRadius:'90px',padding:'0em 0.5em', backgroundColor:'transparent', color:'white'}}
            onClick={() => { 
              props.setPersonInfo({ hiddenMeasures: [] });
              props.setPropositionsObj({});
            }}
          >
            X
          </button>
        {/*<div>
            County:
            <select
              onChange={(e) => {
                setPersonInfo({
                  ...personContext,
                  State: e.target.value
                });
              }}
            >

            </select>
          </div>*/}
        </div>
      </div>
    }

    {/* Toast notifications going here? */}
    <div>
      {/** Seems I'll need a Toast component that can handle its own hiding and showing... */}
    </div>
</div>
  );
}





export default NavigationBar;