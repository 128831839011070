/* Copyright (C) 2024 Christian Miley - All Rights Reserved */

/**
 * ShareMeasure.js
 * This page will ask the user to share the measure for others to sign after they have done so.
 */

import React, { useState, /*useContext, */useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import SharingLinks from '../SharingLinks.js';
//import { UserContext } from '../../Context.js';

//props will include propositionsObj
function ShareMeasure(props) {
    const { measureID, redirected } = useParams();
    //const userContext = useContext(UserContext);
    //const [shareUrl, setShareUrl] = useState("https://www.rballot.org/measure/" + measureID);
    const [shareTitle, setShareTitle] = useState("Sign this measure with me!");

    useEffect(() => {
        if (!props || !measureID || !props.propositionsObj || !props.propositionsObj[measureID]) return;
        const measure = props.propositionsObj[measureID];

        if (measure.rBallotStatus === "volunteering") {
            setShareTitle("Sign the " + measure.rBallotTitle + " with me!");
        } else {
            setShareTitle("Sign the " + measure.BallotpediaTitle + " with me!");
        }

    }, [props, measureID]);

    return (
        <Container style={{marginTop: '8em', maxWidth: '600px'}}>
            <div className="Background2"></div>
            <p style={{ margin: '3em 0em', padding: '2em', border: 'solid 1px gray', borderRadius: '10px', backgroundColor: 'white', boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.4)'}}>
                {(!redirected ? "" : <span>You're on your way to signing this petition!
                <br />
                <br />
                Now, let's talk about how this measure succeeds.</span>)} With hundreds of thousands of signatures needed, we need <strong>exponential growth</strong> to qualify for the ballot.
                <br />
                <br />
                Share this petition and get <strong>at least 2 more people</strong> to sign up to keep growth exponential!
            </p>
            <SharingLinks 
                size={48}
                //shareUrl={shareUrl}
                shareTitle={shareTitle}
                spacing={'1em'}
                margin={'1em'}
                measureID={measureID}
            />
            
            {/*<CopyLink 
                className=""
                link={"www.rballot.org/measure/" + measureID + "/" + (userContext ? userContext.uid : "")}
                text={"Referral Link"}
            />*/}
       </Container>
    )
}

export default ShareMeasure;