/**
 * Copyright Christian Miley 2024 all rights reserved
 * 
 * HTMLTextDisplayPage
 * This page will display the HTML for the specified ballot measure
 * note: may want to implement DOMPurify or another sanitizer to remove any possible <script> or anything in the html
 */

import React from 'react';
import { Container } from 'react-bootstrap';
import { useParams } from "react-router";

export default function HTMLTextDisplayPage(props) {
    const { id } = useParams();
    const html = props?.propositionsObj[id]?.FullTextHTML;
    console.log(html);

    if (!html) return(
        <div className="my-5 py-5"><p>No HTML here</p></div>
    );

    return (
        <Container className="my-4 py-5">
            <div dangerouslySetInnerHTML={{__html: html}}/>
        </Container>
    );
}