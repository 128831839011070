/* Copyright (C) 2023 Christian Miley - All Rights Reserved */

/**
 * Utility.js
 * This will export functions and components that are used by multiple files
 */

// before there were no imports to this file... but should be OK to have some
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';

//------------------Get signature count-------------------
//note: this needs to be modified to just take in signaturesPerSheet,
//not measure, since the formatting varies.

export const getSignatureCount = (pages, signaturesPerSheet) => {
    pages = Number(pages);
    //default to something a little low
    if (!signaturesPerSheet) {
        signaturesPerSheet = [1, 12, 24];
    }

    if (pages < 1) return Number(signaturesPerSheet[0]);
    if (pages === 1) return Number(signaturesPerSheet[0]);
    if (pages === 2) return Number(signaturesPerSheet[1]);
    if (pages === 3) return Number(signaturesPerSheet[2]);
    if (pages > 3) {
        // how many signatures fit on first and last page
        let count = Number(signaturesPerSheet[1]);
        // how many signatures are on each middle page
        const middlePageSigCount = Number(signaturesPerSheet[2]) - Number(signaturesPerSheet[1]);
        // add in as many middle pages as needed
        count += middlePageSigCount * (pages-2);
        return count;
    }
}

//------------------------List array contents----------------------
//this function will be used to list in a grammatically correct fashion
//the elements of an array (presuming they are strings)

export const listArrayContents = (array) => {
    if (!array || array.length === 0) return "_____________";
    if (array.length === 1) return array[0];
    if (array.length === 2) return (array[0] + " and " + array[1]);
    
    let string = "";
    for (let i = 0; i < array.length; i++) {
        if (i>0) string += ", ";
        if (i+1 === array.length) string += "and ";
        string += array[i]
    }
    return string;
}

/** ---------------------------------------------
* Turn a timestamp into a string
* in locale time or, if 'null',
* into the string 'Sending...'
* @param {timestamp} timestamp 
*/
export function dateFromTimestamp(timestamp) {
    //if the server hasn't received message yet
    //(this comes from chat.js, not sure why it was null)
    if (timestamp === null) return "Sending...";

    //if timestamp hasn't been downloaded yet
    if (timestamp === undefined) return "Loading...";

    //for some that are just strings
    if (typeof timestamp === "string") return timestamp;

    //note: toDate() seems not to be used much. I can
    //barely find mention of it online.
    let date = timestamp.toDate();
    return date.toLocaleDateString();
}

/**
 * Turn a timestamp into a string in the form of:
 * {date} at {time}
 * @param {timestamp} timestamp 
 * @returns 
 */
export function datetimeFromTimestamp(timestamp) {
    if (!timestamp) return "";
    //it might already be a string
    if (typeof timestamp === "string") return timestamp;

    const date = timestamp.toDate();
    return date.toLocaleDateString() + " at " + date.toLocaleTimeString();
    
}

/**
 * daysUntilTimestamp
 * Takes a timestamp and returns the days from current time until then
 * @param {*} timestamp
 * @returns {number} days
 */
export function daysUntilTimestamp(timestamp) {
    if (!timestamp) return 'Loading...';

    const presentDate = new Date();
    const date = timestamp.toDate();
    return Math.round((date.getTime() - presentDate.getTime()) / (1000*3600*24));
}

/**
 * capitalize
 * This function will capitalize the first letter of a string
 * and return that string
 * @param {string} s
 * @returns a capitalized string
 * note: this may break on IE 7 or earlier
 */
export function capitalize(s) {
    return (s[0].toUpperCase() + s.slice(1));
}

// this came from off react-bootstrap, but seems key
export const TooltipLink = ({ id, to, title, style, children }) => (
    <OverlayTrigger overlay={<Tooltip id={id}>{title}</Tooltip>}>
        <Link to={to} style={style}>{children}</Link>
    </OverlayTrigger>
);

// any way to make it use a specified component, not just link?
// could be AddTooltip, and take in a component with style and to already applied? hmm
// ohhh children is pulling in the icons! and would pull in whatever text nested underneath. Cool!
export const AddTooltip = ({ id, title, children }) => (
    <OverlayTrigger overlay={<Tooltip id={id}>{title}</Tooltip>}>
        {children}
    </OverlayTrigger>
);

const utility = {
    getSignatureCount,
    listArrayContents,
    dateFromTimestamp,
    capitalize,
    TooltipLink,
    AddTooltip
};

export default utility;
