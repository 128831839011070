/* Copyright (C) 2023 Christian Miley - All Rights Reserved */

/**
 * PetitionDownloads.js
 * This page will display all of the currently approved
 * petition PDFs for a measure for download.
 * They will be displayed as cards for now, and for ones
 * including top funders should only display those currently
 * eligible.
 */

 import React, { useEffect, useContext/*useState, useContext*/ } from 'react';
 import { useParams, useNavigate } from "react-router-dom";
 import { getFilesByPathAndIds, logDownloadPetition } from '../../firebase.js';
 import { Container, Card, Button } from 'react-bootstrap';
 import { UserContext, PersonContext } from '../../Context.js';

function PetitionDownloads(props) {
    const { id } = useParams();
    const navigate = useNavigate();
    const userContext = useContext(UserContext);
    const personContext = useContext(PersonContext);

    useEffect(() => {
        if (!props || !id || !props.propositionsObj || !props.propositionsObj.ordering || !props.propositionsObj[id]) return;

        //this will hold the currently active petitions along with metadata
        //note: I could also do this by just only keeping the currently active petitions
        //in cloud storage and keeping metadata along with the files. But let's try this,
        //as it could allow for lazy loading of files.
        const petitionIDs = props.propositionsObj[id].CurrentPetitionDownloads;
        if (!petitionIDs || Object.keys(petitionIDs).length === 0) return;

        const petitionsToFetch = [];
        for (const petition in petitionIDs) {
            // if we've already fetched and stored this one, don't get it again
            if ("File" in petitionIDs[petition]) continue;
            petitionsToFetch.push(petitionIDs[petition]);
        }

        // here, grab the relevant files and assign them to propositionsObj
        const getPetitionFiles = async() => {
            const path = "/fullPetitions/" + id; //should work with or without trailing slash
            const ids = petitionsToFetch.map((petition) => petition.fileName);
            try {
                const files = await getFilesByPathAndIds(path, ids);
                for (const i in files) {
                    petitionIDs[files[i].name] = {
                        ...petitionIDs[files[i].name],
                        File: files[i]
                    };
                }
                props.setPropositionsObj({
                    ...props.propositionsObj,
                    [id]: {
                        ...props.propositionsObj[id],
                        CurrentPetitionDownloads: petitionIDs
                    }
                });
                return;
            } catch(error) {
                console.error(error);
                return;
            }
        }

        if (petitionsToFetch.length > 0) getPetitionFiles();

    }, [props, id]);

    // wait for us to have propositionsObj
    if (!props || !id || !props.propositionsObj) return (
        <Container>
            <h4>Loading...</h4>
        </Container>
    );

    if (!props.propositionsObj[id]) return (
        <Container>
            <h4>Petition not found.</h4>
        </Container>
    );

    /*if (!props.propositionsObj[id].CurrentPetitionDownloads ||
        props.propositionsObj[id].CurrentPetitionDownloads.length === 0
    ) return (
        <Container>
            <h4>{id} has no petition downloads hosted.</h4>
        </Container>
    );*/

    /*Object.entries(props.propositionsObj[id].CurrentPetitionDownloads).map((keyValue) => {
        const petition = keyValue[1];
        petition.id = keyValue[0];
        
    })
    */
    //Object.values(props.propositionsObj[id].CurrentPetitionDownloads).map((petition) => {\

    // display cards with information and download for all currently available petitions
    if (!props.propositionsObj[id].CurrentPetitionDownloads) {
        console.log("hello");
        return (
            <Container style={{marginTop: '6em', textAlign: 'center', maxWidth: '600px'}}>
                <div className="Background2"/>
                <div style={{backgroundColor: 'white', border: '1px solid black', borderRadius: '6px', padding: '2em 2em 0.5em'}}>
                    <h1>Print a petition</h1>
                    <p>{props.propositionsObj[id].rBallotTitle ? props.propositionsObj[id].rBallotTitle : props.propositionsObj[id].BallotpediaTitle}</p>
                    <p style={{textAlign: 'left'}}>No petition has yet been uploaded for this ballot measure campaign.</p>
                </div>
            </Container>
        )
    }
    
    return (
        <Container style={{marginTop: '6em', textAlign: 'center', maxWidth: '600px'}}>
            <div className="Background2"/>
            <div style={{backgroundColor: 'white', border: '1px solid black', borderRadius: '6px', padding: '1em 2em 0.5em', boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.3)'}}>
                <h1>Print a petition</h1>
                <p>{props.propositionsObj[id].rBallotTitle ? props.propositionsObj[id].rBallotTitle : props.propositionsObj[id].BallotpediaTitle}</p>
                <p style={{textAlign: 'center'}}>Choose any of the petitions below to download and print.</p>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-evenly', marginTop: '2em'}}>
            {/*Object.values(props.propositionsObj[id].CurrentPetitionDownloads).map((petition) => {*/
            Object.entries(props.propositionsObj[id].CurrentPetitionDownloads).map((keyValue) => {
                const petition = keyValue[1];
                petition.id = keyValue[0];
                petition.County = personContext.County;
                return(
                    <Card key={petition.fileName} style={{maxWidth: '18em', boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.3)'}}>
                        <Card.Header style={{padding: '0.5em', fontSize: '1.75em'}}>
                            {petition.petitionName ? petition.petitionName : petition.fileName}
                        </Card.Header>
                        <Card.Body style={{textAlign: 'left'}}>
                            <Card.Text>Signatures: {petition.signatures}</Card.Text>
                            <Card.Text>Page Size: {petition.pageSize ? petition.pageSize : "Unspecified"}</Card.Text>
                            <Card.Text>Pages: {Math.round(petition.pages/2)} <span style={{fontSize: '0.9em'}}>(double-sided)</span></Card.Text>
                            {petition.binding ? 
                                <Card.Text>Binding: {petition.binding}</Card.Text>
                                :
                                ""
                            }
                        </Card.Body>
                        <Card.Footer>
                            {petition.File ? 
                                <Button>
                                    <a 
                                        // this causes issues on phone!
                                        target="_blank"
                                        rel="noreferrer"
                                        href={URL.createObjectURL(petition.File)}
                                        download={"rBallot" + petition.File.name}
                                        style={{color: 'white', textDecoration: 'none'}}
                                        onClick={async () => {
                                            console.log("You downloaded this petition!");
                                            await logDownloadPetition(id, petition); //now with id
                                            navigate("/user/" + userContext.uid);
                                        }}
                                    >
                                        Download
                                    </a>
                                </Button>
                                :
                                <Button disabled>Loading...</Button>
                            }
                        </Card.Footer>
                    </Card>
                );
            })}
            </div>
        </Container>
    );
}

export default PetitionDownloads;