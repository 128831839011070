/* Copyright (C) 2023 Christian Miley - All Rights Reserved */

/**
 * CopyLink.js
 * This component will create a button that, when pressed,
 * will copy a link to the clipboard and also display a message
 * to let the user know it did so.
 */

import React from 'react';
import { Button } from 'react-bootstrap';
import { ArrowUpOnSquareIcon } from '@heroicons/react/24/outline';

function CopyLink(props) {
    let link = "www.rballot.org";
    if (props.link) link = props.link;
    let text = "";
    if (props.text) text = props.text;
    let className = "";
    if (props.className) className = props.className;
    const size = props.size;
    const round = props.round;
    let radius = '0px';
    if (round) radius = '90px';

    //const [copiedText, setCopiedText] = useState("");
    
    //custom padding?
    let padding;
    if (round) padding = String(size/10) + "px " + String(size/8) + "px " + String(size/6) + "px ";
    if (!round) padding = String(size/28) + "px " + String(size/8) + "px " + String(size/7) + "px ";

    //toast stuff
    //const [showToast, setShowToast] = useState(false);
    //const showToast = props.showToast;
    const setShowToast = props.setShowToast;

    return (
        <div>
            {/*<div style={{display: 'flex', flexDirection: 'column', textAlign: 'center'}}>*/}
                <Button
                    style={{backgroundColor: 'transparent', borderColor: 'transparent', margin: '0', padding: '0'}}
                    className={className}
                    onClick={() => {
                        navigator.clipboard.writeText(link);
                        console.log("Copied link!");
                        console.log(link);
                        //setCopiedText("Copied link!");
                        setShowToast(true);
                    }}
                >
                    <ArrowUpOnSquareIcon 
                        style={{
                            //height: 2*size/3, 
                            //width: 2*size/3, 
                            height: size,
                            width: size,
                            padding: padding, 
                            borderRadius: radius, 
                            backgroundColor: 'rgb(218, 81, 71)', 
                            borderColor: 'rgb(218, 81, 71)'
                        }}/>
                    <span style={{marginLeft: '0.25em', color: 'black'}}>{text}</span>
                </Button>
                {/*copiedText*/}
            {/*</div>*/}
        </div>
    );
}

export default CopyLink;