/* Copyright (C) 2023 Christian Miley - All Rights Reserved */

/** ActionHandler.js
 * This page will be firebase-specific, and it will contain the functions
 * necessary to handle user actions like email verification,
 * resetting passwords
 */

import React, { useContext, useEffect, useState} from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Container } from 'react-bootstrap';
//import { auth, resubmitRequestsUponVerification, logEmailVerified } from '../../firebase.js';
import { verifyEmail } from '../../firebase.js';
import { UserContext } from '../../Context.js';
import SignIn from '../SignIn.js';
import '../Entry.css';

function ActionHandler()
{
   const userContext = useContext(UserContext);
   const [verified, setVerified] = useState(false);
   let [searchParams, setSearchParams] = useSearchParams(); //searchParams.get('title')
   const [searchParamsObj, setSearchParamsObj] = useState({
      oobCode: searchParams.get('oobCode'),
      mode: searchParams.get('mode')
   });

   useEffect(() => {
      //if everything is the same, return
      if (searchParams.get('oobCode') === searchParamsObj.oobCode && searchParams.get('mode') === searchParamsObj.mode) return;
      console.log("Updating search params obj");
      setSearchParamsObj({
         oobCode: searchParams.get('oobCode'),
         mode: searchParams.get('mode')
      });
   }, [searchParams, searchParamsObj]);
   
   useEffect(() => {
      if (!searchParamsObj || !searchParamsObj.oobCode || !userContext || userContext === "Not loaded") return;
      console.log(searchParamsObj.mode);
      async function verification(){
         try {
            await verifyEmail(searchParamsObj.oobCode);
            setVerified(true);
         } catch (error) {
            console.error(error);
            return;
         }
      }
      if (searchParamsObj.mode === 'verifyEmail') verification();
   }, [searchParamsObj, userContext]);
   
   
   
   /* Below is an idea to get it to update the screen once the user arrives;
   currently, once the page loads, the user is verified, but they are not told that.
   So, not great UX.
   const [text, setText] = useState(<div><p>Email not yet verified.</p></div>);

   useEffect(() => {
      if (userContext) {
         if (userContext.emailVerified){
            setText(<div>
               <p>Success! You've verified your email.</p>
               <Link to={searchParams.get('continueUrl')}>Click here to continue</Link>
            </div>)
         } else {
            setText(<div><p>Email not yet verified</p></div>);
         }
      }
   }, [userContext])*/

   //const [appliedCode, setAppliedCode] = useState(false);

   /*useEffect(() => {
      if (appliedCode || verified || !searchParams || !userContext || userContext === "Not loaded") return;
      console.log("Made it through check and going to apply code...");

      const verifyEmail = async () => {
         console.log("Our API key is " + searchParams.get('apiKey'));
         //technically, we haven't yet, but this should help keep down the error count
         setAppliedCode(true);
         try {
            //below will throw an error if the action code has already been applied
            await auth.checkActionCode(searchParams.get('oobCode'));
            //here, actually apply the code
            await auth.applyActionCode(searchParams.get('oobCode'));
            //Email address has been verified
            console.log("Email verified!");
            await resubmitRequestsUponVerification('email-not-verified');
            // this will ensure that if the verification takes place in another browser,
            // it will still propagate to the primary browser
            await logEmailVerified();
            setVerified(true);
         } catch (error) {
            console.error(error);
            return;
         }
      }

      if (searchParams.get('mode') === "verifyEmail"){
         verifyEmail();
      }
      
   }, [searchParams, userContext, appliedCode, verified]);*/

   /*useEffect(() => {
      //user not loaded or not verified
      if (!userContext || !userContext.emailVerified) return;
      //user is verified, so shouldn't need to do anything
      if (userContext.emailVerified && verified) return;
      //in below case, the verification has gone through fully
      const submitWaitingRequests = async () => {
         await resubmitRequestsUponVerification('email-not-verified');
         setVerified(true);
      } 
      if (userContext.emailVerified && !verified) {
         submitWaitingRequests();
      }



   }, [userContext, userContext.emailVerified, verified, setVerified])*/

   


   if (userContext) {
      return (
         <Container style={{maxWidth: '600px'}}>
            <div style={{borderRadius: '10px', backgroundColor: 'white', color: 'black', marginTop: '5em', padding: '2em', maxWidth: '600px', textAlign: 'center'}}>
               <div className="Background2"></div>
               {verified || userContext.emailVerified ? 
                  <div>
                     <p>Success! Your email is verified.</p>
                     {/* Why not a Link ? */}
                     <a className="ActionHandlerLink" href={searchParams.get('continueUrl')}>Click here to continue</a>
                  </div>
                  :
                  <div>
                     <p>Email not yet verified</p>
                  </div>
                  
               }
            </div>
         </Container>
      )
   // user is not signed in, which happens when the email link opens in a different client.
   // ideally, I could send a login token along with the email... but I'm not sure about how.
   } else {
      return (
         <Container style={{maxWidth: '600px'}}>
            <div className="Background2"></div>                   
            <div style={{borderRadius: '10px', backgroundColor: 'white', color: 'black', marginTop: '5em', padding: '2em', maxWidth: '600px'}}>
               <div>
                  <h5 className="Header" style={{color:'black'}}>rBallot</h5>
               </div>

               <div>
                  <h2 className="Sub-header" style={{color:'black'}}>Make Real Change</h2>
               </div>
               <div style={{textAlign: 'center', color: 'black'}}>
                  <p>
                     Please log in to verify your email.
                  </p>
                  <SignIn redirect={false}/>
               </div>
            </div>
         </Container>
      )
   }

}

export default ActionHandler;
