/* Copyright (C) 2023 Christian Miley - All Rights Reserved */

import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import './Home.css';
import '../../Variables.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from 'react-bootstrap';
import { UserContext } from '../../Context.js';
import Loading from '../Loading.js';
import SignIn from '../SignIn.js';

function Home(){

  const userContext = useContext(UserContext);
  //for loading
  const [finished, setFinished] = useState("Not started");

  return (
    <Container style={{display:'flex', justifyContent: 'center'}}>
      {/*Header*/}

  {/*    <div className="HomeBackground"></div>  */}
  {  <div className="Background2"></div>  }                    

    <div style={{borderRadius: '10px', backgroundColor: 'white', color: 'black', marginTop: '5em', padding: '2em', maxWidth: '600px'}}>
      <div>
        <h5 className="Header" style={{color:'black'}}>rBallot</h5>
      </div>

      <div>
        <h2 className="Sub-header" style={{color:'black'}}>Make Real Change</h2>
      </div>

      <div>
        <br />
        {userContext === "Not loaded" ||  !finished ?
          <Loading minimumIterations={1} setFinished={setFinished}/>
        : userContext ?
          <div className="WelcomeBack" style={{color:'black'}}>
            <p>{"Welcome back, " + userContext.displayName }</p>
            <br />
            <Link 
              className="Option"
              style={{color:'black'}}
              to="/"
            >
              Continue to site
            </Link>
          </div>
        :
          <div>
            <div className="Sub-header-signIn">
              <hr/>
              <SignIn textColor='black' redirect={"/"} />
            </div>

            <div className="Sub-header-signUp">
              <div>
                <p className="SignUpText">New to rBallot?</p>
              </div>
              <Link
                to="/emailsignup"
                className="SignUpToday"
              >
                  Sign Up Today
              </Link>
            </div>

          </div>
        }

        {/*{ userContext ? "" :
          <div style={{textAlign: 'center', marginTop: '2em'}}>
            <Link className="Option" to="/">Explore Measures</Link>
          </div>
        }

        <div style={{textAlign: 'center', marginTop: '2em', marginBottom: '3em'}}>
          <Link className="Option" to="/About">Learn more</Link>
        </div>

        {/*<div>
          <p style={{color: 'white', fontSize: '0.9em', padding: '0.75em', textAlign: 'justify'}}>rBallot facilitates grassroots ballot measure petition drives. rBallot allows people to find initiatives they support and download packets for gathering signatures in their communities, and for ballot measure campaigns to manage and receive those packets through the mail.</p>
        </div>*/}

      </div>
    </div>
    </Container>
  );

}

export default Home;