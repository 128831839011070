/* Copyright (C) 2023 Christian Miley - All Rights Reserved */

/**
 * Follow.js
 * This component will take in some information and functions and render
 * the appropriate follow button that will either follow/unfollow a measure
 * or redirect a user to sign in or to verify their email
 */

import React from 'react';
import { followMeasure, unfollowMeasure } from '../firebase.js';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

//props will include: userContext, id, measure, setMeasure, buttonClass, following
//changed, setChanged <== these must be associated with state variables...
function Follow(props) {
    //changing the guest redirect from a link to a button
    //to ensure consistent styling
    const navigate = useNavigate();
    
    //nothing to follow
    if (!props || !props.id || !props.measure) return "";

    //Should not show anything until we have a value.
    if (props.following === undefined) return "";

    //console.log("Changing: " + props.changed);
    //this will tell us what the button on screen does
    //if (!props.changed) console.log((props.following ? "Unf" : "F") + "ollow: " + props.user.uid + " -> " + props.id);

    //here we will return the appropriately sized loading animation
    //if (props.changed) {

    //}

    const measure = props.measure;

    //there is a user logged in
    //note: this not loaded stuff should definitely be handled by userContext,
    //which should be designed in an OOP way, as a class with a getUser() function
    //note: button loading before info loaded... may be receive userContext before info
    //about following
    if (props.user && props.user !== "Not loaded") { 
        if (props.user.emailVerified) {
            if (props.following) {
                return (
                    <Button
                        className={props.buttonClass}
                        onClick={async() => {
                            console.log("Unfollowing measure now.");
                            if (props.setChanged) props.setChanged(true);
                            let followers;
                            if (measure.Followers) {
                                followers = measure.Followers-1;
                            //this shouldn't happen with unfollow below
                            } else {
                                followers = 0;
                            }
                            props.setMeasure(followers);
                            await unfollowMeasure(props.id);
                        }}
                    >
                        {props.changed ? "Loading..." : "Unfollow"}
                    </Button>
                )
            } else {
                return (
                    <Button
                        className={props.buttonClass}
                        onClick={async () => {
                            console.log("Following measure now.");
                            if (props.setChanged) props.setChanged(true);
                            let followers;
                            if (measure.Followers) {
                                followers = measure.Followers+1;
                            //this shouldn't happen with unfollow below
                            } else {
                                followers = 1;
                            }
                            props.setMeasure(followers);
                            await followMeasure(props.id);
                        }}
                    >
                        {props.changed ? "Loading..." : "Follow"}
                    </Button>
                )
            }
        //user's email is not verified
        //what should happen in this case? Send to user.js?
        } else {

        }
    //Guest below
    } else {
        return (
            /*<Link to="/emailsignup" className={props.buttonClass}>Follow</Link>*/
            <Button className={props.buttonClass} onClick={() => navigate('/emailsignup')}>Follow</Button>
        )
    }
}

export default Follow;