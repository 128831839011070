/* Copyright (C) 2024 Christian Miley - All Rights Reserved */

/**
 * LikesDislikes.js
 * This will export a pair of like/dislike buttons and a bar showing the ratio between
 */

import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Modal, Button } from 'react-bootstrap';
import { UserContext, MeasuresContext } from '../Context.js';
import { vote } from '../firebase.js';
import { AddTooltip } from './Utility.js';
import { 
    HandThumbUpIcon, 
    HandThumbDownIcon, 
} from '@heroicons/react/24/outline';
import { 
    HandThumbUpIcon as SolidHandThumbUpIcon, 
    HandThumbDownIcon as SolidHandThumbDownIcon, 
} from '@heroicons/react/24/solid';
import { ThumbsUp, ThumbsDown } from '@phosphor-icons/react';

// separating out the modal component so it's not nested
function LikeModal(props) {
    const [showModal, setShowModal] = useState(false);
    //descriptors for liking and disliking
    const likeDescriptors = ['Straightforward', 'Good value', 'Grassroots'];
    const dislikeDescriptors = ['Complicated', 'Expensive', 'Special interest'];
    const likeValue = props.likeValue || 'none';
    return (
        <Modal
            { ...props }
            show={showModal}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Like/dislike</Modal.Title>Like/dislike
            </Modal.Header>
            <Modal.Body>
            { /* This will be the area to choose descriptors, leave a comment. Will display differently whether focused or not, probably. */
                likeValue === 'none' ? "" : 
                <div /*style={{padding: '0.5em', border: '1px solid lightgray', width: '100%'}}*/>
                    {likeValue !== "liked" ? "" :
                        likeDescriptors.map((descriptor) => {
                            return (
                                <button key={descriptor} style={{borderRadius: '10px', border: '1px solid green', padding: '0.25em'}}>
                                    {descriptor}
                                </button>
                            )
                        })
                    }
                    {likeValue !== "disliked" ? "" :
                        dislikeDescriptors.map((descriptor) => {
                            return (
                                <button key={descriptor} style={{borderRadius: '10px', border: '1px solid red', padding: '0.25em'}}>
                                    {descriptor}
                                </button>
                            )
                        })
                    }
                </div>
            }    
                <Form>
                    <Form.Label>Why did you like/dislike this measure?</Form.Label>
                    <Form.Control 
                        as='textarea'
                    />
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <button>Submit</button>
            </Modal.Footer>
        </Modal>
    );
}

//props has access to propositionsObj, setPropositionsObj, likeValue, measure, setLikeValue
export default function LikesDislikes(props) {
    const userContext = useContext(UserContext);
    const [measuresContext, setMeasuresContext] = useContext(MeasuresContext);
    const navigate = useNavigate();
    const measure = props.measure || {};
    const id = measure.id;

    // figure out the likeValue... should this be in useEffect?
    const [likeValue, setLikeValue] = useState(props.likeValue || undefined);
    useEffect(() => {
        if (!props || !measure || !userContext) return;
        //figure out status of this user's reaction
        if ('Likes' in measure && userContext.uid in measure.Likes) {
            setLikeValue('liked');
        } else if ('Dislikes' in measure && userContext.uid in measure.Dislikes) {
            setLikeValue('disliked');
        } else {
            setLikeValue('none');
        }

    }, [props, props.measure, userContext]);

    //const likeValue = props.likeValue || 'none';
    //const setLikeValue = props.setLikeValue;
    
    //this function will handle liking the measure itself
    //it will work for both like and unlike buttons, and will figure out whether to 
    const likeMeasure = async (action, newLikeValue, likeObject) => {
        //like(status, action, document, field)
        try {
            await vote(likeValue, action, id, "", likeObject);
            setLikeValue(newLikeValue);
            // here, correctly update the likes/dislikes in the propositionsObj so that
            //it correctly updates the like dislike bar (mostly relevant with few likes)
            //this works by just specifying whether the user should be added or taken away
            //from each 
            let likes = measure.Likes || {};
            let dislikes = measure.Dislikes || {};
            if (newLikeValue === 'liked') {
                likes[userContext.uid] = { ...likeObject, name: userContext.displayName };
                delete dislikes[userContext.uid];
            } else if (newLikeValue === 'disliked') {
                dislikes[userContext.uid] = { ...likeObject, name: userContext.displayName };
                delete likes[userContext.uid];
            } else if (newLikeValue === 'none') {
                delete likes[userContext.uid];
                delete dislikes[userContext.uid];
            }
            // I think this should modify just the Likes and Dislikes for this measure
            const newPropsObj = { ...measuresContext };
            newPropsObj[id] = {
                ...measure,
                Dislikes: dislikes,
                Likes: likes
            };
            //the measure's id is also the id of its element in the feed
            //props.setRefocusElementId(measure.id);
            //props.setPropositionsObj(newPropsObj);
            setMeasuresContext(newPropsObj);
            //does this even have access to window?
            //window.focus(document.getElementById(id));
        } catch (error) {
            throw new Error (error);
        }
    }

    // here calculate the percentages of likes/dislikes
    let likesCount = 0;
    let dislikesCount = 0;
    if ('Likes' in measure) likesCount = Object.keys(measure.Likes).length;
    if ('Dislikes' in measure) dislikesCount = Object.keys(measure.Dislikes).length;
    const reactionsCount = likesCount + dislikesCount;
    const likesPercentage = String(likesCount / reactionsCount * 100) + '%';//) + 'em'; //
    const dislikesPercentage = String(dislikesCount / reactionsCount * 100) + '%';//)  + 'em'; //

    // try out a modal for descriptor + comment on like/dislike
    const [showModal, setShowModal] = useState(false);
    const [likeOrDislike, setLikeOrDislike] = useState(undefined);
    const LikeModal = (props) => {
        //descriptors for liking and disliking
        const descriptors = {
            'like': ['Clear', 'Important', 'Efficient', 'Transparent', 'Ethical', 'Equitable', 'Good value', 'Grassroots', 'Liberating', 'Adaptable'],
            'dislike': ['Complicated', 'Expensive', 'Special interest', 'Unnecessary', 'Regressive', 'Unethical', 'Ineffective', 'Corrupt', 'Oppressive', 'Poorly written']
        }
        // which ones are currently selected?
        const [selectedDescriptors, setSelectedDescriptors] = useState([]);
        const [comment, setComment] = useState("");

        return (
            <Modal
                { ...props }
                show={showModal}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                            {
                                /*(likeOrDislike ? likeOrDislike[0].toUpperCase() + likeOrDislike.slice(1) : "")
                                + " " +*/
                                (measure.BallotName || measure.rBallotTitle || measure.BallotpediaTitle || "")
                            }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                { /* This will be the area to choose descriptors, leave a comment. Will display differently whether focused or not, probably. */
                    !likeOrDislike ? "" : 
                    <div /*style={{padding: '0.5em', border: '1px solid lightgray', width: '100%'}}*/>
                        <p>Pick up to 3</p>
                        { descriptors[likeOrDislike].map((descriptor) => {
                            return (
                                <button 
                                    key={descriptor} 
                                    style={{
                                        borderRadius: '10px', 
                                        border: '1px solid lightgray',//likeOrDislike === 'like' ? '1px solid green' : '1px solid red', 
                                        padding: '0.25em', 
                                        margin: '0em 0.5em 0.5em 0em',
                                        backgroundColor: selectedDescriptors.includes(descriptor) ? 
                                            likeOrDislike === 'like' ? 
                                                'lightgreen' 
                                                : 
                                                'pink'
                                            : 'white'
                                    }}
                                    onClick={() => {
                                        if (!selectedDescriptors.includes(descriptor) && selectedDescriptors.length<3) {
                                            setSelectedDescriptors([ ...selectedDescriptors, descriptor ]);
                                        } else {
                                            setSelectedDescriptors([ ...selectedDescriptors.filter((string) => string !== descriptor) ]);
                                        }
                                    }}
                                >
                                    {descriptor}
                                </button>
                            )
                        })}
                    </div>
                }    
                    <Form style={{margin: '1em 0em 0em'}}>
                        <Form.Label>Leave a comment</Form.Label>
                        <Form.Control 
                            as='textarea'
                            placeholder={"Why do you " + (likeOrDislike === "like" ? "support" : "oppose") + " this measure?"}
                            value={comment}
                            onChange={(e) => {
                                //500 character limit
                                if (e.target.value.length < 301) {
                                    setComment(e.target.value);
                                }
                            }}
                        />
                        <Form.Text style={{float:'right'}}>{comment.length}/300</Form.Text>
                    </Form>
                </Modal.Body>
                <Modal.Footer style={{display:'flex',justifyContent:'center'}}>
                    <Button 
                        style={{
                            padding: '0.25em 2.5em',
                            backgroundColor: likeOrDislike === 'like' ? 'forestgreen' : 'darkred',
                            border: '0px'
                        }}
                        onClick={async () => {
                            //needs to take in "like" and "dislike" for some reason
                            await likeMeasure(likeOrDislike, likeOrDislike+'d', { descriptors: selectedDescriptors, comment: comment });
                            setShowModal(false);
                        }}
                    >
                        {likeOrDislike ? likeOrDislike[0].toUpperCase() + likeOrDislike.slice(1) : ""}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    // find the top two descriptors to display
    const descriptorCounts = {}
    // first, handle the likes
    if ('Likes' in measure) {
        for (const like of Object.values(measure.Likes)) {
            for (const descriptor of like.descriptors) {
                // for each descriptor in each like, increase its count by 1,
                // accounting for whether it is already present or not
                if (!(descriptor in descriptorCounts)) {
                    descriptorCounts[descriptor] = {
                        count: 1,
                        type: 'like'
                    }
                } else {
                    descriptorCounts[descriptor].count += 1;
                }
            }
        }
    }
    // second, handle the dislikes
    if ('Dislikes' in measure) {
        for (const dislike of Object.values(measure.Dislikes)) {
            for (const descriptor of dislike.descriptors) {
                // for each descriptor in each like, increase its count by 1,
                // accounting for whether it is already present or not
                if (!(descriptor in descriptorCounts)) {
                    descriptorCounts[descriptor] = {
                        count: 1,
                        type: 'dislike'
                    }
                } else {
                    descriptorCounts[descriptor].count += 1;
                }
            }
        }
    }

    //3/7/24: Thanks ChatGPT for below
    // Function to find the top keys with the highest counts
    // data represents the descriptors Object, topCount is how many we want returned
    function orderDescriptorsByCount(data/*, topCount*/) {
        const topDescriptors = [];

        // Iterate over the keys of the main object
        for (const descriptor in data) {
            if (data.hasOwnProperty(descriptor)) {
                const count = data[descriptor].count;

                // Find the index to insert the current key
                let insertIndex = 0;
                while (insertIndex < topDescriptors.length && count <= data[topDescriptors[insertIndex]].count) {
                    insertIndex++;
                }

                // Insert the current key at the determined index
                topDescriptors.splice(insertIndex, 0, descriptor);

                // Ensure the top keys array doesn't exceed the specified count
                //if (topDescriptors.length > topCount) {
                //   topDescriptors.pop(); // Remove the last key if count exceeds topCount
                //}
            }
        }

        return topDescriptors;
    }

    const topDescriptors = orderDescriptorsByCount(descriptorCounts).map((descriptor) => { 
        return { 
            ...descriptorCounts[descriptor],
            descriptor: descriptor
        } 
    });

    return(
        <div style={{display:'flex', flexDirection: 'column', alignItems: 'center'}}>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'baseline', width: '100%'}}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    {likeValue !== 'liked' ?
                        <AddTooltip title="Like this measure" id={"Like"+id}>
                            <button
                                style={{padding: '0.4em', background: 'white', color: 'green', borderRadius: '10px', border: '0px', margin: '0.25em', textDecoration: 'none'}}
                                onClick={async () => { 
                                    // I forget all the possibilities, but basically just redirect to sign in
                                    // if they try to like a measure
                                    if (!userContext || userContext === "Guest" || userContext === "Not loaded") {
                                        navigate('/signin');
                                        return;
                                    }
                                    //await likeMeasure('like', 'liked');
                                    setLikeOrDislike("like");
                                    setShowModal(true);
                                }}
                            >
                                <ThumbsUp size={24} />
                            </button>
                        </AddTooltip>
                        :
                        <AddTooltip title="Remove like" id={"Like"+id}>
                            <button
                            style={{padding: '0.4em', background: 'white', color: 'green', borderRadius: '10px', border: '0px', margin: '0.25em', textDecoration: 'none'}}
                            onClick={async () => { await likeMeasure('removeLike', 'none'); }}
                            >
                                <ThumbsUp size={24} weight="fill"/>
                            </button>
                        </AddTooltip>
                    }
                    <span>{likesCount}</span>
                </div>
                {/* here is the bar that shows relative amounts of dislike vs likes */}
                <AddTooltip 
                    title={likesCount + (likesCount === 1 ? " person has" : " people have") + " liked this measure. " + dislikesCount + (dislikesCount === 1 ? " person has" : " people have") + " disliked this measure."}
                    id={"ReactionsBar" + id}
                >
                    {/* Bar should disappear when no likes or dislikes */}
                    <div style={{display: likesCount || dislikesCount ? 'flex' : 'none', justifyContent: 'center', alignItems: 'center', margin: '0em 0.5em', width: '100%', maxWidth: '16em'}}>
                        <div style={{width: likesPercentage, height: '0.5em', background: '#88B04B'}} />
                        <div style={{width: dislikesPercentage, height: '0.5em', background: '#FF6347'}} />
                    </div>
                </AddTooltip>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                {likeValue !== 'disliked' ?
                    <AddTooltip title="Dislike this measure" id={"Dislike"+id}>
                        <button
                            style={{padding: '0.4em', background: 'white', color: 'red', borderRadius: '10px', border: '0px', margin: '0.25em', textDecoration: 'none'}}
                            onClick={async () => { 
                                // I forget all the possibilities, but basically just redirect to sign in
                                // if they try to like a measure
                                if (!userContext || userContext === "Guest" || userContext === "Not loaded") {
                                    navigate('/signin');
                                    return;
                                }
                                //await likeMeasure('dislike', 'disliked'); 
                                setLikeOrDislike("dislike");
                                setShowModal(true);
                            }}
                        >
                            <ThumbsDown size={24}/>
                        </button>
                    </AddTooltip>
                    :
                    <AddTooltip title="Remove dislike" id={"Dislike"+id}>
                        <button
                        style={{padding: '0.4em', background: 'white', color: 'red', borderRadius: '10px', border: '0px', margin: '0.25em', textDecoration: 'none'}}
                        onClick={async () => { await likeMeasure('removeDislike', 'none'); }}
                        >
                            <ThumbsDown size={24} weight="fill" />
                        </button>
                    </AddTooltip>
                }
                    <span>{dislikesCount}</span>
                </div>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-evenly', width: '100%', flexWrap: 'wrap'}}>
                { // make a way to only display the first 'x' amount, which should change based on platform
                topDescriptors.slice(0, 6).map((data) => {
                    return(
                        <span key={data.descriptor} style={{position: 'relative', margin: '1em 0.5em'}}>
                            <span 
                                style={{
                                    backgroundColor: data.type === 'like' ? 'lightgreen' : 'pink',
                                    padding: '0.35em',
                                    //border: '1px solid gray',
                                    borderRadius: '10px',
                                    boxShadow: '3px 3px 5px lightgray',
                                    margin: '0em'
                                }}
                            >
                                {data.type === 'like' ? <strong>+ </strong> : <strong>- </strong>}{data.descriptor}
                            </span>
                            {/*<AddTooltip title={data.count + (data.count === 1 ? " person" : " people") + " classified this measure as: " + data.descriptor}>
                                <span style={{
                                    position: 'absolute',
                                    bottom: '-1em',
                                    right: '-0.6em',
                                    backgroundColor: 'white',
                                    border: '1px solid lightgray',
                                    borderRadius: '10px',
                                    minWidth: '2em',
                                    height: '2em',
                                    padding: '0.25em',
                                    textAlign: 'center',
                                    fontSize: '0.6em',
                                    fontWeight: 'bold',
                                }}>
                                    {data.count}
                                </span>
                            </AddTooltip>*/}
                        </span>
                    );
                })}
            </div>
            <LikeModal show={showModal} onHide={() => setShowModal(false)}/>
        </div>
    );
}