/* Copyright (C) 2024 Christian Miley - All Rights Reserved */

/**
 * MobileButtons.js
 * These buttons will appear on mobile versions of the site
 */

import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './UI.css';
import { Button, Container} from 'react-bootstrap';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline';

function MobileButtons() {
    const location = useLocation();
    const navigate = useNavigate();
    //return if we're on a path that shouldn't display the Buttons
    let pathsWithoutButtons = ['/signin', '/verify'];
    //slicing because /signup or other paths can have a parameter afterwards
    if (pathsWithoutButtons.indexOf(location.pathname.slice(0, 7).toLowerCase()) !== -1) return null;

    return (
    <div className="MobileBackButton">
        <Container>
            <Button 
                onClick={() => navigate(-1)}
                style={{background: 'transparent', borderWidth: '0.5px', borderColor: 'white', float: 'left', margin: '0'}}
            >
                <ArrowLeftIcon style={{height: '24px', width: '24px'}} />
            </Button>
            <br />
            {/*<Button
                onClick={() => navigate(1)}
                style={{background: 'transparent', borderColor: 'white', float: 'right', margin: '0'}}
            >
                <ArrowRightIcon style={{height: '24px', width: '24px'}}/>
            </Button>*/}
        </Container>
    </div>
    );
}

export default MobileButtons;