/* Copyright (C) 2023 Christian Miley - All Rights Reserved */

/**
 * Loading.js
 * This component will return a (or at some multiple perhaps)
 * Loading icons. It is possible that Loading.css will just be bundled up with
 * some sort of general utilities document I'll include everywhere, like Variables.css
 * props: minimumIterations, setFinished
 */

import React, { useEffect } from 'react';
import './Loading.css';

function Loading(props) {

    const loadingElement = document.getElementById("LoadingAnimation");

    useEffect(() => {
        if (loadingElement) {
            loadingElement.addEventListener("animationiteration", listener, false );
            loadingElement.className = "LoadingCircle";
            //below is to indicate we've started; should be set at "Not started" by default
            if (props.minimumIterations) props.setFinished(false); 
        } 
        
        let iterations = 0;

        //this listener keeps track of every time the animation ends
        //note: does this need to use state? I imagine so because it gets called fresh
        function listener(event) {
            if (event.type === "animationiteration") {
                iterations += 1;
                //report that we've reached our minimum number of iterations once it happens
                if (props.minimumIterations <= iterations){
                    props.setFinished(true);
                } 
            }
        }

        //remove event listener
        return () => { if (loadingElement) loadingElement.removeEventListener("animationiteration", listener, false); }
    }, [loadingElement, props]);

    return(
        <div id="LoadingAnimation" ></div>
    )

} 

export default Loading;