/* Copyright (C) 2024 Christian Miley - All Rights Reserved */

/**
 * ReactionsComments
 * This component will be responsible for rendering the reactions and comments
 * section for a post. First step is to allow people to react to and comment on
 * likes/dislikes of measures. Afterwards, reactions and comments to those comments
 * will be supported, probably by this component but potentially another.
 */

import React, { useState, useEffect, useContext, useRef } from 'react';
import { Link } from 'react-router-dom';
import { reactToVote, commentOnVote, deleteCommentOnVote, now, /*getUser*/ } from '../firebase.js';
import { /*OverlayTrigger, Popover,*/ Dropdown } from 'react-bootstrap';
import { UserContext } from '../Context.js';
import { AddTooltip, datetimeFromTimestamp } from './Utility.js';
import { ThumbsUp, ChatCircle, Heart, Lightbulb, Question, PaperPlaneRight, Flag, DotsThreeOutline } from '@phosphor-icons/react';

// props will include the 
export default function ReactionsComments(props) {
    const id = props.measureId;
    const authorId = props.authorId;
    const vote = props.vote;
    const setVotes = props.setVotes;
    const userContext = useContext(UserContext);
    const [reaction, setReaction] = useState(props.userReaction || "");
    const [commentText, setCommentText] = useState("");
    // this will be used to update the lines on the textarea when
    // the user has filled them up
    const textareaRef = useRef(null);


    // this function will handle updating the reaction in the database
    const submitReaction = async (newReaction) => {
        const prevReaction = reaction || "";
        // if reactions are the same, we are removing the reaction
        if (prevReaction === newReaction) {
            setReaction("");
        // otherwise, we are setting the reaction
        } else {
            setReaction(newReaction);
        }
        if (!id || !authorId) return;
        try {
            // do before actually reacting to avoid lagging?
            // also... would this mess up the function call by causing a component reload?
            //setReaction(newReaction);
            await reactToVote(reaction, newReaction, id, authorId);
            const newVote = vote;
            // add an empty array if it doesn't already exist
            if (!(newReaction+'s' in newVote)) newVote[newReaction+'s'] = [];
            // add the user's id if it is not already present
            if (!newVote[newReaction+'s'].includes(userContext.uid)) newVote[newReaction+'s'].push(userContext.uid);
            // remove the user's id from previous if it is present
            if (prevReaction+'s' in newVote && newVote[prevReaction+'s'].includes(userContext.uid)) newVote[prevReaction+'s'].splice(newVote[prevReaction+'s'].indexOf(userContext.uid), 1);
            console.log(newVote);
            // set the votes above to match
            setVotes(newVote);
        } catch (error) {
            console.error(error);
            // restore the reaction to show that submitting failed
            setReaction(prevReaction);
        }
    }

    // display the reactions that are present on the document
    const [reactionsCount, setReactionsCount] = useState(0);
    const [reactionsByLength, setReactionsByLength] = useState([]);
    // and also the comments for each vote!
    const [comments, setComments] = useState([]);
    const [showComments, setShowComments] = useState(false);

    useEffect(() => {
        if (!vote) return;
        let count = 0;
        
        const reactions = {};
        const reactionTypes = ['Likes', 'Loves', 'Insights', 'Questions'];
        for (const reactionType of reactionTypes){
            if (reactionType in vote && vote[reactionType].length>0) {
                count += vote[reactionType].length;
                reactions[reactionType] = vote[reactionType].length;
            }
        }
        setReactionsCount(count);

        // now sort the object containing the length of each reactionType
        // chatGPT below
        function sortKeysByNumberValue(obj) {
            // Get an array of key-value pairs
            const entries = Object.entries(obj);
        
            // Sort the array based on the values (the numerical part of each pair)
            // this will be in descending order
            entries.sort((a, b) => b[1] - a[1]);
        
            // Extract keys from the sorted array
            const sortedKeys = entries.map(([key]) => key);
        
            return sortedKeys;
        }
        const newReactionsByLength = sortKeysByNumberValue(reactions);
        setReactionsByLength(newReactionsByLength);

        //Comments!
        // first go in and add the userId as a field in all comments
        const commentsObject = Object.fromEntries(Object.entries(vote.Replies || {}).map(([authorId, replies]) => {
            const newReplies = replies.map((reply) => {
                reply.authorId = authorId;
                return reply;
            })
            return [authorId, newReplies];
        }));
        // thanks chatgpt for below:
        // Combine all arrays into one array
        const combinedArray = Object.values(commentsObject || {}).reduce((acc, val) => acc.concat(val), []);
        // Sort the combined array based on timestamps in descending order
        combinedArray.sort((a, b) => b.timestamp.toMillis() - a.timestamp.toMillis());
        setComments(combinedArray);
    }, [vote]);


    return (
        <div>
        <div style={{display:'flex', justifyContent:'space-between'}}>
            {reactionsCount === 0 ? <span /> :
                <span style={{display:'flex', alignItems:'center', fontSize:'0.8em', margin:'0.5em 0em 0em 0em'}}>
                    {reactionsByLength.map((reactionType) => {
                        let icon;
                        if (reactionType === 'Likes') {
                            icon = <ThumbsUp key={reactionType} size={16} weight="fill" style={{color:'blue'}} />;
                        } else if (reactionType === 'Loves') {
                            icon = <Heart key={reactionType} size={16} weight="fill" style={{color:'red'}} />;
                        } else if (reactionType === 'Insights') {
                            icon = <Lightbulb key={reactionType} size={16} weight="fill" style={{color:'gold'}} />;
                        } else if (reactionType === 'Questions') {
                            icon = <Question key={reactionType} size={16} weight="fill" style={{color:'green'}} />;
                        }
                        return icon;
                    })}
                    {reactionsCount}
                </span>
            } { !comments || comments.length < 1 ? "" : 
                <button 
                    style={{
                        fontSize:'0.9em', 
                        color:'gray',
                        backgroundColor:'transparent',
                        border:'0px',
                    }}
                    onClick={() => {
                        setShowComments(!showComments);
                    }}
                >
                    {comments.length} comment{comments.length === 1 ? "" : "s"}
                </button>

            }
        </div>
        <hr style={{margin:'0.5em 0em 0em'}}/>
        <div style={{display: 'flex', justifyContent: 'space-evenly', margin: '0em 0em 0em'}}>
            {/*<OverlayTrigger
                trigger={['click', 'focus']}
                placement="top"
                rootClose={false}
                overlay={
                    <Popover id={'popover-'+id} style={{zIndex:'1'}}>
                        <Popover.Body style={{display:'flex'}}>
                            <AddTooltip title="Like">
                                <button 
                                    name="Like"
                                    onClick={(e) => setReaction(e.target.name)}
                                    style={{border:'0px', backgroundColor:'transparent', width: '100%'}}
                                >
                                    <ThumbsUp size={24}/>
                                </button>
                            </AddTooltip>
                            <AddTooltip title="Love">
                                <button 
                                    name="Love"
                                    onClick={(e) => { 
                                        if (reaction === "Love") {
                                            setReaction("");
                                        } else {
                                            setReaction("Love");
                                        }
                                    }}
                                    style={{border:'0px', backgroundColor:'transparent', width: '100%'}}
                                >
                                    <Heart 
                                        style={{color: reaction === 'Love' ? 'red' : 'black'}} 
                                        weight={reaction === 'Love' ? "fill" : "regular"} 
                                        size={24}
                                    />
                                </button>
                            </AddTooltip>
                            <AddTooltip title="Insight">
                                <button 
                                    name="Insight"
                                    onClick={(e) => setReaction(e.target.name)}
                                    style={{border:'0px', backgroundColor:'transparent', width: '100%'}}
                                >
                                    <Lightbulb size={24}/>
                                </button>
                            </AddTooltip>
                            <AddTooltip title="Question">
                                <button
                                    name="Question"
                                    onClick={(e) => setReaction(e.target.name)}
                                    style={{border:'0px', backgroundColor:'transparent', width: '100%'}}
                                >
                                    <Question size={24}/>
                                </button>
                            </AddTooltip>
                        </Popover.Body>
                    </Popover>
                }
            >
                <button  className="ModernButton ReactionsDropdown" style={{display: 'flex', alignItems: 'center'}}>
                    <ThumbsUp size={24}/>
                    <span className="IconText" text={" Like"} />
                </button>
            </OverlayTrigger>*/}
            {/*<AddTooltip title={"React to this post"} >*/}
                <Dropdown
                    drop="up-centered"
                    style={{flexGrow:'1', display: 'flex', justifyContent:'center'}}
                    autoClose="outside" //for reactions, so it doesn't close after clicking
                >
                    <Dropdown.Toggle  className="ModernButton ReactionsDropdown">
                        { //show the user's reaction
                        reaction === "Like" ?
                            <span style={{display: 'flex', alignItems: 'center'}}>
                                <ThumbsUp size={24} weight="fill" style={{color:'blue'}}/>
                                <span className="IconText" text={" Liked"} />
                            </span>
                        :
                        reaction === "Love" ?
                            <span style={{display: 'flex', alignItems: 'center'}}>
                                <Heart size={24}  weight="fill" style={{color:'red'}}/>
                                <span className="IconText" text={" Loved"} />
                            </span>
                        :
                        reaction === "Insight" ?
                            <span style={{display: 'flex', alignItems: 'center'}}>
                                <Lightbulb size={24}  weight="fill" style={{color:'gold'}}/>
                                <span className="IconText" text={" Insight"} />
                            </span>
                        :    
                        reaction === "Question" ?
                            <span style={{display: 'flex', alignItems: 'center'}}>
                                <Question size={24}  weight="fill" style={{color:'green'}}/>
                                <span className="IconText" text={" Questioning"} />
                            </span>
                        :
                        // default
                        <span style={{display: 'flex', alignItems: 'center'}}>
                            <ThumbsUp size={24} />
                            <span className="IconText" text={" Like"} />
                        </span>
                        }
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item>
                            <AddTooltip title="Like">
                                <button 
                                    onClick={async () => { 
                                        /*if (reaction === "Like") {
                                            setReaction("");
                                        } else {
                                            setReaction("Like");
                                        }*/
                                        await submitReaction('Like');
                                    }}
                                    style={{border:'0px', backgroundColor:'transparent', width: '100%'}}
                                >
                                    <ThumbsUp 
                                        size={24}
                                        weight={reaction === "Like" ? "fill" : "regular"}
                                        style={{color: reaction === 'Like' ? 'blue' : 'black'}}
                                    />
                                </button>
                            </AddTooltip>
                        </Dropdown.Item>
                        <Dropdown.Item>
                            <AddTooltip title="Love">
                                <button 
                                    style={{border:'0px', backgroundColor:'transparent', width: '100%'}}
                                    onClick={async () => { 
                                        /*if (reaction === "Love") {
                                            setReaction("");
                                        } else {
                                            setReaction("Love");
                                        }*/
                                        await submitReaction('Love');
                                    }}
                                >
                                    <Heart 
                                        size={24} 
                                        weight={reaction === "Love" ? "fill" : "regular"}
                                        style={{color: reaction === 'Love' ? 'red' : 'black'}} 
                                    />
                                </button>
                            </AddTooltip>
                        </Dropdown.Item>
                        <Dropdown.Item>
                            <AddTooltip title="Insight">
                                <button 
                                    style={{border:'0px', backgroundColor:'transparent', width: '100%'}}
                                    onClick={async () => { 
                                        /*if (reaction === "Inspiration") {
                                            //setReaction("");
                                        } else {
                                            //setReaction("Inspiration");
                                        }*/
                                        await submitReaction('Insight');
                                    }}
                                >
                                    <Lightbulb /*style={{height: '24px', width: '24px'}}*/
                                        size={24}
                                        weight={reaction === "Insight" ? "fill" : "regular"}
                                        style={{color: reaction === 'Insight' ? 'gold' : 'black'}}
                                    />
                                </button>
                            </AddTooltip>
                        </Dropdown.Item>
                        <Dropdown.Item>
                            <AddTooltip title="Question">
                                <button 
                                    style={{border:'0px', backgroundColor:'transparent', width: '100%'}}
                                    onClick={async () => { 
                                        /*if (reaction === "Question") {
                                            //setReaction("");
                                        } else {
                                            //setReaction("Question");
                                        }*/
                                        await submitReaction('Question');
                                    }}
                                >
                                    <Question 
                                        size={24}
                                        weight={reaction === "Question" ? "fill" : "regular"}
                                        style={{color: reaction === 'Question' ? 'green' : 'black'}}
                                    />
                                </button>
                            </AddTooltip>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            {/*</AddTooltip>*/}
            <AddTooltip title={"Leave a comment"} >
                <button
                    className="ModernButton"
                    style={{flexGrow:'1', display: 'flex', justifyContent:'center'}}
                    onClick={(e) => {
                        e.preventDefault();
                        setShowComments(!showComments);
                        //console.log(document.getElementById('textarea'+id));
                        textareaRef.current.focus();
                    }}
                >
                    <span style={{display: 'flex', alignItems: 'center'}}>
                        <ChatCircle size={24}/>
                        <span className="IconText" text={" Comment"} />
                    </span>
                </button>
            </AddTooltip>
        </div>
        {!showComments || !comments || comments.length === 0 ? "" :
        <div style={{maxHeight:'14em', overflowY:'auto', borderBottom: '1px solid #ccc', margin:'0em 0em 0.5em'}}>
            { comments.map((reply, index) => {
                return (
                    <span key={reply.authorId+reply.timestamp} style={{display: showComments?'flex':'none',flexDirection:'column'}}>
                        <div style={{backgroundColor:'rgba(211, 211, 211, 0.3)', borderRadius:'10px', padding:'0.25em', border:'0px'}}>
                            <div style={{display:'flex',justifyContent:'space-between'}}>
                                <Link 
                                    to={"/user/"+reply.authorId}
                                    style={{fontWeight:'bold',textDecoration:'none'}}
                                >
                                    {reply.displayName}
                                </Link>
                                { //someone else's comment, show a flag box 
                                !userContext || reply.authorId !== userContext.uid ?
                                    <button style={{backgroundColor:'transparent',border:'0px',padding:'0px'}}>
                                        <Flag size={20} style={{marginRight:'0.5em'}}/>
                                    </button>
                                : //your own comment, show an edit/delete
                                    //<button style={{backgroundColor:'transparent',border:'0px',padding:'0px'}}>
                                    <Dropdown
                                        drop="end"
                                    >
                                        <Dropdown.Toggle className="ModernButton ReactionsDropdown" style={{marginBottom:'0px',padding:'0.2em'}}>
                                            <AddTooltip title=/*"Edit or d*/"Delete this">
                                                <DotsThreeOutline size={20} weight="fill"/>
                                            </AddTooltip>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {/*<Dropdown.Item>
                                                <button 
                                                    onClick={() => console.log("You clicked me brother")}
                                                    style={{border:'0px', backgroundColor:'transparent', width: '100%'}}
                                                >
                                                    Edit
                                                </button>
                                            </Dropdown.Item>*/}
                                            <Dropdown.Item>
                                                <button 
                                                    onClick={async () => {
                                                        try {
                                                            await deleteCommentOnVote(id, authorId, { 
                                                                // have to redefine the comment rather than just
                                                                // pasting in 'reply' object as fields have been added to it
                                                                comment: reply.comment,
                                                                timestamp: reply.timestamp,
                                                                displayName: reply.displayName
                                                            });
                                                            const newComments = [...comments];
                                                            newComments.splice(index, 1);
                                                            setComments(newComments);
                                                        } catch (error) {
                                                            console.error(error);
                                                        }
                                                    }}
                                                    style={{border:'0px', backgroundColor:'transparent', width: '100%'}}
                                                >
                                                    Delete
                                                </button>
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    //</button>
                                }
                            </div>
                            <span>{reply.comment}</span>
                        </div>
                        <div stle={{display:'flex'}}>
                            <span style={{fontSize:'0.8em',color:'gray', marginRight:'0.75em'}}>{datetimeFromTimestamp(reply.timestamp)}</span>
                            <span style={{fontSize:'0.8em',color:'gray', marginRight:'0.75em', fontWeight:'bold'}}>Like</span>
                            <span style={{fontSize:'0.8em',color:'gray', marginRight:'0.75em', fontWeight:'bold'}}>Reply</span>
                        </div>
                    </span>
                )
            })
            }
        </div>
        }
        <form
            style={{
                display: showComments ? 'flex' : 'none',
                flexDirection:'column',
                borderRadius:'10px',
                border:'1px solid gray',
                padding:'0.35em',
            }}
            onSubmit={async (e) => {
                e.preventDefault();
                try {
                    setComments([...comments, { authorId: userContext.uid, comment: commentText, displayName: userContext.displayName, timestamp: now() }])
                    await commentOnVote(id, authorId, commentText);
                    setCommentText("");
                    // add comment to vote data
                } catch(error) {
                    console.error(error);
                }
            }}
        >
            <textarea 
                id={'textarea'+id}
                placeholder="Write a comment..." 
                value={commentText}
                rows={1}
                ref={textareaRef}
                onChange={(e) => {
                    const textarea = textareaRef.current;
                    setCommentText(e.target.value);
                    textarea.style.height = 'auto'; // reset height to auto
                    textarea.style.height = `${textarea.scrollHeight}px`; // set height to scroll height
                }}
                style={{width: '100%', border:'0px'}}
                className="CommentBox"
            />
            <AddTooltip title="Comment">
                <button 
                    type="submit"
                    style={{
                        backgroundColor:'transparent',
                        border:'0px',
                        alignSelf:'flex-end',
                        height: 'auto',
                        resize: 'none'
                    }}
                    className="SubmitComment"
                >
                    <PaperPlaneRight 
                        size={24} 
                        style={{color:commentText.length>0?'blue':'black'}}
                    />
                </button>
            </AddTooltip>
        </form>
        </div>
    )
}