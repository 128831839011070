/* Copyright (C) 2023 Christian Miley - All Rights Reserved */

/**
 * EditMeasure.js
 * This page will be used by proponents/managers
 * to modify the data present on the page.
 * There will be two main uses of it:
 * 1) To prepare a measure for circulation on rBallot on sign up
 * 2) To modify a measure's information after it is already signed up
 * For submission, there are two possibilities:
 * 1) That the p/m is able to directly write the measure's document,
 * except for the rBallotStatus field, or
 * 2) The p/m must submit a request to the requests section with the
 * relevant information present, which will then propagate
 */

//things to do, in no particular order:
//0) I think I should undo the putting everything into one state variable, as
//this means everything has to refresh at once
//1) Fix date year entry by having them only turn into timestamps upon submission
//2) Figure out cleaner way to display validation at accordion level
//3) Regex for addresses and things this works for
//4) Abstract some functionality to App.js

import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { UserContext, TermsContext } from '../../Context.js';
import { db, timestampFromDate, uploadFullText, uploadMeasureInfo, getMeasure, getFullTextFile } from '../../firebase.js';
import { Container, Form, Button, Accordion, Row, Col, ToggleButton, ToggleButtonGroup, InputGroup } from 'react-bootstrap';
import { allStates, californiaCodes, californiaInitiativeTypes } from '../Information.js';
import SignaturePDF from '../PDFCirculator.js';
/*just using .toDate() for now, but a reminder that I should abstract to here more
import { dateFromTimestamp } from '../Utility.js';*/
import './EditMeasure.css';
import UnverifiedEmail from '../UnverifiedEmail.js';
import Loading from '../Loading.js';
import { endText } from 'pdf-lib';

//props includes permissionsObj to see if user is awaiting proponent verification
function EditMeasure(props) {

    //user and measure
    const userContext = useContext(UserContext);
    const navigate = useNavigate();
    const terms = useContext(TermsContext);
    const { id } = useParams();

//-----------------Both effects below are duplicated,---------------------
//-----------------and should be abstracted to App.js---------------------

    //-------------Effect to grab the measure information from db-------------------
    const [measure, setMeasure] = useState(undefined);
    const [measureInDb, setMeasureInDb] = useState(undefined);

    //set up a listener on the petition that will update when anything changes
    //note: should be done with suspense in newer react
    //second note: should this be a listener? I guess this means it will update
    //on successful submission, but also if for instance someone follows
    //the measure while it is being edited... and updating on successful submission
    //is pretty redundant considering the information should be the same
    //redesign: make it on page load/id change, and have it also look for the pdf
    //in cloud storage. Will this work for adding to the file upload bin?
    useEffect(() => {    
        const setUpEditing = async () => {
            if (!terms) return;

            const measureDownload = await getMeasure(id);
            setMeasureInDb(measureDownload);

            let editingMeasure = { ...measureDownload };
            editingMeasure['id'] = id;
            editingMeasure['agreement'] = false;
            editingMeasure['agreementID'] = terms.id;
            const defaultMailing = {
                count: 1,
                0: {
                    address1: "",
                    address2: "",
                    city: "",
                    state: "",
                    zip: "",
                    counties: ["Remainder"]
                }
            }

            //if no signaturesPerSheet, set to default
            if (!measureDownload.SignaturesPerSheet) editingMeasure['SignaturesPerSheet'] = [3, 14, 25];
            //contact info
            if (!measureDownload.Website) editingMeasure['Website'] = "";
            if (!measureDownload.PhoneNumber) editingMeasure['PhoneNumber'] = "";
            //the public ones are booleans telling the site whether to display this on ModularMeasurePage too
            if (!("PhoneNumberPublic" in measureDownload)) editingMeasure['PhoneNumberPublic'] = false;
            if (!measureDownload.Email) editingMeasure['Email'] = "";
            if (!("EmailPublic" in measureDownload)) editingMeasure['EmailPublic'] = false;
            if (!measureDownload.AdditionalInstructionsWebsite) editingMeasure['AdditionalInstructionsWebsite'] = "";

            if (measureDownload.rBallotStatus === "signing up") {
                editingMeasure['signUpAgreement'] = false;
                editingMeasure['fullTextFile'] = "";
                editingMeasure['rBallotTitle'] = "";
                editingMeasure['topics'] = [""];
                editingMeasure['codes'] = [""];
                editingMeasure['mailing'] = defaultMailing;
            } else if (measureDownload.rBallotStatus === "volunteering") {
                if (!measureDownload.rBallotTitle) editingMeasure['rBallotTitle'] = "";
                //constitutional amendments do not have codes
                if (measureDownload.Type !== "CICA") {
                    editingMeasure['codes'] = [...measureDownload.Codes];
                } else {
                    //this just so that if they change it to CISS it doesn't break
                    editingMeasure['codes'] = [""];
                }
                editingMeasure['topics'] = [...measureDownload.Topic];
                if (!measureDownload.MailingAddress) { editingMeasure['mailing'] = defaultMailing; }
                else { editingMeasure['mailing'] = {...measureDownload.MailingAddress}; }

                //if this were abstracted, it could either use firebase.firestore.Timestamp.toDate() in firebase.js,
                //or use this current method in Utility.js. I believe it would also work on other timestamps, which
                //have a seconds property.
                let circulationDate;
                if (measureDownload.CirculationDate === "" || !measureDownload.CirculationDate) {
                    circulationDate = "01-01-2024";
                } else {
                    circulationDate = new Date(measureDownload.CirculationDate.seconds*1000).toJSON().split("T")[0];
                    console.log(circulationDate);
                }
                let deadlineDate;
                if (measureDownload.Deadline === "" || !measureDownload.Deadline) {
                    deadlineDate = "01-01-2024";
                } else {
                    deadlineDate = new Date(measureDownload.Deadline.seconds*1000).toJSON().split("T")[0];
                    console.log(deadlineDate);
                }
                editingMeasure['CirculationDate'] = circulationDate;
                editingMeasure['Deadline'] = deadlineDate;

                const fullTextFile = await getFullTextFile(id, 'letter');
                editingMeasure['fullTextFile'] = fullTextFile;
                //now we can be aware if an upload is necessary
                //this is a functional update
                setMeasureInDb(m => ({...m, 'fullTextFile': fullTextFile}));
            }
            console.log(editingMeasure);
            setMeasure(editingMeasure);
        }

        setUpEditing();

    }, [id, terms]);

    //--------------------------User Access effect-----------------------------
    //below will make the appropriate value from userContext
    //accessible more legibly. This should not compromise integrity
    //as a nefarious actor can already change their local userContext
    //We actually want to do this in App.js 
    //note: is this just the permissions object?
    const [userAccess, setUserAccess] = useState(undefined);
    useEffect(() => {
        if (userContext === "Not loaded") { return; }
        else if (userContext === undefined) { setUserAccess("Guest"); }
        else if (userContext.permissions) {
            //in custom claims, roles are abbreviated to save space.
            // This will help expand them for ease and clarity of us.
            const roles = {
                c: "Circulator",
                p: "Proponent",
                m: "Manager",
            };

            let personRoles = [];
            //assign the appropriate full string length role to the current viewer's array,
            //which will be able to handle multiple access levels
            for (const [key, value] of Object.entries(roles)) {
                if (userContext.permissions[key] && userContext.permissions[key].indexOf(id) !== -1) {
                    personRoles.push(value);
                }
            }
            setUserAccess(personRoles);
        }
    }, [userContext, id]);



//-------------------------Subcomponents---------------------------

    //when the user first is signing up, the page will work a bit differently.
    //I think realistically this should probably be its own section, but will take out as needed.
    /*const SetupSpiel = (props) => {
        return (
            <div>
                <h3>Welcome to rBallot!</h3>
                <p>In order to circulate your measure's petition through rBallot, you will need to provide
                    some information about your measure.
                </p>
                <p>NOTE: All information related to the measure on this page is provided "as is" with no
                    assurance or suggestion of accuracy, correctness, or legal standing on the part of rBallot.
                    You are responsible for correctly and accurately entering the necessary information to circulate
                    your measure, and for ensuring the correctness of any documents circulated through rBallot.
                    By using the software, you agree to indemnify rBallot from any legal action related to the use or...
                </p>
            </div>
        );
    }*/
    const [activeKey, setActiveKey] = useState("rBallotTitle");

    //next button so people don't get confused by accordion so much
    //note: I have each one put in the currentItem, but shouldn't activeKey always reflect that?
    //in that case though, I would need to have activeKey be part of the useEffect...
    const nextButton = (type, currentItem) => {
        let accordionItems = ["rBallotTitle", "topics", "codes", "fullTextFile",
                             "attnGeneralData", "mailing", "details", "contacts", "submit"];
        if (type === "CICA") accordionItems = accordionItems.filter(item => item !== "codes");

        //modulus not really necessary for this accordion since won't show up in submit
        const nextItem = (items, currentItem) => {
            return items[items.indexOf(currentItem)+1 % items.length];
        }

        return (
            <Button 
                onClick={() => { setActiveKey(nextItem(accordionItems, currentItem)); }}
                className="NextButton"
                //style={{'marginTop':'0.5em'}}
            >
                Next
            </Button>
        )
    }

    //---------------------Topics and codes display-------------------------
    //I think this will be engaging to people using it, even if not mandatory
    //needs to change based on Type, and to use the below listArrayContents
    //function that I wrote for PDFCirculator.js. Should be abstracted
    //to the little-used Utility.js
    //outcome: looks aite, could use more styling

    //This to be abstracted to Utility.js
    //this function will be used to list in a grammatically correct fashion
    //the elements of an array (presuming they are strings)
    const listArrayContents = (array) => {
        if (!array || array.length === 0) return "__________";
        if (array.length === 1) return array[0];
        if (array.length === 2) return (array[0] + " and " + array[1]);
        
        let string = "";
        for (let i = 0; i < array.length; i++) {
            if (i>0) string += ", ";
            if (i+1 === array.length) string += "and ";
            string += array[i]
        }
        return string;
    }

    const [topicCodeDisplay, setTopicCodeDisplay] = useState("");

    useEffect(() => {
        if (!measure || !measure.Type ||!measure.topics) return;

        let modifiedSection = " ";
        if (measure.Type === "CICA") modifiedSection = " Constitution of California";
        if (measure.Type === "CICA/SS") modifiedSection = " Constitution of California and";

        //map codes to show when a code is lacking
        const displayCodes = (codes) => codes.map((code) => {
            if (code === "") return "________";
            return code;
        });
        let codesVisualizer = "";
        if (measure.Type === "CISS" || measure.Type === "CICA/SS") {
            codesVisualizer = (
                <span style={{color: 'red'}}>{" " + listArrayContents(displayCodes(measure.codes))}</span>
            )
        }

        const displayTopics = measure.topics.map((topic) => {
            if (topic === "") return "_________";
            return topic;
        });
        let topicsVisualizer = (
            <span style={{color: 'blue'}}>{listArrayContents(displayTopics)}</span>
        );

        setTopicCodeDisplay(
            <p style={{fontFamily: "Times New Roman, Times, serif"}}>
                <br />
                "We, the undersigned, registered voters [...], hereby propose amendments to the
                {modifiedSection}{codesVisualizer}
                {measure.Type === "CISS" || measure.Type === "CICA/SS" ?
                    measure.codes.length > 1 ? " Codes" : " Code" : ""}
                , relating to {topicsVisualizer}, and petition the Secretary of State..."
            </p>
        )
    }, [measure]);

//-------------------------------Topics------------------------------------
    //const [topics, setTopics] = useState([""]);
    const [topicsForm, setTopicsForm] = useState(["Loading..."]);

    useEffect(() => {
        if (!measure || !measure.topics) return;
        
        const handleTopicChange = (i, value) => {
            const newTopics = measure.topics.map((topic, index) => {
                if (index === i) {
                    return value;
                } else {
                    return topic;
                }
            });
            setMeasure({
                ...measure,
                topics: newTopics
            });
        }

        const newTopicsForm = measure.topics.map((topic, i) => (
            <Form.Group key={i} controlId="topics">
                {measure.topics.length === 1 ? "" :
                <Form.Label>
                    {"Topic #" + (i+1)}
                </Form.Label>
                }
                <Row>
                    <Col>
                        <Form.Control
                            required
                            value={topic}
                            onChange={(e) => handleTopicChange(i, e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid">
                            Please choose a topic or subject that describes the measure.
                        </Form.Control.Feedback>
                    </Col>
                    <Col>
                        {measure.topics.length === 1 ? "" :
                            <Button
                                onClick={() => {
                                    setMeasure({
                                        ...measure,
                                        topics: measure.topics.filter((t, index) => index !== i)
                                    })
                                }}
                                className="RemoveListEntryButton"
                            >
                                x
                            </Button>
                        }
                    </Col>
                </Row>
                {i+1 !== measure.topics.length ? "" :
                <Button
                    onClick={() => {
                        setMeasure({
                            ...measure,
                            topics: [...measure.topics, ""]
                        });
                    }}
                    className="AddListEntryButton"
                >
                    +
                </Button>}     
                {i+1 !== measure.topics.length ? "" :
                <Row>
                    <Form.Text>
                        Topics will categorize your measure on rBallot, and
                        will appear in each section 
                        of the petition that is circulated, as previewed below in blue:
                    </Form.Text>
                </Row>}
                {i+1 !== measure.topics.length ? "" :
                    <Col>
                        <Row>
                        { topicCodeDisplay }
                        </Row>
                        <Row>
                        {nextButton(measure.Type, "topics")}
                        </Row>
                    </Col>
                }    
            </Form.Group>
        ));

        setTopicsForm(newTopicsForm);

    }, [measure, topicCodeDisplay]);

//---------------------California Code Selection (CISS only)--------------------
//-----Note: I completely copied the above topics code and just changed it to use
//-----new state variables. Could this be abstracted?
//answer: no, because these work differently. I need to grab select code from below.
//but with some more thought and care, probably. They both are represented by an
//array of arbitrary size in state
    const [codesForm, setCodesForm] = useState(["Loading..."]);

    useEffect(() => {
        if (!measure || !measure.codes) return;

        const handleCodeChange = (i, value) => {
            const newCodes = measure.codes.map((code, index) => {
                if (index === i) {
                    return value;
                } else {
                    return code;
                }
            });
            setMeasure({
                ...measure,
                codes: newCodes
            });
        }

        const newCodesForm = measure.codes.map((code, i) => (
            <Form.Group key={i} controlId="codes">
                {measure.codes.length === 1 ? "" :
                    <Form.Label>
                        {"Code Section #" + (i+1)}
                    </Form.Label>
                }
                <Row>
                    <Col>
                        <Form.Control
                            required 
                            as="select"
                            value={code}
                            onChange={(e) => handleCodeChange(i, e.target.value)}
                        >
                            <option value="" key="default">Choose code section</option>
                            {californiaCodes.map((codeEntry) => 
                                <option key={codeEntry}>{codeEntry}</option>)}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            Please specify the code section the measure modifies.
                        </Form.Control.Feedback>
                    </Col>
                    <Col>
                        {measure.codes.length === 1 ? "" :
                            <Button
                                onClick={() => {
                                    setMeasure({ 
                                        ...measure, 
                                        codes: measure.codes.filter((c, index) => index !== i) 
                                    });
                                }}
                                className="RemoveListEntryButton"
                            >
                                x
                            </Button>
                        }
                    </Col>
                </Row>
                {i+1 !== measure.codes.length ? "" :
                <Button
                    onClick={() => {
                        setMeasure({
                            ...measure,
                            codes: [...measure.codes, ""]
                        });
                    }}
                    className="AddListEntryButton"
                >
                    +
                </Button>}      
                {i+1 !== measure.codes.length ? "" :
                <Row>
                    <Form.Text>
                        Specify which sections of the California Code the measure modifies. 
                        These topics will appear in each section 
                        of the petition that is circulated, as previewed below in red.

                        If the measure does not modify the California Code,
                        check the 'Type' section under "Official Circulating Information"
                        to see if it is categorized correctly.
                    </Form.Text>
                </Row>}   
                {i+1 !== measure.codes.length ? "" :
                    <Col>
                        <Row>
                        { topicCodeDisplay }
                        </Row>
                        <Row>
                            {nextButton(measure.Type, "codes")}
                        </Row>
                    </Col>
                }   
            </Form.Group>
        ));

        setCodesForm(newCodesForm);

    }, [measure, topicCodeDisplay]);

//------------------------Mailing Address Functionality--------------------
    //mailingAddresses will be an object, but with numbered keys so that it can be
    //rendered as a list. This should mean that individual addresses can be deleted,
    //and not just the last one added, though will have to figure out how
    //to easily rename keys in an object.
    //note: reference below in case of errors using numbers as keys, but seems OK
    //https://stackoverflow.com/questions/67642675/key-of-a-javascript-object-must-be-a-string
    /*const [mailingAddresses, setMailingAddresses] = useState({
        count: 1,
        0: {
            address1: "",
            address2: "",
            city: "",
            state: "",
            zip: "",
            counties: ["Remainder"]
        }
    })*/
    //previously used a component MailingAddresses, but this would
    //get re-rendered every state change and lose focus. Now,
    //using a state variable that is edited, we don't lose focus
    const [mailingForm, setMailingForm] = useState(["Loading..."]);
    useEffect(() => {
        if (!measure || !measure.mailing) return;
        
        let mailingForm = [];
        const number = measure.mailing.count;

        //copying inside useEffect to remove warning
        //commenting out as not supporting multiple mailings in 0.0.1
        /*const mailingAddressTemplate = {
            address1: "",
            address2: "",
            city: "",
            state: "",
            zip: "",
            counties: ["Remainder"]
        }*/

        //see if we can abstract this to avoid repetiion
        const handleChange = (address, index, line, value) => {
            address[line] = value;
            setMeasure({
                ...measure,
                mailing: {
                    ...measure.mailing,
                    [index]: address
                }
            });
        }

        for (let i = 0; i<number; i++) {
            //get a local, mutable copy of the current mailing address
            let address = measure.mailing[i];
            //sequentially push in the mailing addresses, to be
            //rendered in DOM (I think I'm using that term right)
            mailingForm.push(
                <Form.Group key={i}>
                    {number === 1 ? "" :
                        <Form.Label>
                            {"Mailing Address #" + (i+1)}
                        </Form.Label>
                    }
                    <Form.Control 
                        required
                        placeholder="Address line 1"
                        value={address.address1}
                        onChange={(e) => {
                            handleChange(address, i, "address1", e.target.value)
                        }}
                    />
                    <Form.Control 
                        placeholder="Address line 2"
                        value={address.address2}
                        onChange={(e) => 
                            handleChange(address, i, "address2", e.target.value)
                        }
                    />
                    <Form.Control
                        required
                        placeholder="City"
                        value={address.city}
                        onChange={(e) => {
                            handleChange(address, i, "city", e.target.value)
                        }}
                    />
                    <Form.Control
                        required
                        as="select"
                        value={address.state}
                        onChange={(e) => {
                            handleChange(address, i, "state", e.target.value)
                        }}
                    >
                        <option value="" key="default">Choose state</option>
                        {allStates.map((state) => <option key={state}>{state}</option>)}
                    </Form.Control>
                    <Form.Control 
                        required
                        placeholder="Zip"
                        value={address.zip}
                        onChange={(e) => {
                            handleChange(address, i, "zip", e.target.value)
                        }}
                    />
                    {/*number === 1 ? "" :
                    <Form.Control
                        as="select"
                        multiple={true}
                        value={address.counties}
                        onChange={(e) => {
                            address.counties = e.target.value;
                            console.log(address.counties);
                            setMailingAddresses({
                                ...mailingAddresses,
                                [i]: address
                            })
                        }}
                    >
                        <option key="default">Remainder</option>
                        {countiesByState['California'].map((county) => 
                            <option key={county}>{county}</option>
                        )}
                    </Form.Control>
                    */}
                    {i+1 !== number ? "" :
                        <Form.Text>
                            Provide a valid mailing address to receive the completed 
                            petition sections from circulators. {/*You can specify multiple 
                            mailing addresses for different counties using the + button.*/}
                        </Form.Text>
                    }
                    {/*i+1 !== number ? "" :
                        <Button
                            onClick={() => {
                                let count = mailingAddresses.count;
                                count += 1;
                                setMailingAddresses({ 
                                    ...mailingAddresses,
                                    [i+1]: { ...mailingAddressTemplate }, 
                                    ["count"]: count
                                });
                            }}
                        >
                            +
                        </Button>
                        */}
                        {i+1 !== number ? "" :
                            <Row>
                                {nextButton(measure.Type, "mailing")}
                            </Row>
                        }
                </Form.Group>
            )
        }
        setMailingForm(mailingForm);
    }, [measure]);

    //---------------------Attorney General's Information---------------------------
    //will include: OfficialTitle, Summary, Fiscal Impact Summary from Legislative Analyst
    const [attnGeneralData, setAttnGeneralData] = useState("");

    useEffect(() => {
        if (!measure) return;

        //reformatting for use in form
        let formMeasure = { ...measure };
        if (!formMeasure.Title) formMeasure.Title = "";
        if (!formMeasure.Summary) formMeasure.Summary = "";
        if (!formMeasure.FiscalImpact) formMeasure.FiscalImpact = "";
        //we will reformat type to make it clearer to user
        if (!formMeasure.Type) { 
            formMeasure.Type = "Choose Type"; 
        } else {
            formMeasure.Type = californiaInitiativeTypes[measure.Type] + " (" + measure.Type + ")";
        }

        const handleChange = (/*newAddress,*/key, value) => {
            let newValue;
            
            if (key === "Type") {
                newValue = value.split("(")[1].slice(0, -1);
            } else {
                newValue = value;
            }

            setMeasure({
                ...measure,
                [key]: newValue
            })
        }

        //eventually this will be pulled from Information.js,
        //but for now we only have one possibility
        const possibleStatuses = ['Cleared for signature gathering', 'Not on ballot'];

        //see if we can transition to this one, which was used in details
        //(where status used to reside)
        const handleChangeDirectly = (e) => {
            console.log(measure);
            setMeasure({
                ...measure,
                [e.target.name]: e.target.value
            });
        }

        setAttnGeneralData(
            <Accordion.Body>
                <Form.Group>
                    <Form.Label>Official Title</Form.Label>
                    <Form.Control
                        required
                        name="Title"
                        value={formMeasure.Title}
                        onChange={(e) => handleChange("Title", e.target.value)} 
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Summary</Form.Label>
                    <Form.Control 
                        required
                        name="Summary"
                        value={formMeasure.Summary}
                        as="textarea"
                        rows="3"
                        onChange={(e) => handleChange("Summary", e.target.value)} 
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Fiscal Impact Summary</Form.Label>
                    <Form.Control 
                        required
                        name="FiscalImpact"
                        value={formMeasure.FiscalImpact}
                        as="textarea"
                        onChange={(e) => handleChange("FiscalImpact", e.target.value)} 
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Type</Form.Label>
                    <Form.Control
                        required
                        name="Type"
                        as="select"
                        value={formMeasure.Type}
                        onChange={(e) => handleChange("Type", e.target.value)} 
                    >
                        <option key="default">Choose Type</option>
                        {Object.entries(californiaInitiativeTypes).map((entry) =>
                            <option key={entry[0]}>{entry[1] + " (" + entry[0] + ")"}</option>
                        )}
                    </Form.Control>
                </Form.Group>

                <Form.Group>
                    <Form.Label>Status</Form.Label>
                    <Form.Control 
                        required
                        name="Status"
                        as="select"
                        value={measure.Status}
                        onChange={(e) => handleChangeDirectly(e)}
                    >  
                        {possibleStatuses.map((status) => 
                            <option key={status}>{status}</option>
                        )}
                    </Form.Control>

                    <Row>
                        {nextButton(measure.Type, "attnGeneralData")}
                    </Row>
                </Form.Group>
            </Accordion.Body>
        );
    }, [measure]);


    //---------------------Details (other data)---------------------------
    //includes type, deadline, circulationDate, jurisdiction info (name and type), proponents, signatures req, status
    //type is the most important of these as it affects the pdf generation, and is more likely to have errors than
    //jurisdiction info (which is also crucial and affects pdfs drastically, but unlikely to have errors)

    const [details, setDetails] = useState("");

    useEffect(() => {
        if (!measure) return;

        console.log(measure);

        const dates = {
            CirculationDate: measure.CirculationDate,
            Deadline: measure.Deadline
        };

        const handleChange = (e) => {
            setMeasure({
                ...measure,
                [e.target.name]: e.target.value
            });
        }

        const handleDateChange = (e) => {
            
            //if we're clearing it, then no data
            //though, this kind of sucks. It should really
            //reload the original date. Another reason to maintain
            //a copy of the actual data we got from site
            if (e.target.value === null || e.target.value === "") {
                setMeasure({
                    ...measure,
                    [e.target.name]: ""
                });
                return;
            }

            console.log(e.target.value);
            console.log(e.target);

            //make the measure's version the one from the form,
            //so that it works correctly. Make it change to timestamp
            //only on submission

            setMeasure({
                ...measure,
                [e.target.name]: e.target.value
            });
        }

        setDetails(
            <Accordion.Body>
                <Form.Group>
                    <Form.Label>Circulation Date</Form.Label>
                    <Form.Control 
                        required
                        name="CirculationDate"
                        type="date"
                        value={dates.CirculationDate}
                        onChange={(e) => handleDateChange(e)}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Deadline</Form.Label>
                    <Form.Control 
                        required
                        name="Deadline"
                        type="date"
                        value={dates.Deadline}
                        onChange={(e) => handleDateChange(e)}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Proponents</Form.Label>
                    <Form.Control 
                        required
                        name="Proponents"
                        value={measure.Proponents ? measure.Proponents : ""}
                        onChange={(e) => handleChange(e)}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Signatures Required</Form.Label>
                    <Form.Control 
                        required
                        name="SignaturesReq"
                        value={measure.SignaturesReq}
                        onChange={(e) => handleChange(e)}
                    />
                </Form.Group>
                <Row>
                    {nextButton(measure.Type, "details")}
                </Row>
            </Accordion.Body>
        );
    }, [measure]);

    //--------------------------Contact---------------------------
    /** This will include:
     *  1) Website
     *  2) Email
     *  3) Phone number
     *  4) Additional instructions website page
     */

    const [contacts, setContacts] = useState("");

    useEffect(() => {
        if (!measure) return;

        const handleChange = (e) => {
            setMeasure({
                ...measure,
                [e.target.name]: e.target.value
            });
        }

        const handleCheckChange = (e) => {
            setMeasure({
                ...measure,
                [e.target.name]: e.target.checked
            });
        }

        setContacts(
            <Accordion.Body>
                <Form.Text>All of below are optional.</Form.Text>
                <Form.Group style={{marginTop: '0.5em'}}>
                    <Form.Label>Website</Form.Label>
                    <InputGroup>
                        <InputGroup.Text>https://</InputGroup.Text>
                        <Form.Control 
                            name="Website"
                            value={measure.Website}
                            onChange={(e) => handleChange(e)}
                        />
                    </InputGroup>
                </Form.Group>
                <Form.Group style={{marginTop: '0.5em'}}>
                    <Form.Label>Gathering Instructions Webpage</Form.Label>
                    <InputGroup>
                        <InputGroup.Text>https://</InputGroup.Text>
                        <Form.Control 
                            name="AdditionalInstructionsWebsite"
                            value={measure.AdditionalInstructionsWebsite}
                            onChange={(e) => handleChange(e)}
                        />
                    </InputGroup>
                    <Form.Text>
                        If you have your own webpage with instructions for signature gatherers,
                        you can paste it here for signature gatherers to view. This could be a link to a video, too.
                    </Form.Text>
                </Form.Group>
                <Form.Group style={{marginTop: '0.5em'}}>
                    <Form.Label>Email</Form.Label>
                    <Form.Control 
                        name="Email"
                        value={measure.Email}
                        onChange={(e) => handleChange(e)}
                    />
                    <Form.Check
                        name="EmailPublic"
                        value={measure.EmailPublic}
                        checked={measure.EmailPublic}
                        onChange={(e) => handleCheckChange(e)}
                        label="Make Email Public?"
                    />
                </Form.Group>
                <Form.Group style={{marginTop: '0.5em'}}>
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control 
                        name="PhoneNumber"
                        value={measure.PhoneNumber}
                        onChange={(e) => handleChange(e)}
                    />
                    <Form.Check
                        name="PhoneNumberPublic"
                        value={measure.PhoneNumberPublic}
                        checked={measure.PhoneNumberPublic}
                        onChange={(e) => handleCheckChange(e)}
                        label="Make Phone Number Public?"
                    />
                </Form.Group>
                <Form.Text>
                        Emails and phone numbers you enter here will be displayed to signature
                        gatherers. If you mark them as public, they will be displayed
                        to anyone viewing your measure's public page. Note: Technically
                        savvy users will still be able to access any email or phone number you provide, even
                        if you do not mark it as public.
                    </Form.Text>
                <Row>
                    {nextButton(measure.Type, "contacts")}
                </Row>
            </Accordion.Body>
        );
    }, [measure]);

    //--------------------------Submit!---------------------------
    //this will include:
    //1) a button to submit that will then verify all correct
    //information has been inputted (oh brother...), and then submit the
    //correct request to requests for proponent.
    //2) Questions asking proponent to certify their agreement to the
    //terms and conditions of the site, and their understanding that
    //the information provided by rBallot was complied from publicly-available
    //data sources, and is solely provided for informative purposes without any
    //guarantee of 

    const [requestID, setRequestID] = useState("");
    const [requestResult, setRequestResult] = useState(undefined);

    useEffect(() => {
        if (!requestID || requestID === "" || !userContext) return;

        //this might also be able to be passed in to avoid warning
        const requestRef = db.collection("requests").doc(userContext.uid)
                             .collection("organizations").doc(id)
                             .collection("measureEdits").doc(requestID);

        const unsubscribe = requestRef.onSnapshot(function(doc) {
            if (!doc.exists) {
                console.log("No such request.");
            } else {
                console.log("Request received!");
                const data = doc.data();
                if (data.status === "new") return;
                setRequestResult({ status: data.status, error: data.error });
                setSubmitting(false);
                if (data.status === "success") {
                    //redirect after 500 ms
                    //I don't think this delay matters much, since the loading animation
                    //is not being put in anyway
                    //setTimeout(navigate('/measure/' + id + '/campaign'), 500);
                    navigate('/measure/' + id + '/campaign');
                }
            }
        });

        return function cleanUp() { unsubscribe(); }

    }, [requestID, id, navigate, userContext]);
    
    //turn json date into timestamp
    //question: I'm feeding data in year-month-day, and it 
    //seems to take it, but then it prints out month-day-year
    //answer: "The displayed date format will differ from the actual value —
    // the displayed date is formatted based on the locale of the user's browser,
    // but the parsed value is always formatted yyyy-mm-dd"
    const getTimestampFromForm = (json_date) => {
        //Reorder it
        const [year, month, day] = json_date.split("-");
        //January is 0
        const date = new Date(year, month-1, day);

        const timestamp = timestampFromDate(date);

        return timestamp;
    }

    const [submitForm, setSubmitForm] = useState("");
    const [validated, setValidated] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const onSubmit = async (e) => {
        e.preventDefault();

        //before checking validity, I should filter out
        //topics and codes that are blank, unless there is only one left?
        //whatever the way, deal with that
        //OK but now this is broken because it deletes everything
        /*setMeasure({
            ...measure,
            topics: measure.topics.filter(topic => topic !== ""),
            codes: measure.codes.filter(code => code !== "")
        });*/

        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
            //I think this just tells it to display a feedback message for
            //each form entry
            setValidated(true);
            return;
        }
        //what does it look like when I still have it for success?
        //setValidated(true);

        // uploadMeasure will be built to conform to database specifications
        // e.g. all capital words or whatever, and to contain all the necessary fields
        // after creating, we'll want to check that it includes all necessary fields,
        // as well as whether it's modifying key fields to ask for second confirmation.
        // note: should I do all that here? Or should the database capitalize everything? here, probs
        let uploadMeasure = { ...measure };
        if (uploadMeasure.Type !== "CICA") uploadMeasure['Codes'] = measure['codes'];
        uploadMeasure['Topic'] = measure['topics'];
        //will this have issues with formatting?
        uploadMeasure['MailingAddress'] = measure['mailing'];
        delete uploadMeasure['codes'];
        delete uploadMeasure['topics'];
        delete uploadMeasure['mailing'];
        delete uploadMeasure['fullTextFile'];
        uploadMeasure['CirculationDate'] = getTimestampFromForm(uploadMeasure['CirculationDate']);
        uploadMeasure['Deadline'] = getTimestampFromForm(uploadMeasure['Deadline']);
        
        //only upload optional fields if present
        if (uploadMeasure['Website'] === "") { 
            delete uploadMeasure['Website']; 
        //websites need full url added
        //note: this allows them to enter in an http:// site
        } else if (!uploadMeasure['Website'].startsWith("http")){
            uploadMeasure['Website'] = "https://" + uploadMeasure['Website'];
        }
        if (uploadMeasure['AdditionalInstructionsWebsite'] === "") {
            delete uploadMeasure['AdditionalInstructionsWebsite'];
        } else if (!uploadMeasure['AdditionalInstructionsWebsite'].startsWith("http")) {
            uploadMeasure['AdditionalInstructionsWebsite'] = "https://" + uploadMeasure['AdditionalInstructionsWebsite'];
        }
        if (uploadMeasure['PhoneNumber'] === "") { delete uploadMeasure['PhoneNumber']; delete uploadMeasure['PhoneNumberPublic']; }
        if (uploadMeasure['Email'] === "") { delete uploadMeasure['Email'];  delete uploadMeasure['EmailPublic']; }

        console.log(Object.entries(uploadMeasure));

        //here, check the necessary fields are present?
        //kind of already have done that through validation.
        //only ones not in form are: election date, jurisdiction name and type
        //verdict: skip for now
        
        //tell the loading animation to display
        setSubmitting(true);

        //if the person has modified the full text file, upload that
        if (measure.fullTextFile !== measureInDb.fullTextFile) {
            console.log("Submitting full text to storage...");
            const request = await uploadFullText(measure.fullTextFile, id, userContext.uid, "letter");
            console.log(request);
        }
        
        console.log("Now submitting request to firestore...");
        const firestoreRequest = await uploadMeasureInfo(uploadMeasure, userContext.uid);
        console.log(firestoreRequest);
        
        //here, since request has to be processed by server, should perhaps set a state
        //variable for the request's id, that will then cause a useEffect to fire and
        //put a listener on that request to then display a message if there was an error,
        //and on success to display that and maybe redirect?
        setRequestID(firestoreRequest.id);
    };

    //-------Terms for submission-------

    //this useEffect should change based on the new measure state variable,
    //which should be separate from the originally-fetched measure variable,
    //which should still exist. I could do this most easily by migrating all
    //fields currently logged on a separate object to measure, and then splitting
    //measure into two when first grabbed, one with fields modified for editing and
    //one unchanging for comparison later
    useEffect(() => {
        if (!measure) return;

        setSubmitForm(
            <Accordion.Body>
                <Form.Group>
                    <Row style={{textAlign: 'center'}}>
                        <Form.Label>Publish this measure: {measure.rBallotTitle}</Form.Label>
                    </Row>
                    {measure.rBallotStatus !== "signing up" ? "" :
                        <Row style={{fontSize: '0.8em', color: 'grey', textAlign: 'center'}}>
                            <Form.Label>
                                I acknowledge my understanding that suggestions provided by rBallot 
                                for certain fields were obtained from publicly-accessible sources online, and may contain 
                                factual, typographical, and other errors. I understand that I am responsible for ensuring 
                                the accuracy of all information, and rBallot has made no assurance to me of the correctness 
                                or accuracy of the suggested information.
                            </Form.Label>
                            <div style={{display: 'flex', justifyContent: 'center', gap: '1em', padding: '1em'}}>
                                <Form.Check.Input
                                    type="checkbox" 
                                    name="signUpAgreement"
                                    style={{flexShrink: '0'}}
                                    value={measure.signUpAgreement}
                                    onChange={(e) => {
                                        setMeasure({
                                            ...measure,
                                            signUpAgreement: e.target.checked
                                        });
                                    }}
                                    required
                                />
                                <Form.Check.Label>
                                    I have read and agree.
                                </Form.Check.Label>
                                <Form.Control.Feedback type="invalid">
                                    You must agree before submitting.
                                </Form.Control.Feedback>
                            </div>
                        </Row>
                    }
                    <Row style={{fontSize: '0.8em', color: 'grey', textAlign: 'center'}}>
                        <Form.Label>
                            I agree that I have reviewed all information that I am submitting on this page for correctness and accuracy.
                            I acknowledge and agree that the rBallot software used to generate petition sections is provided "as is"
                            with no warranty of fitness for a particular purpose, and my use is subject to the {" "}
                            <Link to="/terms" target="_blank">Terms and Conditions and Privacy Policy</Link>
                        </Form.Label>
                        <div style={{display: 'flex', justifyContent: 'center', gap: '1em', padding: '1em'}}>
                            <Form.Check.Input 
                                type="checkbox" 
                                name="agreement"
                                style={{flexShrink: '0'}}
                                value={measure.agreement}
                                onChange={(e) => {
                                    setMeasure({
                                        ...measure,
                                        agreement: e.target.checked
                                    });
                                }}
                                required
                            />
                            <Form.Check.Label>
                                I have read and agree to the Terms and Conditions and Privacy Policy.
                            </Form.Check.Label>
                            <Form.Control.Feedback type="invalid">
                                You must agree before submitting.
                            </Form.Control.Feedback>
                        </div>
                    </Row>
                    <Row>
                        { 
                        !submitting ?
                            <Button type="submit">Submit</Button>
                        : 
                            <Loading />
                        }
                    </Row>
                    <Row>
                        {!requestResult ? "" :
                            <div>
                                {requestResult.status ? requestResult.status : ""}
                                {requestResult.status === "rejected" ? requestResult.error : ""}
                                {requestResult.status === "success" ? "Redirecting..." : ""}
                            </div>
                        }
                    </Row>
                </Form.Group>
            </Accordion.Body>
        );


    }, [measure, requestResult, submitting]);

//----------------------Validation tracking for accordion--------------------

    //I want to be able to display on the accordion header whether a field within
    //needs validating or not. This will keep track of which accordion items are
    //completed, and then will display when 'validated' state from above is set
    //to validated
    //note: maybe this should just work by having each accordion Form Group check
    //all its inputs for validity, and display that?
    const [accordionValidate, setAccordionValidate] = useState({
        rBallotTitle: "",
        topics: "",
        codes: "",
        fullTextFile: "",
        circulatingInfo: "",
        mailing: "",
        details: "",
        submit: ""
    });

    //keep track on every change of the validation state
    //this is a lot of overhead, sort of negating the bonuses
    //from using native html validation, though I suppose that
    //should work without javascript too
    useEffect(() => {
        if (!measure) return;
        //below could improve performance, by only figuring out validity
        //once we are actually even showing it. But for now it doesn't
        //seem to cause an issue, and makes it glitch for a split second
        //when submitting
        if (!validated) return;
        let newAccordionValidate = {};

        //I put the style here into EditMeasure.css, but it didn't load when I used the class name here
        //note: I looked at EditmMeasure.css. The reason it didn't load was that I put everything in quotes.
        //note: above note was from when this was specified in EditForm(), might be different now
        const invalidAccordion = (
            <span style={{
                color:'red', 
                fontSize:'1.25em', 
                marginLeft:'1em', 
                padding:'1px 3px', 
                border:'1px', 
                borderWidth:'2px', 
                borderStyle:'dashed solid'
            }}>
            !
            </span>
        );

        const validAccordion = (
            <span style={{
                color: 'green',
                fontSize: '1.25em',
                marginLeft:'1em', 
                padding:'1px 3px', 
                border:'1px', 
                borderWidth:'2px', 
                borderStyle:'dashed solid'
            }}>
                {/** Below is a checkmark in unicode. I imagine this will work everywhere? */}
                {"\u2713"}
            </span>
        )

        //rBallotTitle
        if (measure.rBallotTitle && measure.rBallotTitle !== "") {
            newAccordionValidate["rBallotTitle"] = validAccordion;
        } else {
            newAccordionValidate["rBallotTitle"] = invalidAccordion;
        }
        //topics
        //this is awful, don't want to do this every single change.
        //though, really this is only O(n), right?
        const topicsCheck = measure.topics.filter(topic => topic !== "");
        if (topicsCheck && topicsCheck.length > 0) {
            newAccordionValidate["topics"] = validAccordion;
        } else {
            newAccordionValidate["topics"] = invalidAccordion;
        }
        //codes
        if (measure.Type !== "CICA") {
            const codesCheck = measure.codes.filter(code => code !== "");
            if (codesCheck && codesCheck.length > 0) {
                newAccordionValidate["codes"] = validAccordion;;
            } else {
                newAccordionValidate["codes"] = invalidAccordion;
            }
        }
        //fullTextFile
        if (measure.fullTextFile && measure.fullTextFile !== "") {
            newAccordionValidate["fullTextFile"] = validAccordion;
        } else {
            newAccordionValidate["fullTextFile"] = invalidAccordion;
        }
        //mailing
        if (measure.mailing[0].address1 && measure.mailing[0].address1 !== ""
            && measure.mailing[0].city && measure.mailing[0].city !== ""
            && measure.mailing[0].state && measure.mailing[0].state !== ""
            && measure.mailing[0].zip && measure.mailing[0].zip !== "")
        {
            newAccordionValidate["mailing"] = validAccordion;
        } else {
            newAccordionValidate["mailing"] = invalidAccordion;
        }
        //circulatingInfo
        if (measure.Title && measure.Title !== ""
            && measure.Summary && measure.Summary !== ""
            && measure.FiscalImpact && measure.FiscalImpact !== ""
            && measure.Status && measure.Status !== "")
        {
            newAccordionValidate["circulatingInfo"] = validAccordion;
        } else {
            newAccordionValidate["circulatingInfo"] = invalidAccordion;
        }
        //details
        if (measure.CirculationDate && measure.CirculationDate !== ""
            && measure.Deadline && measure.Deadline !== ""
            && measure.SignaturesReq && measure.SignaturesReq !== ""
            && measure.Proponents && measure.Proponents !== "")
        {
            newAccordionValidate["details"] = validAccordion;
        } else {
            newAccordionValidate["details"] = invalidAccordion;
        }
        //submit
        if (measure.agreement) {
            newAccordionValidate["submit"] = validAccordion;
        } else {
            newAccordionValidate["submit"] = invalidAccordion;
        }

        setAccordionValidate(newAccordionValidate);

    }, [measure, validated])

    //--------------------rBallotTitle, Full Text, and PDF Preview------------------------

    const [refreshCount, setRefreshCount] = useState(1);
    //const [fullTextFile, setFullTextFile] = useState("");
    
    //in order to allow people to edit the measure without reuploading full text
    const fullTextRef = useRef(null);
    useEffect(() => {
        if (!measure) return;
        if (fullTextRef === null || fullTextRef.current === null) return;
        if (measure.rBallotStatus === "signing up") return;
        if (measure.rBallotStatus === "volunteering") {
            //check if we need to update, or if current is up to speed with fullTextFile
            if (fullTextRef.current.files[0] !== measure.fullTextFile) {
                //from https://stackoverflow.com/questions/1696877/how-to-set-a-value-to-a-file-input-in-html/70485949#70485949
                //DataTransfer is designed to hold data during a drag and drop operation, so we're basically
                //dragging and dropping the file in.
                let container = new DataTransfer();
                container.items.add(measure.fullTextFile);
                fullTextRef.current.files = container.files;
                return;
            } else {
                return;
            }
        }

    }, [fullTextRef, measure]);


    const onFileChange = (e) => {
        const fileList = e.target.files;
        console.log(fileList[0].name);
        setMeasure({
            ...measure,
            fullTextFile: fileList[0]
        });
    }

    //five elements we actually need from them:
    //rBallotTitle, FullText (pdf or text), Topics, Address, and code section (if SS)
    //full text will be trickiest. Currently have ability to concatenate it as a pdf,
    //and next I think should be to import from Google Docs by exporting that to JSON
    //note: should this be stored in state rather than being a function?
    const EditForm = () => {
        return (
            <Form noValidate validated={validated} onSubmit={onSubmit}>
                <Accordion 
                    activeKey={activeKey}
                    onSelect={(eventKey) => setActiveKey(eventKey)}
                >
                    <Accordion.Item eventKey="rBallotTitle">
                        <Accordion.Header>
                            rBallot Title
                            {validated ? accordionValidate.rBallotTitle : ""}
                        </Accordion.Header>
                        <Accordion.Body>
                            <Form.Group controlId="rBallotTitle">
                                <Form.Control
                                    required
                                    type="text"
                                    name="rBallotTitle"
                                    value={measure.rBallotTitle}
                                    onChange={(e) => { setMeasure({
                                        ...measure,
                                        rBallotTitle: e.target.value
                                        });
                                    }}
                                />
                                <Form.Text>
                                    This title will be used to refer to this measure on rBallot.
                                </Form.Text>
                                <Form.Control.Feedback type="invalid">
                                    Please choose a title for your measure.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Row>
                                {nextButton(measure.type, activeKey)}
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="topics">
                        <Accordion.Header>
                            Topics
                            {validated ? accordionValidate.topics : ""}
                        </Accordion.Header>
                        <Accordion.Body>
                            { topicsForm }
                        </Accordion.Body>
                    </Accordion.Item>
 
                    { measure.Type === "CICA" ? "" :
                    <Accordion.Item eventKey="codes">
                        <Accordion.Header>
                            Codes {validated ? accordionValidate.codes : ""}
                        </Accordion.Header>
                        <Accordion.Body>
                            { codesForm }
                        </Accordion.Body>
                    </Accordion.Item> 
                    }

                    <Accordion.Item eventKey="fullTextFile">
                        <Accordion.Header>
                            Full Text of Measure
                            {validated ? accordionValidate.fullTextFile : ""}
                        </Accordion.Header>
                        <Accordion.Body>
                            <Form.Group controlId="fullTextUpload">
                                <Form.Label>PDF File Upload</Form.Label>
                                <Form.Control
                                    required
                                    type="file"
                                    name="FullText"
                                    ref={fullTextRef}
                                    onChange={onFileChange}
                                />
                            </Form.Group>
                            <Form.Text>
                                Text of measure must be in 8 point font or larger.
                                A space at least 1 inch wide should be left blank at the top
                                of each page.
                                Contact or visit the website of the Secretary of State of California
                                for more information.
                            </Form.Text>
                            <Form.Control.Feedback type="invalid">
                                Please upload a PDF of the full text of the measure.
                            </Form.Control.Feedback>
                            <Row>
                                {nextButton(measure.Type, "fullTextFile")}
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                    
                    <Accordion.Item eventKey="attnGeneralData">
                        <Accordion.Header>
                            Official Circulating Information
                            {validated ? accordionValidate.circulatingInfo : ""}
                        </Accordion.Header>
                        {attnGeneralData}
                    </Accordion.Item>

                    <Accordion.Item eventKey="mailing">
                        <Accordion.Header>
                            Mailing Address
                            {validated ? accordionValidate.mailing : ""}
                        </Accordion.Header>
                        <Accordion.Body>
                            { mailingForm }
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="details">
                        <Accordion.Header>Details {validated ? accordionValidate.details : ""}</Accordion.Header>
                        {details}
                    </Accordion.Item>

                    {/* Note: contacts is always valid. Also, looks like the className does work! */}
                    <Accordion.Item eventKey="contacts">
                        <Accordion.Header>Contacts {validated ? <span className="ValidAccordion">{"\u2713"}</span>: ""}</Accordion.Header>
                        {contacts}
                    </Accordion.Item>

                    <Accordion.Item eventKey="submit">
                        <Accordion.Header>Submit {validated ? accordionValidate.submit : ""}</Accordion.Header>
                        {submitForm}
                    </Accordion.Item>
                </Accordion>
            </Form>
        )
    }

//----------------------------Return-------------------------------

    //below are used to allow proponents to manually set the signatures per page
    const [pages, setPages] = useState(1);
    const handleSignaturesPerSheetChange = (e, index) => {
        const newSignaturesPerSheet = measure.SignaturesPerSheet;
        newSignaturesPerSheet[index] = Number(e.target.value);
        setMeasure({
            ...measure,
            SignaturesPerSheet: newSignaturesPerSheet
        });
    }

    //just show the example
    if (id !== "example") {
        //wait for us to have the user's access loaded
        if (!userAccess) {
            return (
                <div style={{margin: '6em'}}>
                    <h3>
                        Loading...
                    </h3>
                </div>
            );
        //once user access is loaded, check 
        } else if (userAccess.indexOf('Proponent') === -1) {
            //here check to see if they are awaiting proponent access, and if so tell them their request has 
            if (props && props.permissions && props.permissions[id] && props.permissions[id].indexOf('awaiting-proponent-access') !== -1) {
                return (
                    <div style={{margin: '6em'}}>
                        <p>
                            Your request to verify yourself as the proponent for measure {id} is awaiting verification.
                            We will send you an email to the email address associated with your account once it has been
                            manually checked. This may take up to several weeks if we must request a copy of your voter
                            registration information from the Secretary of State. If you have any questions or would like
                            to notify us about an error in your request, please email rballot@rballot.org.
                        </p>
                    </div>
                );
            //if they aren't listed as a proponent, but email isn't verified, it may be that their request is awaiting email verification
            } else if (userContext && !userContext.emailVerified) {
                return (
                    <div style={{margin: '6em'}}>
                        <UnverifiedEmail />
                    </div>
                );
            //they just aren't a proponent
            } else {
                return (
                    <div style={{margin: '6em'}}>
                        <p>
                            You must be registered as a proponent for this measure to edit it.
                        </p>
                    </div>
                );
            }
        }
    }

    //below will now only be if userAccess contains 'Proponent'
    return (
        <Container fluid>
            <div style={{textAlign: 'center', marginBottom: '1em'}}>
                <h1 style={{marginTop: '2.25em'}}>
                    {!measure || !measure.rBallotTitle || measure.rBallotTitle === "" ? "Measure " + id : measure.rBallotTitle}
                </h1>
                <Link 
                    to={"/measure/" + id + "/campaign"} 
                >
                    Return to campaign page
                </Link>
            </div>
            {!measure || measure.rBallotStatus === "not active" ? "Loading..." : (
                <Row>
                    <Col style={{minWidth: '300px'}}>
                        {measure.rBallotStatus === "signing up" ? <p>Welcome to rBallot!</p> : ""}                        
                        {EditForm()}
                        <br />
                    </Col>
                    <Col style={{maxWidth: '48em'}}>
                        {!measure.fullTextFile || measure.fullTextFile === "" ? "Upload the full text of your measure to see a preview of its petition section." :
                            <div>
                                <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0" style={{borderRadius: '6px 6px 0px 0px'}}>
                                    <Accordion.Header>Controls</Accordion.Header>
                                    <Accordion.Body className="ControlsAccordionBody">
                                    <ToggleButtonGroup 
                                        name="togglemebrother" 
                                        value={pages} 
                                        onChange={(value) => setPages(value)}
                                        vertical={true}
                                    >
                                        <h5>Pages</h5>
                                        <ToggleButton id="1" value={1} name="1" className="PageToggleButton">
                                            1
                                        </ToggleButton>
                                        <ToggleButton id="2" value={2} name="2" className="PageToggleButton">
                                            2
                                        </ToggleButton>
                                        <ToggleButton id="3" value={3} name="3" className="PageToggleButton">
                                            3
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                    <Form.Group>
                                        <div style={{display: 'flex', flexDirection: 'column', maxWidth: '10em', padding: '0em 1em'}}>
                                            <h5>Signatures</h5>
                                            <Form.Control 
                                                required
                                                name="SignaturesPerSheet1"
                                                value={measure.SignaturesPerSheet[0]}
                                                onChange={(e) => handleSignaturesPerSheetChange(e, 0)}
                                                style={{borderRadius: '0px'}}
                                                onClick={() => setPages(1)}
                                            />
                                            <Form.Control 
                                                required
                                                name="SignaturesPerSheet2"
                                                value={measure.SignaturesPerSheet[1]}
                                                onChange={(e) => handleSignaturesPerSheetChange(e, 1)}
                                                style={{borderRadius: '0px'}}
                                                onClick={() => setPages(2)}
                                            />
                                            <Form.Control 
                                                required
                                                name="SignaturesPerSheet3"
                                                value={measure.SignaturesPerSheet[2]}
                                                onChange={(e) => handleSignaturesPerSheetChange(e, 2)}
                                                style={{borderRadius: '0px'}}
                                                onClick={() => setPages(3)}
                                            />
                                        </div>
                                        {/*<Form.Label>Signature Spaces Per Page</Form.Label>*/}
                                    </Form.Group>
                                    <div style={{textAlign: 'center'}}>
                                        <h5>Refresh</h5>
                                        <Button
                                            onClick={() => setRefreshCount(refreshCount + 1)}
                                            className="RefreshButton"
                                        >
                                            {"\u21BB"}
                                        </Button>
                                    </div>
                                </Accordion.Body>
                                </Accordion.Item>
                                </Accordion>
                                <SignaturePDF 
                                    identifier={id} 
                                    title={measure.Title} 
                                    summary={measure.Summary} 
                                    fiscalImpact={measure.FiscalImpact}
                                    county={undefined}
                                    pages={pages}
                                    signaturesPerSheet={measure.SignaturesPerSheet}
                                    type={measure.Type}
                                    topics={measure.topics ? measure.topics.filter(topic => topic !== "") : undefined}
                                    codes={measure.Type === "CICA" ? "" : 
                                        measure.codes ? measure.codes.filter(code => code !== "")
                                        : undefined
                                    }
                                    fullTextFile={measure.fullTextFile}
                                    refreshCount={refreshCount}
                                />
                            </div>
                        }
                    </Col>
                </Row>
            )}
        </Container>
    );

}

export default EditMeasure;