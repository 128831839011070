/* Copyright (C) 2023 Christian Miley - All Rights Reserved */

/**
 * SharingLinks.js
 * This component will return the supported sharing links
 * Specify size, title, url, etc
 * now adding in second export: dropdown of sharing links
*/

import React, { useState, useContext, forwardRef } from 'react';
import { Toast/*, ToastContainer*/, Dropdown } from 'react-bootstrap';
import { 
    FacebookShareButton, 
    FacebookIcon,
    FacebookShareCount,
    RedditShareButton, 
    RedditIcon,
    RedditShareCount,
    TwitterShareButton,
    XIcon,
    LinkedinShareButton,
    LinkedinIcon
} from 'react-share';
import { ShareFat } from '@phosphor-icons/react';
import CopyLink from './CopyLink.js';
import { UserContext } from '../Context.js';
import './SharingLinks.css';
import { AddTooltip } from './Utility.js';

export function SharingDropdown(props) {
    //copying all the stuff from below
    const userContext = useContext(UserContext);
    const size = props.size;
    const measureID = props.measureID;
    const shareUrl = "https://www.rballot.org/measure/" + measureID + "/" + (userContext ? userContext.uid : "");//props.shareUrl;
    const shareTitle = props.shareTitle;
    let spacing = props.size/80 + 'em';
    if (props.spacing) spacing = props.spacing;
    let margin = "";
    if (props.margin) margin = props.margin;
    let round = true; //means round
    if (props.round !== undefined) round = props.round;

    //note: IconText class is defined in UserFeedPage.css
    return (
        <AddTooltip title="Share this measure" id={'share'+measureID}>
            <Dropdown drop="down-centered" style={{flexGrow: '1', display:'flex', justifyContent:'center'}}>
                <Dropdown.Toggle className="ShareButtonsDropdown ModernButton">
                    <ShareFat size={size} />
                    <span className="IconText" text=" Share" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item>
                        <FacebookShareButton 
                            url={shareUrl}
                            quote={shareTitle}
                        >
                            <FacebookIcon size={size} round={round} /> Facebook
                        </FacebookShareButton>
                    </Dropdown.Item>
                    <Dropdown.Item>
                    <TwitterShareButton
                        url={shareUrl}
                        title={shareTitle}
                    >
                        {<XIcon size={size} round={round} />} Twitter/X
                    </TwitterShareButton>
                    </Dropdown.Item>
                    <Dropdown.Item>
                        <RedditShareButton
                            url={shareUrl}
                            title={shareTitle}
                        >
                            <RedditIcon size={size} round={round} /> Reddit
                        </RedditShareButton>
                    </Dropdown.Item>
                    <Dropdown.Item>
                        <LinkedinShareButton
                            url={shareUrl}
                            title={shareTitle}
                            summary={shareTitle}
                            source={"www.rballot.org"}
                        >
                            <LinkedinIcon size={size} round={round} /> LinkedIn
                        </LinkedinShareButton>
                    </Dropdown.Item>
                    <Dropdown.Item>
                        <CopyLink 
                            className=""
                            link={"www.rballot.org/measure/" + measureID + "/" + (userContext ? userContext.uid : "")}
                            text={"Copy Link"}
                            setShowToast={(val) => console.log(val)/*setShowToast*/}
                            size={size}
                            round={round}
                        /> 
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </AddTooltip>
    )
}

export default function SharingLinks(props) {
    const userContext = useContext(UserContext);

    const size = props.size;
    const measureID = props.measureID;
    const shareUrl = "https://www.rballot.org/measure/" + measureID + "/" + (userContext ? userContext.uid : "");//props.shareUrl;
    const shareTitle = props.shareTitle;
    let spacing = props.size/80 + 'em';
    if (props.spacing) spacing = props.spacing;
    let margin = "";
    if (props.margin) margin = props.margin;
    let round = true; //means round
    if (props.round !== undefined) round = props.round;

    //toast stuff
    const [showToast, setShowToast] = useState(false);
    const toggleToast = () => setShowToast(!showToast);

    return(
        <div>
            <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly', alignItems: 'baseline', margin: margin, padding: ('0em '+ spacing)}}>
                <div style={{margin: spacing}}>
                    <FacebookShareButton 
                        url={shareUrl}
                        quote={shareTitle}
                    >
                        <FacebookIcon size={size} round={round} />
                    </FacebookShareButton>
                    <FacebookShareCount url={shareUrl}>

                    </FacebookShareCount>
                </div>

                <TwitterShareButton
                    url={shareUrl}
                    title={shareTitle}
                    style={{margin: spacing}}
                >
                    {<XIcon size={size} round={round} />}
                </TwitterShareButton>

                <div style={{margin: spacing}}>
                    <RedditShareButton
                        url={shareUrl}
                        title={shareTitle}
                    >
                        <RedditIcon size={size} round={round} />
                    </RedditShareButton>
                    <RedditShareCount url={shareUrl}>

                    </RedditShareCount>
                </div>

                <div style={{margin: spacing}}>
                    <LinkedinShareButton
                        url={shareUrl}
                        title={shareTitle}
                        summary={shareTitle}
                        source={"www.rballot.org"}
                    >
                        <LinkedinIcon size={size} round={round} />
                    </LinkedinShareButton>
                </div>

                <div style={{margin: spacing}}>
                    <CopyLink 
                        className=""
                        link={"www.rballot.org/measure/" + measureID + "/" + (userContext ? userContext.uid : "")}
                        //showToast={showToast}
                        setShowToast={setShowToast}
                        size={size}
                        round={round}
                    />
                </div>
            </div>
            {/*<ToastContainer position={'bottom-start'} style={{ zIndex: 1}}>*/}
                <Toast show={showToast} onClose={toggleToast} autohide>
                    {/*<Toast.Header>
                        <img
                        src="holder.js/20x20?text=%20"
                        className="rounded me-2"
                        alt=""
                        />
                        <strong className="me-auto">Bootstrap</strong>
                        <small>11 mins ago</small>
                    </Toast.Header>*/}
                    <Toast.Body>Copied link!</Toast.Body>
                </Toast>
            {/*</ToastContainer>*/}
        </div>
    );
}

//export default SharingLinks;