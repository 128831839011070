/* Copyright (C) 2023 Christian Miley - All Rights Reserved */

/**
 * PDFFunctions.js
 * This page will try to reorganize the PDFCirculator file as separate functions
 * that can be used to export different PDF URLs as needed. To start with,
 * the letter-sized pdfs.
 * 10/22/23: Currently, this is set up to only serve California Statewide initiatives.
 * The goal is to have it serve California county and city initiatives.
 * After researching, I find that general law county and city initiatives are basically
 * the same as state ones, with minor changes, while chater cities and counties can
 * differ much more. Those may want to be separate files.
 */

import React from 'react';
import boldSerifFont from '../PlayfairDisplayBold.ttf';
import boldSansSerifFont from '../SwanseaBold.ttf';
import { pdf, Document, Page, Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import { PDFDocument } from 'pdf-lib';
import { getSignatureCount, listArrayContents } from './Utility.js';

//example is used to highlight in yellow. Should usually be false.
//note: is there a way to make the argument option like in python?
function createSignaturePacketPortion(id, measure, county, pages, example) {
    Font.register({
        family:"boldSerifFont",
        fontWeight:"bold",
        src: boldSerifFont
    })

    Font.register({
        family:"boldSansSerifFont",
        fontWeight:"bold",
        src: boldSansSerifFont
    })

    const styles = StyleSheet.create({
        page: {
            backgroundColor: 'white',
            fontSize: '11pt',
            lineHeight: '1',
            marginBottom: '0.2in'
        },
        section: {
            margin: '0.2in 0.3in',
            padding: '1pt'
        },
        firstSignatureSection: {
            marginTop: '0.2in',
            marginHorizontal: '0.3in',
            marginBottom: '0in',
            padding: '1pt'
        },
        signatureSection: {
            margin: '0in 0.3in',
            padding: '1pt'
        },
        line: {
            margin: '3pt 1pt'
        },
        boldLine: {
            margin: '3pt 1pt',
            fontFamily: 'boldSansSerifFont',
            fontSize: '11'
        },
        signatureLine: {
            margin: '5pt 1pt',
            marginLeft: '4%'
        },
        description: {
            textAlign: 'center',
            fontSize: '9',
            color: 'gray',
            marginBottom: '2pt',
            marginTop: '4pt',
            position: 'relative'
        },
        highlight: {
            backgroundColor: 'yellow'
        },
        circulatorDeclaration: {
            margin: '0.2in 0.3in',
            padding: '1pt',
            position: 'absolute',
            left: '0',
            bottom: '0',
            paddingTop: '4pt',
            fontSize: '10',
            backgroundColor: "white",
            borderTop: '2px',
            borderColor: 'black',
            borderStyle: 'solid'
        },
        fullText: {
            fontSize: '8pt',
            margin: '0.1in'
        }
    });

    //circulator declaration right-aligned, will go at bottom of page
    const circulatorDeclarationRight = (
        <View style={styles.circulatorDeclaration} key={'circulatorDeclaration'}>
            <Text style={{textSize: '11'}}>
                <Text style={{fontFamily: 'boldSansSerifFont', border: '1px solid', padding: '1pt'}}>Declaration of Circulator: </Text>
                <Text> I am 18 years of age or older, and my name is 
                    <Text style={example ? styles.highlight : ""}> ________________________________________</Text>.
                </Text>
            </Text>
            <Text style={[styles.description, {left: '413px'}]}>
                (print name)
            </Text>
            <Text>
                My residence address is 
                <Text style={example ? styles.highlight : ""}> ________________________________________________________________________________</Text>.
            </Text>
            <Text style={[styles.description, {left: '297px'}]}>       
                (address, city, state)
            </Text>
            <Text style={{lineHeight: '1.5'}}>
                I circulated this section of the petition and witnessed each of the appended signatures being written.
                Each signature on this petition is, to the best of my information and belief, the genuine
                signature of the person whose name it purports to be. All signatures on this document were obtained between the dates of: 
            </Text>
            <Text style={{position: 'relative', left: '29vw'}}>
                <Text style={example ? styles.highlight : ""}> ________________________________</Text> and ________________________________.
            </Text>
            <Text style={[styles.description, {left: '37vw'}]}>
                (month/day/year)                                                     (month/day/year)
            </Text>
            <Text style={{lineHeight: '1.5'}}>
             I certify under penalty of perjury under the laws of the State of California
                that the foregoing is true and correct. Executed on: 
            </Text>
            <Text style={{marginLeft: '214pt'}}>
                <Text style={example ? styles.highlight : ""}> _____________________</Text>
                , at <Text style={example ? styles.highlight : ""}> _____________________________________</Text>.
            </Text>
            <Text style={[styles.description, {left: '240px'}]}>
            (month/day/year)                    (place of signing: for example, 'Los Angeles, CA')
            </Text>
            <Text style={[styles.line, {marginTop: '20pt'}]}>
                <Text style={example ? styles.highlight : ""}>_________________________________________</Text>
            </Text>
            <Text style={[styles.description, {left: '73px'}]}>
                (complete signature)
            </Text>
        </View>
    )

    //for different levels affected by general law in California
    let preparationSentence;
    if (measure.JurisdictionType === "State" && measure.JurisdictionName === "California") {
        preparationSentence = "The Attorney General of California has prepared the following circulating title and summary of the chief purposes and points of the proposed measure:";
    } else if (measure.JurisdictionType === "County") {
        preparationSentence = "The county counsel has prepared the following title and summary of the chief purpose and points of the proposed measure:";
    } else if (measure.JurisdictionType === "City") {
        preparationSentence = "The city attorney has prepared the following title and summary of the chief purpose and points of the proposed measure:";
    }

    //entire page, including declaration of circulator
    const introduction = (
        <View style={[styles.section, {fontSize: '11', marginTop: '1in', marginBottom: '0.5pt'}]} key={'introduction'}>
            <Text style={[styles.boldLine, {textAlign:'center', marginTop: '0in', marginBottom: '0.3in'}]}> {/* Or should it say the actual one? */}
                INITIATIVE MEASURE TO BE SUBMITTED DIRECTLY TO THE VOTERS
            </Text>
            <Text style={styles.boldLine}>
                {preparationSentence}
                {/*The Attorney General of California has prepared the following circulating title and summary
                of the chief purposes and points of the proposed measure:*/}
            </Text>
            <Text style={styles.boldLine}>
                {/*id*/}
            </Text>
            <Text style={styles.boldLine}>
                {id + ": " + measure.Title.toUpperCase() /**Is same line cool? Probly*/}
            </Text>
            <Text style={styles.line}>
                {measure.Summary}
                {measure.JurisdictionType === "State" ? "\nSummary of estimate by Legislative Analyst and Director of Finance of fiscal impact on state and local governments: " : ""}
            </Text>
            {measure.JurisdictionType !== "State" ? undefined :
                <Text style={[styles.boldLine, {marginTop: '0'}]}>
                    {measure.FiscalImpact}
                </Text>
            }
        </View>
    )

    //notice of intention, for county and city initiatives
    let noticeOfIntention;
    if (measure.JurisdictionType !== "State") {
        noticeOfIntention = (
            <View key="noticeOfIntention">
                <Text style={{textAlign: 'center'}}>
                    { /* For some reason suggested phrasing varies for county vs city */
                    measure.JurisdictionType === "County" ? 
                        "Notice of Intention to Circulate Petition" 
                        : 
                        "Notice of Intent to Circulate Petition"}
                </Text>
                <Text>
                    Notice is hereby given by the persons whose names appear hereon of their intention to circulate the petition within the
                    {measure.JurisdictionType === "County" ? " County of " : " City of "} {measure.JurisdictionName} for the purpose of
                    {measure.Purpose ? measure.Purpose : "_____________"}.
                    {!measure.Statement ? "" : 
                        ("A statement of the reasons of the proposed action as contemplated in the petition is as follows: " + measure.Statement)}
                </Text>
            </View>
        );
    }

    //topFunders, not ultimately that difficult to make. This will add in the top funders disclosure, if specified
    //includes: Month, CommitteeName, TopContributors, Endorsers
    let topFunders;
    if (measure.TopFunders) {
        topFunders = (
            <View key="TopFunders" style={{margin: '0px 20px 10px', padding: '0px 5px 5px', border: '1px solid black', fontFamily: 'Helvetica', fontSize: '10pt', textAlign: 'center'}}>
                <Text style={styles.boldLine}>OFFICIAL TOP FUNDERS. Valid only for {measure.TopFunders.Month? measure.TopFunders.Month : "________"}, 2023</Text>
                <Text>Petition paid for by {measure.TopFunders.CommitteeName ? measure.TopFunders.CommitteeName : "_______________"}</Text>
                {!measure.TopFunders.TopContributors ? undefined : 
                    <div>
                        <Text style={{textDecoration: 'underline', margin: '3pt'}}>Committee major funding from:</Text>
                        {measure.TopFunders.TopContributors.map((contributor) => { 
                            return (
                                <Text key={contributor} style={styles.boldLine}>{contributor}</Text>
                            )}
                        )}
                    </div>
                }
                {!measure.TopFunders.Endorsers ? undefined : 
                    <div>
                        <br />
                        <Text>Endorsed by:</Text>
                        {measure.TopFunders.Endorsers.map((endorser) => { 
                            return (
                                <Text key={endorser}>{endorser}</Text>
                            )}
                        )}
                        <br />
                    </div>
                }
                <Text style={{textDecoration: 'underline'}}>
                    Latest info: https://www.sos.ca.gov/elections/ballot-measures/initiative-and-referendum-status/official-top-funders
                </Text>
            </View>
        );
    }

    //should replace with an actual top funders box that they have to include to run the petition
    const noticeToPublic = (
        <View style={[styles.section, {marginTop: '-5px'}]} key={'topFunders'}>
            <Text style={[styles.boldLine, {textAlign:'center'}]}>
                NOTICE TO THE PUBLIC:
            </Text>
            {/*This may have been removed from the law
            <Text style={styles.boldLine}> {/**Tell user to look at top funders sheet in their acct */}
                {/*</View>YOU HAVE THE RIGHT TO SEE AN "OFFICIAL TOP FUNDERS" SHEET.
            </Text>*/}
            { !measure.TopFunders ? undefined :
                <Text style={styles.boldLine}>
                    SIGN ONLY IF IT IS THE SAME MONTH SHOWN IN THE OFFICIAL TOP FUNDERS OR
                    YOU SAW AN “OFFICIAL TOP FUNDERS” SHEET FOR THIS MONTH.
                </Text>
            }
            <Text style={styles.line}>
                THIS PETITION MAY BE CIRCULATED BY A PAID SIGNATURE GATHERER OR
                A VOLUNTEER. YOU HAVE THE RIGHT TO ASK.
            </Text>
            {measure.JurisdictionType !== "State" ? undefined :
                <Text style={styles.line}>
                    THE PROPONENTS OF THIS PROPOSED INITIATIVE MEASURE HAVE THE
                    RIGHT TO WITHDRAW THIS PETITION AT ANY TIME BEFORE THE
                    MEASURE QUALIFIES FOR THE BALLOT.
                </Text>
            }
            { measure.JurisdictionType !== "State" ? undefined :
                <Text style={{marginTop: '20pt', marginBottom: '-20pt'}}>
                    {"Circulated in: " + (county ? county : "______________________") + (county === "San Francisco" ? " City and County" : " County")}
                </Text>
            }
        </View>
    );

    /* Signature Box Below */
    const nextSignatureBox = (count) => {

        //how to adjust line length based on number
        //currently supporting more than 1000? Excessive but whatevs
        let extraLine = "___";
        if (count > 9) extraLine = "__";
        if (count > 99) extraLine = "_";

        //at end, leave space for the declaration of circulator
        //let minPresenceAhead = 0;
        //should equal to number of signatures at end since start with 1
        //if (count === max) {
        //    minPresenceAhead = 100;
        //}

        //First signature box settings
        let officialUseDisclaimer = (
            <Text style={{width: '1in', marginTop: '-30pt'}}>
                For Official Use Only
            </Text>
        )
        let styleType = styles.firstSignatureSection;

        //Settings for rest of them
        if (count > 1) {
            officialUseDisclaimer = <div></div>;
            styleType = styles.signatureSection;
        }

        return (
            <View 
                style={[styleType, {fontSize: '9', border:'1px'}]}
                key={count}
                wrap={false}
                //minPresenceAhead={minPresenceAhead}
            >
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <div style={{borderRight: '1px'}}>
                        <Text style={[styles.signatureLine]}>
                            {count}.  Print your name: <Text style={example ? styles.highlight : ""}> ________________________________{extraLine} </Text>
                            Signature: <Text style={example ? styles.highlight : ""}> _____________________________</Text>
                        </Text>
                        <Text style={[styles.signatureLine]}>
                            Residence Address ONLY: <Text style={example ? styles.highlight : ""}> ______________________________ </Text>
                            City: <Text style={example ? styles.highlight : ""}> ____________________ </Text>
                            Zip: <Text style={example ? styles.highlight : ""}> _________</Text>
                        </Text>
                    </div>
                    {officialUseDisclaimer}
                </div>
            </View>
        )
    }

    /** Full text of measure */
    let fullText;
    if (measure.JurisdictionType === "State") { 
        fullText = (
        <View>
            <Text style={{textAlign: 'center', margin: '10pt', marginTop: '10pt'}}>
                To the Honorable Secretary of State of California
            </Text>
            <Text style={{marginHorizontal: '0.3in', marginVertical: '10pt', lineHeight: '1.5'}}>
                We, the undersigned, registered, qualified voters of California, residents of 
                { county ? 
                    county === "San Francisco" ?
                        " San Francisco City and County" 
                        :
                        " " + county + " County"
                    :
                    " the County (or City and County) referenced on the signature page of this petition"},
                hereby propose amendments to the
                {measure.Type === "CICA" ? " Constitution of California" : ""}
                {measure.Type === "CISS" ? (" " + listArrayContents(measure.Codes)) : ""}
                {measure.Type === "CICA/SS" ? (" " + listArrayContents(["Constitution of California", ...measure.Codes])) : ""}
                {/*measure.Type === "CISS" || measure.Type === "CICA/SS" ? (" " + listArrayContents(measure.Codes)/* + " Code"*//*) : ""*/}
                {/*measure.Codes && measure.Codes.length > 1 ? "s" : ""*/}, relating to{" "}
                {measure.Topic && measure.Topic.length > 0 ? listArrayContents(measure.Topic) : "such matters"},
                and petition the Secretary of State to submit the same to the voters of California
                for their adoption or rejection at the next succeeding general election or at
                any special statewide election held prior to that general election or as
                otherwise provided by law. The proposed 
                {measure.Type === "CICA" || measure.Type === "CICA/SS" ? " constitutional " : " statutory "}
                {measure.Type === "CICA/SS" ? "and statutory " : ""}
                amendments read as follows:
            </Text>
            <Text style={styles.fullText}>
                {measure.FullText ? measure.FullText : ""}
            </Text>
        </View>
    )} else if (measure.JurisdictionType === "County") {
        <View>
            <Text>The people of the County of {measure.County ? measure.County : "_____________"} ordain as follows:</Text>
        </View>
    } else if (measure.JurisdictionType === "City") {
        <View>
            <Text>The people of the City of {measure.City ? measure.City : "______________"} do ordain as follows:</Text>
        </View>
    }

//=======================================Creating packets========================================

    //calculating signatures automatically


    //put it together into a page here
    const getCirculatingPacket = () => {
        const sections = [/*introduction, */topFunders, noticeToPublic]
        const signatures = getSignatureCount(pages, measure.SignaturesPerSheet);
        
        for (let i = 0; i < signatures; i++){
            //count starts at 1 instead of 0
            sections.push(nextSignatureBox(i+1));
        } 
        sections.push(circulatorDeclarationRight);

        const page = (
            <Document>
                <Page size="Letter" style={styles.page} wrap>
                    <View fixed>
                        {introduction}
                    </View>
                    {noticeOfIntention}
                    {sections}
                </Page>
                <Page size="Letter" style={styles.page} wrap>
                    {introduction}
                    {fullText}
                </Page>
            </Document>
        )
        return page;
    }

    return getCirculatingPacket();
}

async function createFullPacketUrl(id, measure, county, pages, fullTextFile, example) {
    const signaturePortion = createSignaturePacketPortion(id, measure, county, pages, example);

    const pdfObj = pdf(signaturePortion);
    //may need to update pdfObj.container.document manually here, as I remember that was an issue

    const signatureBlob = await pdfObj.toBlob();
    console.log("Got blob");
    //returns a promise that resolves to an array buffer
    //https://developer.mozilla.org/en-US/docs/Web/API/Blob/arrayBuffer#browser_compatibility
    const signatureArrayBuffer = await signatureBlob.arrayBuffer();
    console.log("Got buffer");

    //now we should be able to load this into PDF-Lib
    const fullPacketDoc = await PDFDocument.load(signatureArrayBuffer);

    //check that we've been fed a file (as opposed to just text)
    if (!measure.FullText && fullTextFile && fullTextFile !== "") {
        console.log(fullTextFile);
        //turn the File (which is a Blob polished up a bit) into an array buffer
        const fullTextBuffer = await fullTextFile.arrayBuffer();
        //now we can put it into pdf-lib
        const fullTextDoc = await PDFDocument.load(fullTextBuffer);
        const indices = fullTextDoc.getPageIndices();
        const copiedPages = await fullPacketDoc.copyPages(fullTextDoc, indices);
        for (let i = 0; i<indices.length; i++) {
            fullPacketDoc.addPage(copiedPages[i]);
        }
    } else if (measure.FullText) {
        console.log("Using Full Text rather than Full Text PDF");
        //do whatever for text (actually would probably do this in React-PDF solely)
    //otherwise, if no full text, don't render anything
    } else {
        return;
    }

    //then turn it back into bytes...
    const fullPacketBytes = await fullPacketDoc.save();
    //now we have to turn it into a blob...
    const finalBlob = new Blob( [fullPacketBytes], { type: "application/pdf" });
    //then turn that into a url for react-pdf (if we want to still use PDFViewer)
    const finalURL = URL.createObjectURL(finalBlob);

    return finalURL;
}

export { createFullPacketUrl };