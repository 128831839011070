/* Copyright (C) 2024 Christian Miley - All Rights Reserved */

/**
 * ServicesButtons
 * This will display buttons hide, discover/go to, and share a measure,
 * and to go directly to rBallotServices
 * requires in props: measure, 
 * @param {*} props 
 */

import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { setHide } from '../firebase.js';
import { PersonContext, UserContext } from '../Context.js';
import { AddTooltip } from './Utility.js';
import { 
    EnvelopeIcon, 
    PrinterIcon, 
    BookOpenIcon, 
} from '@heroicons/react/24/outline';
import { EyeSlash, Eye } from '@phosphor-icons/react';
import { SharingDropdown } from './SharingLinks.js';

//props.measure, props.personInfo, props.setPersonInfo (for hiding...)
export default function ServicesButtons(props) {

    const personContext = useContext(PersonContext);
    const userContext = useContext(UserContext);

    // this came from off react-bootstrap, but seems key
    const TooltipLink = ({ id, to, title, className, children }) => (
        <OverlayTrigger overlay={<Tooltip id={id}>{title}</Tooltip>}>
          <Link to={to} className={className}>{children}</Link>
        </OverlayTrigger>
    );

    // any way to make it use a specified component, not just link?
    // could be AddTooltip, and take in a component with style and to already applied? hmm
    // ohhh children is pulling in the icons! and would pull in whatever text nested underneath. Cool!
    /*const AddTooltip = ({ id, title, children }) => (
        <OverlayTrigger overlay={<Tooltip id={id}>{title}</Tooltip>}>
          {children}
        </OverlayTrigger>
    );*/

    const measure = props.measure;
    if (!measure) return;
    // is this measure already being hidden?
    // if no hiddenMeasures array exists, it must not be hidden.
    const hideBool = personContext.hiddenMeasures ? personContext.hiddenMeasures.includes(measure.id) : false;
    
    //this will be used to hide if it is a guest, so it just does it locally
    const setPersonInfo = props.setPersonInfo;
    //so, this should be the same as personContext. Not sure which makes more sense to use,
    //or if this is even needed at all...
    const personInfo = props.personInfo;
    // maybe transition to 'Id' instead of 'ID' since it is standard outside of here
    function setLocalHide(measureId, setting, setPersonInfo, personInfo) {
        // what does spread operator do to undefined?
        let newHiddenMeasures = [ ...personInfo.hiddenMeasures ];
        // if setting to hide
        if (setting) {
            newHiddenMeasures.push(measureId);
        // otherwise, if unhiding, filter out the measure from hide list
        } else {
            newHiddenMeasures = newHiddenMeasures.filter(element => element !== measureId);
        }
        // directly set person info without listening to database
        setPersonInfo({
            ...personInfo,
            hiddenMeasures: [ ...newHiddenMeasures ]
        });
    }
    /**
     * These buttons currently display based on the measure's context, not the user's. This issue was why
     * I had the complicated system going on below, but another way to do so would to try to make every component
     * react to the user's state... like, having the print and mail pages be able to account for a guest visiting them.
     */
    return(
        <div className="ServicesButtons">
            <AddTooltip title={(hideBool ? 'Unhide' : 'Hide') + " this measure"} id={(hideBool ? 'Unhide' : 'Hide')+measure.id}>
                <button
                    className="ModernButton"
                    onClick={() => { 
                        //if the user is a not a guest, set hide value in database.
                        //Otherwise, if they are a guest, set the value locally.
                        userContext && userContext !== "Not loaded" ?
                            setHide(measure.id, userContext.uid, !hideBool) 
                            :
                            setLocalHide(measure.id, !hideBool, setPersonInfo, personInfo) 
                    }}
                >
                    {/*<XMarkIcon style={{height: '24px', width: '24px'}} /><span className="IconText" text={hideBool ? ' Unhide' : ' Hide'} />*/}
                    { hideBool ?
                        <span><Eye size={24} /><span className="IconText" text={hideBool ? ' Unhide' : ' Hide'} /></span>
                    :
                        <span><EyeSlash size={24} /><span className="IconText" text={hideBool ? ' Unhide' : ' Hide'} /></span>
                    }
                </button>
            </AddTooltip>
            <TooltipLink
                title="Discover more information"
                id={'discover'+measure.id}
                className="ModernButton"
                to={"/measure/"+measure.id}
            >
                <BookOpenIcon style={{height: '24px', width: '24px'}} /><span className="IconText" text=" Discover" />
            </TooltipLink>
            {/*<p style={{fontSize: '4em'}}>|</p>*/}
            { measure.rBallotServices && measure.rBallotServices.includes("downloads") ?
                <TooltipLink 
                    title="Print a petition"
                    id={'print'+measure.id}
                    className="ModernButton"
                    to={"/measure/"+measure.id+"/petitions"}
                >
                    <PrinterIcon style={{height: '24px', width: '24px'}}/><span className="IconText" text=" Print" />
                </TooltipLink>
                :
                ""
                /*<AddTooltip title="Print a petition unavailable" id={'printUnavailable'+measure.id}>
                    <button
                        title="Print a petition unavailable"
                        style={{padding: '0.4em', background: 'gray', color: 'lightgray', borderRadius: '10px', border: '0px', margin: '0.25em'}}
                        disabled
                    >
                        <PrinterIcon style={{height: '26px', width: '32px'}}/>
                    </button>
                </AddTooltip>*/
            }
            { measure.rBallotServices && measure.rBallotServices.includes("mailing") ?
                <TooltipLink 
                    title="Mail me a petition"
                    id={'mail'+measure.id}
                    className="ModernButton"
                    to={"/addresssignup/"+measure.id}
                >
                    <EnvelopeIcon style={{height: '24px', width: '24px'}}/><span className="IconText" text=" Mail" />
                </TooltipLink>
                :
                ""
                /*<AddTooltip title="Mail this petition unavailable" id={'mailUnavailable'+measure.id}>
                    <button
                        title="Mail me a petition to sign unavailable"
                        style={{padding: '0.4em', background: 'gray', color: 'lightgray', borderRadius: '10px', border: '0px', margin: '0.25em'}}
                        disabled
                    >
                        <EnvelopeIcon style={{height: '26px', width: '32px'}}/>
                    </button>
                </AddTooltip>*/
            }
            {/*<TooltipLink
                title="Share this measure"
                id={'share'+measure.id}
                style={{padding: '0.4em', background: 'blue', color: 'white', borderRadius: '10px', border: '0px', margin: '0.25em', textDecoration: 'none'}}
                to={"/measure/"+measure.id+"/share"}
            >
                <span className="IconText" text="Share "/><ShareFat size={24} />
            </TooltipLink>*/}
            <SharingDropdown 
                size={24} 
                measureID={measure.id} 
                shareTitle={measure.rBallotTitle ? ("Sign the " + measure.rBallotTitle + " with me!") : ("Sign the " + measure.BallotpediaTitle + " with me!")} 
                round={false}
            />

            {/*<AddTooltip title="Like this measure" id={"Like"+measure.id}>
                <button
                    style={{padding: '0.4em', background: 'green', color: 'white', borderRadius: '10px', border: '0px', margin: '0.25em', textDecoration: 'none'}}
                >
                    <HandThumbUpIcon style={{height: '24px', width: '24px'}}/>
                </button>
            </AddTooltip>
            <AddTooltip title="Dislike this measure" id={"Like"+measure.id}>
                <button
                    style={{padding: '0.4em', background: 'red', color: 'white', borderRadius: '10px', border: '0px', margin: '0.25em', textDecoration: 'none'}}
                >
                    <HandThumbDownIcon style={{height: '24px', width: '24px'}}/>
                </button>
            </AddTooltip>*/}
        </div>
    );
}