/* Copyright (C) 2024 Christian Miley - All Rights Reserved */

/**
 * Buttons.js
 * This component will export different buttons to be used
 * throughout the application. Starting with a 'sign' button, 
 * and eventually will probably fold SharingLinks.js, CopyLink.js,
 * and LikesDislikes.js into here.
 */

import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../Context.js';
import { AddTooltip } from './Utility.js';
import { Pen } from '@phosphor-icons/react';

//this component will direct the user to the correct location for signing.
//for now, it just directs to SignMeasure page, but perhaps it could also
//directly take the user to the mail/print page if it is the only one available.
//However, I think that having the signmeasure page present a brief explanation of
//what is going on could be useful, even if there is only one option.
//props: includes measure and potentially referrerID
export function SignButton(props) {
    const navigate = useNavigate();
    const userContext = useContext(UserContext);
    const measure = props.measure || {};
    const referrerId = props.referrerId || undefined;

    return (
        <AddTooltip id={"sign"+measure.id} title="Sign Measure">
            <button
                className="ModernButton"
                onClick={() => {
                    //does this make sense? If not referredID OR there is a userContext?
                    if (!referrerId || userContext) navigate("/measure/"+ measure.id + "/sign");
                    //if a referredId is in the params, user clicked a link from another user
                    if (referrerId) navigate("/emailsignup/referredby/"+referrerId+"/"+measure.id);
                }}
            >
                <Pen size={24}/> Sign
            </button>
        </AddTooltip>
    );
}

