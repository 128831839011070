/* Copyright (C) 2023 Christian Miley - All Rights Reserved */

/**
 * CycleComments.js
 * This component will be used on ModularMeasurePage to display a cycling list of comments
 * on the measure. By putting it here, this will make it so the entire page doesn't rerender.
 * Also, can include a modal to view all comments. How do modals do on phones?
 */

import React, { useState, useEffect, useContext } from 'react';
import { /*useParams, useNavigate, */Link } from 'react-router-dom';
import { getVoteInfo } from '../firebase.js';
//import Follow from '../Follow.js';
import { UserContext } from '../Context.js';
import { datetimeFromTimestamp } from './Utility.js';
import ReactionsComments from './ReactionsComments.js';
import { CaretLeft, CaretRight } from '@phosphor-icons/react';

export default function CycleComments(props) {
    const measure = props.measure;
    const userContext = useContext(UserContext);

    //-------------create a function to fetch new comment data---------------------------
    const [previewVotes, setPreviewVotes] = useState({
        ordering: [],
        index: -1
    });
    const [pauseScroll, setPauseScroll] = useState(false);

    useEffect(() => {
        //if (!getNewVote) return
        if (!measure || pauseScroll) return;

        const getNewVote = async() => {
            if (!measure) return;
            console.log("Running get new vote");
            console.log(previewVotes);
            //console.log(previewVotesOrdering);
            //console.log(previewVotesIndex);
            // get all the likes and dislikes
            const measureVotes = {
                ...Object.fromEntries(Object.entries(measure.Likes || {}).map(([key, value]) => {
                    return(
                        [ key, { ...value, 'type': 'like' } ]
                    );
                })),
                ...Object.fromEntries(Object.entries(measure.Dislikes || {}).map(([key, value]) => {
                    return(
                        [ key, { ...value, 'type': 'dislike' } ]
                    );
                })),
            };
            const votesKeysArray= Object.keys(measureVotes);
        
            // if there are no votes to grab, just return. Maybe clear interval even?
            if (votesKeysArray.length === 0) return;
            
            // do not fetch if we have already displayed all comments
            // in this case, just start looping back through using modulus
            if (votesKeysArray.length === previewVotes.ordering.length) {
                console.log("Already fetched all comment data, just switching the comment.");
                //setPreviewVotesIndex(previewVotesIndex+1 % previewVotesOrdering.length);
                setPreviewVotes({
                    ...previewVotes,
                    index: (previewVotes.index+1) % previewVotes.ordering.length
                })
                return;
            }
            // do not fetch a new comment if the user has focused a particular comment
            // also must specify that previewVotesOrdering cannot be 0 (if it is supposed to be 0 since
            // there are 0 votes, the first if statement a few lines above should handle that)
            // also note that this will stop working once all comments are downloaded, as the previous
            // if statement will come into play
            if (previewVotes.index !== previewVotes.ordering.length-1 && previewVotes.ordering.length !== 0) return;
        
            let randomKey = false;
            // fetch a random key, repeating if it is already present in the comments being previewed
            while (!randomKey || previewVotes.ordering.includes(randomKey)) {
                const randomIndex = Math.floor(Math.random() * votesKeysArray.length);
                randomKey = votesKeysArray[randomIndex];
            }
        
            //finally, if there are more votes to display, and the user is not looking at another vote
            try {
                // get the reactions and replies for that vote
                const voteInfo = await getVoteInfo(measure.id, randomKey);
                const newPreviewVote = {
                    ...measureVotes[randomKey],
                    ...voteInfo
                }
                setPreviewVotes({
                    ...previewVotes,
                    [randomKey]: newPreviewVote,
                    ordering: [...previewVotes.ordering, randomKey],
                    index: previewVotes.index+1
                });
                //setPreviewVotesOrdering([...previewVotesOrdering, randomKey]);
                //setPreviewVotesIndex(previewVotesIndex+1);
            } catch(error) {
                console.error(error);
            }
        }

        let timeoutId;
        if (previewVotes.index === -1) {
            getNewVote();
        } else {
            timeoutId = setTimeout(getNewVote, 10000);
        }

        // on load, fetch the first vote
        //getNewVote();
        // then fetch a new comment every 10 seconds (10000 milliseconds)
        /*if (previewVotesIndex === -1) {
            getNewVote();
        } else {
            setTimeout(getNewVote, 10000);
        }*/
        //const intervalId = setInterval(getNewVote, 10000);

        return () => clearTimeout(timeoutId);
        /*return () => {
            console.log("clearing interval...");
            clearInterval(intervalId);
        }*/
    //  Line 122:7:   The 'getNewVote' function makes the dependencies of useEffect Hook (at line 212)
    // change on every render. Move it inside the useEffect callback.
    // Alternatively, wrap the definition of 'getNewVote' in its own useCallback() Hook  react-hooks/exhaustive-deps
    }, [previewVotes, measure, pauseScroll]);

    return (
        <div style={{overflow:'hidden', position:'relative', maxWidth:'90%'}}>
        <div style={{transform: `translateX(-${previewVotes.index * 100}%)`, transition: 'transform 0.5s ease', display:'flex'}}>
            {!previewVotes || previewVotes.index === -1 || !previewVotes[previewVotes.ordering[previewVotes.index]] ? "" :
            previewVotes.ordering/*.slice(previewVotes.index, previewVotes.index+1)*/.map((authorId) => {
                const vote = previewVotes[authorId];
                if (!vote) return "";
                return (
                    <div /*className="Propbox"*/ style={{borderTop: '1px solid #ccc', flex:'0 0 100%'}} key={authorId}>
                        <div style={{display:'flex', justifyContent:'center', paddingTop:'0.5em'}}>
                            <button 
                                style={{backgroundColor:'transparent', border:'0px'}}
                                onClick={() => setPreviewVotes({ 
                                    ...previewVotes, 
                                    index: previewVotes.index === 0 ? 
                                        previewVotes.ordering.length-1 
                                        : 
                                        previewVotes.index-1
                                })}
                                //disabled={previewVotes.index === 0 ? true : false}
                            >
                                <CaretLeft size={20} weight="fill" />
                            </button>
                            <span>
                                <Link to={'/user/'+authorId}>
                                    <strong>{vote.name}</strong>
                                </Link> 
                                {' is voting '}<strong>{vote.type === "like" ? "yes" : "no"}</strong>:
                            </span>
                            <button 
                                style={{backgroundColor:'transparent', border:'0px'}}
                                onClick={() => setPreviewVotes({ ...previewVotes, index: (previewVotes.index+1)%previewVotes.ordering.length})}
                            >
                                <CaretRight size={20} weight="fill" />
                            </button>
                        </div>
                        <span style={{fontSize: '0.8em', color: 'gray', fontStyle: 'italic'}}>
                            { vote.timestamp ? datetimeFromTimestamp(vote.timestamp) : ""}
                        </span>
                        <br />
                        <span>"{vote.comment}"</span>
                        <br />
                        <div onClick={() => { setPauseScroll(true); }}>
                            <ReactionsComments 
                                measureId={measure.id} 
                                authorId={authorId} 
                                vote={vote}
                                //note: previously it was [measureId]. Will this break things? hmm...
                                //I don't think so, because that was due to a user's comments being organized by measureId
                                setVotes={(newVote) => setPreviewVotes({ ...previewVotes, [authorId]: { ...newVote } })}
                                userReaction={
                                    !userContext ? "" :
                                    'Likes' in vote && vote.Likes.includes(userContext.uid) ?
                                    "Like" :
                                    vote.Loves && vote.Loves.includes(userContext.uid) ?
                                    "Love" :
                                    vote.Insights && vote.Insights.includes(userContext.uid) ?
                                    "Insight" :
                                    vote.Questions && vote.Questions.includes(userContext.uid) ?
                                    "Question" :
                                    ""
                                }
                            />
                        </div>
                    </div>
                );
            })
            }
        </div>
        </div>
    );

}