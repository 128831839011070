/* Copyright (C) 2023 Christian Miley - All Rights Reserved */

/** EmailSignUp.js
 * This component will provide the initial sign up
 * process for rBallot, where users will input their
 * email, password, nickname (username?),
 * county, state, and city
 * note: switching from name to nickname; will ask for
 * full name in circulator sign up
 * second note: this is really a page, not component at this point
 */

import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams, redirect } from 'react-router-dom';
import { Form, Row, Button, Container } from 'react-bootstrap';
import './Entry.css';
import Loading from './Loading.js';
import { createNewFirebaseUser } from '../firebase.js';
import {countiesByState} from './Information.js';

//props.setPersonInfo allows us to set the personInfo directly upon account creation
function EmailSignUp(props)
{
   const navigate = useNavigate();
   //const { id/*, title, summary, fiscalImpact*/ } = useParams();
   const { redirectType, id, paramState } = useParams(); // this is cursed.

   //const [firstName, setFirstName] = useState("");
   //const [firstNameErr, setFirstNameErr] = useState("");
   const [city, setCity] = useState("");
   const [state, setState] = useState("");
   const [county, setCounty] = useState("");

   //or should these not be saved in state? It seems the form must have some inner state
   //anyway in order for it to work, so don't think that would be any safer than this.
   const [nickname, setNickname] = useState("");
   const [email, setEmail] = useState("");
   //const [emailErr, setEmailErr] = useState("");
   const [password, setPassword] = useState("");
   //const [passwordErr, setPasswordErr] = useState("");
   const [confirmPassword, setConfirmPassword] = useState("");

   const [errorMessage, setErrorMessage] = useState("");

   //terms
   const [terms, setTerms] = useState(undefined);
   useEffect(() => {
      if (!props || !props.terms) return;
      setTerms(props.terms);
   }, [props]);
   const [termsAgreement, setTermsAgreement] = useState(false);

   //function to actually sign the user up using firebase.auth,
   //and to create the user and user_private documents
   async function SignUp()
   {
      if (!termsAgreement || !terms) {
         setErrorMessage("Error agreeing to terms and conditions. Try again momentarily.");
         return;
      }

      //this redirectType link is passed through to the email verifiation function, so that when the
      //user completes email verification, they will be redirectTypeed to this place on the site
      //referredBy will tell the create new firebase user function to do upline/downline stuff
      let redirectTypeLink = undefined;
      let referredBy = undefined;
      if (redirectType) {
         if (redirectType.toLowerCase() === "proponentverification") {
            redirectTypeLink = "measure/" + id + "/edit";
         } else if (redirectType.toLowerCase() === "circulatorverification") {
            redirectTypeLink = "measure/" + id + "/organization";
         } else if (redirectType.toLowerCase() === "referredby") {
            referredBy = id;
         //do I even want this?
         } else if (redirectType.toLowerCase() === "signmeasure") {
            redirectTypeLink = "measure/" + id + "/sign";
         }
      }

      const user = await createNewFirebaseUser(nickname, state, county, city, email, password, terms.id, referredBy, redirectTypeLink)
         .catch((error) => {
            console.error(error);
            setErrorMessage(error.message);
            throw new Error(error);
         });
      //manually set the personContext
      //ruh roh
      props.setPersonInfo({
         Nickname: nickname,
         City: city,
         County: county,
         State: state
      });

      //redirectType to user's page on successful user creation
      console.log(redirectType);
      console.log(user.uid);
      //return redirect("/user/" + user.uid);
      navigate("../user/" + user.uid, { replace: true });
      //navigate(0);
      /*if (!redirectType) { 
         return redirect("/user/" + user.uid);
      //or, if we got here by trying to verify for a specific petition, go back to it
      } else if (redirectType.toLowerCase() === "proponentverification" || redirectType.toLowerCase() === "circulatorverification") {
         return redirect('/' + redirectType + '/' + id + '/' + paramState); 
      //otherwise, if we were referred, see if there was a measure
      } else if (redirectType.toLowerCase() === "referredby" && paramState) { //here, using paramState for measureID... weird
         return redirect('/measure/' + paramState + '/sign');
      //this will redirectType back to sign measure page if came from there
      } else if (redirectType.toLowerCase() === "signmeasure" && id) {
         return redirect('/measure/' + id + '/sign');
      }*/
   }

   //this will emulate the object that we will pass through a separate file
   const countyOptions = {};
   countyOptions["California"] = (
      <Form.Control 
         required
         as="select" 
         value={county} 
         onChange={(e) => setCounty(e.target.value)}
      >
         <option value="" key={"default"}>Select county</option>
         {countiesByState["California"].map((county) => <option key={county}>{county}</option>)}
      </Form.Control>
   );

   countyOptions["Oregon"] = (
      <Form.Control 
         required
         as="select" 
         value={county} 
         onChange={(e) => setCounty(e.target.value)}
      >
         <option name="" key={"default"}>Select county</option>
         {countiesByState["Oregon"].map((county) => <option key={county}>{county}</option>)}
      </Form.Control>
   );

  //bug note: email and password text inputs result in:
  //"Warning: A component is changing an uncontrolled input of type text to be controlled."
  //Must rectify. 
  
   const [submitting, setSubmitting] = useState(false);
   const [validated, setValidated] = useState(false);

   return (
      <Container className="SignUpContainer">
         <div className="Background2"></div>
         {/*<h5 className="SignUpHeader">rBallot</h5>*/}

         <div style={{borderRadius: '10px', backgroundColor: 'white', marginTop: '5em'}}>
            <div className = "Guidelines">
               <p>Welcome to rBallot! Let's bring the power to the people.
               </p>
               { redirectType && redirectType.toLowerCase() !== "referredby" && redirectType.toLowerCase() !== "signmeasure" ? "" :
                  <p>
                     We need you to confirm your email to stay in contact, and to provide the
                     county and state you live in so that we can show you ballot measures relevant
                     to you.
                  </p>
               }
               {!redirectType ? "" :
                  redirectType.toLowerCase() === "proponentverification" ? 
                     <div>
                        <strong>Proponents: </strong>
                        <p>Before setting up measure {id}, you must create an rBallot account.</p>
                        <p>
                           To verify yourself as a proponent, you must verify your possession of either the phone number
                           or email address listed in your voter registration. If you plan to use your voter registration email,
                           make sure to sign up with that email address.
                        </p>
                     </div>
                     :
                  redirectType.toLowerCase() === "circulatorverification" ? 
                     <p>
                        After creating your rBallot account, you will be redirectTypeed to volunteer for measure {id}.
                     </p>
                     :
                  redirectType.toLowerCase() === "signmeasure" ?
                     <p>
                        After creating your rBallot account, you will be redirectTypeed to sign measure {id}.
                     </p>
                     :
                  redirectType.toLowerCase() === "referredby" ?
                     <p>
                        After creating your rBallot account, you will be redirectTypeed to sign measure {paramState}.
                     </p>
                     :
                     ""
               }
            <hr/>
            </div>

            <Form 
               noValidate
               validated={validated}
               className="SignUpForm" 
               onSubmit = {async (e) => { 
                  e.preventDefault();
                  const form = e.currentTarget;
                  if (form.checkValidity() === false) {
                     e.stopPropagation();
                     setValidated(true);
                     return;
                  } 
                  setSubmitting(true);
                  await SignUp()
                     .catch((error) => {
                        //console.error(error);
                        setErrorMessage(error.message);
                        setSubmitting(false);
                     });
                  //return redirect('/');
               }}
            >
            <Container style={{maxWidth: '500px'}}>
            <Row>
            <Form.Group className="SignUpRowItem">
               <Form.Label>Nickname <span className="Required">*</span></Form.Label>
               <Form.Control 
                  required
                  type = "text" 
                  placeholder = "Enter nickname" 
                  name = "nickname" 
                  value={nickname} 
                  onChange={(e) => setNickname(e.target.value)}
               />
               <Form.Control.Feedback type="invalid">
                  Please choose a nickname for your account.
               </Form.Control.Feedback>
            </Form.Group>
            </Row>
            <Row>
            <Form.Group className="SignUpRowItem" controlId="formBasicCity">
               <Form.Label>City</Form.Label>
               <Form.Control type = "text" placeholder = "Enter your city" name="city" value={city} onChange={(e) => setCity(e.target.value)}/>
            </Form.Group>
            </Row>
            <Row>
            <Form.Group className="SignUpRowItem" controlId="formBasicState">
               <Form.Label>State <span className="Required">*</span></Form.Label>
               <Form.Control 
                  required
                  as="select" 
                  value={state} 
                  onChange={(e) => setState(e.target.value)}
               >
                  <option value="" key="select">Choose state</option>
                  <option key="CA">California</option>
                  <option key="OR">Oregon</option>
               </Form.Control>
               <Form.Control.Feedback type="invalid">
                  Please choose the state you are registered to vote.
               </Form.Control.Feedback>
            </Form.Group>
            </Row>
            <Row>
            <Form.Group  className="SignUpRowItem" controlId="formBasicCounty">
               <Form.Label>County <span className="Required">*</span></Form.Label>
               {(state && state !== "Choose state" && state !== "") ?
                  countyOptions[state] : 
                  (
                  <Form.Control as="select" disabled>
                     <option>Pick a State first</option>
                  </Form.Control>
                  )}
            </Form.Group>
            </Row>
            <Row>
            <Form.Group className="SignUpRowItem" controlId="formBasicEmail">
               <Form.Label>Email <span className="Required">*</span></Form.Label>
               <Form.Control 
                  required
                  type = "text" 
                  placeholder = "Enter email" 
                  name="email" 
                  value={email} 
                  onChange={(e) => setEmail(e.target.value)}
               />
               <Form.Control.Feedback type="invalid">
                  Please input your email.
               </Form.Control.Feedback>
            </Form.Group>
            </Row>
            <Row>
            <Form.Group className="SignUpRowItem" controlId="formBasicPassword">
               <Form.Label>Password <span className="Required">*</span></Form.Label>
               <Form.Control 
                  required
                  type = "password" 
                  placeholder = "Enter password" 
                  name="password" 
                  value={password} 
                  onChange={(e) => setPassword(e.target.value)}
               />
               <Form.Control.Feedback type="invalid">
                  Please choose a password.
               </Form.Control.Feedback>
            </Form.Group>
            </Row>
            <Row>
            <Form.Group className="SignUpRowItem" controlId="formBasicConfirmPassword">
               <Form.Label>Confirm Password <span className="Required">*</span></Form.Label>
               <Form.Control 
                  required
                  type = "password" 
                  placeholder = "Confirm password" 
                  name="confirmPassword" 
                  value={confirmPassword} 
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  pattern={'^' + password + '$'}
               />
               <Form.Control.Feedback type="invalid">
                  Passwords must match.
               </Form.Control.Feedback>
            </Form.Group>
            </Row>
            <Row style={{textAlign: 'center'}}>
               <Form.Text>
                  <span className="Required">*required</span>
               </Form.Text>
            </Row>

            <Row className="SignUpRow">
               <Form.Group style={{display: 'flex', justifyContent: 'center', color: 'gray', fontSize: '0.8em', marginTop: '1em'}}>
                  <Form.Label>
                     Check to agree to the {" "}
                     <Link to="/terms" target="_blank">
                        Terms and Conditions and Privacy Policy.
                     </Link>
                  </Form.Label>
                  <Form.Check 
                     required
                     name = "termsAgreement" 
                     value={termsAgreement} 
                     onChange={(e) => setTermsAgreement(!termsAgreement)}
                     label={"I have read and agree to the Terms and Conditions and Privacy Policy."}
                     feedback="You must agree before submitting."
                     feedbackType="invalid"
                  />
               </Form.Group>
            </Row>

            <Row>
               <Form.Text className="ErrorMessage">{errorMessage}</Form.Text>
            </Row>
            <Row>
            {/*submitting ? 
               <Loading /> 
               :*/
               <Button className="SignUpSubmit" variant = 'primary' type = 'submit'>
                  Sign Up
               </Button>
            }
            </Row>

            <Row>
               <Form.Text style={{color: 'gray', fontSize: '0.8em', marginTop: '1em'}}>
                  By providing us with your information and clicking sign up, you agree to
                  receive email correspondence from rBallot to the email address provided.
                  Your county, state, and city, if provided, will be associated on rBallot
                  with the nickname and account you are creating.
               </Form.Text>
            </Row>

            </Container>
            </Form>
         </div>

      </Container>

    );

}

export default EmailSignUp;
