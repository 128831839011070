/* Copyright (C) 2023 Christian Miley - All Rights Reserved */

/**
 * OrganizationPage.js
 * This page will render the organization-side interfaces
 * for ballot measure circulators, proponents, etc.
 */

import React, { useState, useEffect, useContext } from 'react';
import { useParams, Link } from "react-router-dom";
import { Container, Form, Row, Col, Button, ListGroup } from 'react-bootstrap';
import { getMeasure, getFullTextFile } from '../../firebase.js';
import { UserContext } from '../../Context.js';
import { countiesByState } from '../Information.js';
import { getSignatureCount } from '../Utility.js';
import { createFullPacketUrl } from '../PDFFunctions.js';
import UnverifiedEmail from '../UnverifiedEmail.js';
import Loading from '../Loading.js';
/*import RichDraftEditor from '../RichDraftEditor.js';
import ReadOnlyDraftEditor from '../ReadOnlyDraftEditor.js';*/
import './Campaign.css';

//propositionsObj and setPropositionsObj
function OrganizationPage(props) {

    const userContext = useContext(UserContext);
    const { id } = useParams();

//-------------Effect to grab the measure information from db-------------------
    //an object to hold the information from the database on measure id
    const [measure, setMeasure] = useState(props.propositionsObj ? props.propositionsObj[id] : undefined);
    const [fullTextFile, setFullTextFile] = useState(props.propositionsObj && props.propositionsObj[id] ? props.propositionsObj[id]['FullTextFile'] : undefined);

    //set up a listener on the petition that will update when anything changes
    //note: should be done with suspense in newer react
    /*useEffect(() => {    
        const propRef = db.collection("petitions").doc(id);

        const unsubscribe = propRef.onSnapshot(function(doc){
            if (doc.exists) {
                setMeasure(doc.data());
                console.log("Snapshotted petition " + id + "'s document.");
            } else {
                setMeasure("Does not exist.");
                console.log("Measure not found: " + id);
            }
        })

        const grabFile = async () => {
            try { 
                const file = await getFullTextFile(id, "letter"); 
                setFullTextFile(file);
                return;
            } catch (error) {
                console.error(error);
                setFullTextFile("No such file.");
                return;
            }
        }

        grabFile();

        return function cleanUp() {
            unsubscribe();
        }

    }, [id]);*/

    //now again but using propositionsObj
    // when refreshing, this is leading to a pretty ridiculous amount of reads.
    // I think because of all these async calls not waiting for one another...
    // Should grab measure and file in one when grabbing measure!
    useEffect(() => {
        if (!id || !props.propositionsObj || !props.propositionsObj.ordering || !props.setPropositionsObj) return;

        const grabFile = async () => {
            try { 
                const file = await getFullTextFile(id, "letter"); 
                //setFullTextFile(file);
                const newPropsObj = { ...props.propositionsObj };
                newPropsObj[id].FullTextFile = file;
                props.setPropositionsObj(newPropsObj);
                return;
            } catch (error) {
                console.error(error);
                setFullTextFile("No such file.");
                return;
            }
        }

        const grabMeasureAndFile = async () => {
            try {
                const measureDownload = await getMeasure(id);
                const file = await getFullTextFile(id, "letter");
                const newPropsObj = { ...props.propositionsObj };
                newPropsObj[id] = measureDownload;
                newPropsObj[id].FullTextFile = file;
                props.setPropositionsObj(newPropsObj);
                return;
            } catch (error) {
                console.error(error);
                setMeasure("Does not exist."); //though I suppose it could be the file too now...
                return;
            }
        }

        if (props.propositionsObj[id]) {
            setMeasure(props.propositionsObj[id]);
        } else {
            grabMeasureAndFile();
            return; // we don't want to do the file yet
        }

        if ('FullTextFile' in props.propositionsObj[id]) {
            setFullTextFile(props.propositionsObj[id].FullTextFile);
        } else {
            grabFile();
        }

    }, [id, props, props.propositionsObj, props.setPropositionsObj]);


//--------------------------User Access effect-----------------------------
    //below will make the appropriate value from userContext
    //accessible more legibly. This should not compromise integrity
    //as a nefarious actor can already change their local userContext
    //We actually want to do this in App.js 
    //note: is this just the permissions object?
    const [userAccess, setUserAccess] = useState(undefined);
    useEffect(() => {
        if (userContext === "Not loaded") { return; }
        else if (userContext === undefined) { setUserAccess("Guest"); }
        else if (userContext.permissions) {
            //in custom claims, roles are abbreviated to save space.
            // This will help expand them for ease and clarity of us.
            const roles = {
                c: "Circulator",
                p: "Proponent",
                m: "Manager",
            };

            let personRoles = [];
            //assign the appropriate full string length role to the current viewer's array,
            //which will be able to handle multiple access levels
            for (const [key, value] of Object.entries(roles)) {
                if (userContext.permissions[key] && userContext.permissions[key].indexOf(id) !== -1) {
                    personRoles.push(value);
                }
            }
            setUserAccess(personRoles);
        }
    }, [userContext, id]);

//------------------------Circulator Packet-------------------------------
    const [county, setCounty] = useState("");
    const [pages, setPages] = useState(2);
    //this will keep track of what the user has generated
    const [packets, setPackets] = useState([]);
    //since generating can take a long time, show that to user
    const [generating, setGenerating] = useState(false);

    const [validated, setValidated] = useState(false);
    const onSubmit = async (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true);
            return;
        }

        setGenerating(true);
        const url = await createFullPacketUrl(id, measure, county, pages, fullTextFile, false);
        console.log("Clicking button; log and do actions here.");
        
        //log the packet we've created
        setPackets([...packets, {
            county: county,
            pages: pages,
            url: url,
            timestamp: new Date(Date.now()) //will be Timestamp.now() or use the associated firebase thing later
        }]);

        setGenerating(false);
        //redirect to open window
        const pdfWindow = window.open(url, '_blank');
        if (pdfWindow) pdfWindow.focus();
    }

//------------------Proponent provided stuff----------------
//instructions (draft-js), contact information
//const [newInstructions, setNewInstructions] = useState(undefined);

//------------------Measure info--------------------------
//estimated signatures gathered, circulator count, 

//------------------Circulator's Personal Info------------
//circulator will have their own personal document in the circulators
//subcollection of the measure. The information we will display to them
//will be: the packets they are circulating (could be more than one),
//with options to 1) remove them or 2) mark them as mailed
//Whether each packet is up to date based on timestamp comparison
//with full text and key fields of measure

//-------------------------------Return-----------------------------------

    const [copiedText, setCopiedText] = useState("");

    if (!measure || userContext === "Not loaded" || !userAccess || !fullTextFile || !id) return (<div style={{marginTop: '5em', textAlign: 'center'}}><h4>Loading...</h4></div>);
    if (measure === "Does not exist." || fullTextFile === "No such file.") return (<div style={{marginTop: '5em', textAlign: 'center'}}><h4>Sample petition generation not set up yet.</h4></div>)
    if (measure.rBallotStatus !== "volunteering" && id !== 'example') return (
        /*<div style={{marginTop: '5em', textAlign: 'center'}}>
            <h3>Measure is not set up for volunteering.</h3>
        </div>*/
        <Container className="CampaignContainer">
            <br />
            <br />
            <br />
            <div>
                <h1 style={{textAlign: 'center', margin: '0.5em'}}>
                    {measure.BallotpediaTitle}
                </h1>
                <p style={{textAlign: 'center', marginBottom: '1em'}}>
                    {measure.JurisdictionName} Measure {id}
                </p>
                <p className="MeasureInfo">
                    Official Title: {measure.Title}
                </p>
                {/*<p className="MeasureInfo">
                    Deadline: {measure.Deadline.toDate().toDateString()}
                </p>*/}
            </div>
            <br />
            {/** Means of looking at pdfs of petition in question */}
            <Form
                noValidate
                validated={validated}
                style={{
                    border: '1px solid black',
                    padding: '1em 2em 0em',
                    marginBottom: '2em',
                    borderRadius: '3px'
                }}
                onSubmit = {(e) => onSubmit(e)}
            >
                <Row>
                    <h2 style={{textAlign: 'center'}}>
                        Circulator Sample
                    </h2>
                    <Col className="PacketFormSelect">
                        <Form.Group>
                        <Form.Label>Select a county</Form.Label>
                        <Form.Select
                            required
                            value={county}
                            onChange={(e) => { setCounty(e.target.value); }}
                        >
                            <option key={"default"} value={""}>Select county</option>
                            {countiesByState["California"].map((county) => 
                                <option key={county}>{county}</option>)}
                        </Form.Select>
                        <Form.Text>This is the county where you will collect signatures.</Form.Text>
                        <Form.Control.Feedback type="invalid">
                            Choose the county that you will circulate the measure in.
                        </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col className="PacketFormSelect">
                        <Form.Group>
                        <Form.Label>Signature Pages</Form.Label>
                        {/*<Form.Control
                            required
                            value={pages}
                            onChange={(e) => setPages(e.target.value)}
                        >
                        </Form.Control>
                        <Form.Text>
                            More signatures means less paper for the campaign, but more time for you.
                        </Form.Text>*/}
                        <Form.Select
                            required
                            value={pages}
                            //just get the first character of string, which ought to be the page digit... right?
                            onChange={(e) => setPages(e.target.value[0])}
                        >
                            { !measure ? "" :
                            [1, 2, 3, 4, 5, 6, 7, 8, 9].map((pages) =>
                                <option key={pages} value={pages}>
                                    {pages} page{pages === 1 ? "" : "s"} - {getSignatureCount(pages, measure.SignaturesPerSheet)} signatures
                                </option>)
                            }
                        </Form.Select>
                        <Form.Text>
                            More signatures per packet means less paper for the campaign, but more effort for you.
                        </Form.Text>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    {generating ? <Loading /> :
                    <Button 
                        type="submit"
                        className="GetPacketButton"
                    >
                        Get new packet
                    </Button>}
                </Row>
                <Row>
                    <ListGroup
                        style={{
                            margin: '0em 0em 1em',
                            textAlign: 'center'
                        }}
                    >
                        {packets.map((packet) => 
                            <ListGroup.Item key={packet.timestamp}>
                                <Link to={packet.url} key={packet.timestamp} target="_blank">
                                    {packet.timestamp.toLocaleString()}: {packet.county} - {getSignatureCount(packet.pages, measure.SignaturesPerSheet)} signatures
                                </Link>
                            </ListGroup.Item>
                        )}
                    </ListGroup>
                </Row>
            </Form>
            <div style={{textAlign: 'center', marginTop: '0.5em', marginBottom: '1em'}}>
                <Link 
                    to={userContext ?
                        "/ProponentVerification/" + id + "/" + measure.JurisdictionName
                        :
                        "/EmailSignUp/ProponentVerification/" + id + "/" + measure.JurisdictionName
                    }
                >
                    Are you the proponent of this measure?
                </Link>
            </div>

            <p className="LegalDisclaimer" style={{marginTop: '7em'}}>
                All information is provided "as is" with no guarantee of correctness or fitness for a particular purpose.
                Petition sections generated by this tool are samples and have not been evaluated by the Proponent.
                Your use of the Circulator tool provided as a sample is subject to the {" "}
                <Link to="/terms" target="_blank">
                     Terms and Conditions and Privacy Policy.
                </Link>
            </p>
        </Container>
    );
    //if user is not verified, don't show it
    if (userContext && !userContext.emailVerified && id !== 'example') return (
        <div style={{marginTop: '5em', textAlign: 'center'}}>
            <UnverifiedEmail />
        </div>
    );
    //if they're a guest, they shouldn't be able to see it
    if (userAccess === "Guest" && id !== 'example') {
        return (
            <div style={{marginTop: '5em', textAlign: 'center'}}>
                <h4>You must be affiliated with the petition drive to view this page.</h4>
            </div>
        );
    }

    return (
        <Container className="CampaignContainer">
            <br />
            <br />
            <br />
            <div>
                {/*<p>{console.log(userAccess)}</p>*/}

                { !measure || !fullTextFile || !id ? "" :
                    <div>
                        <h1 style={{textAlign: 'center', margin: '0.5em'}}>
                            {measure.rBallotTitle}
                        </h1>
                        <p style={{textAlign: 'center', marginBottom: '1em'}}>
                            {measure.JurisdictionName} Measure {id}
                        </p>
                        <p className="MeasureInfo">
                            Official Title: {measure.Title}
                        </p>
                        <p className="MeasureInfo">
                            Deadline: {measure.Deadline.toDate().toDateString()}
                        </p>
                    </div>
                }

                {/** Display a link to edit measure page for proponents */
                !measure || !fullTextFile || !id || !userAccess ? "" :
                    userAccess.indexOf('Proponent') === -1 ? "" :
                        <div className="ProponentControlsBox">
                            <h4 style={{textAlign: 'center'}}>Proponent Actions</h4>
                            <hr style={{marginBottom: '0em'}}/>
                            <div className="ProponentControls">
                                <Link 
                                    to={"/measure/" + id + "/edit"}
                                    className="EditMeasureLink"
                                >
                                    Edit Measure
                                </Link>
                                <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center', paddingLeft: '1em'}}>
                                    <Button
                                        className="GetPacketButton"
                                        onClick={() => {
                                            navigator.clipboard.writeText("www.rballot.org/emailsignup/circulatorverification/" + id + "/" + measure.JurisdictionName);
                                            setCopiedText("Copied link!");
                                        }}
                                    >
                                        Copy volunteering link
                                    </Button>
                                    {copiedText}
                                </div>
                            </div>
                        </div>
                }

                {/** Means of looking at pdfs of petition in question */}
                { /** Display the packet setup when the measure is loaded */
                !measure || !fullTextFile || !id ?
                    <div style={{marginTop: '5em', textAlign: 'center'}}><h4>Loading...</h4></div>
                    :
                    <Form
                        noValidate
                        validated={validated}
                        style={{
                            border: '1px solid black',
                            padding: '1em 2em 0em',
                            marginBottom: '2em',
                            borderRadius: '3px'
                        }}
                        onSubmit = {(e) => onSubmit(e)}
                    >
                        <Row>
                            <h2 style={{textAlign: 'center'}}>
                                1. Print
                            </h2>
                            <Col className="PacketFormSelect">
                                <Form.Group>
                                <Form.Label>Select a county</Form.Label>
                                <Form.Select
                                    required
                                    value={county}
                                    onChange={(e) => { setCounty(e.target.value); }}
                                >
                                    <option key={"default"} value={""}>Select county</option>
                                    {countiesByState["California"].map((county) => 
                                        <option key={county}>{county}</option>)}
                                </Form.Select>
                                <Form.Text>This is the county where you will collect signatures.</Form.Text>
                                <Form.Control.Feedback type="invalid">
                                    Choose the county that you will circulate the measure in.
                                </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col className="PacketFormSelect">
                                <Form.Group>
                                <Form.Label>Signature Pages</Form.Label>
                                {/*<Form.Control
                                    required
                                    value={pages}
                                    onChange={(e) => setPages(e.target.value)}
                                >
                                </Form.Control>
                                <Form.Text>
                                    More signatures means less paper for the campaign, but more time for you.
                                </Form.Text>*/}
                                <Form.Select
                                    required
                                    value={pages}
                                    //just get the first character of string, which ought to be the page digit... right?
                                    onChange={(e) => setPages(e.target.value[0])}
                                >
                                    { !measure ? "" :
                                    [1, 2, 3, 4, 5, 6, 7, 8, 9].map((pages) =>
                                        <option key={pages} value={pages}>
                                            {pages} page{pages === 1 ? "" : "s"} - {getSignatureCount(pages, measure.SignaturesPerSheet)} signatures
                                        </option>)
                                    }
                                </Form.Select>
                                <Form.Text>
                                    More signatures per packet means less paper for the campaign, but more effort for you.
                                </Form.Text>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Button 
                                type="submit"
                                className="GetPacketButton"
                            >
                                Get new packet
                            </Button>
                        </Row>
                        <Row>
                            <ListGroup
                                style={{
                                    margin: '0em 0em 1em',
                                    textAlign: 'center'
                                }}
                            >
                                {packets.map((packet) => 
                                    <ListGroup.Item key={packet.timestamp}>
                                        <Link to={packet.url} key={packet.timestamp} target="_blank">
                                            {packet.timestamp.toLocaleString()}: {packet.county} - {getSignatureCount(packet.pages, measure.SignaturesPerSheet)} signatures
                                        </Link>
                                    </ListGroup.Item>
                                )}
                            </ListGroup>
                        </Row>
                    </Form>
                }

                { /** Description of how to gather signatures */}
                {
                    !measure || !fullTextFile || !id ? "" : (
                        <div style={{textAlign: 'justify', margin: '2.5em 0em', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <h2 className="Step">2. Gather</h2>
                            <p>Now you're ready to gather signatures from registered voters in your community! To make sure your signatures count, follow these rules:</p>
                            <ul style={{listStylePosition: 'inside'}}>
                                <li>Blue or black ink pens only.</li>
                                <li>Only people registered to vote in the county specified on the first page may sign.</li>
                                <li>
                                    Preserve the privacy of people who sign. Do not allow anyone to copy the signatures people give you down or photograph them. The signatures should only be used to qualify this measure for the ballot.
                                    
                                </li>
                                <li>Complete the 'Declaration of Circulator' on page {pages} after gathering all signatures. You are the Circulator of this packet (legally known as a "petition section").</li>
                            </ul>
                            
                            { !measure.AdditionalInstructionsWebsite && !measure.PhoneNumber && !measure.Email ? "" :
                                <div className="CampaignInstructionsBox">
                                    <p>The Campaign behind this measure has provided additional information: </p>
                                    <ul>
                                    { !measure.AdditionalInstructionsWebsite ? "" :
                                        <li>
                                            {"Additional instructions at the following webpage: "}
                                            <br />
                                            <a target="_blank" rel="noreferrer" href={measure.AdditionalInstructionsWebsite}>
                                                {measure.AdditionalInstructionsWebsite}
                                            </a>
                                        </li>
                                    }
                                    { !measure.PhoneNumber ? "" :
                                        <li>{"Campaign Phone Number: " + measure.PhoneNumber}</li>
                                    }
                                    { !measure.Email ? "" :
                                        <li>{"Campaign Email: " + measure.Email}</li>
                                    }
                                    </ul>
                                </div>
                            }
                            {/*<p>
                                California requires paid signature gatherers to certify
                                that they "will not knowingly or willfully allow the signatures for this initiative
                                to be used for any purpose other than qualification of the measure for the ballot."
                            </p>
                            <p>
                                You are a <strong>volunteer</strong> gatherer, so you do not need to sign anything, but you
                                <strong> are</strong> still responsible for preventing misuse of these signatures. This includes
                                not allowing pictures to be taken that contain information provided by signers.
                            </p>*/}
                        </div>
                    )
                }

                { /** Instructions from proponent */ /*
                    !measure || !fullTextFile || !id || !userAccess ? "" : (
                        userAccess.indexOf("Proponent") !== -1 ?
                            <div>
                                <RichDraftEditor 
                                    contentState={measure.Instructions} 
                                    setContentState={setNewInstructions}
                                />
                                <Button
                                    onClick={() => {
                                        if (newInstructions) writeDocument(
                                            ['petitions', id], {Instructions: newInstructions}, true
                                        );
                                    }}
                                >
                                    Save
                                </Button>
                            </div>
                            :
                            <ReadOnlyDraftEditor contentState={measure.Instructions} />
                            )*/
                }

                { /** And the mailing address */
                    !measure || !measure.MailingAddress|| !fullTextFile || !id ? "" : (
                        <div className="Section">
                            <h2 className="Step">3. Mail</h2>
                            <h5 className="Step">Mail completed packet to:</h5>
                            <div className="Address">
                                <p>{
                                    (county ? county : "County name**") +
                                    (pages ? 
                                        (": " + getSignatureCount(pages, measure.SignaturesPerSheet)+"* signatures")
                                    :
                                        ": # of signatures you collected")
                                }</p>
                                <p>{measure.MailingAddress['0']['address1']}</p>
                                {measure.MailingAddress['0']['address2'] === "" ? "" :
                                <p>{measure.MailingAddress['0']['address2']}</p>
                                }
                                <p>
                                {measure.MailingAddress['0']['city']}, 
                                {" " + measure.MailingAddress['0']['state']},
                                {" " + measure.MailingAddress['0']['zip']}
                                </p>
                            </div>
                            <br />
                            {county ? "" :
                                <p className="MeasureInfo" style={{fontStyle: 'italic'}}>
                                    **the name of the county you gathered the signatures in
                                </p>
                            }
                            <p 
                                className="MeasureInfo"
                                style={{fontStyle: "italic"}}
                            >
                                *or however many signatures people gave you, if not  
                                {" " + getSignatureCount(pages, measure.SignaturesPerSheet)}
                            </p>
                            <p 
                                className="MeasureInfo"
                                style={{fontStyle: "italic"}}
                            >
                                Remember to complete the declaration of circulator {pages ? " at the end of page " + pages : ""}!
                            </p>

                        </div>
                    )
                }

                {
                    !measure || !fullTextFile || !id || !userAccess ? "" :
                        //only show this if it is not a proponent; otherwise it goes up top
                        userAccess.indexOf('Proponent') !== -1 ? "" :
                            <div className="ProponentControlsBox">
                                <h4 style={{textAlign: 'center'}}>Actions</h4>
                                <hr style={{marginBottom: '0em'}} />
                                <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center', paddingLeft: '1em'}}>
                                        <Button
                                            className="GetPacketButton"
                                            onClick={() => {
                                                navigator.clipboard.writeText("www.rballot.org/emailsignup/circulatorverification/" + id + "/" + measure.JurisdictionName);
                                                setCopiedText("Copied link!");
                                            }}
                                        >
                                            Copy volunteering link
                                        </Button>
                                        {copiedText}
                                </div>
                            </div>
                }
            </div>
        </Container>
    )

}

export default OrganizationPage;