/* Copyright (C) 2023 Christian Miley - All Rights Reserved */

/**
 * ManagePetitionSection.js
 * This page will be used to update the status of a petition section
 * that a user has begun circulating (through any method)
 */

import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { updatePetitionInProgress, getTimestampFromJsonDate, getFileByPath } from '../../firebase.js';
import { Container, Form, Button } from 'react-bootstrap';
import { UserContext, PersonContext } from '../../Context.js';
import { capitalize, dateFromTimestamp } from '../Utility.js';
import './ManagePetitionSection.css';

function ManagePetitionSection(props) {
    const { petitionID } = useParams();
    const navigate = useNavigate();
    //const { measureID, sectionID } = useParams();
    const userContext = useContext(UserContext);
    const personContext = useContext(PersonContext);

    //the petition we are working with
    const [petition, setPetition] = useState(undefined);
    //custom validation
    const [validated, setValidated] = useState(false);

    const [mailedIn, setMailedIn] = useState(true);
    const [sigCount, setSigCount] = useState(1);
    const [date, setDate] = useState('');

    //get the details on the petition based on how it was "signed"
    useEffect(() => {
        if (!personContext || !petitionID || !personContext.petitionSections || !personContext.petitionSections[petitionID]) return;
        // if (!personContext || !measureID || !sectionID || !personContext.petitionSections || !personContext.petitionSections[measureID] || !personContext.petitionsSections[measureID][sectionID]) return;
        // jeez. With simpler one would be:
        // if (!personContext || !measureID || !sectionID || !personContext.petitionSections || !personContext.petitionSections[petitionID]) return;
        // setPetition(personContext.petitionSections[measureID][sectionID]);
        const petition = personContext.petitionSections[petitionID];
        
        //here, fill out the select form for signatures
        petition.selectSignaturesOptions = [];
        for (let i = 1; i<=petition.signatures; i++) {
            petition.selectSignaturesOptions.push(
                <option key={i}>{i}</option>
            );
        }

        petition.timestamp = personContext.petitionSections[petitionID][petition.status + '_timestamp'];
        setPetition(petition);
        //const signatureType = petition.status;

        /*if (signatureType === "requested") {
            if (!personContext.requestedObj || !personContext.requestedObj[id]) return;
            setPetition(personContext.requestedObj[id]);
        } else if (signatureType === "downloaded") {
            if (!personContext.downloadedObj || !personContext.downloadedObj[id]) return;
            setPetition(personContext.downloadedObj[id]);
        }*/
    }, [personContext, petitionID]);

    // to allow redownloading of file
    //const [file, setFile] = useState(undefined);
    useEffect(() => {
        if (!props || !props.propositionsObj || !petition || !petition.measure || !props.propositionsObj[petition.measure] || petition.status !== "downloaded" || !petition.download || !petition.download.id) return;

        //this async function will be used to set the file to its correct place (which is horrible, tbf)
        const getFile = async (path, name) => {
            const file = await getFileByPath(path, name);
            const newPropsObj = { ...props.propositionsObj };
            newPropsObj[petition.measure].CurrentPetitionDownloads[petition.download.id].File = file;
            props.setPropositionsObj(newPropsObj);
        }

        //jesus christ
        //if we already have the file listed, return
        if (props.propositionsObj[petition.measure].CurrentPetitionDownloads[petition.download.id].File) {
            return;
        //otherwise, time to grab and set it
        } else {
            const path = "/fullPetitions/" + petition.measure + "/" + petition.download.id;
            const name = petition.download.fileName || petition.measure;
            getFile(path, name);
        }

    }, [petition, props]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
           e.stopPropagation();
           setValidated(true);
           return;
        }

        const update = {
            status: "mailed_in",
            timestamp: getTimestampFromJsonDate(date),
            measure: petition.measure,
            signaturesGathered: sigCount,
            //adding Upline in to update 'others' signatures
            upline: personContext.Upline
        };
        console.log(update);

        try {
            //someday request type should maybe be 'mailing', or idk man it's just a bit whack since it doesn't match what's in 'services'
            await updatePetitionInProgress(petitionID, update, "mailing_requests");
        } catch (error) {
            console.error(error);
            return;
        }
    }

    return (
        !petition ? "" : 
            <Container style={{marginTop: '6em', maxWidth: '600px'}}>
                <div className="UserBackground"></div>
                <div style={{backgroundColor: 'white', padding: '1.5em 2em 1.5em', borderRadius: '10px', boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.2)', border: '1px solid black'}}>
                    <div style={{textAlign: 'center', marginBottom: '2em'}}>
                        <h3 style={{margin: '0em 0em 0.25em'}}>
                            Manage Petition for {petition.measure}
                        </h3>
                        <p style={{fontSize: '0.8em', color: 'gray'}}>
                            Petition ID: {petitionID}
                        </p>
                        <Button 
                            onClick={() => navigate('/user/' + userContext.uid)}
                        >
                            Back to my page
                        </Button>
                    </div>
                    <p style={{marginBottom: '0.25em'}}>
                        {capitalize(petition.status).replace('_', ' ')} on: {dateFromTimestamp(petition[petition.status + '_timestamp'])}
                    </p>
                    { petition.status !== "downloaded" || !props.propositionsObj || !props.propositionsObj[petition.measure] || !props.propositionsObj[petition.measure].CurrentPetitionDownloads || !props.propositionsObj[petition.measure].CurrentPetitionDownloads[petition.download.id].File ?
                        ""
                        :
                        <a 
                            // this causes issues on phone!
                            target="_blank"
                            rel="noreferrer"
                            href={URL.createObjectURL(props.propositionsObj[petition.measure].CurrentPetitionDownloads[petition.download.id].File)}
                            download={"rBallot" + props.propositionsObj[petition.measure].CurrentPetitionDownloads[petition.download.id].File.name}
                        >
                            Download again
                        </a>
                    }
                    <hr />
                    { petition.status === "mailed_in" ? "You mailed this in!" :
                        <Form 
                            noValidate 
                            validated={validated} 
                            onSubmit={(e) => handleSubmit(e)}
                        >
                            <div className="MailedOnDate">
                                <Form.Check 
                                    required
                                    label="I mailed in this petition on"
                                    type="switch"
                                    style={{minWidth: '16em'}}
                                    value={mailedIn}
                                    checked={mailedIn}
                                    onChange={(e) => setMailedIn(e.target.checked)}
                                />
                                <Form.Control 
                                    type="date"
                                    required
                                    value={date}
                                    onChange={(e) => setDate(e.target.value)}
                                />
                            </div>
                            <div style={{display: 'flex', alignItems: 'baseline', justifyContent: 'flex-start'}}>
                                <span style={{minWidth: '8em', margin: '0em', padding: '0em'}}>Signature Count</span>
                                {/** Here have a select that reads the signatures possible on this petition section */}
                                <Form.Select 
                                    required
                                    value={sigCount}
                                    onChange={(e) => setSigCount(Number(e.target.value))}
                                >
                                    {petition.selectSignaturesOptions}
                                </Form.Select>
                            </div>
                            <Button style={{marginTop: '1em', width: '100%'}} type="submit">Log {sigCount} Signature{sigCount === 1 ?'':'s'}</Button>
                        </Form>
                    }     
                </div>
            </Container>
    )
}

export default ManagePetitionSection;