/** 
 * AddressSignUp.js
 * This component will provide the secondary sign up
 * process for rBallot, where users will input their
 * mailing address to be able to receive a packet in the mail.
 */

import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import '../Entry.css';
import { addUserAddress, getUsersPrivateInfo, logMailingRequest, createNewFirebaseUser } from '../../firebase.js';
import { allStates, countiesByState, stateToPostalAbbr } from '../Information.js';
import { UserContext, PersonContext } from '../../Context.js';

//might want to pass user info through props at some point
function AddressSignUp(props)
{
   const { id } = useParams();
   const navigate = useNavigate();

   const userContext = useContext(UserContext);
   const personContext = useContext(PersonContext);

   const [firstName, setFirstName] = useState("");
   const [lastName, setLastName] = useState("");
   const [address1, setAddress1] = useState("");
   const [address2, setAddress2] = useState("");
   const [city, setCity] = useState(personContext && personContext.City ? personContext.City : "");
   const [state, setState] = useState(personContext ? personContext.State : "");
   const [stateAbbr, setStateAbbr] = useState("");
   const [county, setCounty] = useState(personContext ? personContext.County : "");
   const [zipcode, setZipcode] = useState("");
   const [phone, setPhone] = useState("");
   const [email, setEmail] = useState("");
   const [isRegistered, setIsRegistered] = useState(false);
   const [isRegistrationAddress, setIsRegistrationAddress] = useState(true);
   const [shareMyInfo, setShareMyInfo] = useState(false);
   //main error message
   const [errorMessage, setErrorMessage] = useState("");
   //to hold the signatures possible for this request
   const [signatures, setSignatures] = useState(0);
   //and to hold the array for when people can pick what to receive
   const [possibleSignatures, setPossibleSignatures] = useState([]);

   const [usersPrivate, setUsersPrivate] = useState(undefined);
   //try to prepopulate the form with the users presubmitted info
   useEffect(() => {
      if (!userContext || userContext === "Not loaded") return;
      console.log(userContext.uid);

      const getAddressInfo = async () => {
         const info = await getUsersPrivateInfo();
         
         console.log(info);

         //if any part of address is present, there has already been a submission
         if (info && "Address1" in info) {
            setFirstName(info.FirstName);
            setLastName(info.LastName);
            setAddress1(info.Address1);
            setAddress2(info.Address2);
            setCity(info.City);
            setState(info.State);
            setCounty(info.County);
            setZipcode(info.Zipcode);
            setPhone(info.PhoneNumber ? info.PhoneNumber : "");
            setEmail(info.Email ? info.Email : "");
            setIsRegistered(info.IsRegistered || false);
            setIsRegistrationAddress(info.IsRegistrationAddress || true);
            setShareMyInfo(info.ShareMyInfo || false);
            setUsersPrivate(info);
         }
      }  
      getAddressInfo();

   }, [userContext]);

   //set state postal abbreviation
   useEffect(() => {
      if (!state) return;
      setStateAbbr(stateToPostalAbbr[state]);
   }, [state]);

   //set maximum signatures that will be uploaded
   useEffect(() => {
      if (!props || !props.propositionsObj || !props.propositionsObj[id] || 
         !props.propositionsObj[id].CurrentPetitionRequestableSignatures)
         return;
      const possibleSignatures = props.propositionsObj[id].CurrentPetitionRequestableSignatures;
      
      //take the array of possible signatures for use in a Form.Select,
      //and set the default value to the lowest one.
      setPossibleSignatures(possibleSignatures);
      if (possibleSignatures.length > 0) setSignatures(possibleSignatures[0]);
   }, [props, id])

   //function to add address info to existing user
   //by updating users and users_private
   async function SignUp()
   {
      //seems like we should get the info from the google api here

      console.log("Signing " + firstName + " up now.");

      const uploadAddress = {
         FirstName: firstName.trim(),
         LastName: lastName.trim(),
         Address1: address1.trim(),
         Address2: address2.trim(),
         City: city.trim(),
         State: state,
         County: county,
         Zipcode: zipcode.trim(),
         PhoneNumber: shareMyInfo ? phone.trim() : "", //only if we are sharing information should phone go out
         //Email: email, on second thought, not the email, since they could change it
         IsRegistered: isRegistered,
         IsRegistrationAddress: isRegistrationAddress,
         ShareMyInfo: shareMyInfo
      };

      if (userContext) { 
         //this awful if statement is to tell if the user is modifying any fields in their private info.
         //if so, it should be updated.
         if (!usersPrivate || usersPrivate.Address1 !== address1 || usersPrivate.Address2 !== address2 ||
            usersPrivate.City !== city || usersPrivate.State !== state || usersPrivate.Zipcode !== zipcode ||
            usersPrivate.FirstName !== firstName || usersPrivate.LastName !== lastName || 
            usersPrivate.IsRegistered !== isRegistered || usersPrivate.IsRegistrationAddress !== isRegistrationAddress ||
            usersPrivate.ShareMyInfo !== shareMyInfo) 
         {
            await addUserAddress(uploadAddress); 
         }
         //regardless of above, log the mailing request to the server.
         //and, add in the email now so we don't overwrite user's email, but do log it for campaign
         //if (shareMyInfo) { uploadAddress['Email'] = email.trim(); }
         //else { uploadAddress['Email'] = ""; }
         // they want the email to be required
         uploadAddress['Email'] = email.trim();
         uploadAddress['StateAbbr'] = stateAbbr;
         uploadAddress['Signatures'] = signatures;
         await logMailingRequest(id, uploadAddress);
      }
      // here, let's have them actually create an account all in one!
      // will need to add in nickname, state, county, city, email, password, terms.id, referredBy, redirectLink
      // at minimum, will need to ask for a nickname and that they check terms... or could just use their first name as nickname to start.
      // ReferredBy will be more annoying... before replicating all functionality though I could just make it so you can create an account
      // through this page, as otherwise I'll need to change it to just be a page that asks you to create an account, which is kind of annoying.
      // all this would require is checking the terms (which honestly are not super relevant) and a disclaimer that you are also creating an rBallot account.
      // for that: need a password, and checking the terms!
      // and: if they say that their mailing address is different than their registration address, need to grab the info there.
      // priority all in all: not that high.
      else { 
         setErrorMessage("Must be signed in to an account to add an address."); 
      }
   }

   //this will emulate the object that we will pass through a separate file
   const countyOptions = {};
   countyOptions["California"] = (
      <Form.Control required as="select" value={county} onChange={(e) => setCounty(e.target.value)}>
         <option key={"default"} value="">Select county</option>
         {countiesByState["California"].map((county) => <option key={county}>{county}</option>)}
      </Form.Control>
   );

   countyOptions["Oregon"] = (
      <Form.Control required as="select" value={county} onChange={(e) => setCounty(e.target.value)}>
         <option key={"default"} value="">Select county</option>
         {countiesByState["Oregon"].map((county) => <option key={county}>{county}</option>)}
      </Form.Control>
   );

   const [submitting, setSubmitting] = useState(false);
   const [validated, setValidated] = useState(false);

   return (
      <Container className="SignUpContainer" style={{padding: '2em', marginTop: '5em', marginBottom: '5em', backgroundColor: 'white', border: '1px solid black', borderRadius: '10px', boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.2)'}}>
         {/*<div className="Background"></div>*/
         <div className="Background2"></div>}

         {/*<h2 className="SignUpHeader">rBallot</h2>*/}
         <h3 style={{textAlign: 'center'}}>Mail me a petition</h3>

         <div style={{marginTop: '1em'}}>
            <p>
               You're almost ready to receive your petition for measure {id}!
               Please confirm your mailing address so that the campaign is able to mail you the necessary materials.
               {/*<a style={{marginLeft:'0.5em'}} href="https://voterstatus.sos.ca.gov/">https://voterstatus.sos.ca.gov/</a>*/}
            </p>
            {/*<p>If you have a mailing address different from your voter registration,
               we'll need that as well. We will use your information to verify
               your registration in your state's voter rolls, and to mail you your
               signature sheets for the ballot measures you've saved.
               By providing us with this information, you consent to receive mail
               correspondence from rBallot at your voter registration address or
            mailing address, if provided.
            </p>*/}
         <hr/>
         </div>

         <Form 
            noValidate
            validated={validated}
            onSubmit = {async (e) => { 
               e.preventDefault(); 
               const form = e.currentTarget;
               if (form.checkValidity() === false) {
                  e.stopPropagation();
                  setValidated(true);
                  return;
               } 
               setSubmitting(true);
               await SignUp()
                  .catch((error) => {
                     //console.error(error);
                     setErrorMessage(error.message);
                     setSubmitting(false);
                  });
               setSubmitting(false);
               //below should display signed measures...
               //navigate("/user/"+userContext.uid);
               //1/10/24: actually go straight to sharing page
               navigate("/measure/"+id+"/share");
            }}
         >
         <Row style={{textAlign: 'center', margin: '-0.5em'}} >
            <Form.Text>
               <span className="Required">*required</span>
            </Form.Text>
         </Row>

         <Row className="SignUpRow">
            <Form.Group className="SignUpRowItem" as = {Form.Col}>
               <Form.Label>First Name <span className="Required">*</span></Form.Label>
               <Form.Control required type = "text" placeholder = "Enter first name" name = "firstname" value={firstName} onChange={(e) => setFirstName(e.target.value)}/>
               <Form.Control.Feedback type="invalid">Input your first name.</Form.Control.Feedback>
            </Form.Group>

            <Form.Group  className="SignUpRowItem" as = {Form.Col} controlId="formBasicLastName">
               <Form.Label>Last Name <span className="Required">*</span></Form.Label>
               <Form.Control required type = "text" placeholder = "Enter last name" name="lastname" value={lastName} onChange={(e) => setLastName(e.target.value)}/>
               <Form.Control.Feedback type="invalid">Input your last name.</Form.Control.Feedback>
            </Form.Group>
         </Row>

         <Form.Group className="SignUpRow" controlId="formBasicAddress">
            <Form.Label>Address Line 1 <span className="Required">*</span></Form.Label>
            <Form.Control required type = "text" placeholder = "Enter main address" name="address1" value={address1} onChange={(e) => setAddress1(e.target.value)}/>
            <Form.Control.Feedback type="invalid">Input your mailing address.</Form.Control.Feedback>
         </Form.Group>

         <Form.Group className="SignUpRow" controlId="formBasicAddress2">
            <Form.Label>Address Line 2</Form.Label>
            <Form.Control type = "text" placeholder = "Enter second address" name="address2" value={address2} onChange={(e) => setAddress2(e.target.value)}/>
         </Form.Group>

         <Row className="SignUpRow">
            <Form.Group className="SignUpRowItem" as = {Form.Col} controlId="formBasicCity">
               <Form.Label>City <span className="Required">*</span></Form.Label>
               <Form.Control type = "text" placeholder = "Enter your city" name="city" value={city} onChange={(e) => setCity(e.target.value)}/>
               <Form.Control.Feedback type="invalid">Input your mailing city.</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="SignUpRowItem" as = {Form.Col} controlId="formBasicState">
               <Form.Label>State <span className="Required">*</span></Form.Label>
               <Form.Control required as="select" value={state} onChange={(e) => setState(e.target.value)}>
                  <option key="select">Choose state</option>
                  {allStates.map((state) => {
                     return (
                        <option key={stateToPostalAbbr[state]}>{state}</option>
                     )
                  })}
               </Form.Control>
               <Form.Control.Feedback type="invalid">Input your mailing State.</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="SignUpRowItem" as = {Form.Col} controlId="formBasicZipcode">
               <Form.Label>Zipcode <span className="Required">*</span></Form.Label>
               <Form.Control required type = "text" placeholder = "Enter zipcode" name="zipcode" value={zipcode} onChange={(e) => setZipcode(e.target.value)}/>
               <Form.Control.Feedback type="invalid">Input your mailing zipcode.</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="SignUpRowItem" as = {Form.Col} controlId="formBasicCounty">
               <Form.Label>County <span className="Required">*</span></Form.Label>
               {(state && state !== "Choose state" && state !== "") ?
                  state in countyOptions ? 
                     countyOptions[state]
                     :
                     <Form.Control type="text" required placeholder="Enter county" name="county" value={county} onChange={(e) => setCounty(e.target.value)}/>
                  : 
                  <Form.Control as="select" disabled>
                     <option>Pick a State first</option>
                  </Form.Control>
               }
               <Form.Control.Feedback type="invalid">Input your mailing County.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="SignUpRowItem" controlId="formBasicEmail">
                  <Form.Label>Email <span className="Required">*</span></Form.Label>
                  <Form.Control required type="text" placeholder="Enter email" name="email" value={email} onChange={(e) => setEmail(e.target.value)}/>
               </Form.Group>
         </Row>

         <Form.Group style={{textAlign: 'left', marginTop: '1em'}}>
            <Form.Check 
               label={"I am registered to vote in CA"}
               value={isRegistered}
               checked={isRegistered}
               onChange={(e) => setIsRegistered(e.target.checked)}
            />
            <Form.Check 
               label="My mailing address and voter registration address are the same"
               value={isRegistrationAddress}
               checked={isRegistrationAddress}
               onChange={(e) => setIsRegistrationAddress(e.target.checked)}
            />
            <Form.Check 
               label="Share my phone number with the campaign"
               value={shareMyInfo}
               checked={shareMyInfo}
               onChange={(e) => setShareMyInfo(e.target.checked)}
            />
         </Form.Group>


         { !shareMyInfo ? "" :
            <Col>
               <Form.Group className="SignUpRowItem" controlId="formBasicPhone">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control type="text" placeholder="Enter phone number" name="phone" value={phone} onChange={(e) => setPhone(e.target.value)}/>
               </Form.Group>
            </Col>
            
         }

         <Form.Text className="ErrorMessage">{errorMessage}</Form.Text>

         <Row style={{textAlign: 'center', margin: '0em 0.25em 0em'}}>
            <Button style={{marginBottom: '2em'}} className="SignUpSubmit" variant = 'primary' type = 'submit'>
                  Submit
            </Button>
            <p style={{textAlign: 'left', fontSize: '0.8em', color: 'gray'}}>
               By providing your information and clicking submit, you authorize rBallot to provide this information
               to the Proponent or Committee associated with this ballot measure petition drive ({id}), and agree to
               receive mail correspondence from the Proponent or Committee to the address you have provided. If you have
               provided a phone number or email, you agree to receive correspondence from the Proponent or Committee to any
               contact method you have provided. Messaging and data rates may apply.
            </p>
         </Row>

         </Form>

      </Container>

    )

}

export default AddressSignUp;
